import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  isAuthenticating: false,
  isAuthenticated: false,
  profile: {},
  roles: [],
};

const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    authStart(state, action) {
      state.isAuthenticating = true;
    },
    authSuccess(state, { payload: { authenticated, profile, roles } }) {
      state.isAuthenticating = false;
      state.isAuthenticated = authenticated || state.isAuthenticated;
      state.profile = profile || state.profile;
      state.roles = roles || state.roles;
    },
    authError(state, action) {
      state.isAuthenticating = false;
    },
  },
});

export const { name, actions, reducer } = authSlice;
export const { authStart, authSuccess, authError } = actions;
export default reducer;
