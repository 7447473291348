import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import ProjectIcon from 'components/ProjectIcon';
import PipelineIcon from 'components/PipelineIcon';
import { DashboardNumberWidget } from 'components/DashboardNumberWidget';
import AppsIcon from 'components/AppsIcon';
import UsersIcon from 'components/UsersIcon';
import ClusterAgentIcon from 'components/ClusterAgentIcon';
import useKubeResources from 'hooks/services/useKubeResources';
import LoaderSpinner from 'components/LoaderSpinner';
import useAppDirectorDetailCount from 'hooks/services/useAppDirectorDetailCounts';

const today = new Date().getTime() / 1000;

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  widgetsContainer: {
    alignContent: 'space-between',
  },
  widgetBox: {
    padding: '0.625em 0',
    marginTop: '20px',
    width: '100%',
    fill: '#0F5EF7',
  },
  widgetBoxPipeline: {
    padding: '0.625em 0',
    width: '100%',
    height: '495px',
  },
  widgetBoxKubeEstimatedBillingValue: {
    padding: '0.625em 0',
    width: '100%',
  },
  widgetBoxKube: {
    padding: '0.625em 0',
    width: '100%',
  },
  heading: {
    paddingBottom: '29px',
  },
  headingTitle: theme.appDirector.pageHeading,
  section: {
    paddingTop: '40px',
  },
  estimatedBillingValueHeader: {
    flex: 0,
  },
  estimatedBillingValueBody: {
    flex: 1,
    height: '100%',
    display: 'flex',
  },
  estimatedBillingValueContent: {
    margin: 'auto',
  },
  estimatedBillingValueLabel: {
    color: '#808CA3',
    margin: '0.5rem',
    display: 'block',
    textAlign: 'center',
  },
  estimatedBillingValue: {
    background: '#DFEAFF',
    color: '#0F5EF7',
    fontWeight: 'bold',
    fontSize: '2.5rem',
    lineHeight: '48px',
    padding: '10px',
    margin: 'auto',
    width: 'fit-content',
    borderRadius: '8px',
    '& sup': {
      fontSize: '1.25rem',
    },
  },
  billingValueKube: {
    background: '#DFEAFF',
    color: '#0F5EF7',
    fontWeight: 'bold',
    fontSize: '1.563rem',
    lineHeight: '34px',
    padding: '0.625em',
    width: 'fit-content',
    borderRadius: '8px',
  },
  billingValueKubeDiv: {
    paddingLeft: '0.625em',
  },
  billingLegendKube: {
    color: '#414D55',
    fontWeight: 'normal',
    fontSize: '0.813rem',
    lineHeight: '20px',
    padding: '10px',
    letterSpacing: '0.416667px',
  },
  estimatedBillingKubeLabel: {
    color: '#808CA3',
    padding: '0.625em 0 0.625em 0.625em',
    display: 'block',
  },
  zeroLabel: {
    color: '#808CA3',
    textAlign: 'center',
  },
  kubeResourcesBillingGrid: {
    height: '100%',
    paddingLeft: '0.3125em',
    '@media (min-width: 600px)': {
      height: '6.25em',
    },
    alignSelf: 'flex-start',
  },
  legendLabel: {
    margin: '1% 0',
  },
  legendLabelDiv: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  spinner: {
    width: '100%',
    height: '100%',
    margin: '10% 0',
  },
  root: {
    width: '100%',
    maxHeight: '9.5em',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    padding: '0px',
  },
  clusterTitle: {
    padding: '0.625em 0 0.625em 0.625em',
    fontWeight: 'bold',
  },
  clusterListGrid: {
    paddingTop: '10%',
    '@media (min-width: 1330px)': {
      paddingTop: '4%',
    },
    width: '100%',
  },
  showZeroCheckbox: {
    paddingBottom: '1%',
  },
  barGrid: {
    alignSelf: 'flex-end',
  },
  barDiv: {
    height: '22em',
  },
  resourcesBillingGrid: {
    '@media (min-width: 600px)': {
      height: '100px',
    },
  },
}));

// const EstimatedBillingValue = ({ classes, value }) => {
//   const [t] = useTranslation('dashboard');
//   if (!value) {
//     return (
//       <DashboardWidget title={t('dashboard.appDirBilling.title')}>
//         <Grid
//           container
//           sm={12}
//           justify="center"
//           alignItems="center"
//           className={classes.spinner}
//         >
//           <LoaderSpinner type="Oval" color="#00BFFF" height={60} width={60} />
//         </Grid>
//       </DashboardWidget>
//     );
//   }
//   return (
//     <DashboardWidget title={t('dashboard.appDirBilling.title')}>
//       <div className={classes.estimatedBillingValueBody}>
//         <div className={classes.estimatedBillingValueContent}>
//           <Typography
//             variant="inherit"
//             className={classes.estimatedBillingValueLabel}
//           >
//             {t('dashboard.appDirBilling.total')}
//           </Typography>
//           <div className={classes.estimatedBillingValue}>
//             <sup>$</sup>
//             <Typography variant="inherit">{value}</Typography>
//           </div>
//         </div>
//       </div>
//     </DashboardWidget>
//   );
// };

const Dashboard = () => {
  const [t] = useTranslation('dashboard');
  const classes = useStyles();
  const { data: kubeResources, isFetching, isSuccess } = useKubeResources();
  const { data: counts } = useAppDirectorDetailCount();

  const date = Math.floor(today).toString();

  return (
    <Grid container classes={{ container: classes.gridContainer }}>
      <Grid item className={classes.heading}>
        <div>
          <div className={classes.headingTitle}>{t('dashboard.title')}</div>
        </div>
      </Grid>
      <Grid
        item
        container
        spacing={2}
        classes={{ container: classes.widgetsContainer }}
      >
        <Grid item sm={4} classes={{ item: classes.widgetBox }}>
          <DashboardNumberWidget
            number={
              counts ? (
                counts?.applications ? (
                  counts.applications
                ) : (
                  '0'
                )
              ) : (
                <Grid container sm={12}>
                  <LoaderSpinner
                    type="Oval"
                    color="#00BFFF"
                    height={30}
                    width={30}
                  />
                </Grid>
              )
            }
            title={t('dashboard.nousers')}
            icon={<UsersIcon />}
          />
        </Grid>
        <Grid item sm={4} classes={{ item: classes.widgetBox }}>
          <DashboardNumberWidget
            number={
              counts ? (
                counts?.applications ? (
                  counts.applications
                ) : (
                  '0'
                )
              ) : (
                <Grid container sm={12}>
                  <LoaderSpinner
                    type="Oval"
                    color="#00BFFF"
                    height={30}
                    width={30}
                  />
                </Grid>
              )
            }
            title={t('dashboard.nouser')}
            icon={<AppsIcon />}
          />
        </Grid>
        <Grid item sm={4} classes={{ item: classes.widgetBox }}>
          <DashboardNumberWidget
            number={
              counts ? (
                counts?.applications ? (
                  counts.applications
                ) : (
                  '0'
                )
              ) : (
                <Grid container sm={12}>
                  <LoaderSpinner
                    type="Oval"
                    color="#00BFFF"
                    height={30}
                    width={30}
                  />
                </Grid>
              )
            }
            title={t('dashboard.noapplication')}
            icon={<AppsIcon />}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        spacing={2}
        classes={{ container: classes.widgetsContainer }}
      >
        <Grid item sm={3} md={2.4} classes={{ item: classes.widgetBox }}>
          <DashboardNumberWidget
            number={
              counts ? (
                counts?.applications ? (
                  counts.applications
                ) : (
                  '0'
                )
              ) : (
                <Grid container sm={12}>
                  <LoaderSpinner
                    type="Oval"
                    color="#00BFFF"
                    height={30}
                    width={30}
                  />
                </Grid>
              )
            }
            title={t('dashboard.deployment')}
            icon={<ProjectIcon />}
          />
        </Grid>
        <Grid item sm={2} classes={{ item: classes.widgetBox }}>
          <DashboardNumberWidget
            number={
              counts ? (
                counts?.applications ? (
                  counts.applications
                ) : (
                  '0'
                )
              ) : (
                <Grid container sm={12}>
                  <LoaderSpinner
                    type="Oval"
                    color="#00BFFF"
                    height={30}
                    width={30}
                  />
                </Grid>
              )
            }
            title={t('dashboard.deployfreq')}
            icon={<AppsIcon />}
          />
        </Grid>
        <Grid item sm={2} classes={{ item: classes.widgetBox }}>
          <DashboardNumberWidget
            number={
              counts ? (
                counts?.applications ? (
                  counts.applications
                ) : (
                  '0'
                )
              ) : (
                <Grid container sm={12}>
                  <LoaderSpinner
                    type="Oval"
                    color="#00BFFF"
                    height={30}
                    width={30}
                  />
                </Grid>
              )
            }
            title={t('dashboard.noenv')}
            icon={<AppsIcon />}
          />
        </Grid>
        <Grid item sm={2} classes={{ item: classes.widgetBox }}>
          <DashboardNumberWidget
            number={
              counts ? (
                counts?.applications ? (
                  counts.applications
                ) : (
                  '0'
                )
              ) : (
                <Grid container sm={12}>
                  <LoaderSpinner
                    type="Oval"
                    color="#00BFFF"
                    height={30}
                    width={30}
                  />
                </Grid>
              )
            }
            title={t('dashboard.noclusters')}
            icon={<AppsIcon />}
          />
        </Grid>
        <Grid item sm={3} md={2.4} classes={{ item: classes.widgetBox }}>
          <DashboardNumberWidget
            number={
              counts ? (
                counts?.applications ? (
                  counts.applications
                ) : (
                  '0'
                )
              ) : (
                <Grid container sm={12}>
                  <LoaderSpinner
                    type="Oval"
                    color="#00BFFF"
                    height={30}
                    width={30}
                  />
                </Grid>
              )
            }
            title={t('dashboard.agent')}
            icon={<ClusterAgentIcon />}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        spacing={2}
        classes={{ container: classes.widgetsContainer }}
      >
        <Grid item sm={3} classes={{ item: classes.widgetBox }}>
          <DashboardNumberWidget
            number={
              counts ? (
                counts?.applications ? (
                  counts.applications
                ) : (
                  '0'
                )
              ) : (
                <Grid container sm={12}>
                  <LoaderSpinner
                    type="Oval"
                    color="#00BFFF"
                    height={30}
                    width={30}
                  />
                </Grid>
              )
            }
            title={t('dashboard.Totalpipeline')}
            icon={<PipelineIcon />}
          />
        </Grid>
        <Grid item sm={3} classes={{ item: classes.widgetBox }}>
          <DashboardNumberWidget
            number={
              counts ? (
                counts?.applications ? (
                  counts.applications
                ) : (
                  '0'
                )
              ) : (
                <Grid container sm={12}>
                  <LoaderSpinner
                    type="Oval"
                    color="#00BFFF"
                    height={30}
                    width={30}
                  />
                </Grid>
              )
            }
            title={t('dashboard.pipelinefreq')}
            icon={<AppsIcon />}
          />
        </Grid>
        <Grid item sm={3} classes={{ item: classes.widgetBox }}>
          <DashboardNumberWidget
            number={
              counts ? (
                counts?.applications ? (
                  counts.applications
                ) : (
                  '0'
                )
              ) : (
                <Grid container sm={12}>
                  <LoaderSpinner
                    type="Oval"
                    color="#00BFFF"
                    height={30}
                    width={30}
                  />
                </Grid>
              )
            }
            title={t('dashboard.Webhookfreq')}
            icon={<AppsIcon />}
          />
        </Grid>
        <Grid item sm={3} classes={{ item: classes.widgetBox }}>
          <DashboardNumberWidget
            number={
              counts ? (
                counts?.applications ? (
                  counts.applications
                ) : (
                  '0'
                )
              ) : (
                <Grid container sm={12}>
                  <LoaderSpinner
                    type="Oval"
                    color="#00BFFF"
                    height={30}
                    width={30}
                  />
                </Grid>
              )
            }
            title={t('dashboard.Webhook')}
            icon={<AppsIcon />}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
