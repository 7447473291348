import { useInfiniteQuery } from 'react-query';

import api from 'services/Api';
import { ACCOUNT_STATUS } from '../../constants';

const useUsers = ({ organizationId, userStatus }) => {
  const fetchUsers = async (nextPage = 0) => {
    const query = new URLSearchParams({
      orgId: organizationId,
    });
    if (userStatus && userStatus !== ACCOUNT_STATUS.ALL) {
      query.append('states', userStatus);
    }

    try {
      const users = await api.get(`core/iam/v1/users/?${query.toString()}`);

      const data = await users.json();

      const user = data.data.users.map((usr) => {
        return usr;
      });

      return user;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useInfiniteQuery(['Users', organizationId, userStatus], fetchUsers, {
    refetchOnWindowFocus: false,
    //getFetchMore: (lastGroup) => lastGroup.meta.nextPage,
  });
};

export default useUsers;
