import React, { useState, useEffect } from 'react';
import EscapeOutside from 'react-escape-outside';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import Grid from '@material-ui/core/Grid';
import { Divider, makeStyles, useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Tooltip from '@material-ui/core/Tooltip';
import Drawer from '@material-ui/core/Drawer';
import TextField from '@mui/material/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import PriorityChip from 'components/PriorityChip';
import StatusText from 'components/StatusText';
import CreateDate from 'components/CreateDateText';
import SearchBox from 'components/SearchBox';
import CustomFilter from 'components/CustomFilter';
import LoaderSpinner from 'components/LoaderSpinner';
import DataTable from 'components/ReactTable/tableInfiniteScroll';
import CloseButton from 'components/CloseButton';
import CustomInput from 'components/CustomInput';
import LoadingButton from 'components/LoadingButton';
import { selectOrganization } from 'state/auth/selectors';
import useAssignTicket from 'hooks/services/useAssignTicket';
import useTickets from 'hooks/services/useTickets';
import useEntitlements from 'hooks/services/useEntitlements';
import useCustomers from 'hooks/services/useCustomers';
import useFilterAssignedTickets from 'hooks/services/useFilterAssignedTickets';
import useAllUsers from 'hooks/services/useAllUsers';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import '../../index.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import useUsers from 'hooks/services/useUsers';
import { convert, getCurrentDateAsString } from '../../utils/index';
const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('xs', 'sm')]: {
      height: '300px',
      overflowX: 'auto',
    },
  },
  sectionTitle: {
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '25px',
    lineHeight: '34px',
    [theme.breakpoints.down('xs', 'sm')]: {
      fontSize: '18px',
    },
  },
  btnRoot: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    backgroundColor: 'transparent !important',
    textTransform: 'none',
    color: '#808CA3',
    alignItems: 'center',
    padding: 0,
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    minWidth: '50px',
    height: '26px',
  },
  titleGrid: {
    paddingTop: '20px',
    paddingLeft: '10px',
    paddingBottom: '10px',
  },
  filters: {
    padding: '10px 0px 10px 0px',
  },
  searchBox: {
    flex: 1,
    marginRight: '40px',
  },
  selectBox: {
    flex: 1,
  },
  spinner: {
    width: '100%',
    height: '100%',
    marginTop: '10%',
  },
  overview: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
    color: '#001847',
  },
  subTopic: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#808CA3',
    padding: '20px 0px 10px 0px',
  },
  topic: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#001847',
    padding: '20px 0px 14px 0px',
  },
  buttonSave: {
    marginTop: '7px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
  },
  textField: {
    marginRight: '30px',
    paddingLeft: '20px',
    width: 250,
    height: 40,
    background: '#EFF3FA',
    borderRadius: '5px',
    border: '0px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    [theme.breakpoints.down('sm', 'xs')]: {
      marginLeft: 0,
    },
  },
  buttonField: {
    marginTop: '50px',
    textAlign: 'right',
  },
  errorTextField: {
    borderColor: 'red !important',
  },
  invalid: {
    color: 'red',
    fontSize: '0.75rem',
    marginTop: '6px',
    marginBottom: 0,
  },
  checkboxLabel: {
    fontSize: '14px',
    fontWeight: 'bold',
    fontFamily: 'Nunito',
  },
  checkBox: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '0px',
    [theme.breakpoints.down('sm', 'xs')]: {
      flexDirection: 'row',
      paddingLeft: '20px',
      marginTop: '0px',
    },
  },
  buttonSpan: {
    width: '0px',
    height: '0px',
  },
  comment: {
    paddingLeft: '10px',
  },
  commentsContainer: {
    paddingTop: '16px',
  },
  mobileFilters: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '5px 16px',
    },
  },
  mobileSearchBox: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '10px',
      marginRight: '0',
    },
  },
  truncate: {
    maxHeight: '1.5em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const AllTickets = ({
  flattenedTickets,
  setFlattenedTickets,
  tickets,
  flow,
  tableHeightPercentage,
}) => {
  const { path } = useRouteMatch();
  const [state, setState] = useState();
  const [rowData, setRowData] = useState();
  const [miniUiData, setMiniUiData] = useState();
  const [error, setError] = useState();
  const [assignTickets, setAssignTickets] = useState(false);
  const [filter, setFilter] = useState({
    status: 'NOT CLOSED',
    userPriority: 'All',
  });
  const [assigneeInfo, setAssigneeInfo] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [isUpdating, setIsUpdating] = useState(false);
  const [usersArr, setUsersArr] = useState([]);
  const classes = useStyles();
  const history = useHistory();
  const matches = useMediaQuery('(max-width:1330px)');
  const organizationId = useSelector(selectOrganization);
  const { data: filterAssignTickets } = useFilterAssignedTickets();
  const { data: users } = useAllUsers({
    organizationId,
    states: 'ACTIVE',
  });
  const {
    data: user,
    isFetching,
    isError: isErrorUsers,
    isFetchingNextPage: isFetchingMoreUsers,
    hasNextPage: canFetchMoreUsers,
    fetchNextPage: fetchMoreUsers,
  } = useUsers({
    userStatus: 'All',
  });
  const flattenedusers =
    user &&
    user.pages.reduce((accum, curr) => {
      return [...accum, ...curr];
    }, []);

  const usersWithNameGroupType =
    flattenedusers &&
    flattenedusers.map((user) => {
      const namedUser = {
        id: user.id,
        name: user.firstName + ' ' + user.lastName,
        group: user.groups[0].name,
        type: user.groups[0].type,
        organizationId: user.organizationId,
        email: user.email,
        state: user.state,
      };
      return namedUser;
    }, []);

  const userEmails =
    usersWithNameGroupType &&
    usersWithNameGroupType
      .filter((user) => user.type === 'INTERNAL')
      .map((user) => user.email);

  const {
    data: allTickets,
    isFetchingNextPage: isFetchingMoreTickets,
    hasNextPage: canFetchMoreTickets,
    fetchNextPage: fetchMoreTickets,
  } = useTickets();
  const [open_box, setOpen] = React.useState();
  const flattenedUsers =
    users &&
    users.pages.reduce((accum, curr) => {
      return [...accum, ...curr];
    }, []);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const defaultDate = '2022-01-01';

  let start = convert(startDate, defaultDate);
  let end = convert(endDate, defaultDate);

  if (end === defaultDate) {
    end = getCurrentDateAsString();
  }

  useEffect(() => {
    if (users) {
      setUsersArr(flattenedUsers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);
  const setEnqueueSnackbar = (msg, snackerVariant) => {
    enqueueSnackbar(msg, {
      variant: snackerVariant,
      autoHideDuration: 3000,
    });
  };

  const reducedflattenedTickets =
    allTickets &&
    allTickets.pages.reduce((accum, curr) => {
      return [...accum, ...curr.tickets];
    }, []);

  const assignedTickets =
    filterAssignTickets &&
    filterAssignTickets.pages.reduce((accum, curr) => {
      return [...accum, ...curr.tickets];
    }, []);

  const handleRowClick = (id, { original: miniUiData }) => {
    history.push({
      state: {
        ticketData: miniUiData,
        ticketId: id,
      },
      pathname: `${path}/${miniUiData?.entitlementId}/${miniUiData?.ticketId}`,
    });
  };

  const setDisableStatus = () => {
    if (isUpdating) {
      return true;
    } else {
      return false;
    }
  };

  const handleClose = () => {
    setState(false);
  };

  const handleAssignTickets = (e) => {
    setAssignTickets(e.target.checked);
  };

  const handlePriorityChange = (event) => {
    setFilter({ ...filter, userPriority: event.target.value });
  };

  const handleStatusChange = (event) => {
    setFilter({ ...filter, status: event.target.value });
  };

  const handleChange = (value) => {
    setAssigneeInfo({ internalAssignee: value });
  };

  const { mutateAsync: assigneeCreater, isError } = useAssignTicket({
    entitlementId: miniUiData?.entitlementId,
    ticketId: miniUiData?.ticketId,
  });

  useEffect(() => {
    if (assignTickets) {
      if (flow === 'Tickets') {
        const assignedTicketsbyCustomer = tickets.filter((x) => x.isAssigned);
        setFlattenedTickets(assignedTicketsbyCustomer);
      } else if (flow === 'Entitlements') {
        const assignedTicketsbyEntitlement = tickets.filter(
          (item) => item.isassignee
        );
        setFlattenedTickets(assignedTicketsbyEntitlement);
      } else {
        setFlattenedTickets(assignTickets);
      }
    } else {
      if (flow === 'Tickets') {
        setFlattenedTickets(tickets);
      } else if (flow === 'Entitlements') {
        setFlattenedTickets(tickets);
      } else {
        setFlattenedTickets(reducedflattenedTickets);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignTickets]);

  const [searchTerm, setSearchTerm] = useState('');

  const filterTickets = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);
  };

  const saveAssignee = async () => {
    setIsUpdating(true);
    try {
      await assigneeCreater(assigneeInfo);
      !isError
        ? setEnqueueSnackbar('assignee assigned successfully', 'success')
        : setEnqueueSnackbar('assignee assigning faild', 'error');
      history.push({ pathname: '/tickets' });
    } catch (e) {
      console.log(e);
    }
    setIsUpdating(false);
  };

  const emailValidation = (email) => {
    if (!/\S+@randoli.ca/.test(email)) {
      return true;
    }
    return false;
  };

  const columns = React.useMemo(() => [
    {
      Header: 'entitlementId',
      accessor: 'entitlementId',
    },
    {
      Header: 'Ticket No',
      accessor: 'ticketId',
      Cell: ({ row }) =>
        `${
          row.original.ticketDisplayId
            ? row.original.ticketDisplayId
            : row.original.ticketId
        }`,
    },
    {
      Header: 'Customer',
      accessor: 'name',
    },
    {
      Header: 'Title',
      accessor: 'title',
    },
    {
      Header: 'Priority',
      accessor: 'userPriority',
      Cell: ({
        cell: {
          row: { values },
        },
        isHovered,
      }) => {
        if (values.userPriority === null) {
          return <></>;
        } else {
          return (
            <PriorityChip
              priority={values.userPriority}
              isHovered={isHovered}
            />
          );
        }
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({
        cell: {
          row: { values },
        },
      }) => {
        return <StatusText priority={values.status} />;
      },
    },

    {
      Header: 'Internal Assignee',
      accessor: 'internalAssigneeEmail',
    },
    {
      Header: 'Create Date',
      accessor: 'createdAt',
      Cell: ({
        cell: {
          row: { values },
        },
      }) => {
        return <CreateDate date={values.createdAt} />;
      },
    },
    {
      Header: 'Action',
      Cell: ({
        cell: {
          row: { values },
        },
      }) => {
        return (
          <Tooltip title={'Assign Users'}>
            <Button
              classes={{
                root: classes.btnRoot,
              }}
              onClick={(e) => {
                e.stopPropagation();
                setState(true);
                setTimeout(() => {
                  setMiniUiData(values);
                }, 100);
              }}
              id="btn-btn"
              endIcon={
                <PersonAddIcon
                  className={classes.editIconRoot}
                  color={'#808CA3'}
                />
              }
            ></Button>
          </Tooltip>
        );
      },
    },
  ]);

  const statusData = [
    {
      id: 1,
      name: 'OPEN',
      value: 'OPENED'
    },
    {
      id: 2,
      name: 'IN PROGRESS',
      value: 'IN_PROGRESS',
    },
    {
      id: 3,
      name: 'ON HOLD',
      value: 'ON_HOLD',
    },
    {
      id: 4,
      name: 'IN REVIEW',
      value: 'IN_REVIEW',
    },
    {
      id: 5,
      name: 'REOPENED',
    },
    {
      id: 6,
      name: 'CLOSED',
    },
    {
      id: 7,
      name: 'NOT CLOSED',
    },
  ];

  const PriorityData = [
    {
      id: 1,
      name: 'LOW',
    },
    {
      id: 2,
      name: 'MEDIUM',
    },
    {
      id: 3,
      name: 'HIGH',
    },
    {
      id: 4,
      name: 'URGENT',
    },
  ];

  const filteredTickets = (tickets, filter, start, end, searchTerm) => {
    return tickets.filter((ticket) => {
      const statusMatches = matchesStatusFilter(ticket, filter);
      const priorityMatches = matchesUserPriorityFilter(ticket, filter);
      const createdAtMatches = matchesDateRangeFilter(ticket, start, end);
      const searchTermMatches = matchesSearchTerm(ticket, searchTerm);

      return (
        (statusMatches &&
          priorityMatches &&
          createdAtMatches &&
          searchTermMatches) ||
        (filter.status === 'NOT CLOSED' &&
          ticket.status.toUpperCase() !== 'CLOSED' &&
          priorityMatches &&
          createdAtMatches &&
          searchTermMatches)
      );
    });
  };

  const matchesStatusFilter = (ticket, filter) => {
    if (filter.status === 'All') {
      return true;
    } else if (filter.status === 'NOT CLOSED') {
      return ticket.status.toUpperCase() !== 'CLOSED';
    } else {
      return ticket.status.toUpperCase() === filter.status.toUpperCase();
    }
  };

  const matchesUserPriorityFilter = (ticket, filter) => {
    const ticketUserPriority = ticket.userPriority || '';
    const filterUserPriority = filter.userPriority || '';
    return (
      filterUserPriority === 'All' ||
      ticketUserPriority.toUpperCase() === filterUserPriority.toUpperCase()
    );
  };

  const matchesDateRangeFilter = (ticket, start, end) => {
    const ticketDate = ticket.createdAt.slice(0, 10);
    return ticketDate >= start && ticketDate <= end;
  };

  const matchesSearchTerm = (ticket, searchTerm) => {
    return (
      searchTerm === '' ||
      ticket.ticketId.toString().padStart(6, '0') === searchTerm ||
      ticket.ticketId.toString().padStart(6, '0').includes(searchTerm.trim()) ||
      ticket.name.toLowerCase().includes(searchTerm) ||
      ticket.title.toLowerCase().includes(searchTerm) ||
      (ticket.internalAssigneeEmail &&
        ticket.internalAssigneeEmail.toLowerCase().includes(searchTerm))
    );
  };

  const filteredData =
    flattenedTickets &&
    filteredTickets(flattenedTickets, filter, start, end, searchTerm);

  return (
    <Grid container justifyContent="space-between">
      <Grid
        item
        container
        className={`${classes.filters} ${classes.mobileFilters}`}
      >
        <Grid
          item
          className={`${classes.searchBox} ${classes.mobileSearchBox}`}
        >
          <SearchBox
            placeholder="Search by title, customer or ticket number..."
            onchangefunc={filterTickets}
            md={4}
            sm={3}
          />
        </Grid>
        <Grid item container spacing={3} className={classes.selectBox}>
          <Grid item className={classes.selectBox} md={4} sm={3}>
            <CustomFilter
              placeholder="Select status"
              selectItems={statusData}
              handleChange={handleStatusChange}
              selectedValue={filter?.status}
            />
          </Grid>
          <Grid item className={classes.selectBox} md={4} sm={3}>
            <CustomFilter
              placeholder="Select priority"
              selectItems={PriorityData}
              handleChange={handlePriorityChange}
            />
          </Grid>
          <Grid item md={3} sm={2}>
            <DatePicker
              class="react-datepicker-popper"
              id="datePicker"
              selectsRange={true}
              placeholderText="Select date range"
              startDate={startDate}
              endDate={endDate}
              className={classes.textField}
              onChange={(update) => {
                setDateRange(update);
              }}
              isClearable={true}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container className={classes.checkBox}>
        <FormControlLabel
          disabled={!flattenedTickets}
          control={
            <Checkbox
              onClick={(e) => {
                handleAssignTickets(e);
              }}
            />
          }
          label={
            <span className={classes.checkboxLabel}>
              {'Show Assigned Tickets'}
            </span>
          }
        />
      </Grid>
      <Grid item container>
        <EscapeOutside onEscapeOutside={handleClose}>
          <Drawer
            anchor="right"
            variant="persistent"
            open={state}
            onClose={() => {
              setState(false);
              setAssigneeInfo({
                internalAssigneeEmail: '',
              });
            }}
            PaperProps={{
              style: { boxShadow: ' 0px 1px 8px rgba(0, 0, 43, 0.69)' },
            }}
          >
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              style={{
                maxHeight: '90px',
                padding: '20px',
                backgroundColor: '#EFF3FA',
              }}
            >
              <div className={classes.head}>
                <div className={classes.overview}>Ticket Details</div>
              </div>
              <div style={{ display: 'flex' }}>
                <CloseButton
                  id="btn-close"
                  onClick={() => {
                    setState(false);
                    setAssigneeInfo({
                      internalAssigneeEmail: '',
                    });
                  }}
                />
              </div>
            </Grid>
            <div style={{ padding: '0px 20px' }}>
              <Grid item>
                <div className={classes.topic}>
                  <span>{miniUiData?.title}</span>
                </div>
              </Grid>
              <Divider />
              <Grid item>
                <div className={classes.subTopic}>Description : </div>
                <div>
                  <CustomInput path="summary" value={miniUiData?.description} />
                </div>
              </Grid>
              <Grid item>
                <div className={classes.subTopic}>Status : </div>
                <div>
                  <CustomInput path="summary" value={miniUiData?.status} />
                </div>
              </Grid>
              <Grid item>
                <div>
                  <div className={classes.subTopic}>Internal Assignee : </div>
                  <div>
                    <span>
                      <Autocomplete
                        disablePortal
                        clearOnBlur={false}
                        blurOnSelect={false}
                        variant="outlined"
                        open={
                          miniUiData?.internalAssigneeEmail != null
                            ? false
                            : open_box
                        }
                        onInputChange={(_, value) => {
                          if (value.length === 0) {
                            if (open_box) setOpen(false);
                          } else {
                            if (!open_box) setOpen(true);
                          }
                        }}
                        value={miniUiData?.internalAssigneeEmail || null}
                        onClose={() => setOpen(false)}
                        options={userEmails}
                        onChange={(e, value) => {
                          setError(emailValidation(value));
                          handleChange(value);
                        }}
                        renderInput={(params) => (
                          <FormControl>
                            <TextField
                              style={{ width: '459px', height: '55px' }}
                              InputProps={
                                error && {
                                  classes: {
                                    notchedOutline: classes.errorTextField,
                                  },
                                  ...params.InputProps,
                                }
                              }
                              {...params}
                              className={classes.textField}
                            />{' '}
                            {error && (
                              <p className={classes.invalid}>Invalid Email</p>
                            )}
                          </FormControl>
                        )}
                      />
                    </span>
                    <Grid item className={classes.buttonField}>
                      <LoadingButton
                        id="btn-assign"
                        onClickHandler={saveAssignee}
                        updatingStatus={isUpdating}
                        disableStatus={setDisableStatus()}
                      >
                        Assign
                      </LoadingButton>
                    </Grid>
                  </div>
                </div>
              </Grid>
            </div>
          </Drawer>
        </EscapeOutside>
      </Grid>
      {flattenedTickets === undefined ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.spinner}
        >
          <LoaderSpinner type="Oval" color="#00BFFF" height={60} width={60} />
        </Grid>
      ) : (
        <Grid item container className={classes.container}>
          <DataTable
            data={filteredData || []}
            setRowData={setRowData}
            onClickTableRow={handleRowClick}
            hiddenColumns={
              matches
                ? ['entitlementId', 'ticketId', 'status', 'description', 'tags']
                : ['entitlementId']
            }
            columns={columns}
            isNextPageLoading={isFetchingMoreTickets}
            hasNextPage={canFetchMoreTickets}
            loadMore={fetchMoreTickets}
            tableHeightPercentage={
              tableHeightPercentage ? tableHeightPercentage : 30
            }
          />
        </Grid>
      )}
    </Grid>
  );
};
export default AllTickets;
