import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import api from 'services/Api';
import { PATH_TICKET_SERVICE, PATH_UPLOAD_SERVICE } from '../../constants';
import { Dialog, Tooltip, Typography, IconButton } from '@material-ui/core';
import ConfirmationDialog from 'components/ConfirmationDialog';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { GetApp } from '@material-ui/icons';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import ImageIcon from '@material-ui/icons/Image';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

const useStyles = makeStyles((theme) => ({
  img: {
    display: 'block',
    width: '10%',
    height: '100%',
    marginRight: '2%',
  },
  thumb: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.5),
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: '#f9f9f9',
    width: '100%',
    height: 'fit-content',
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxHeight: '14vh',
    overflowY: 'auto',
    borderColor: 'rgba(0, 0, 0, 0.23)',
  },
  fileInfo: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
  },
  fileName: {
    fontSize: '0.8rem',
    fontWeight: 'lighter',
  },
  fileSize: {
    fontSize: '1rem',
    color: '#757575',
  },
  downloadButton: {
    color: '#0F5EF7',
  },
  deleteButton: {
    color: 'red',
    marginLeft: '15%',
  },
  attachIcon: {
    color: 'black',
    marginRight: '2%',
    fontSize: '1.5rem',
  },
  add: {
    display: 'flex',
    flexDirection: 'inherit',
    alignItems: 'center',
  },
  heading: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '0.9rem',
    fontWeight: 'bold',
  },
  attachTopic: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    color: '#0F5EF7',
  },
}));

const Attachment = ({ passFiles, attachments, ticketData }) => {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const [showImage, setShowImage] = useState(false);
  const [imageToShow, setImageToShow] = useState('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const setEnqueueSnackbar = (msg, snackerVariant) => {
    enqueueSnackbar(msg, {
      variant: snackerVariant,
      autoHideDuration: 3000,
    });
  };

  useEffect(() => {
    if (attachments) {
      setIsEdit(true);
    }
  }, [attachments]);

  useEffect(() => {
    let exec = async () => {
      if (attachments) {
        let attach = await Promise.all(
          attachments.map(async (attachment) => {
            return {
              id: attachment.attachmentId,
              name: attachment.fileName,
              url: attachment.url,
              type: attachment.fileType,
            };
          })
        );
        setFiles(attach);
      }
    };
    exec();
  }, [attachments]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: [
      '.jpeg',
      '.jpg',
      '.png',
      '.txt',
      '.log',
      '.yaml',
      'application/json',
    ],
    onDrop: (acceptedFiles) => {
      for (let acceptedFile of acceptedFiles) {
        if (!isEdit) {
          passFiles(files.concat(acceptedFile));
          setFiles(
            files.concat({
              id: '',
              name: acceptedFile.name,
              url: URL.createObjectURL(acceptedFile),
              type: acceptedFile.type,
            })
          );
        } else {
          acceptedFiles.map(async (acceptedFile) => {
            const response = await uploadFileToS3Bucket(acceptedFile);
            const attachmentId = await saveFileInfoToTicketService(response);
            setFiles(
              files.concat({
                id: attachmentId,
                name: response.fileName,
                url: response.url,
                type: response.fileType,
              })
            );
          });
        }
      }
    },
    multiple: true,
  });

  const saveFileInfoToTicketService = async (uploadServiceResponse) => {
    try {
      const addAttachment = await api.post(
        `${PATH_TICKET_SERVICE}/v1/tickets/${ticketData.entitlementId}/${ticketData.ticketId}/attachments`,
        JSON.stringify(uploadServiceResponse)
      );
      const result = await addAttachment.json();

      setEnqueueSnackbar('File added successfully', 'success');
      return result.data.attachment.attachmentId;
    } catch (error) {
      setEnqueueSnackbar('Failed to add file', 'error');
      return Promise.reject(error);
    }
  };

  const uploadFileToS3Bucket = async (f) => {
    const data = new FormData();
    data.append('file', f);
    try {
      const addFile = await api.post(`${PATH_UPLOAD_SERVICE}/v1/file`, data);
      const result = await addFile.json();
      const url = result.data.url;
      const file = url.substring(url.lastIndexOf('/') + 1);
      const fileArray = file.split('.');
      const fileName = fileArray[0];
      const fileType = fileArray[1];

      return {
        fileName,
        fileType,
        url,
      };
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const removeImg = (fileName, fileType, fileId) => {
    const filteredFiles = files.filter((file) => file.name !== fileName);
    if (!isEdit) {
      passFiles(filteredFiles);
    } else {
      removeFileFromS3Bucket(`${fileName}.${fileType}`);
      deleteFileInfoFromTicketService(fileId);
    }
    setFiles(filteredFiles);
  };

  const removeFileFromS3Bucket = async (file) => {
    try {
      await api.delete(`${PATH_UPLOAD_SERVICE}/v1/file/${file}`);
      setEnqueueSnackbar('File deleted successfully', 'success');
    } catch (error) {
      setEnqueueSnackbar('Failed to delete file', 'error');
      return Promise.reject(error);
    }
  };

  const deleteFileInfoFromTicketService = async (id) => {
    await api.delete(
      `${PATH_TICKET_SERVICE}/v1/tickets/${ticketData.entitlementId}/${ticketData.ticketId}/attachments/${id}`
    );
  };

  const confirmDelete = (file) => {
    setFileToDelete(file);
    setOpenConfirmDialog(true);
  };

  const handleDelete = () => {
    if (fileToDelete) {
      removeImg(fileToDelete.name, fileToDelete.type, fileToDelete.id);
      setOpenConfirmDialog(false);
    }
  };

  const closeImage = () => {
    setShowImage(false);
  };

  const downloadFile = (file) => {
    const link = document.createElement('a');
    link.href = file.url;
    link.setAttribute('download', file.name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const getFileIcon = (file) => {
    const extension = file.type.split('.').pop().toLowerCase();

    switch (extension) {
      case 'txt':
      case 'log':
        return <DescriptionIcon className={classes.attachIcon} />;
      case 'jpg':
      case 'jpeg':
      case 'png':
        return (
          <img
            src={file.url}
            className={classes.img}
            onClick={() => {
              setShowImage(true);
              setImageToShow(file.url);
            }}
            role="button"
          />
        );
      default:
        return <InsertDriveFileIcon className={classes.attachIcon} />;
    }
  };

  const thumbs = files.map((file) => (
    <div className={classes.thumb} key={file.name}>
      <div className={classes.fileInfo}>
        {getFileIcon(file)}
        <Typography className={classes.fileName}>
          {file.name}.{file.type}
        </Typography>
      </div>
      <div className={classes.buttonContainer}>
        <IconButton
          className={classes.deleteButton}
          onClick={() => confirmDelete(file)}
        >
          <Tooltip title="Delete">
            <DeleteIcon />
          </Tooltip>
        </IconButton>
        <IconButton
          className={classes.downloadButton}
          onClick={() => downloadFile(file)}
        >
          <Tooltip title="Download">
            <GetApp />
          </Tooltip>
        </IconButton>
      </div>
    </div>
  ));

  useEffect(() => {
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <>
      <span>
        <Dialog open={showImage} onClose={closeImage} maxWidth="xl">
          <img
            src={imageToShow}
            onClick={() => {
              closeImage();
            }}
          />
        </Dialog>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <span className={classes.heading}>Attachments</span>
          </Grid>
          <Grid item>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <div className={classes.add}>
                <span className={classes.attachTopic} size="small">
                  Add Attachment
                </span>
                <AddCircleOutlineRoundedIcon style={{ color: '#0F5EF7' }} />
              </div>
            </div>
          </Grid>
        </Grid>
      </span>

      <div className={classes.thumbsContainer}>{thumbs}</div>
      <ConfirmationDialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        buttonConfirmText="Yes"
        buttonCancelText="No"
        backgroundColor="#FF7C7C"
        onConfirm={handleDelete}
        icon={
          <HelpOutlineIcon
            style={{
              alignSelf: 'center',
              width: '42px',
              height: '42px',
              position: 'absolute',
              marginTop: '25px',
              color: '#FF7C7C',
            }}
          />
        }
      />
    </>
  );
};

export default Attachment;
