import React from 'react';

const ClusterIcon = (props) => (
  <svg
    className={props.className}
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5,10.8c-0.4,1.5-1.4,2.7-2.7,3.4l0.2,0.4c1.4-0.8,2.5-2.1,2.9-3.6L14.5,10.8z M10.9,10.2l3.7,0.8l0.1-0.4
       L11,9.9L10.9,10.2z M10.3,11.1c0.4-0.2,0.7-0.6,0.8-1L10.7,10c-0.1,0.3-0.4,0.6-0.7,0.8L10.3,11.1z M12,14.3l-1.7-3.4L10,11.1
       l1.7,3.4L12,14.3z M9.2,14.9c-0.8,0-1.5-0.2-2.2-0.5l-0.2,0.4c0.7,0.3,1.5,0.5,2.3,0.5V14.9z M11.4,14.4c-0.7,0.3-1.4,0.5-2.2,0.5
       v0.4c0.8,0,1.6-0.2,2.3-0.5L11.4,14.4z M9.6,11.3l1.7,3.4l0.4-0.2L10,11.1L9.6,11.3z M9.2,11.5c0.2,0,0.5,0,0.7-0.1L9.7,11
       c-0.2,0.1-0.3,0.1-0.5,0.1V11.5z M8.5,11.4c0.2,0.1,0.4,0.1,0.7,0.1v-0.4c-0.2,0-0.4,0-0.5-0.1L8.5,11.4z M7.1,14.7l1.7-3.4
       l-0.4-0.2l-1.7,3.4L7.1,14.7z M6.6,14.2c-1.3-0.7-2.3-1.9-2.7-3.4l-0.4,0.1C4,12.5,5,13.8,6.5,14.6L6.6,14.2z M8,10.9l-1.7,3.4
       l0.4,0.2l1.7-3.4L8,10.9z M7.3,10.1c0.2,0.4,0.4,0.8,0.8,1l0.2-0.3C8,10.6,7.8,10.3,7.7,10L7.3,10.1z M3.8,11.1l3.7-0.8L7.4,9.9
       l-3.7,0.8L3.8,11.1z M3.8,9.4c0-1.2,0.4-2.3,1.1-3.2L4.5,6c-0.7,1-1.1,2.2-1.1,3.5H3.8z M3.8,10.4c-0.1-0.3-0.1-0.7-0.1-1H3.4
       c0,0.4,0,0.7,0.1,1.1L3.8,10.4z M7.3,9.4l-3.7,0.8l0.1,0.4l3.7-0.8L7.3,9.4z M7.2,9.4c0,0.1,0,0.1,0,0.2l0.4,0c0-0.1,0-0.1,0-0.2
       H7.2z M7.5,8.4C7.3,8.7,7.2,9,7.2,9.4h0.4c0-0.3,0.1-0.6,0.2-0.9L7.5,8.4z M4.5,6.2l3,2.4l0.2-0.3l-3-2.4L4.5,6.2z M5.1,5.9
       C6,4.8,7.4,4,9,4l0-0.4c-1.7,0.1-3.2,0.8-4.2,2L5.1,5.9z M8,8l-3-2.4L4.8,5.9l3,2.4L8,8z M9,7.4C8.5,7.5,8.1,7.7,7.8,8l0.3,0.3
       C8.3,8,8.6,7.8,9,7.8L9,7.4z M8.8,3.8v3.8h0.4V3.8H8.8z M9.4,4c1.6,0.1,3,0.8,3.9,1.9l0.3-0.3c-1-1.2-2.5-2-4.2-2L9.4,4z M9.6,7.6
       V3.8H9.2v3.8H9.6z M10.6,8c-0.3-0.3-0.7-0.5-1.2-0.6l0,0.4c0.4,0,0.7,0.2,1,0.5L10.6,8z M13.3,5.6l-3,2.4l0.2,0.3l3-2.4L13.3,5.6z
        M10.4,9.4c0,0.7-0.5,1.2-1.2,1.2V11c0.9,0,1.6-0.7,1.6-1.6H10.4z M9.2,8.2c0.7,0,1.2,0.5,1.2,1.2h0.4c0-0.9-0.7-1.6-1.6-1.6V8.2z
        M8,9.4c0-0.7,0.5-1.2,1.2-1.2V7.8c-0.9,0-1.6,0.7-1.6,1.6H8z M9.2,10.6C8.5,10.6,8,10.1,8,9.4H7.6c0,0.9,0.7,1.6,1.6,1.6V10.6z
        M14.6,9.4c0,0.3,0,0.7-0.1,1l0.4,0.1C15,10.2,15,9.8,15,9.4H14.6z M13.6,6.2c0.7,0.9,1.1,2,1.1,3.2H15c0-1.3-0.4-2.5-1.1-3.5
       L13.6,6.2z M10.9,8.6l3-2.4l-0.2-0.3l-3,2.4L10.9,8.6z M11.2,9.4c0-0.4-0.1-0.8-0.3-1.1l-0.3,0.2c0.2,0.3,0.2,0.5,0.2,0.9H11.2z
        M11.2,9.6c0-0.1,0-0.1,0-0.2h-0.4c0,0.1,0,0.1,0,0.2L11.2,9.6z M14.8,10.3l-3.7-0.8L11,9.8l3.7,0.8L14.8,10.3z M15.5,9.4
       c0-3.5-2.8-6.3-6.3-6.3v0.4c3.2,0,5.9,2.6,5.9,5.9H15.5z M9.2,15.7c3.5,0,6.3-2.8,6.3-6.3h-0.4c0,3.2-2.6,5.9-5.9,5.9V15.7z
        M2.9,9.4c0,3.5,2.8,6.3,6.3,6.3v-0.4c-3.2,0-5.9-2.6-5.9-5.9H2.9z M9.2,3.1C5.7,3.1,2.9,6,2.9,9.4h0.4c0-3.2,2.6-5.9,5.9-5.9V3.1z
        M14.7,10.9l0.2,0.1l0.1-0.2l-0.2,0L14.7,10.9z M10.9,10l0-0.2l-0.2,0L10.7,10L10.9,10z M10.2,11l-0.1-0.2l-0.1,0.1l0.1,0.2L10.2,11
       z M11.8,14.4l-0.2,0.1l0.1,0.2l0.2-0.1L11.8,14.4z M11.4,14.6l0.1,0.2l0.2-0.1l-0.1-0.2L11.4,14.6z M9.8,11.2l0.2-0.1l-0.1-0.2
       L9.7,11L9.8,11.2z M8.6,11.2L8.7,11l-0.2-0.1l-0.1,0.2L8.6,11.2z M7,14.6l-0.2-0.1l-0.1,0.2l0.2,0.1L7,14.6z M6.6,14.4l-0.1,0.2
       l0.2,0.1l0.1-0.2L6.6,14.4z M8.2,11l0.2,0.1l0.1-0.2l-0.1-0.1L8.2,11z M7.5,10L7.7,10L7.6,9.8l-0.2,0L7.5,10z M3.7,10.9l0-0.2
       l-0.2,0l0.1,0.2L3.7,10.9z M3.6,9.4H3.4H3.6z M3.7,10.5l-0.2,0l0,0.2l0.2,0L3.7,10.5z M7.4,9.6l0,0.2l0.2,0l0-0.2L7.4,9.6z M7.6,8.5
       l0.2,0.1l0.1-0.2L7.8,8.3L7.6,8.5z M4.7,6.1l0.1-0.2L4.6,5.8L4.5,6L4.7,6.1z M4.9,5.7L4.8,5.6L4.6,5.8l0.2,0.1L4.9,5.7z M7.9,8.1
       L7.8,8.3l0.1,0.1l0.1-0.1L7.9,8.1z M9,7.6l0,0.2l0.2,0V7.6H9z M9,3.8h0.2V3.6L9,3.6L9,3.8z M9.4,3.8l0-0.2l-0.2,0v0.2H9.4z M9.4,7.6
       H9.2v0.2l0.2,0L9.4,7.6z M10.5,8.1l-0.1,0.1l0.1,0.1l0.1-0.1L10.5,8.1z M13.5,5.7l0.1,0.2l0.2-0.1l-0.1-0.2L13.5,5.7z M13.7,6.1
       L13.9,6l-0.1-0.2l-0.2,0.1L13.7,6.1z M10.8,8.5l-0.1-0.2l-0.1,0.1l0.1,0.2L10.8,8.5z M11,9.6l-0.2,0l0,0.2l0.2,0L11,9.6z M14.7,10.5
       l0,0.2l0.2,0l0-0.2L14.7,10.5z"
      fill="#0F5EF7"
      stroke="#0F5EF7"
      stroke-width="0.2"
    />
    <path
      d="M11.8,14.4L10.2,11c0.3-0.2,0.6-0.5,0.7-0.9l3.7,0.8C14.2,12.4,13.2,13.7,11.8,14.4z M7,14.6l1.7-3.4
      c0.2,0.1,0.4,0.1,0.6,0.1s0.4,0,0.6-0.1l1.7,3.4c-0.7,0.3-1.5,0.5-2.2,0.5C8.4,15.1,7.6,14.9,7,14.6z M3.7,10.9L7.5,10
      c0.1,0.4,0.4,0.7,0.7,0.9l-1.7,3.4C5.2,13.7,4.2,12.4,3.7,10.9z M4.7,6.1l3,2.4c-0.2,0.3-0.3,0.6-0.3,1c0,0.1,0,0.1,0,0.2l-3.7,0.8
      c-0.1-0.3-0.1-0.7-0.1-1C3.6,8.2,4,7,4.7,6.1z M9,3.8v3.8c-0.4,0-0.8,0.2-1.1,0.5l-3-2.4C5.9,4.6,7.4,3.8,9,3.8z M13.5,5.7l-3,2.4
      c-0.3-0.3-0.7-0.5-1.1-0.5V3.8C11,3.8,12.5,4.6,13.5,5.7z M9.2,10.8c-0.8,0-1.4-0.6-1.4-1.4C7.8,8.7,8.4,8,9.2,8s1.4,0.6,1.4,1.4
      C10.6,10.2,10,10.8,9.2,10.8z M14.7,10.5L11,9.6c0-0.1,0-0.1,0-0.2c0-0.4-0.1-0.7-0.3-1l3-2.4c0.7,0.9,1.1,2.1,1.1,3.3
      C14.8,9.8,14.8,10.1,14.7,10.5z M9.2,3.3c-3.4,0-6.1,2.7-6.1,6.1c0,3.4,2.7,6.1,6.1,6.1s6.1-2.7,6.1-6.1C15.3,6.1,12.6,3.3,9.2,3.3z
      "
      fill="#0F5EF7"
      stroke="#0F5EF7"
      stroke-width="0.2"
    />
    <path
      d="M16.3,9.4c0,3.9-3.2,7.1-7.1,7.1v0.4c4.1,0,7.5-3.3,7.5-7.5H16.3z M9.2,2.4c3.9,0,7.1,3.2,7.1,7.1h0.4
      c0-4.1-3.3-7.5-7.5-7.5V2.4z M2.1,9.4c0-3.9,3.2-7.1,7.1-7.1V2C5.1,2,1.7,5.3,1.7,9.4H2.1z M9.2,16.5c-3.9,0-7.1-3.2-7.1-7.1H1.7
      c0,4.1,3.3,7.5,7.5,7.5V16.5z M18.4,11.5c0.1-0.2-0.1-0.5-0.3-0.5L18,11.4c0,0,0,0,0,0L18.4,11.5z M18,11.8c0.2,0,0.4-0.1,0.4-0.3
      L18,11.4c0,0,0,0,0,0V11.8z M17.9,11.8C17.9,11.8,18,11.8,17.9,11.8l0.1-0.4c0,0,0,0,0,0L17.9,11.8z M16.6,11.5l1.3,0.3l0.1-0.4
      l-1.3-0.3L16.6,11.5z M12.8,16.4c2-1,3.5-2.8,4-5l-0.4-0.1c-0.5,2.1-1.9,3.8-3.8,4.8L12.8,16.4z M13.5,17.4l-0.6-1.2l-0.4,0.2
      l0.6,1.2L13.5,17.4z M13.3,17.9c0.2-0.1,0.3-0.4,0.2-0.6l-0.4,0.2c0,0,0,0,0,0L13.3,17.9z M13.1,18c0.1,0,0.1,0,0.2,0l-0.2-0.4
      c0,0,0,0,0,0V18z M12.7,17.7c0.1,0.2,0.2,0.2,0.4,0.2v-0.4c0,0,0,0,0,0c0,0,0,0,0,0L12.7,17.7z M12.2,16.5l0.6,1.2l0.4-0.2l-0.6-1.2
      L12.2,16.5z M9.2,17.3c1.1,0,2.2-0.2,3.2-0.7l-0.2-0.4c-0.9,0.4-2,0.7-3.1,0.7V17.3z M6,16.6c1,0.4,2.1,0.7,3.2,0.7v-0.4
      c-1.1,0-2.1-0.2-3.1-0.7L6,16.6z M5.7,17.7l0.6-1.2l-0.4-0.2l-0.6,1.2L5.7,17.7z M5.3,18c0.2,0,0.3-0.1,0.4-0.2l-0.4-0.2
      c0,0,0,0,0,0c0,0,0,0,0,0V18z M5.1,17.9c0.1,0,0.1,0,0.2,0v-0.4c0,0,0,0,0,0L5.1,17.9z M4.9,17.4c-0.1,0.2,0,0.5,0.2,0.6l0.2-0.4
      c0,0,0,0,0,0L4.9,17.4z M5.5,16.2l-0.6,1.2l0.4,0.2l0.6-1.2L5.5,16.2z M1.6,11.4c0.6,2.2,2.1,4,4,5l0.2-0.4c-1.9-1-3.3-2.7-3.8-4.8
      L1.6,11.4z M0.5,11.8l1.3-0.3l-0.1-0.4l-1.3,0.3L0.5,11.8z M0.4,11.8C0.4,11.8,0.5,11.8,0.4,11.8l0-0.4c0,0,0,0,0,0V11.8z M0,11.5
      c0,0.2,0.2,0.3,0.4,0.3v-0.4c0,0,0,0,0,0L0,11.5z M0.3,11C0.1,11.1,0,11.3,0,11.5l0.4-0.1c0,0,0,0,0,0L0.3,11z M1.6,10.7L0.3,11
      l0.1,0.4l1.3-0.3L1.6,10.7z M1.3,9.4c0,0.5,0.1,1,0.2,1.5l0.4-0.1c-0.1-0.5-0.1-1-0.1-1.5H1.3z M2.9,4.7c-1,1.3-1.6,3-1.6,4.7h0.4
      c0-1.7,0.6-3.2,1.5-4.5L2.9,4.7z M1.9,4.1l1,0.8l0.2-0.3l-1-0.8L1.9,4.1z M1.8,3.5C1.7,3.7,1.7,4,1.9,4.1l0.2-0.3c0,0,0,0,0,0
      L1.8,3.5z M2.4,3.5C2.2,3.3,2,3.4,1.8,3.5l0.3,0.2c0,0,0,0,0,0L2.4,3.5z M3.5,4.3l-1-0.8L2.2,3.8l1,0.8L3.5,4.3z M9,1.5
      C6.7,1.6,4.6,2.7,3.2,4.3l0.3,0.3C4.8,3,6.8,2,9,1.9L9,1.5z M8.8,0.4v1.3h0.4V0.4H8.8z M9.2,0C9,0,8.8,0.2,8.8,0.4h0.4c0,0,0,0,0,0
      V0z M9.6,0.4C9.6,0.2,9.4,0,9.2,0v0.4c0,0,0,0,0,0H9.6z M9.6,1.7V0.4H9.2v1.3H9.6z M15.2,4.3c-1.4-1.7-3.5-2.7-5.8-2.8l0,0.4
      C11.6,2,13.6,3,14.9,4.6L15.2,4.3z M16,3.5l-1,0.8l0.2,0.3l1-0.8L16,3.5z M16.6,3.5c-0.1-0.2-0.4-0.2-0.6-0.1l0.2,0.3c0,0,0,0,0,0
      L16.6,3.5z M16.5,4.1c0.2-0.1,0.2-0.4,0.1-0.6l-0.3,0.2c0,0,0,0,0,0L16.5,4.1z M15.5,5l1-0.8l-0.2-0.3l-1,0.8L15.5,5z M17.1,9.4
      c0-1.8-0.6-3.4-1.6-4.7l-0.3,0.2c0.9,1.3,1.5,2.8,1.5,4.5H17.1z M16.9,11c0.1-0.5,0.2-1,0.2-1.5h-0.4c0,0.5-0.1,1-0.1,1.5L16.9,11z
       M18.1,11l-1.3-0.3l-0.1,0.4l1.3,0.3L18.1,11z M18.2,11.5l0.2,0l0,0L18.2,11.5z M17.9,11.6l0-0.2l0,0L17.9,11.6z M16.7,11.4l0-0.2
      l-0.2,0l0,0.2L16.7,11.4z M12.7,16.3l-0.1-0.2l-0.2,0.1l0.1,0.2L12.7,16.3z M13.3,17.4l-0.2,0.1l0,0L13.3,17.4z M13.2,17.7l0.1,0.2
      l0,0L13.2,17.7z M12.9,17.6l0.2-0.1l0,0L12.9,17.6z M12.3,16.5l0.2-0.1l-0.1-0.2l-0.2,0.1L12.3,16.5z M6.1,16.5l0.1-0.2L6,16.2
      l-0.1,0.2L6.1,16.5z M5.5,17.6l-0.2-0.1l0,0L5.5,17.6z M5.2,17.7l-0.1,0.2l0,0L5.2,17.7z M5.1,17.4l0.2,0.1l0,0L5.1,17.4z M5.7,16.3
      l0.2,0.1l0.1-0.2l-0.2-0.1L5.7,16.3z M1.7,11.4l0.2-0.1l0-0.2l-0.2,0L1.7,11.4z M0.5,11.6l0-0.2l0,0L0.5,11.6z M0.2,11.5l-0.2,0l0,0
      L0.2,11.5z M0.4,11.2l0,0.2l0,0L0.4,11.2z M1.7,10.9l0,0.2l0.2,0l0-0.2L1.7,10.9z M3.1,4.8l0.2,0.1l0.1-0.2L3.2,4.6L3.1,4.8z M2,4
      L1.9,4.1l0,0L2,4z M2,3.7l0.2,0.1l0,0L2,3.7z M2.3,3.6L2.2,3.8l0,0L2.3,3.6z M3.3,4.5L3.2,4.6l0.2,0.1l0.1-0.1L3.3,4.5z M9,1.7
      l0,0.2l0.2,0V1.7H9z M9.4,1.7H9.2v0.2l0.2,0L9.4,1.7z M15.1,4.5l-0.2,0.1L15,4.7l0.2-0.1L15.1,4.5z M16.1,3.6L16,3.5l0,0L16.1,3.6z
       M16.4,3.7l-0.2,0.1l0,0L16.4,3.7z M16.4,4l0.1,0.2l0,0L16.4,4z M15.3,4.8l-0.1-0.2l-0.2,0.1l0.1,0.2L15.3,4.8z M16.7,10.9l-0.2,0
      l0,0.2l0.2,0L16.7,10.9z M18,11.2l0,0.2l0,0L18,11.2z"
      fill="#0F5EF7"
      stroke="#0F5EF7"
      stroke-width="0.2"
    />
    <path
      d="M9.2,16.7c-4,0-7.3-3.3-7.3-7.3s3.3-7.3,7.3-7.3s7.3,3.3,7.3,7.3S13.2,16.7,9.2,16.7z M18,11.2l-1.3-0.3
      c0.1-0.5,0.1-1,0.1-1.5c0-1.7-0.6-3.3-1.5-4.6l1-0.8c0.1-0.1,0.1-0.2,0-0.3c-0.1-0.1-0.2-0.1-0.3,0l-1,0.8c-1.4-1.6-3.4-2.7-5.7-2.7
      V0.4c0-0.1-0.1-0.2-0.2-0.2C9.1,0.2,9,0.3,9,0.4v1.3C6.7,1.8,4.7,2.8,3.3,4.5l-1-0.8C2.2,3.6,2.1,3.6,2,3.7C1.9,3.8,1.9,3.9,2,4
      l1,0.8c-1,1.3-1.5,2.9-1.5,4.6c0,0.5,0.1,1,0.1,1.5l-1.3,0.3c-0.1,0-0.2,0.1-0.2,0.3c0,0.1,0.1,0.2,0.2,0.2c0,0,0,0,0,0l1.3-0.3
      c0.6,2.1,2,3.9,3.9,4.9l-0.6,1.2c-0.1,0.1,0,0.2,0.1,0.3c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2-0.1l0.6-1.2c1,0.4,2,0.7,3.1,0.7
      s2.2-0.2,3.1-0.7l0.6,1.2c0,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0.1-0.1,0.2-0.2,0.1-0.3l-0.6-1.2c1.9-1,3.4-2.8,3.9-4.9l1.3,0.3
      c0,0,0,0,0,0c0.1,0,0.2-0.1,0.2-0.2C18.2,11.4,18.1,11.2,18,11.2z"
      fill="#0F5EF7"
      stroke="#0F5EF7"
      stroke-width="0.2"
    />
  </svg>
);

export default ClusterIcon;
