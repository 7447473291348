import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import AuthRouter from 'components/AuthRouter';
import store from 'state';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

// Commented the lines until the keycloak is fixed
import { AuthProvider } from 'hooks/useAuth';
import { ROUTE_AUTH_CALLBACK } from '../../constants';

const initConfig = {
  onLoad: 'login-required',
  promiseType: 'native',
  redirectUri: `${process.env.REACT_APP_DIRECTOR_DOMAIN}${process.env.PUBLIC_URL}${ROUTE_AUTH_CALLBACK}`,
};

const App = () => {
  const handleEvent = (event) => {
  };

  const queryClient = new QueryClient();

  let persistor = persistStore(store);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider initConfig={initConfig} onEvent={handleEvent}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <BrowserRouter basename={process.env.PUBLIC_URL}>
                <AuthRouter />
              </BrowserRouter>
            </SnackbarProvider>
          </PersistGate>
        </Provider>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
