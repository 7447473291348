import { useInfiniteQuery } from 'react-query';

import api from 'services/Api';

const useTickets = (entitlementId, ticketId) => {
  const fetchTickets = async (nextPage) => {
    const query = new URLSearchParams({ page: nextPage });

    if (entitlementId) {
      query.append('entitlement-ids', entitlementId);
    }

    if (ticketId) {
      query.append('ticket-ids', ticketId);
    }

    try {
      const response = await api.get(
        `csp/ticket/v1/tickets/?${query.toString()}`
      );

      const tickets = await response.json();

      return tickets.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useInfiniteQuery(
    ['tickets', entitlementId, ticketId],
    ({ pageParam = 0 }) => fetchTickets(pageParam),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: ({ meta }) => {
        if (meta.nextPage !== 0) {
          return meta.nextPage;
        }
        return false;
      },
    }
    // getFetchMore: (lastGroup) => lastGroup.meta.nextPage,
  );
};

export default useTickets;
