import { useQuery } from 'react-query';

import api from 'services/Api';

const useProject = (projectId) => {
  const fetchProjects = async (nextPage = 0) => {
    try {
      if (projectId) {
        const projects = await api.get(
          `appdir/applications/v1/projects/${projectId}`
        );
        const data = await projects.json();

        const {
          data: { project },
        } = data;

        return project;
      } else {
        return [];
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useQuery(['project', projectId], fetchProjects, {
    refetchOnWindowFocus: false,
  });
};

export default useProject;
