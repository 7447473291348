import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import UploadButton from 'components/UploadButton';
import CredentialType from 'schema/credential-type';
import useCreateCredentials from 'hooks/services/useCreateCredential';
import useUpdateCredential from 'hooks/services/useUpdateCredential';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  heading: {
    paddingTop: '29px',
  },
  headingTitle: theme.appDirector.pageHeading,
  section: {
    paddingTop: '40px',
  },
  label: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    paddingBottom: '14px',
  },
  button: {
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingTop: '36px',
  },
  required: {
    color: 'red',
  },
  cancelButton: {
    color: '#FF5E5E',
    backgroundColor: '#FFFFFF',
    border: '1px solid #FF5E5E',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
}));

const FromFile = ({
  flow,
  name,
  disable,
  credentialId,
  organizationId,
  projectId,
  fileData,
  setIsEmpty,
  handleCloseCredential,
  setCheckCredWarning,
  refetchCred,
  setOpenCreateCredential,
}) => {
  const [t] = useTranslation('credentials');
  const classes = useStyles();
  const history = useHistory();
  const [configJson, setConfigJson] = useState(
    fileData ? atob(fileData['.dockerconfigjson']) : ''
  );

  const { mutateAsync: credentialsCreater, isError } = useCreateCredentials();
  const { mutateAsync: credentialsUpdater } = useUpdateCredential(credentialId);

  const handleConfigJson = (e) => {
    setConfigJson(e.target.value);
  };

  const handleChange = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = (e) => {
      setConfigJson(e.target.result);
    };
  };

  useEffect(() => {
    if (configJson) {
      setCheckCredWarning(true);
    } else {
      setCheckCredWarning(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configJson]);

  const handleSubmit = async () => {
    const newCredentials = {
      name: name,
      type: CredentialType.DOCKER_CONFIG_JSON.enumKey,
      data: [
        {
          secretName: '.dockerconfigjson',
          secretData: btoa(configJson),
        },
      ],
    };

    try {
      if (flow === 'Create') {
        newCredentials['organizationId'] = organizationId;
        newCredentials['projectId'] = projectId;
        await credentialsCreater(newCredentials);
        if (!isError) {
          refetchCred();
          setOpenCreateCredential(false);
        }
      }
      if (flow === 'Update') {
        await credentialsUpdater(newCredentials);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Grid container>
        <Grid item container justify="space-between">
          <div className={classes.label}>
            {t('credentials.create.docker.file.configJson')}
            {flow === 'View' ? null : (
              <span className={classes.required}> *</span>
            )}
          </div>
          {flow === 'View' ? null : (
            <UploadButton handleChange={handleChange} setState={true}>
              {t('credentials.create.docker.file.importFile')}
            </UploadButton>
          )}
        </Grid>
        <FormControl fullWidth>
          <TextField
            id="filled-multiline-flexible"
            multiline
            rows={10}
            variant="outlined"
            value={configJson}
            onChange={(e) => {
              handleConfigJson(e);
              setIsEmpty(false);
            }}
            disabled={disable ? false : true}
          />
        </FormControl>
      </Grid>
      {flow === 'Update' || flow === 'Create' ? (
        <Grid item container className={classes.button}>
          <Button
            onClick={handleSubmit}
            disabled={name && configJson ? false : true}
          >
            {flow === 'Create' ? <>Add</> : <>Update</>}
            {t('credentials.create.docker.saveBtn')}
          </Button>
          <div style={{ paddingRight: '10px' }}>
            <Button
              onClick={handleCloseCredential}
              color="secondary"
              variant="outlined"
              className={classes.cancelButton}
            >
              Cancel
            </Button>
          </div>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};

export default FromFile;
