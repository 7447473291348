export const capitalizeEachWord = (str) => {
  var newStr = str.replace(/-/g, ' ');
  return newStr.replace(/\b(\w)/g, (s) => s.toUpperCase());
};

export const titleCase = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.replace(word[0], word[0].toUpperCase());
    })
    .join(' ');
};

export let isCamelCase = (name) => !name.match(/[A-Z]/);

export const SetEnvironmentColor = (index) => {
  if (index % 2 === 0) {
    return '#F3FBFF';
  } else {
    return '#F7FFF9';
  }
};
export const convert = (str, defaultDate) => {
  const formattedDate = str
    ? new Date(str).toISOString().split('T')[0]
    : defaultDate;

  return formattedDate;
};

export const getCurrentDateAsString = () => {
  const today = new Date();
  const date =
    today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  return date;
};

export const mapTickets = (tickets, entitlement) => {
  return tickets.map((value) => {
    const matchingTicket = entitlement?.find(
      (ticket) => value.entitlementId === ticket.entitlementId
    );

    return {
      ...value,
      customerId: matchingTicket?.customerId || null,
    };
  });
};

export const mapTicketsWithCustomerNames = (combinedTickets, customer) => {
  return combinedTickets.map((ticket) => {
    const matchingTicket = customer?.find(
      (value) => value.customerId === ticket.customerId
    );

    return {
      ...ticket,
      name: matchingTicket?.name,
      adminFirstName: matchingTicket?.adminFirstName || null,
      adminLastName: matchingTicket?.adminLastName || null,
    };
  });
};
