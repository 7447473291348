import React from 'react';
import { Switch, Route } from 'react-router-dom';

import SignIn from 'services/SignIn';
import AuthCallback from 'services/AuthCallback';
import AppInitializer from 'components/AppInitializer';
import { ROUTE_AUTH_CALLBACK, ROUTE_SIGN_IN } from '../../constants';

const AuthRouter = () => {
  return (
    <Switch>
      <Route exact path={ROUTE_AUTH_CALLBACK} component={AuthCallback} />
      <Route exact path={ROUTE_SIGN_IN} component={SignIn} />
      <Route path="/" component={AppInitializer} />
    </Switch>
  );
};

export default AuthRouter;
