import { useQuery } from 'react-query';

import api from 'services/Api';
import TicketStatus from 'schema/ticket-status';
const useFilteredTicketSummary = ({ entitlementId }) => {
  const query = new URLSearchParams({
    'entitlement-ids': entitlementId,
  });

  const fetchTickets = async () => {
    const titles = {
      [TicketStatus.OPENED.enumKey]: 'New Tickets',
      [TicketStatus.IN_REVIEW.enumKey]: 'Assigned Tickets',
      [TicketStatus.IN_PROGRESS.enumKey]: 'In-Progress / In-Review Tickets ',
      [TicketStatus.CLOSED.enumKey]: 'Resolved Tickets',
    };

    try {
      const response = await api.get(
        `csp/ticket/v1/tickets/summary/?${query.toString()}`
      );

      const {
        data: { ticket },
      } = await response.json();
      ticket['IN_PROGRESS'] = ticket['IN_PROGRESS'] + ticket['IN_REVIEW'];
      return Object.keys(titles).map((key) => {
        return { count: ticket[key] || 0, title: titles[key] };
      });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useQuery(['ticket/counts', entitlementId], fetchTickets, {
    refetchOnWindowFocus: false,
  });
};

export default useFilteredTicketSummary;
