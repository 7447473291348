import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import SearchBar from 'components/SearchBar';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';

import DeleteIcon from 'components/DeleteIcon';
import IconButton from 'components/IconButton';
import { Avatar } from '@material-ui/core';
import StatusType from 'schema/status-type';
import { selectOrganization } from 'state/auth/selectors';
import useUsers from 'hooks/services/useUsers';
import useUpdateProject from 'hooks/services/useUpdateProject';
import useProject from 'hooks/services/useProject';
import LoaderSpinner from 'components/LoaderSpinner';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '38em',
    height: '30em',
    padding: '20px',
    background: '#FFFFFF',
    boxShadow: '0px 1px 8px rgba(20, 46, 110, 0.1)',
    borderRadius: '8px',
  },
  list: {
    marginTop: '20px',
    overflowY: 'auto',
    maxHeight: '23em',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  deleteIcon: {
    width: '0.7em',
    height: '0.7em',
    paddingRight: '0.1em',
  },
  deleteText: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '13px',
    lineHeight: '18px',
    color: '#FF7C7C',
  },
  deleteTextForDeletedProjects: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '13px',
    lineHeight: '18px',
    color: '#b1b1b1',
  },
  text: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#000000',
  },
}));

const UsersProjects = ({ projectId }) => {
  const [t] = useTranslation('project');
  const classes = useStyles();
  const [usersArr, setUsersArr] = useState([]);
  const [assignedUsersArr, setAssignedUsersArr] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const organizationId = useSelector(selectOrganization);

  const { data: project, isFetching: isFetchingProject } =
    useProject(projectId);
  const { data: users } = useUsers({
    organizationId,
    states: 'ACTIVE',
  });

  const { mutateAsync: projectsUpdater } = useUpdateProject(projectId);

  const flattenedUsers =
    users &&
    users.pages.reduce((accum, curr) => {
      return [...accum, ...curr];
    }, []);

  const getUserName = (id) => {
    if (flattenedUsers) {
      const result = flattenedUsers.find((user) => user.id === id);
      if (result) {
        return result.firstName + ' ' + result.lastName;
      }
    }
  };

  useEffect(() => {
    if (project) {
      const userData = project?.assignedUsers.map((x) => {
        const arr = { id: x, name: getUserName(x), check: 'true' };
        return arr;
      });
      setAssignedUsers([...userData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, users]);

  useEffect(() => {
    if (assignedUsers && !isFetchingProject) {
      setAssignedUsersArr(assignedUsers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedUsers, isFetchingProject]);

  useEffect(() => {
    if (users) {
      const userList = flattenedUsers.map((user) => {
        const modifiedUser = {
          id: user.id,
          name: user.firstName + ' ' + user.lastName,
        };
        return modifiedUser;
      });

      setUsersArr([...userList]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const handleIsChecked = (e, name, id) => {
    if (e.target.checked) {
      setAssignedUsersArr([...assignedUsersArr, { name, id }]);
    } else {
      const result = assignedUsersArr.filter((user) => user.id !== id);
      setAssignedUsersArr([...result]);
    }
    setIsUpdated(true);
  };

  const removeUser = (id) => {
    const result = assignedUsersArr.filter((user) => user.id !== id);
    setAssignedUsersArr([...result]);
    setIsUpdated(true);
  };

  const getState = (userId) => {
    const result = assignedUsersArr.find(({ id }) => id === userId);
    if (result) {
      return true;
    } else {
      return false;
    }
  };

  const disable = (status) => {
    if (status === StatusType.DELETED.displayValue) {
      return true;
    } else {
      return false;
    }
  };

  const disableIconButton = (status) => {
    if (status === StatusType.DELETED.displayValue) {
      return true;
    } else {
      return false;
    }
  };

  const setDeleteButtonColor = (status) => {
    if (status === StatusType.DELETED.displayValue) {
      return '#b1b1b1';
    } else {
      return '#FF7C7C';
    }
  };

  const setDeleteTextStyles = (status) => {
    if (status === StatusType.DELETED.displayValue) {
      return classes.deleteTextForDeletedProjects;
    } else {
      return classes.deleteText;
    }
  };

  useEffect(() => {
    if (isUpdated) {
      handleAssignUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated]);

  const handleAssignUsers = async () => {
    const assignUserIds = assignedUsersArr.map(({ id }) => {
      return id;
    });
    const newProject = {
      organizationId,
      name: project.name,
      description: project.description,
      assignedUsers: assignUserIds,
      tags: project.tags,
    };
    try {
      await projectsUpdater(newProject);
      setIsUpdated(false);
    } catch (e) {
      console.log(e);
    }
  };

  const allUsers = () => (
    <>
      <p className={classes.text}> {t('projects.details.users.allUsers')} </p>
      <Grid item container>
        <Paper className={classes.paper}>
          <SearchBar placeholder={t('projects.details.users.searchText')} />
          <div className={classes.list}>
            <List dense component="div" role="list">
              {usersArr &&
                usersArr.map(({ name, id }) => (
                  <ListItem
                    disabled={disable(project?.status)}
                    role="listitem"
                    button
                  >
                    <ListItemIcon>
                      <Checkbox
                        id="input-check"
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleChecked />}
                        onChange={(e) => handleIsChecked(e, name, id)}
                        tabIndex={-1}
                        checked={getState(id)}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={id}
                      primary={
                        <Grid item container spacing={1}>
                          <Grid item>
                            <Avatar
                              style={{ width: '1em', height: '1em' }}
                              src={encodeURI(
                                'https://avatars.dicebear.com/api/initials/' +
                                  `${name}` +
                                  '.svg'
                              )}
                            />
                          </Grid>
                          <Grid item>
                            <span className={classes.text}>{name}</span>
                          </Grid>
                        </Grid>
                      }
                    />
                  </ListItem>
                ))}

              <ListItem />
            </List>
          </div>
        </Paper>
      </Grid>
    </>
  );

  const allAssignedUsers = () => (
    <>
      <p className={classes.text}>{t('projects.details.users.assignUsers')}</p>
      <Grid item container>
        <Paper className={classes.paper}>
          <SearchBar placeholder={t('projects.details.users.searchText')} />
          <div className={classes.list}>
            <List dense>
              {assignedUsers &&
                assignedUsers.map(({ name, id }) => (
                  <>
                    {name ? (
                      <ListItem
                        disabled={disable(project?.status)}
                        role="listitem"
                      >
                        <ListItemText
                          id={id}
                          primary={
                            <Grid item container spacing={1}>
                              <Grid item>
                                <Avatar
                                  style={{
                                    width: '1em',
                                    height: '1em',
                                  }}
                                  src={encodeURI(
                                    'https://avatars.dicebear.com/api/initials/' +
                                      `${name}` +
                                      '.svg'
                                  )}
                                />
                              </Grid>
                              <Grid item>
                                <span className={classes.text}>{name}</span>
                              </Grid>
                            </Grid>
                          }
                        />
                        <IconButton
                          id="btn-remove-user"
                          disabled={disableIconButton(project?.status)}
                          onClickHandler={() => removeUser(id)}
                        >
                          <DeleteIcon
                            className={classes.deleteIcon}
                            color={setDeleteButtonColor(project?.status)}
                          />
                          <span
                            className={setDeleteTextStyles(project?.status)}
                          >
                            {t('projects.details.users.remove')}
                          </span>
                        </IconButton>
                      </ListItem>
                    ) : (
                      <Grid container sm={12}>
                        <LoaderSpinner
                          type="Oval"
                          color="#00BFFF"
                          height={30}
                          width={30}
                        />
                      </Grid>
                    )}
                  </>
                ))}

              <ListItem />
            </List>
          </div>
        </Paper>
      </Grid>
    </>
  );

  return (
    <Grid container>
      {project ? (
        <>
          <Grid item xs={6}>
            {allUsers()}
          </Grid>
          <Grid item xs={6}>
            {allAssignedUsers()}
          </Grid>
        </>
      ) : (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ width: '100%', height: '100%' }}
        >
          <LoaderSpinner type="Oval" color="#00BFFF" height={45} width={45} />
        </Grid>
      )}
    </Grid>
  );
};

export default UsersProjects;
