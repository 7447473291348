import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import { TabContext } from '@material-ui/lab';
import Button from '@material-ui/core/Button';

import BackIcon from 'components/BackIcon';
import IconButton from 'components/IconButton';
import OverviewProjects from 'pages/Customers/overview-customers';
import UserProjects from 'pages/Customers/user-customers';
import ListApplications from 'pages/Applications/list-applications';
import { selectProjectIds } from 'state/projectId/selector';
import { selectOrganization } from 'state/auth/selectors';
import StatusType from 'schema/status-type';
import LabeledChip from 'components/LabeledChip';
import DeleteIcon from 'components/DeleteIcon';
import AlertDialogDelete from 'components/AlertDialogDelete';
import useDeleteProject from 'hooks/services/useDeleteProject';
import {
  convertTime,
  convertDeletedDateToLocalTime,
  convertLocalTimestamp,
} from 'time';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  headingTitle: theme.appDirector.pageHeading,
  section: {
    paddingTop: '40px',
    height: '80%',
    paddingBottom: '40px',
  },
  deletedProjectTitle: {
    padding: '27px 25px 40px 0px',
  },
  deletedProjectInfo: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    color: '#808CA3',
    fontWeight: 600,
    fontSize: '15px',
    paddingRight: '5px',
    verticalAlign: 'middle',
  },
  btn: {
    minWidth: '133px',
  },
  menuIconRoot: {
    width: '16px',
    height: '16px',
    color: '#808CA3',
    marginRight: '10px',
  },
  editIconRoot: {
    marginRight: '10px !important',
  },
  deleteBtn: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    textTransform: 'none',
    color: '#FF7C7C',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    minWidth: '50px',
    padding: '40px 2px 40px 0px',
  },
  deleteBtnIcon: {
    width: '25px',
    height: '25px',
    color: '#808CA3',
    marginRight: '25px',
  },
  topic: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '0.875rem',
    lineHeight: '20px',
    color: '#001847',
  },
  value: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '0.875rem',
    lineHeight: '20px',
    color: '#001847',
  },
  detailsBox: {
    marginLeft: '6em',
    marginBottom: '2%',
    gap: '4em',
    display: 'flex',
  },
  tabList: {
    marginLeft: '3em',
    justifyContent: 'space-evenly',
  },
  tab: {
    '@media (max-width: 1300px)': {
      margin: '0',
    },
  },
}));

const DetailCustomer = ({
  location: {
    state: { data, flow, tabValue, backNavigation, backNavigationData },
  },
}) => {
  const [t] = useTranslation('project');
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState(0);

  const orgId = useSelector(selectOrganization);
  const cachedProjectId = useSelector(selectProjectIds);

  const [openDeleteAlert, setOpenDeleteAlert] = useState();
  const [openReferenceAlert, setOpenReferenceAlert] = useState();
  const [openDeleteSuccessAlert, setOpenDeleteSuccessAlert] = useState();
  const [projectIdToDelete, setProjectIdToDelete] = useState();
  const [openPrepareAlert, setOpenPrepareAlert] = useState();

  const [applications, setApplications] = useState();
  const [clusterAgents, setClusterAgents] = useState();
  const [users, setUsers] = useState();
  const [credentials, setCredentials] = useState();
  const [integrations, setIntegrations] = useState();
  const [currentProjectStatus, setCurrentProjectStatus] = useState(
    StatusType.ACTIVE.displayValue
  );

  const cachedAllProjectId = cachedProjectId?.allProjectId;
  const cachedProjectStatus = cachedProjectId?.projectStatus;

  const organizationId = data?.projectId === cachedAllProjectId ? '' : orgId;

  const projectName = data?.projectId === cachedAllProjectId ? 'all' : '';

  const projectId =
    data?.projectId === cachedAllProjectId
      ? cachedAllProjectId
      : data?.projectId;

  const { mutateAsync: projectDeleter } = useDeleteProject({
    projectId: projectIdToDelete,
    organizationId: organizationId,
  });

  const handleClose = () => {
    history.push({
      pathname: `/projects`,
    });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (tabValue) {
      setValue(tabValue);
    }
  }, [tabValue]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const a11yProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  };

  useEffect(() => {
    if (tabValue) {
      setValue(tabValue);
    }
  }, [tabValue]);

  const handleClickOpen = (e) => {
    e.stopPropagation();
    setOpenDeleteAlert(true);
    setProjectIdToDelete(projectId);
  };

  const handleAlertClose = () => {
    setOpenDeleteAlert(false);
    setOpenReferenceAlert(false);
    setOpenDeleteSuccessAlert(false);
    setOpenPrepareAlert(false);
    setProjectIdToDelete(null);
  };

  const handleOkDelete = async () => {
    setOpenDeleteAlert(false);
    setOpenPrepareAlert(true);
    try {
      const deleteProject = await projectDeleter();
      if (deleteProject?.data?.projectDeleteData?.references === true) {
        setApplications(
          deleteProject?.data?.projectDeleteData?.referenceCount?.applications
        );
        setClusterAgents(
          deleteProject?.data?.projectDeleteData?.referenceCount?.clusterAgents
        );
        setUsers(deleteProject?.data?.projectDeleteData?.referenceCount?.users);
        setCredentials(
          deleteProject?.data?.projectDeleteData?.referenceCount?.credentials
        );
        setIntegrations(
          deleteProject?.data?.projectDeleteData?.referenceCount?.integrations
        );
        setOpenReferenceAlert(true);
        setOpenPrepareAlert(false);
      } else if (deleteProject?.data?.projectDeleteData?.references === false) {
        setOpenDeleteSuccessAlert(true);
        setOpenPrepareAlert(false);
        setCurrentProjectStatus(StatusType.DELETED.displayValue);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Grid container classes={{ container: classes.gridContainer }}>
      <Grid item container justify="space-between">
        <span className={classes.headingTitle}>
          <IconButton id="btn-back" onClickHandler={handleClose}>
            <BackIcon />
          </IconButton>
          {t('projects.details.title', { name: `${data?.name}` })}
        </span>
        {data?.status === StatusType.DELETED.displayValue ||
        currentProjectStatus === StatusType.DELETED.displayValue ? (
          <span className={classes.deletedProjectTitle}>
            <span className={classes.deletedProjectInfo}>
              {t('projects.details.deletedDate', {
                date: `${convertDeletedDateToLocalTime(
                  data?.markedAsDeletedTime
                )}`,
              })}
            </span>
            <LabeledChip
              label={'Deleted Project'}
              backgroundColor={'#B5B5B5'}
            />
          </span>
        ) : (
          <Button
            id="btn-delete"
            variant="text"
            classes={{
              root: classes.deleteBtn,
            }}
            onMouseUp={(e) => {
              handleClickOpen(e);
            }}
            onTouchTap={(e) => {
              handleClickOpen(e);
            }}
            endIcon={<DeleteIcon className={classes.deleteBtnIcon} />}
          ></Button>
        )}
      </Grid>
      <div className={classes.detailsBox}>
        {data?.description && (
          <div>
            <span className={classes.topic}>
              {t('projects.details.overview.desc')}:{' '}
            </span>
            <span className={classes.value}>{data?.description}</span>
          </div>
        )}
        {data?.createdDate && (
          <div>
            <span className={classes.topic}>
              {t('projects.details.overview.date')}:{' '}
            </span>
            <span className={classes.value}>
              {convertLocalTimestamp(data?.createdDate)}
            </span>
          </div>
        )}
      </div>

      <AlertDialogDelete
        openDeleteAlert={openDeleteAlert}
        handleOkDelete={handleOkDelete}
        handleClose={handleAlertClose}
        openReferenceAlert={openReferenceAlert}
        openDeleteSuccessAlert={openDeleteSuccessAlert}
        openPrepareAlert={openPrepareAlert}
        applications={applications}
        clusterAgents={clusterAgents}
        users={users}
        credentials={credentials}
        integrations={integrations}
        name={data?.name}
        type="project"
      />

      <TabContext value={value}>
        <Tabs
          value={value}
          onChange={handleChange}
          scrollButtons="auto"
          variant="scrollable"
          indicatorColor="primary"
          textColor="primary"
          className={classes.tabList}
        >
          <Tab
            label={t('projects.details.overview.title')}
            {...a11yProps(0)}
            className={classes.tab}
          />
          <Tab
            label={t('projects.details.apps.title')}
            {...a11yProps(1)}
            className={classes.tab}
          />
          <Tab
            label={t('projects.details.users.title')}
            {...a11yProps(2)}
            className={classes.tab}
          />
          <Tab
            label={t('projects.details.clusterAgents.title')}
            {...a11yProps(3)}
            className={classes.tab}
          />
        </Tabs>
        <Grid item style={{ marginLeft: '4em' }}>
          <TabPanel value={value} index={0}>
            <OverviewProjects projectData={data} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ListApplications
              organizationId={organizationId}
              projectName={projectName}
              projectId={projectId}
              cachedAllProjectId={cachedAllProjectId}
              cachedProjectStatus={cachedProjectStatus}
              projectData={data}
              projectFlow={flow}
              projectTabValue={value}
              projectBackNavigation={backNavigation}
              projectBackNavigationData={backNavigationData}
              tableHeightPercentage={50}
            />
          </TabPanel>
          <TabPanel value={value} index={2} style={{ marginTop: '3em' }}>
            <UserProjects data={data} projectId={projectId} />
          </TabPanel>
        </Grid>
      </TabContext>
    </Grid>
  );
};

export default DetailCustomer;
