import { useMutation, useQueryClient } from 'react-query';

import api from 'services/Api';

const useUpdateCredential = (credentialId) => {
  const QueryClient = useQueryClient();
  const credentialApi = `appdir/credentials/v1/credentials/${credentialId}`;

  return useMutation(
    (obj) => api.put(credentialApi, JSON.stringify(obj)),
    {
      onSuccess: async () => {
        QueryClient.invalidateQueries('success');
      },
    },
    {
      onError: async () => {
        console.log('error');
      },
    }
  );
};

export default useUpdateCredential;
