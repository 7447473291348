import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import CredentialTypeForIntegrations from 'pages/Integrations/manage-integrations/credentialTypesMap';
import LabeledCustomSelect from 'components/LabeledCustomSelect';
import useCreateIntegration from 'hooks/services/useCreateIntegration';
import useCredential from 'hooks/services/useCredential';
import { useSelector } from 'react-redux';
import { selectProjectId } from 'state/projectId/selector';
import { selectOrganization } from 'state/auth/selectors';
import BrowserCloseValidation from 'validation/browserCloseValidation';
import CloseButton from 'components/CloseButton';
import CreateCredential from 'components/CreateCredential';
import CredentialType from 'schema/credential-type';
import AlertDialog from 'components/AlertDialog';
import StatusType from 'schema/status-type';
import scanSourceInputValidation from 'validation/scanSourceInputValidation';
import ReposAndRegistryTypes from 'schema/reposAndRegistryTypes';
import useUpdateIntegration from 'hooks/services/useUpdateIntegration';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  heading: {
    paddingTop: '29px',
  },
  headingTitle: theme.appDirector.pageHeading,
  section: {
    padding: '10px 25px 0px 25px',
    gap: '19px',
    flexWrap: 'nowrap',
  },
  sectionTwo: {
    padding: '20px 25px 0px 25px',
    gap: '19px',
    flexWrap: 'nowrap',
  },
  label: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    paddingBottom: '14px',
  },
  block: {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: '36px 25px 10px 0px',
  },
  errorTextField: {
    borderColor: 'red !important',
  },
  required: {
    color: 'red',
  },
  cancelButton: {
    color: '#FF5E5E',
    backgroundColor: '#FFFFFF',
    border: '1px solid #FF5E5E',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
}));

const SonarIntegration = ({
  location: {
    state: { data, path },
  },
  handleCloseIntegration,
  refetch,
  setCheckWarning,
  setOpenCreateIntegration,
}) => {
  const [t] = useTranslation('registries');
  const classes = useStyles();
  const { register, getValues, reset } = useForm({
    mode: 'all',
  });
  const [openAlertCred, setOpenCredAlert] = useState(false);
  const [checkCredWarning, setCheckCredWarning] = useState(false);
  const [serverUrl, setServerUrl] = useState(data?.data?.host);
  const [credName, setCredName] = useState(data?.credentialId);
  const [isUpdating, setIsUpdating] = useState(false);
  const [errors, setErrors] = useState({});
  const [openCreateCredential, setOpenCreateCredential] = useState(false);
  const [setDirty, setPristine] = BrowserCloseValidation();
  const { enqueueSnackbar } = useSnackbar();

  const name = 'sonar-server-url';

  const organizationId = useSelector(selectOrganization);

  const cachedProjectId = useSelector(selectProjectId);
  const projectId = cachedProjectId?.projectId;

  const { data: credentials, refetch: refetchCred } = useCredential({
    organizationId,
    projectId,
    types: CredentialTypeForIntegrations.sonar,
    status: StatusType.ACTIVE.enumKey,
  });

  const flattenedCredentials =
    credentials &&
    credentials.pages.reduce((accum, curr) => {
      return [...accum, ...curr.credentials];
    }, []);

  const flattenedCredentialsModified =
    flattenedCredentials &&
    flattenedCredentials.map((cred, index) => {
      const obj = {
        key: index,
        value: cred.credentialId,
        name: cred.name,
      };
      return obj;
    }, []);

  const { mutateAsync: integrationCreater, isCreateError } =
    useCreateIntegration();

  const { mutateAsync: integrationUpdater, isUpdateError } =
    useUpdateIntegration({
      projectId,
      integrationId: data?.value,
    });

  useEffect(() => {
    if (serverUrl !== data?.data?.host || credName !== data?.credentialId) {
      setCheckWarning(true);
    } else {
      setCheckWarning(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverUrl, name, credName]);

  const handleServerUrl = (e) => {
    setErrors(scanSourceInputValidation('sonarServerURL', e.target.value));
    setServerUrl(e.target.value);
  };

  const handleCredential = (e) => {
    setCredName(e.target.value);
  };

  const handleCreateCredential = () => {
    setOpenCreateCredential(true);
  };

  const handleCloseCredential = () => {
    if (checkCredWarning) {
      setOpenCredAlert(true);
    } else {
      setOpenCreateCredential(false);
    }
  };

  const handleAlertOk = () => {
    setOpenCredAlert(false);
    setOpenCreateCredential(false);
    setCheckCredWarning(false);
  };

  const handleAlertClose = () => {
    setOpenCredAlert(false);
  };

  const location = {
    state: {
      data: { type: CredentialType.TOKEN.enumKey },
      flow: 'Create',
    },
  };

  const setEnqueueSnackbar = (msg, snackerVariant) => {
    enqueueSnackbar(msg, {
      variant: snackerVariant,
      autoHideDuration: 3000,
    });
  };

  const handleClick = async () => {
    const values = getValues();

    if (path === 'Edit') {
      const newIntegration = {
        name: name,
        type: ReposAndRegistryTypes.SONAR.enumKey,
        data: {
          host: values?.serverUrl,
        },
      };
      setIsUpdating(true);
      try {
        newIntegration['credentialId'] = credName;
        await integrationUpdater(newIntegration);
        if (!isUpdateError) {
          setEnqueueSnackbar(
            t('integrations.update.sonar.snackBarMsg'),
            'success'
          );
          setCheckWarning(false);
          reset({ host: '', credential: '' });
          setPristine();
          refetch();
          setOpenCreateIntegration(false);
        } else {
          setEnqueueSnackbar(
            t('integrations.update. sonar.snackBarErrorMsg'),
            'error'
          );
        }
      } catch (e) {
        setEnqueueSnackbar(
          t('integrations.update.sonar.snackBarErrorMsg'),
          'error'
        );
        console.log(e);
      }
      setIsUpdating(false);
    } else {
      const newIntegration = {
        organizationId,
        name: name,
        type: ReposAndRegistryTypes.SONAR.enumKey,
        data: {
          host: values?.serverUrl,
        },
        projectId: projectId,
      };
      setIsUpdating(true);
      try {
        newIntegration['projectId'] = projectId;
        newIntegration['credentialId'] = credName;
        await integrationCreater(newIntegration);
        if (!isCreateError) {
          setEnqueueSnackbar(
            t('integrations.create.sonar.snackBarMsg'),
            'success'
          );
          setCheckWarning(false);
          reset({ host: '', credential: '' });
          setPristine();
          refetch();
          setOpenCreateIntegration(false);
        } else {
          setEnqueueSnackbar(
            t('integrations.create.sonar.snackBarErrorMsg'),
            'error'
          );
        }
      } catch (e) {
        setEnqueueSnackbar(
          t('integrations.create.sonar.snackBarErrorMsg'),
          'error'
        );
        console.log(e);
      }
      setIsUpdating(false);
    }
  };

  const setButtonDisable = () => {
    if (isUpdating) {
      return true;
    } else {
      if (path === 'Edit') {
        return name && serverUrl && credName ? false : true;
      } else {
        return credName && serverUrl && Object.keys(errors).length === 0
          ? false
          : true;
      }
    }
  };

  return (
    <Grid container classes={{ container: classes.gridContainer }}>
      <Grid item className={classes.section}>
        <div className={classes.label}>
          {t('integrations.create.sonar.url')}
          <span className={classes.required}> *</span>
        </div>
        <FormControl fullWidth>
          <TextField
            inputRef={register}
            name="serverUrl"
            placeholder={t('integrations.create.sonar.urlPlaceholder')}
            variant="outlined"
            value={serverUrl}
            onChange={(e) => {
              handleServerUrl(e);
              setDirty();
            }}
            disabled={path === 'View' ? true : false}
            helperText={errors?.sonarServerURL ? errors.sonarServerURL : ''}
            error={errors.sonarServerURL}
          />
        </FormControl>
      </Grid>
      <Grid item container className={classes.sectionTwo}>
        <Grid item xs={6}>
          <LabeledCustomSelect
            label={t('integrations.create.img.credential')}
            name="credential"
            create="Add Credential"
            handleCreate={handleCreateCredential}
            id="credential-select"
            placeholder={t('integrations.create.img.credPlaceholder')}
            required
            register={register}
            value={credName}
            onChange={(e) => {
              handleCredential(e);
              setDirty();
            }}
            selectItems={
              flattenedCredentials ? flattenedCredentialsModified : []
            }
            path={path}
          />
        </Grid>
      </Grid>
      <Grid item container className={classes.block}>
        {path !== 'View' ? (
          <>
            <Button
              disabled={setButtonDisable()}
              className={classes.button}
              onClick={handleClick}
            >
              {path === 'Edit' ? 'Save' : 'Create'}
            </Button>
            <div style={{ paddingRight: '10px' }}>
              <Button
                onClick={handleCloseIntegration}
                color="secondary"
                variant="outlined"
                className={classes.cancelButton}
              >
                Cancel
              </Button>
            </div>
          </>
        ) : null}
      </Grid>
      <Dialog
        open={openCreateCredential}
        fullWidth
        maxWidth="md"
        onClose={handleCloseCredential}
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <div>Add Credential</div>
            <CloseButton onClick={handleCloseCredential} id="closeButton"/>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <CreateCredential
            location={location}
            handleCloseCredential={handleCloseCredential}
            setOpenCreateCredential={setOpenCreateCredential}
            refetchCred={refetchCred}
            type="scanSource"
            setCheckCredWarning={setCheckCredWarning}
          />
        </DialogContent>
      </Dialog>
      <AlertDialog
        title="Do you want to leave?"
        buttonConfirmText="Ok"
        buttonCancelText="Cancel"
        open={openAlertCred}
        handleOk={handleAlertOk}
        handleClose={handleAlertClose}
      >
        The changes you made will be lost.
      </AlertDialog>
    </Grid>
  );
};

export default SonarIntegration;
