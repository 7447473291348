import React from 'react';

const ClusterAgentIcon = ({ className }) => (
  <svg
    className={className}
    height="20"
    viewBox="0 0 30.26 31.98"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.59,15.78h0v2.66h0Z" transform="translate(-0.87 -0.02)" />
    <path d="M22.4,15.87v2.6h0V15.74h0Z" transform="translate(-0.87 -0.02)" />
    <path
      d="M21.25,14.33a2.69,2.69,0,0,0-2.19-1.14H12.94a2.74,2.74,0,0,0-1.42.4,2.82,2.82,0,0,0-1,1,2.74,2.74,0,0,0-.3,1.25v4.52A2.69,2.69,0,0,0,12.9,23H19a2.69,2.69,0,0,0,2.68-2.68V15.87A2.62,2.62,0,0,0,21.25,14.33Zm-7.09,3.28a.51.51,0,0,1-1,.2.67.67,0,0,1,0-.2V16.7a.5.5,0,0,1,.6-.4.51.51,0,0,1,.4.4Zm2.75,2.46H15.07a.52.52,0,0,1,0-1h1.84a.51.51,0,0,1,.64.36.52.52,0,0,1-.35.64A.48.48,0,0,1,16.91,20.07Zm1.95-2.46a.51.51,0,0,1-.36.64.52.52,0,0,1-.64-.35.48.48,0,0,1,0-.29V16.7a.52.52,0,0,1,1,0Z"
      transform="translate(-0.87 -0.02)"
    />
    <path
      d="M9.62,15.78v2.66h0c-.57-.09-1-.4-1-.73V16.56C8.62,16.19,9,15.88,9.62,15.78Z"
      transform="translate(-0.87 -0.02)"
    />
    <path
      d="M23.38,16.55v1.16c0,.34-.41.67-1,.76h0V15.74h0C23,15.84,23.38,16.17,23.38,16.55Z"
      transform="translate(-0.87 -0.02)"
    />
    <path
      d="M22.16,10.33a1.39,1.39,0,0,1-.91,1.31v1.7a3.41,3.41,0,0,0-.95-.58V11.65a1.4,1.4,0,1,1,1.86-1.32Z"
      transform="translate(-0.87 -0.02)"
    />
    <path
      d="M12.46,10.33a1.41,1.41,0,0,1-.94,1.32v1.19a3.38,3.38,0,0,0-1,.67V11.64a1.4,1.4,0,1,1,1.9-1.31Z"
      transform="translate(-0.87 -0.02)"
    />
    <path
      d="M16,32a3.69,3.69,0,0,1-1.75-.44L2.79,25.31A3.67,3.67,0,0,1,.87,22.09V9.91A3.67,3.67,0,0,1,2.79,6.69L14.25.46a3.68,3.68,0,0,1,3.5,0L29.21,6.69a3.67,3.67,0,0,1,1.92,3.22V22.09a3.67,3.67,0,0,1-1.92,3.22L17.75,31.54A3.6,3.6,0,0,1,16,32ZM16,1.72a1.93,1.93,0,0,0-.94.24L3.6,8.19a2,2,0,0,0-1,1.72V22.09a2,2,0,0,0,1,1.72L15.06,30a2,2,0,0,0,1.88,0L28.4,23.81a2,2,0,0,0,1-1.72V9.91a2,2,0,0,0-1-1.72L16.94,2A2,2,0,0,0,16,1.72Z"
      transform="translate(-0.87 -0.02)"
    />
  </svg>
);

export default ClusterAgentIcon;
