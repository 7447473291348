import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import DetailBox from './detail-box';

const useStyles = makeStyles((theme) => ({
  headingTitle: theme.appDirector.pageHeading,
  gridContainer: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  topic: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    paddingBottom: '14px',
  },
}));

const OverviewProjects = ({ projectData }) => {
  return (
    <>
      {/* <Grid container className={classes.gridContainer} spacing={2}>
        <Grid item xs={4}>
          <CalculationBox
            icon={<MemoryIcon />}
            label="Memory"
            value="1.5"
            unit="GB"
          />
        </Grid>

        <Grid item xs={4}>
          <CalculationBox
            icon={<CPUIcon />}
            label="CPU"
            value="500"
            unit="mi"
          />
        </Grid>
        <Grid item xs={4}>
          <CalculationBox
            icon={<EstimateIcon />}
            label="Estimate"
            value="575.00"
            unit="USD"
            color="#00BC8B"
          />
        </Grid>
      </Grid> */}
      <DetailBox projectData={projectData} />
    </>
  );
};

export default OverviewProjects;
