import { createSelector } from 'reselect';

export const selectProjectIds = (state) => state.projectId;

export const selectProjectId = createSelector(
  [selectProjectIds],
  ({ projectId, projectStatus, allProjectId, defaultProjectId }) => {
    return { projectId, projectStatus, allProjectId, defaultProjectId };
  }
);
