/* eslint-disable no-unused-expressions */
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import BillingMethod from 'schema/billing-method';
const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    color: props.statusColor,
    fontFamily: 'Nunito',
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '14px',
    textTransform: 'capitalize',
    lineHeight: '18px',
  }),
}));

const Billingmethod = ({ date }) => {
  const classes = useStyles();
  let method;

  // eslint-disable-next-line default-case
  switch (date) {
    case 'cc':
      method = BillingMethod.cc.displayValue;
      break;
    case 'inv':
      method = BillingMethod.inv.displayValue;
      break;
    case 'aws':
      method = BillingMethod.aws.displayValue;
      break;
    case 'az':
      method = BillingMethod.az.displayValue;
      break;
    case 'nb':
      method = BillingMethod.nb.displayValue;
      break;
    case 'ibm':
      method = BillingMethod.ibm.displayValue;
      break;
  }
  return <span className={classes.root}>{method}</span>;
};

export default Billingmethod;
