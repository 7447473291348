import { createSelector } from 'reselect';

export const selectButtonVisible = (state) => state.buttonVisible;

export const selectButtonVisibleData = createSelector(
  [selectButtonVisible],
  ({ buttonVisible }) => {
    return buttonVisible;
  }
);
