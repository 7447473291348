import React, { useEffect } from 'react';

import { useAuth } from 'hooks/useAuth';
import { ROUTE_AUTH_CALLBACK } from '../../constants';

const LogIn = () => {
  const { login } = useAuth();

  useEffect(() => {
    login({
      redirectUri: `${process.env.REACT_APP_DIRECTOR_DOMAIN}${process.env.PUBLIC_URL}${ROUTE_AUTH_CALLBACK}`,
    });
  }, [login]);

  return <h1>Redirecting to Login...</h1>;
};

export default LogIn;
