import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { useTheme, useMediaQuery } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMore from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import { useAuth } from 'hooks/useAuth';
import { selectUserName } from 'state/auth/selectors';
import { ROUTE_AUTH_CALLBACK } from '../../constants';
import Avatar from '../Avatar';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { selectGroupId } from 'state/auth/selectors';
import { selectButtonVisibleData } from 'state/saveButton/selector';
import { selectGeneralInfoData } from 'state/generalInfo/selectors';
import useGroups from 'hooks/services/useGroups';

const useStyles = makeStyles((theme) => ({
  company: {
    marginLeft: '95px',
  },
  companyName: {
    color: theme.palette.primary.main,
    fontFamily: 'Comfortaa',
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: '1rem',
    lineHeight: '18px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    '@media (min-width: 1960px)': {
      fontSize: '1.8rem',
    },
  },
  header: {
    paddingLeft: theme.appDirector.appContainer.paddingLeft,
    paddingRight: theme.appDirector.appContainer.paddingLeft,
    backgroundColor: 'white',
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    '@media (min-width: 1960px)': {
      paddingLeft: '6.6em',
    },
    [theme.breakpoints.down('xs', 'sm')]: {
      paddingLeft: '20px',
    },
  },
  headerScroll: {
    paddingLeft: theme.appDirector.appContainer.paddingLeft,
    paddingRight: theme.appDirector.appContainer.paddingLeft,
    backgroundColor: 'white',
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    boxShadow: '0px 2px 15px rgba(20, 46, 110, 0.1)',
  },
  toolbarMargin: {
    ...theme.mixins.toolbar,
  },
  toolbarRoot: {
    height: '59px',
    '@media (min-width: 1960px)': {
      height: '8em',
    },
  },
  profileSettings: {
    flexWrap: 'nowrap',
    height: '59px',
    width: 'auto',
    padding: '10px 0 10px 35px',
    '@media (min-width: 1960px)': {
      height: '112px',
    },
  },
  dividerRoot: {
    borderLeft: `1px solid ${theme.palette.primary.light}`,
    opacity: '0.5',
    '@media (min-width: 1960px)': {
      opacity: '1',
    },
  },
  expandMoreRoot: {
    padding: '0',
  },
  expandMoreLabel: {
    backgroundColor: theme.palette.grey[650],
    borderRadius: theme.shape.borderRadius,
  },
  icon: {
    minWidth: '36px',
  },
  profile: {
    display: 'flex',
    flexGrow: '1',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  userProfile: {
    display: 'flex',
    flexGrow: '1',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  dropdown: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '0.938rem',
  },
  appName: {
    paddingLeft: '150px',
  },
  deleteBtn: {
    marginRight: '10px',
  },
  deleteBtnIcon: {
    width: '29px',
    height: '29px',
    color: '#808CA3',
  },
  iconNew: {
    '@media (min-width: 1960px)': {
      fontSize: '35px',
    },
  },
  menuIcon: {
    color: '#808CA3',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const Header = ({ isDrawerOpen, onDrawerOpen }) => {
  const [t] = useTranslation('header');
  const classes = useStyles();
  const theme = useTheme();
  const { isAuthenticated, logout } = useAuth();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const removeProjectPath = useRouteMatch([
    '/projects',
    '/pipelines',
    '/clusters',
    '/audit-log',
    '/dashboard',
    '/iam',
    '/userProfile',
  ]);
  const buttonVisibility = useSelector(selectButtonVisibleData);
  const { generalInfo } = useSelector(selectGeneralInfoData);

  const userName = useSelector(selectUserName);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleMoreClick = (e) => {
    setAnchorEl(e.currentTarget);
    setOpenMenu(true);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleMenuItemClick = (e, i) => {
    handleClose();
  };

  const handleLogout = () => {
    const redirectUri = `${process.env.REACT_APP_DIRECTOR_DOMAIN}${process.env.PUBLIC_URL}${ROUTE_AUTH_CALLBACK}`;
    logout(redirectUri);
  };
  const groupId = useSelector(selectGroupId);
  const { data: groups } = useGroups();

  const flattenedGroups =
    groups &&
    groups.pages.reduce((accum, curr) => {
      return [...accum, ...curr];
    }, []);

  const [groupData, setGroupData] = useState();

  useEffect(() => {
    if (flattenedGroups) {
      const result = flattenedGroups.find((group) => group.id === groupId);
      setGroupData(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        classes={{
          root: buttonVisibility ? classes.headerScroll : classes.header,
        }}
      >
        <Toolbar disableGutters classes={{ root: classes.toolbarRoot }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            className={classes.menuIcon}
            onClick={onDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.company}>
            {buttonVisibility ? null : (
              <span className={classes.companyName}></span>
            )}
          </div>
          {buttonVisibility && (
            <div className={isDrawerOpen ? classes.appName : null}>
              <span className={classes.companyName}>
                {generalInfo?.applicationName}
              </span>
            </div>
          )}

          <div
            className={
              !removeProjectPath ? classes.profile : classes.userProfile
            }
          >
            {' '}
            :
            {isAuthenticated && !buttonVisibility && (
              <Grid
                container
                alignItems="center"
                classes={{ root: classes.profileSettings }}
              >
                <Divider flexItem classes={{ root: classes.dividerRoot }} />
                {isMd ? (
                  <Avatar userName={userName.fullName} group={groupData} />
                ) : (
                  <Avatar userName={userName.firstName} group={groupData} />
                )}

                <IconButton
                  aria-label="settings"
                  aria-controls="settings-menu"
                  aria-haspopup="true"
                  onClick={handleMoreClick}
                  color="inherit"
                  classes={{
                    root: classes.expandMoreRoot,
                    label: classes.expandMoreLabel,
                  }}
                >
                  <ExpandMore classes={{ root: classes.iconNew }} />
                </IconButton>

                <Menu
                  id="profile-menu"
                  anchorEl={anchorEl}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  open={openMenu}
                  onClose={handleClose}
                >
                  <MenuItem
                    data-id="userProfile"
                    to="/userProfile"
                    onClick={handleMenuItemClick}
                    component={NavLink}
                  >
                    <ListItemIcon className={classes.icon}>
                      <AccountCircleIcon />
                    </ListItemIcon>
                    <div className={classes.dropdown}>User Profile</div>
                  </MenuItem>
                  <MenuItem onClick={handleLogout} data-id="logout">
                    <ListItemIcon className={classes.icon}>
                      <PowerSettingsNewIcon />
                    </ListItemIcon>
                    <div className={classes.dropdown}>Logout</div>
                  </MenuItem>
                </Menu>
              </Grid>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.toolbarMargin} />
    </>
  );
};

export default Header;
