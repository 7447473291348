import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  isUpdating: false,
  closeApplication: false,
};

const closeApplicationSlice = createSlice({
  name: 'closeApplication',
  initialState: INITIAL_STATE,
  reducers: {
    closeApplicationUpdateStart(state, action) {
      state.isUpdating = true;
      state.closeApplication = false;
    },
    closeApplicationUpdateSuccess(state, { payload: { closeApp } }) {
      state.isUpdating = false;
      state.closeApplication = closeApp;
    },
    closeApplicationUpdateError(state, action) {
      state.isUpdating = false;
      state.closeApplication = false;
    },
  },
});

export const { name, actions, reducer } = closeApplicationSlice;
export const {
  closeApplicationUpdateStart,
  closeApplicationUpdateSuccess,
  closeApplicationUpdateError,
} = actions;
export default reducer;
