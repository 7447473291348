import React from 'react';

const DashboardIcon = (props) => (
  <svg className={props.className} width="30" height="30" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M50 0H0V50H50V0Z" />
<path d="M43.8999 10H6.09996C5.54308 10 5.00868 10.2212 4.615 10.615C4.22132 11.0087 4 11.5432 4 12.1V36.9671C4 37.524 4.22125 38.0581 4.615 38.4518C5.00875 38.8459 5.54316 39.0671 6.09996 39.0671H20.6863L19.7142 42.2481L17.8892 42.2478C17.5027 42.2478 17.1892 42.5612 17.1892 42.9478C17.1892 43.3343 17.5027 43.6478 17.8892 43.6478H32.1108C32.4973 43.6478 32.8108 43.3343 32.8108 42.9478C32.8108 42.5612 32.4973 42.2478 32.1108 42.2478H30.2858L29.3137 39.0671H43.9C44.4569 39.0671 44.9913 38.8459 45.385 38.4518C45.7788 38.0581 46 37.524 46 36.9671V12.1C46 11.5431 45.7788 11.0087 45.385 10.615C44.9912 10.2213 44.4568 10 43.9 10L43.8999 10ZM6.09996 11.4H43.8999C44.0855 11.4 44.2636 11.4737 44.3949 11.605C44.5261 11.7362 44.5999 11.9144 44.5999 12.1V32.2336H5.39996V12.1C5.39996 11.7134 5.7134 11.4 6.09996 11.4ZM28.8215 42.2471H21.1778L22.1499 39.0662H27.8493L28.8215 42.2471ZM43.8999 37.6665H6.09996C5.7134 37.6665 5.39996 37.3531 5.39996 36.9665V33.6331H44.5999V36.9665C44.5999 37.1521 44.5261 37.3299 44.3949 37.4615C44.2636 37.5928 44.0855 37.6665 43.8999 37.6665Z" fill="white"/>
<path d="M25.4967 34.95H24.5029C24.116 34.95 23.8029 35.2634 23.8029 35.65C23.8029 36.0365 24.116 36.35 24.5029 36.35H25.497H25.4967C25.8835 36.35 26.1967 36.0365 26.1967 35.65C26.1967 35.2634 25.8835 34.95 25.4967 34.95H25.4967Z" fill="#0F5EF7"/>
<path d="M8.48398 29.5651H18.3392C18.5248 29.5651 18.7029 29.4914 18.8342 29.3601C18.9654 29.2289 19.0392 29.0508 19.0392 28.8651V14.7676C19.0392 14.5819 18.9654 14.4041 18.8342 14.2729C18.7029 14.1413 18.5248 14.0676 18.3392 14.0676H8.48398C8.09742 14.0676 7.78398 14.381 7.78398 14.7676V28.8651C7.78398 29.0508 7.85773 29.2289 7.98929 29.3601C8.12054 29.4914 8.29836 29.5651 8.48398 29.5651ZM9.18398 15.4684L17.6392 15.4681V28.1656H9.18398V15.4684Z" fill="white"/>
<path d="M24.5487 21.0841H41.5159C41.7015 21.0841 41.8793 21.0104 42.0105 20.8791C42.1421 20.7479 42.2159 20.5697 42.2159 20.3841V14.77C42.2159 14.5844 42.1421 14.4063 42.0105 14.275C41.8793 14.1438 41.7015 14.07 41.5159 14.07H24.5487C24.1621 14.07 23.8487 14.3835 23.8487 14.77V20.3841C23.8487 20.5697 23.9224 20.7479 24.054 20.8791C24.1852 21.0104 24.3631 21.0841 24.5487 21.0841ZM25.2487 15.47H40.8159V19.6841H25.2487V15.47Z" fill="white"/>
<path d="M24.5487 29.5655H35.9159C36.1015 29.5655 36.2793 29.4918 36.4106 29.3605C36.5421 29.2293 36.6159 29.0512 36.6159 28.8655V23.2543C36.6159 23.0686 36.5421 22.8905 36.4106 22.7593C36.2793 22.628 36.1015 22.5543 35.9159 22.5543H24.5487C24.1621 22.5543 23.8487 22.8677 23.8487 23.2543V28.8683C23.8502 29.2536 24.1634 29.5655 24.5487 29.5655V29.5655ZM25.2487 23.9515H35.2159V28.1655H25.2487V23.9515Z" fill="white"/>
</svg>

);

export default DashboardIcon;
