import { useQuery } from 'react-query';

import api from 'services/Api';

const useAppDirectorDetailCount = (orgId) => {
  const fetchCounts = async () => {
    try {
      if (orgId) {
        const resources = await api.get(
          `appdir/director-aggregator/api/v1/summaries/counts?organization-id=${orgId}`
        );

        const data = await resources.json();
        return data.data.counts;
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useQuery(['counts', orgId], fetchCounts, {
    refetchOnWindowFocus: false,
  });
};

export default useAppDirectorDetailCount;
