import React, { useMemo } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Divider } from '@material-ui/core';
import ViewListIcon from '@material-ui/icons/ViewList';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Tooltip from '@material-ui/core/Tooltip';

import DataTable from 'components/ReactTable/tableInfiniteScroll';
import EditIcon from 'components/EditIcon';
import LabeledChip from 'components/LabeledChip';
import StatusType from 'schema/status-type';
import LoaderSpinner from 'components/LoaderSpinner';
import ErrorDataCard from 'components/ErrorDataCard';
import NoDataCard from 'components/NoDataCard';
import useCustomers from 'hooks/services/useCustomers';
import Billingmethod from 'schema/return-billingmethods';
const useStyles = makeStyles((theme) => ({
  gridContainer: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  headingTitle: theme.appDirector.pageHeading,
  section: {
    height: '80%',
    padding: '40px 0px',
    [theme.breakpoints.down('xs', 'sm')]: {
      overflowX: 'auto',
    },
  },
  btn: {
    minWidth: '133px',
    [theme.breakpoints.down('xs', 'sm')]: {
      minWidth: '30px',
    },
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    direction: 'rtl',
  },
  menuIconRoot: {
    color: '#808CA3',
    marginRight: '10px',
  },
  editIconRoot: {
    marginRight: '10px !important',
  },
  btnRoot: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    textTransform: 'none',
    color: '#808CA3',
    padding: 0,
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    minWidth: '50px',
    height: '26px',
  },
  divider: {
    margin: '0px 30px',
    '@media (max-width: 1330px)': {
      margin: '0px 0px',
    },
  },
  spinner: {
    width: '100%',
    height: '100%',
    marginTop: '10%',
  },
  btnText: {
    '@media (max-width: 1330px)': {
      display: 'none',
    },
  },
}));

const ListCustomers = () => {
  const [t] = useTranslation('customers');
  const matches = useMediaQuery('(max-width:1330px)');
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const { path } = useRouteMatch();
  const history = useHistory();
  const {
    data: customers,
    isFetching,
    isError,
    isFetchingNextPage: isFetchingMoreProjects,
    hasNextPage: canFetchMoreProjects,
    fetchNextPage: fetchMoreProjects,
  } = useCustomers();
  const handleRowClick = () => {};
  const flattenedCustomers =
    customers &&
    customers.pages.reduce((accum, curr) => {
      return [...accum, ...curr.customers];
    }, []);

  const getCountryCode = (rowData) => {
    let regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
    return regionNames.of(rowData['countryCode']);
  };

  const columns = useMemo(() => [
    {
      Header: 'Customer Id',
      accessor: 'customerId',
    },
    {
      Header: 'organization Id',
      accessor: 'organizationId',
    },
    {
      Header: t('customers.tableHeaders.name'),
      accessor: 'name',
    },
    {
      Header: t('customers.tableHeaders.countryCode'),
      accessor: getCountryCode,
    },
    {
      Header: t('customers.tableHeaders.billing'),
      accessor: 'billingMethod',
      Cell: ({
        cell: {
          row: { values },
        },
      }) => {
        return <Billingmethod date={values.billingMethod} />;
      },
    },
    {
      Header: t('customers.tableHeaders.adminEmailAddress'),
      accessor: 'adminEmailAddress',
    },
    {
      Header: t('customers.tableHeaders.manage.title'),
      accessor: 'actions',
      width: '30%',
      Cell: ({
        cell: {
          row: { values },
        },
      }) => {
        return (
          <div className={classes.actionContainer}>
            {values.status === StatusType.DELETED.displayValue ? (
              <LabeledChip
                label={'Deleted Project'}
                backgroundColor={'#B5B5B5'}
              />
            ) : (
              <>
                <Tooltip
                  title={t('customers.tableHeaders.manage.manageEntitlements')}
                >
                  <Button
                    id="btn-manage"
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push({
                        state: {
                          data: {
                            customerId: values.customerId,
                            customerName: values.name,
                            adminEmailAddress: values.adminEmailAddress,
                            organizationId: values.organizationId,
                          },
                          flow: 'Entitlements',
                        },
                        pathname: `${path}/${values.customerId}/entitlements`,
                      });
                    }}
                    variant="text"
                    classes={{
                      root: classes.btnRoot,
                    }}
                    endIcon={
                      <SettingsIcon
                        className={classes.menuIconRoot}
                        status={values.status}
                      />
                    }
                  ></Button>
                </Tooltip>
                <Divider
                  orientation="vertical"
                  flexItem
                  className={classes.divider}
                />
                <Tooltip title={t('customers.tableHeaders.manage.viewTicket')}>
                  <Button
                    id="btn-tickets"
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push({
                        state: {
                          flow: 'Tickets',
                          id: values.organizationId,
                        },
                        pathname: `${path}/${values.organizationId}/tickets`,
                      });
                    }}
                    variant="text"
                    classes={{
                      root: classes.btnRoot,
                    }}
                    endIcon={
                      <ViewListIcon
                        className={classes.editIconRoot}
                        color={'#808CA3'}
                      />
                    }
                  ></Button>
                </Tooltip>
                <Divider
                  orientation="vertical"
                  flexItem
                  className={classes.divider}
                />
                <Tooltip title="Edit">
                  <Button
                    id="btn-edit"
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push({
                        state: {
                          customerId: values.customerId,
                          path: 'edit',
                        },
                        pathname: `${path}/${values.customerId}/edit`,
                      });
                    }}
                    variant="text"
                    classes={{
                      root: classes.btnRoot,
                    }}
                    endIcon={
                      <EditIcon
                        color="#808CA3"
                        className={classes.PipelineAndEventIconRoot}
                      />
                    }
                  ></Button>
                </Tooltip>
              </>
            )}
          </div>
        );
      },
    },
  ]);

  return (
    <Grid container classes={{ container: classes.gridContainer }}>
      <Grid item container justifyContent="space-between">
        <span className={classes.headingTitle}>{t('customers.title')}</span>
        <div>
          <Button
            id="btn-create-project"
            endIcon={<AddCircleOutlineIcon />}
            classes={{ root: classes.btn }}
            onClick={() =>
              history.push({
                state: {
                  data: {
                    tags: [],
                  },
                  path: 'create',
                  projectId: '',
                },
                pathname: `${path}/create`,
              })
            }
          >
            {isXs ? 'Create' : <span>{t('customers.create.createBtn')}</span>}
          </Button>
        </div>
      </Grid>
      {isError ? (
        <ErrorDataCard message={t('customers.errorMsg')} />
      ) : isFetching && !customers ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.spinner}
        >
          <LoaderSpinner type="Oval" color="#00BFFF" height={60} width={60} />
        </Grid>
      ) : flattenedCustomers.length === 0 ? (
        <NoDataCard message={t('customers.noDataMsg')} />
      ) : (
        <Grid item className={classes.section}>
          <DataTable
            data={flattenedCustomers}
            onClickTableRow={handleRowClick}
            hiddenColumns={
              matches
                ? [
                    'customerId',
                    'organizationId',
                    'status',
                    'description',
                    'tags',
                  ]
                : ['customerId', 'organizationId', 'status']
            }
            columns={columns}
            isNextPageLoading={isFetchingMoreProjects}
            hasNextPage={canFetchMoreProjects}
            loadMore={fetchMoreProjects}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ListCustomers;
