import { createSelector } from 'reselect';

export const selectGeneralInfo = (state) => state.generalInfo;

export const selectGeneralInfoData = createSelector(
  [selectGeneralInfo],
  (genInfo) => {
    return genInfo;
  }
);
