import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const styles = (theme) => ({
  root: {
    backgroundColor: 'transparent',
  },
  iconRoot: {
    color: 'rgba(15, 94, 247, 1)',
    '&$activeIcon': {
      color: 'red',
    },
    '&$completedIcon': {
      color: 'rgba(15, 94, 247, 1)',
    },
  },
  activeIcon: {}, // required
  completedIcon: {}, // required
  connector: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '8px',
      marginRight: '8px',
    },
  },
});

const StepperNav = ({ classes, steps, currentStep }) => {
  return (
    <Stepper
      activeStep={currentStep - 1}
      classes={{ root: classes.root, connector: classes.connector }}
      alternativeLabel
    >
      {steps.map((label) => {
        const stepProps = {};
        const labelProps = {};

        return (
          <Step key={label} {...stepProps} alternativeLabel>
            <StepLabel
              StepIconProps={{
                classes: {
                  root: classes.iconRoot,
                  active: classes.activeIcon,
                  completed: classes.completedIcon,
                },
              }}
              {...labelProps}
            >
              {label}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default withStyles(styles)(StepperNav);
