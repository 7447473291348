import { createSelector } from 'reselect';

const selectAuth = (state) => state.auth;

export const selectAuthenticated = createSelector(
  [selectAuth],
  (auth) => auth.isAuthenticated
);

export const selectProfile = createSelector(
  [selectAuth],
  (auth) => auth.profile
);

export const selectUserName = createSelector(
  [selectProfile],
  ({ firstName, lastName }) => {
    return { firstName, lastName, fullName: `${firstName} ${lastName}` };
  }
);

export const selectOrganization = createSelector([selectProfile], (profile) => {
  localStorage.setItem(
    'org-id',
    profile && profile.attributes && profile.attributes.orgId[0]
  );
  return profile && profile.attributes && profile.attributes.orgId[0];
});

export const selectUser = createSelector([selectProfile], (profile) => {
  localStorage.setItem(
    'user-id',
    profile && profile.attributes && profile.attributes.userId[0]
  );
  return profile && profile.attributes && profile.attributes.userId[0];
});

export const selectGroupId = createSelector([selectProfile], (profile) => {
  return profile && profile.attributes && profile.attributes.groupId[0];
});

export const selectState = createSelector([selectProfile], (profile) => {
  return profile && profile.attributes && profile.attributes.state[0];
});

export const selectRoles = createSelector([selectAuth], (auth) => auth.roles);
