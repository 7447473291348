import React from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import AlertDialog from 'components/AlertDialog';
import LoaderSpinner from 'components/LoaderSpinner';

const useStyles = makeStyles((theme) => ({
  list: {
    flexGrow: 1,
    marginTop: '15px',
  },
  listItem: {
    fonstSize: '16px',
    padding: '0px',
    margin: '0px',
  },
  listItemText: {
    paddingRight: '4px',
  },
}));

const AlertDialogDelete = ({
  openDeleteAlert,
  handleOkDelete,
  handleClose,
  openReferenceAlert,
  openDeleteSuccessAlert,
  openDeleteErrorAlert,
  openPrepareAlert,
  applications,
  clusterAgents,
  users,
  credentials,
  integrations,
  gitRepos,
  imageRegistries,
  slackIntegrations,
  emailIntegrations,
  sonarServers,
  environments,
  name,
  type,
}) => {
  const classes = useStyles();
  const titleDeleteAlert = 'Do you want to delete ' + type + '?';
  const titleReferenceAlert = `Unable to submit ${type} deletion request as following references exists`;
  const titleDeleteSuccessAlert =
    'The ' + type + ' delete request has been submitted successfully!';
  const titlerepareAlert = 'Loading ' + type + ' deletion status...';

  const titleDeleteErrorAlert = 'Failed!';

  const referencesArr = [
    {
      referenceType: 'Applications',
      referenceCount: applications,
    },
    {
      referenceType: 'Cluster Agents',
      referenceCount: clusterAgents,
    },
    {
      referenceType: 'Users',
      referenceCount: users,
    },
    {
      referenceType: 'Credentials',
      referenceCount: credentials,
    },
    {
      referenceType: 'Integrations',
      referenceCount: integrations,
    },
    {
      referenceType: 'Git Repos',
      referenceCount: gitRepos,
    },
    {
      referenceType: 'Image Registries',
      referenceCount: imageRegistries,
    },
    {
      referenceType: 'Slack Integrations',
      referenceCount: slackIntegrations,
    },
    {
      referenceType: 'Email Integrations',
      referenceCount: emailIntegrations,
    },
    {
      referenceType: 'Sonar Servers',
      referenceCount: sonarServers,
    },
    {
      referenceType: 'Environments',
      referenceCount: environments,
    },
  ];
  const doubled = referencesArr.map((number) => number * 2);

  return (
    <div>
      <AlertDialog
        title={titleDeleteAlert}
        buttonConfirmText="Yes"
        buttonCancelText="No"
        open={openDeleteAlert}
        handleOk={handleOkDelete}
        handleClose={handleClose}
        icon="question"
      >
        Do you want to delete {type} "{name}" ?
      </AlertDialog>

      <AlertDialog
        title={titleReferenceAlert}
        buttonConfirmText="Ok"
        open={openReferenceAlert}
        handleOk={handleClose}
      >
        <Grid container>
          <List dense className={classes.list}>
            {referencesArr.map(({ referenceType, referenceCount }) => (
              <>
                {referenceCount > 0 ? (
                  <ListItem className={classes.listItem}>
                    <ListItemText
                      primary={
                        <Typography className={classes.listItemText}>
                          {referenceType}:
                        </Typography>
                      }
                    />
                    {referenceCount}
                  </ListItem>
                ) : null}
              </>
            ))}
          </List>
        </Grid>
      </AlertDialog>

      <AlertDialog
        title={titleDeleteSuccessAlert}
        buttonConfirmText="Ok"
        open={openDeleteSuccessAlert}
        handleOk={handleClose}
        icon="success"
      >
        This {type} will get permanently deleted within 48 hours.
      </AlertDialog>

      <AlertDialog
        title={titleDeleteErrorAlert}
        buttonConfirmText="Ok"
        open={openDeleteErrorAlert}
        handleOk={handleClose}
        icon="failed"
      >
        Unable to delete {type} "{name}". Please Try again...
      </AlertDialog>

      <AlertDialog
        title={titlerepareAlert}
        open={openPrepareAlert}
        handleOk={handleClose}
        icon="loading"
      >
        <LoaderSpinner type="Oval" color="#FF7C7C" height={30} width={30} />
      </AlertDialog>
    </div>
  );
};

export default AlertDialogDelete;
