import { useInfiniteQuery } from 'react-query';

import api from 'services/Api';

const useAllCustomers = () => {
  const fetchCustomers = async () => {
    try {
      const customers = await api.get(
        `core/customers/v1/customers/get-all-customers`
      );

      const data = await customers.json();

      return { customers: data.data.customers };
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useInfiniteQuery(['Customers'], () => fetchCustomers(), {
    refetchOnWindowFocus: false,
  });
};

export default useAllCustomers;
