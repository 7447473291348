import { useInfiniteQuery } from 'react-query';

import api from 'services/Api';

const useProducts = () => {
  const fetchProducts = async () => {
    try {
      const query = new URLSearchParams({});
      const products = await api.get(
        `core/entitlements/v1/products/by-category/`
      );
      const data = await products.json();
      return data.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };
  return useInfiniteQuery(['products'], fetchProducts, {
    refetchOnWindowFocus: false,
  });
};

export default useProducts;
