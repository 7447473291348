import { useInfiniteQuery } from 'react-query';

import api from 'services/Api';

const useCustomers = () => {
  const fetchCustomers = async (nextPage) => {
    try {
      const query = new URLSearchParams({
        page: nextPage,
      });
      const customers = await api.get(
        `core/customers/v1/customers/?${query.toString()}`
      );

      const data = await customers.json();

      return { customers: data.data.customers, meta: data.data.meta };
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useInfiniteQuery(
    ['customers'],
    ({ pageParam = 0 }) => fetchCustomers(pageParam),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: ({ clusters, meta }) => {
        if (meta.nextPage !== 0) {
          return meta.nextPage;
        }
        return false;
      },
    }
  );
};

export default useCustomers;
