import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { Input } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  select: {
    background: '#EFF3FA',
    borderRadius: theme.shape.borderRadius,
    height: '40px',
    paddingLeft: '20px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
  },
}));

const CustomInput = ({
  id,
  name,
  placeholder,
  disableUnderline = true,
  defaultValue,
  value,
  register,
  handleChange,
  disabled,
  defaultLabel,
  path,
  type,
  endAdornment,
}) => {
  const classes = useStyles();

  return (
    <div>
      <FormControl fullWidth>
        <Input
          disabled={path === 'summary' || disabled === 'true' ? true : false}
          id={id}
          name={name}
          className={classes.select}
          placeholder={placeholder}
          inputRef={register}
          disableUnderline={disableUnderline}
          defaultValue={defaultValue}
          value={value}
          onChange={handleChange}
          style={{ color: defaultLabel === 'true' ? '#808CA3' : '#001847' }}
          type={type === 'password' ? 'password' : 'text'}
          endAdornment={endAdornment}
        />
      </FormControl>
    </div>
  );
};

export default CustomInput;
