import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import countryList from 'react-select-country-list';

import { Button, Checkbox } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import BillingMethod from 'schema/billing-method';
import CustomSelect from 'components/CustomSelect';
import validate from 'validation/BillingInfor';

import {
  billingInfoFetchError,
  billingInfoFetchStart,
  billingInfoFetchSuccess,
} from 'state/billingInfo';
import {
  checkBoxInfoFetchError,
  checkBoxInfoFetchStart,
  checkBoxInfoFetchSuccess,
} from 'state/checkBox';
import { selectGeneralInfoData } from 'state/generalInfo/selectors';
import { selectBillingInfoData } from 'state/billingInfo/selectors';
import { visibleUpdateSuccess } from 'state/saveButton';
import BrowserCloseValidation from 'validation/browserCloseValidation';
import { selectCheckBoxInfoData } from 'state/checkBox/selectors';
import getCountryAndStates from 'services/CountriesAndState/index';
const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: '0px 57px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  heading: {
    paddingTop: '29px',
  },
  headingTitle: theme.appDirector.pageHeading,
  section: {
    paddingTop: '30px',
    flex: 1,
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  textField: {
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flex: 'none',
    },
  },
  errorTextField: {
    borderColor: 'red !important',
  },
  label: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    paddingBottom: '14px',
  },
  checkbox_grid: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    alignItems: 'center',
    paddingTop: '14px',
  },
  block: {
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingTop: '36px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'flex-center',
    },
  },
  button: {
    width: '166px',
    height: '40px',
    marginBottom: '50px',
    marginLeft: '10px',
    marginRight: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '130px',
      marginBottom: '20px',
      flex: 1,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100px',
      marginBottom: '20px',
      flex: 1,
    },
  },
  invalid: {
    color: 'red',
    fontSize: '12px',
  },
  tags: {
    paddingTop: '10px',
  },
  required: {
    color: 'red',
  },
  checkbox: {
    padding: '0px',
  },
  checkbox_label: {
    paddingLeft: '10px',
  },
  disabled: {
    backgroundColor: 'lightgray',
  },

  disabled: {
    '& .Mui-disabled': {
      backgroundColor: 'lightgrey',
      color: 'black',
    },
  },
  textFieldBox: {
    '& .MuiInputBase-input': {
      fontSize: '16px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
  },
}));

const BillingInformation = ({ data, path, isEmpty, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cachedBillingInfoData = useSelector(selectBillingInfoData);
  const cachedGeneralInfoData = useSelector(selectGeneralInfoData);
  const cachedCheckBoxState = useSelector(selectCheckBoxInfoData);
  const [setDirty, setPristine] = BrowserCloseValidation();

  const options = useMemo(() => getCountryAndStates().countries);
  const optionsUSState = useMemo(() => getCountryAndStates().usState);
  const optionsCAState = useMemo(() => getCountryAndStates().caState);
  const [billingInfoProvinceState, setBillingProvince] = useState();
  const [billingInfo, setBillingInfo] = useState({
    billingContactName: `${
      cachedBillingInfoData?.billingInfo?.billingContactName ||
      data?.billingContactName ||
      ''
    }`,
    billingEmailAddress: `${
      cachedBillingInfoData?.billingInfo?.billingEmailAddress ||
      data?.billingEmailAddress ||
      ''
    }`,
    billingMethod: `${
      cachedBillingInfoData?.billingInfo?.billingMethod ||
      data?.billingMethod ||
      ''
    }`,
    billingAddress1: `${
      cachedBillingInfoData?.billingInfo?.billingAddress1 ||
      data?.billingAddress1 ||
      ''
    }`,
    billingAddress2: `${
      cachedBillingInfoData?.billingInfo?.billingAddress2 ||
      data?.billingAddress2 ||
      ''
    }`,
    billingCity: `${
      cachedBillingInfoData?.billingInfo?.billingCity || data?.billingCity || ''
    }`,
    billingProvince: `${
      cachedBillingInfoData?.billingInfo?.billingProvince ||
      data?.billingProvince ||
      ''
    }`,
    billingPostalCode: `${
      cachedBillingInfoData?.billingInfo?.billingPostalCode ||
      data?.billingPostalCode ||
      ''
    }`,
    billingCountryCode: `${
      cachedBillingInfoData?.billingInfo?.billingCountryCode ||
      data?.billingCountryCode ||
      ''
    }`,
  });
  const [isEditable, setEditable] = useState(
    billingInfo.billingMethod == 'nb' ? true : false
  );
  const billingMethod = [
    {
      id: '1',
      name: BillingMethod.nb.displayValue,
      value: BillingMethod.nb.enumKey,
    },
    {
      id: '2',
      name: BillingMethod.cc.displayValue,
      value: BillingMethod.cc.enumKey,
    },
    {
      id: '3',
      name: BillingMethod.inv.displayValue,
      value: BillingMethod.inv.enumKey,
    },
    {
      id: '4',
      name: BillingMethod.ibm.displayValue,
      value: BillingMethod.ibm.enumKey,
    },
    {
      id: '5',
      name: BillingMethod.aws.displayValue,
      value: BillingMethod.aws.enumKey,
    },
    {
      id: '6',
      name: BillingMethod.az.displayValue,
      value: BillingMethod.az.enumKey,
    },
  ];

  let fullGenerelInfoAddress =
    data?.address1 +
    data?.address2 +
    data?.city +
    data?.countryCode +
    data?.postalCode +
    data?.province;

  let fullBillingInfoAddress =
    data?.billingAddress1 +
    data?.billingAddress2 +
    data?.billingCity +
    data?.billingCountryCode +
    data?.billingPostalCode +
    data?.billingProvince;

  const generateCheckedBoxState = () => {
    if (path === 'edit') {
      return (
        fullGenerelInfoAddress === fullBillingInfoAddress ||
        cachedCheckBoxState?.checkBoxInfo?.checked
      );
    } else {
      return cachedCheckBoxState?.checkBoxInfo?.checked ? true : false;
    }
  };

  const [checkBoxInfo, setCheckBoxInfo] = useState({
    checked: generateCheckedBoxState(),
  });

  const [errors, setErrors] = useState({});

  const { register } = useForm({
    mode: 'onBlur',
  });

  const CANADA = 'CA';
  const USA = 'US';

  useEffect(() => {
    dispatch(visibleUpdateSuccess({ visible: false, page: 'billing_info' }));
  });

  const [countryRelatedProvince, setCountryRelatedProvince] = useState();
  const handleChange = (e, name) => {
    if (e.target.value === CANADA || e.target.value === USA) {
      if (e.target.value == 'nb') {
        setEditable(true);
      } else {
        setEditable(false);
      }
      setBillingInfo({ ...billingInfo, [name]: e.target.value });
    } else {
      if (e.target.value == 'nb') {
        setEditable(true);
      } else {
        setEditable(false);
      }
      setBillingInfo({ ...billingInfo, [name]: e.target.value });
    }
  };

  const handleChangeCheckBox = (e, name) => {
    setCheckBoxInfo({ ...checkBoxInfo, [name]: e.target.checked });
  };

  const handleEmail = (e) => {
    setErrors(validate('billingEmailAddress', e.target.value));
    handleChange(e, 'billingEmailAddress');
  };

  const addBillingInfo = () => {
    if (Object.keys(errors).length === 0) {
      dispatch(billingInfoFetchStart());
      dispatch(billingInfoFetchSuccess({ billingInfo }));
      setPristine();
    } else {
      dispatch(billingInfoFetchError({ errors }));
    }
  };

  const addCheckBoxInfo = () => {
    if (Object.keys(errors).length === 0) {
      dispatch(checkBoxInfoFetchStart());
      dispatch(checkBoxInfoFetchSuccess({ checkBoxInfo }));
      setPristine();
    } else {
      dispatch(checkBoxInfoFetchError({ errors }));
    }
  };

  useEffect(() => {
    if (checkBoxInfo.checked) {
      setBillingInfo({
        ...billingInfo,
        billingAddress1: `${cachedGeneralInfoData?.generalInfo?.address1}`,
        billingAddress2: `${cachedGeneralInfoData?.generalInfo?.address2}`,
        billingCity: `${cachedGeneralInfoData?.generalInfo?.city}`,
        billingProvince: `${cachedGeneralInfoData?.generalInfo?.province}`,
        billingPostalCode: `${cachedGeneralInfoData?.generalInfo?.postalCode}`,
        billingCountryCode: `${cachedGeneralInfoData?.generalInfo?.countryCode}`,
      });
    } else {
      if (fullGenerelInfoAddress === fullBillingInfoAddress) {
        setBillingInfo({
          ...billingInfo,
          billingAddress1: `${''}`,
          billingAddress2: `${''}`,
          billingCity: `${''}`,
          billingProvince: `${''}`,
          billingPostalCode: `${''}`,
          billingCountryCode: `${''}`,
        });
      } else {
        setBillingInfo({
          ...billingInfo,
          billingAddress1: `${
            data?.billingAddress1 === undefined ? '' : data?.billingAddress1
          }`,
          billingAddress2: `${
            data?.billingAddress2 === undefined ? '' : data?.billingAddress2
          }`,
          billingCity: `${
            data?.billingCity === undefined ? '' : data?.billingCity
          }`,
          billingProvince: `${
            data?.billingProvince === undefined ? '' : data?.billingProvince
          }`,
          billingPostalCode: `${
            data?.billingPostalCode === undefined ? '' : data?.billingPostalCode
          }`,
          billingCountryCode: `${
            data?.billingCountryCode === undefined
              ? ''
              : data?.billingCountryCode
          }`,
        });
      }
    }
  }, [checkBoxInfo.checked]);

  const isBillingInfoComplete = () => {
    const requiredFields = [
      'billingContactName',
      'billingEmailAddress',
      'billingAddress1',
      'billingCountryCode',
      'billingPostalCode',
      'billingMethod',
      'billingCity',
    ];

    if (
      billingInfo.billingCountryCode === CANADA ||
      billingInfo.billingCountryCode === USA
    ) {
      requiredFields.push('billingProvince');
    }

    const missingFields = requiredFields.filter((field) => !billingInfo[field]);

    return missingFields.length === 0 && Object.keys(errors).length === 0;
  };

  const isCanadaProvinceStateComplete = () => {
    return (
      billingInfo.billingCountryCode === CANADA &&
      billingInfo.billingProvince !== ''
    );
  };

  const isUSProvinceStateComplete = () => {
    return (
      billingInfo.billingCountryCode === USA &&
      billingInfo.billingProvince !== ''
    );
  };

  const isBillingInfoValid = () => {
    return (
      isBillingInfoComplete() ||
      isCanadaProvinceStateComplete() ||
      isUSProvinceStateComplete()
    );
  };

  const isDisabled = !isBillingInfoComplete() || !isBillingInfoValid();

  return (
    <Grid container classes={{ container: classes.container }}>
      <Grid item container className={classes.section} spacing={3}>
        <Grid item className={classes.textField} xs={12}>
          <Grid item className={classes.label}>
            <span>Billing Method</span>
            <span className={classes.required}> *</span>
          </Grid>
          <CustomSelect
            id="billingMethod"
            name="billingMethod"
            placeholder="Select Billing Method"
            selectItems={billingMethod}
            onChange={(e) => {
              handleChange(e, 'billingMethod');
            }}
            defaultValue={billingInfo.billingMethod}
            register={register}
            className={classes.textFieldBox}
          />
        </Grid>
      </Grid>
      <Grid item container className={classes.section} spacing={3}>
        <Grid item className={classes.textField} xs={12}>
          <Grid item className={classes.label}>
            <span>
              Billing Contact Name<span className={classes.required}> *</span>
            </span>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                className={`${classes.disabled} ${classes.textFieldBox}`}
                disabled={isEditable}
                InputProps={
                  errors.name && {
                    classes: {
                      notchedOutline: classes.errorTextField,
                    },
                  }
                }
                inputRef={register}
                id="billingContactName"
                name="billingContactName"
                placeholder="Enter billing name"
                variant="outlined"
                onChange={(e) => {
                  handleChange(e, 'billingContactName');
                  setErrors(validate('billingContactName', e.target.value));
                }}
                defaultValue={billingInfo.billingContactName}
                inputProps={{ maxLength: 50 }}
              />
              {errors.billingContactName && (
                <p className={classes.invalid}>{errors.billingContactName}</p>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid item className={classes.textField} xs={12}>
          <Grid item className={classes.label}>
            <span>
              Billing Contact Email<span className={classes.required}> *</span>
            </span>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                className={`${classes.disabled} ${classes.textFieldBox}`}
                disabled={isEditable}
                InputProps={
                  errors.billingEmailAddress && {
                    classes: {
                      notchedOutline: classes.errorTextField,
                    },
                  }
                }
                inputRef={register}
                id="billingEmail"
                name="billingEmail"
                placeholder="Enter billing email"
                variant="outlined"
                onChange={(e) => {
                  handleEmail(e);
                  handleChange(e, 'billingEmailAddress');
                }}
                defaultValue={billingInfo.billingEmailAddress}
                inputProps={{ maxLength: 50 }}
              />
              {errors.email && (
                <p className={classes.invalid}>{errors.email}</p>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container className={classes.checkbox_grid}>
        <Grid>
          <FormControl>
            <Checkbox
              disabled={isEditable}
              checked={checkBoxInfo.checked}
              id="checked"
              className={`${classes.checkbox} ${classes.textFieldBox}`}
              onClick={(e) => {
                handleChangeCheckBox(e, 'checked');
              }}
              value={checkBoxInfo.checked}
            />
          </FormControl>
        </Grid>
        <Grid className={classes.checkbox_label}>
          Same as customer details{' '}
        </Grid>
      </Grid>

      <Grid item container className={classes.section} spacing={3}>
        <Grid item className={classes.textField} xs={12}>
          <Grid item className={classes.label}>
            <span>
              Billing Address Line 1<span className={classes.required}> *</span>
            </span>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                className={`${classes.disabled} ${classes.textFieldBox}`}
                disabled={checkBoxInfo.checked || isEditable}
                InputProps={
                  errors.cardAddress1 && {
                    classes: {
                      notchedOutline: classes.errorTextField,
                    },
                  }
                }
                inputRef={register}
                id="billingAddress1"
                name="billingAddress1"
                placeholder="Enter first line of address"
                variant="outlined"
                onChange={(e) => {
                  handleChange(e, 'billingAddress1');
                  setErrors(validate('billingAddress1', e.target.value));
                }}
                value={billingInfo.billingAddress1}
                inputProps={{ maxLength: 50 }}
              />
              {errors.billingAddress1 && (
                <p className={classes.invalid}>{errors.billingAddress1}</p>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid item className={classes.textField} xs={12}>
          <Grid item className={classes.label}>
            <span>Billing Address Line 2</span>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                className={`${classes.disabled} ${classes.textFieldBox}`}
                disabled={checkBoxInfo.checked || isEditable}
                InputProps={
                  errors.billingAddress2 && {
                    classes: {
                      notchedOutline: classes.errorTextField,
                    },
                  }
                }
                inputRef={register}
                id="billingAddress2"
                name="billingAddress2"
                placeholder="Enter second line of address"
                variant="outlined"
                onChange={(e) => {
                  handleChange(e, 'billingAddress2');
                  setErrors(validate('billingAddress2', e.target.value));
                }}
                value={billingInfo.billingAddress2}
                inputProps={{ maxLength: 50 }}
              />
              {errors.billingAddress2 && (
                <p className={classes.invalid}>{errors.billingAddress2}</p>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container className={classes.section} spacing={3}>
        <Grid item className={classes.textField} xs={12}>
          <Grid item className={classes.label}>
            <span>
              Billing City
              {billingInfo.countryCode === CANADA ||
              billingInfo.countryCode === USA ? (
                <span className={classes.required}> *</span>
              ) : null}
            </span>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                className={`${classes.disabled} ${classes.textFieldBox}`}
                disabled={checkBoxInfo.checked || isEditable}
                InputProps={
                  errors.billingCity && {
                    classes: {
                      notchedOutline: classes.errorTextField,
                    },
                  }
                }
                inputRef={register}
                id="billingCity"
                name="billingCity"
                placeholder="Enter billing City"
                variant="outlined"
                onChange={(e) => {
                  handleChange(e, 'billingCity');
                  setErrors(validate('billingCity', e.target.value));
                }}
                value={billingInfo.billingCity}
                inputProps={{ maxLength: 50 }}
              />
              {errors.billingCity && (
                <p className={classes.invalid}>{errors.billingCity}</p>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid item className={classes.textField} xs={12}>
          <Grid item className={classes.label}>
            <span>
              Billing Country<span className={classes.required}> *</span>
            </span>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <Select
                className={`${classes.disabled} ${classes.textFieldBox}`}
                disabled={checkBoxInfo.checked || isEditable}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: '400px',
                    },
                  },
                }}
                inputRef={register}
                id="billingCountryCode"
                name="billingCountryCode"
                placeholder="Enter billing country"
                variant="outlined"
                onChange={(e) => {
                  handleChange(e, 'billingCountryCode');
                  setCountryRelatedProvince('');
                  // setDirty();
                  setBillingProvince(true);
                  // isEmpty(false);
                }}
                value={billingInfo.billingCountryCode}
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {errors.billingCountryCode && (
                <p className={classes.invalid}>{errors.billingCountryCode}</p>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid item className={classes.textField} xs={12}>
          <Grid item className={classes.label}>
            <span>
              Billing Province / State
              {billingInfo.countryCode === CANADA ||
              billingInfo.countryCode === USA ? (
                <span className={classes.required}> *</span>
              ) : null}
            </span>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              {billingInfo.billingCountryCode === CANADA ||
              billingInfo.billingCountryCode === USA ? (
                <Select
                  className={classes.disabled}
                  disabled={checkBoxInfo.checked || isEditable}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: {
                        maxHeight: '400px',
                      },
                    },
                  }}
                  inputRef={register}
                  id="billingProvince"
                  name="billingProvince"
                  placeholder="Enter billing Province / State"
                  variant="outlined"
                  onChange={(e) => {
                    handleChange(e, 'billingProvince');
                    setBillingProvince(false);
                    setErrors(validate('billingProvince', e.target.value));
                  }}
                  value={billingInfo?.billingProvince}
                >
                  {billingInfo.billingCountryCode === USA &&
                    optionsUSState.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  {billingInfo.billingCountryCode === CANADA &&
                    optionsCAState.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </Select>
              ) : (
                <TextField
                  className={classes.disabled}
                  disabled={checkBoxInfo.checked || isEditable}
                  InputProps={
                    errors.billingProvince && {
                      classes: {
                        notchedOutline: classes.errorTextField,
                      },
                    }
                  }
                  inputRef={register}
                  id="billingProvince"
                  name="billingProvince"
                  placeholder="Enter billing Province / State"
                  variant="outlined"
                  onChange={(e) => {
                    handleChange(e, 'billingProvince');
                    setCountryRelatedProvince();
                    setBillingProvince(false);
                    setErrors(validate('billingProvince', e.target.value));
                  }}
                  value={
                    countryRelatedProvince == null
                      ? billingInfo?.billingProvince
                      : countryRelatedProvince
                  }
                  inputProps={{ maxLength: 50 }}
                />
              )}
              {errors.billingProvince && (
                <p className={classes.invalid}>{errors.billingProvince}</p>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid item className={classes.textField} xs={12}>
          <Grid item className={classes.label}>
            <span>
              Billing Postal Code / ZIP Code
              <span className={classes.required}> *</span>
            </span>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                className={classes.disabled}
                disabled={checkBoxInfo.checked || isEditable}
                InputProps={
                  errors.billingPostalCode && {
                    classes: {
                      notchedOutline: classes.errorTextField,
                    },
                  }
                }
                inputRef={register}
                id="billingPostalCode"
                name="billingPostalCode"
                placeholder="Enter billing postal code / ZIP Code"
                variant="outlined"
                onChange={(e) => {
                  handleChange(e, 'billingPostalCode');
                  setErrors(validate('billingPostalCode', e.target.value));
                }}
                value={billingInfo.billingPostalCode}
                inputProps={{ maxLength: 10 }}
              />
              {errors.billingPostalCode && (
                <p className={classes.invalid}>{errors.billingPostalCode}</p>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container className={classes.block}>
        {isEditable == true ? (
          <Button
            className={classes.button}
            spacing={3}
            onClick={() => {
              addCheckBoxInfo();
              addBillingInfo();
              props.nextStep();
            }}
          >
            Next
          </Button>
        ) : (
          <Button
            disabled={isDisabled}
            className={classes.button}
            spacing={3}
            onClick={() => {
              addCheckBoxInfo();
              addBillingInfo();
              props.nextStep();
            }}
          >
            Next
          </Button>
        )}

        <Button
          className={classes.button}
          onClick={() => {
            addCheckBoxInfo();
            addBillingInfo();
            props.previousStep();
          }}
        >
          Back
        </Button>
      </Grid>
    </Grid>
  );
};

export default BillingInformation;
