import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';

import AppRouter from 'components/AppRouter';
import ThemeDefault from 'themes/default';
import { lastLocFetchStart, lastLocFetchSuccess } from 'state/lastLocation';

const AppInitializer = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(lastLocFetchStart());
    // dispatch(
    //   lastLocFetchSuccess({
    //     lastLoc: location.pathname,
    //     lastLocState: location.state,
    //   })
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ThemeProvider theme={ThemeDefault}>
      <CssBaseline />
      <Helmet title="Randoli Inc. | Admin Portal" />
      <AppRouter />
    </ThemeProvider>
  );
};

export default AppInitializer;
