import { useQuery } from 'react-query';

import api from 'services/Api';
import TicketStatus from 'schema/ticket-status';

const useCustomerTicketSummary = ({organizationId}) => {
  const fetchCounts = async () => {
    const titles = {
      [TicketStatus.OPENED.enumKey]: 'New Tickets',
      [TicketStatus.ASSIGNED.enumKey]: 'Assigned Tickets',
      [TicketStatus.IN_PROGRESS.enumKey]: 'In-Progress / In-Review Tickets',
      [TicketStatus.CLOSED.enumKey]: 'Resolved Tickets',
    };

    try {
      const query = new URLSearchParams({
        'organization-id': organizationId
      });
      const response = await api.get(
        `csp/aggregator/v1/tickets/summary?${query.toString()}`
      );
        
      const {
        data: { ticket },
      } = await response.json();

      return Object.keys(titles).map((key) => {
        return { count: ticket[key] || 0, title: titles[key] };
      });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useQuery('ticket/counts', fetchCounts, {
    refetchOnWindowFocus: false,
  });
};

export default useCustomerTicketSummary;
