import { Enumify } from 'enumify';

class CustomerType extends Enumify {
  static CUSTOMER = new CustomerType({
    get displayValue() {
      return 'CUSTOMER';
    },
  });

  static INTERNAL = new CustomerType({
    get displayValue() {
      return 'INTERNAL';
    },
  });

  static PARTNER = new CustomerType({
    get displayValue() {
      return 'PARTNER';
    },
  });

  static _ = this.closeEnum();

  constructor(props) {
    super();
    Object.defineProperties(this, Object.getOwnPropertyDescriptors(props));
  }
}

export default CustomerType;