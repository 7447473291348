import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  isUpdating: false,
  saveApplication: false,
};

const saveApplicationSlice = createSlice({
  name: 'saveApplication',
  initialState: INITIAL_STATE,
  reducers: {
    applicationUpdateStart(state, action) {
      state.isUpdating = true;
      state.saveApplication = false;
    },
    applicationUpdateSuccess(state, { payload: { saveApp } }) {
      state.isUpdating = false;
      state.saveApplication = saveApp;
    },
    applicationUpdateError(state, action) {
      state.isUpdating = false;
      state.saveApplication = false;
    },
  },
});

export const { name, actions, reducer } = saveApplicationSlice;
export const {
  applicationUpdateStart,
  applicationUpdateSuccess,
  applicationUpdateError,
} = actions;
export default reducer;
