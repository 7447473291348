import React, { useState, useEffect } from 'react';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import TextField from '@material-ui/core/TextField';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import * as KeyCode from 'keycode-js';
import api from 'services/Api';

import { useSelector } from 'react-redux';
import { selectUser } from 'state/auth/selectors';

import CommentItem from '../CommentItem/index';
import { Popover, List } from '@material-ui/core';
import useAddComment from 'hooks/services/useAddComment';
import useUpdateComment from 'hooks/services/useUpdateComment';
import useDeleteComment from 'hooks/services/useDeleteComment';
import useIsAssigned from 'hooks/services/useIsAssigned';

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    height: '93%',
    maxHeight: '93%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      display: 'flex',
    },
  },
  commentsListContainer: {
    flexGrow: 1,
    flexShrink: 1,
    // see reason for flexBasis=1px,  https://stackoverflow.com/questions/52487743/prevent-flex-item-from-exceeding-parent-height-and-make-scroll-bar-work
    flexBasis: '1px',
    overflowY: 'auto',
    minHeight: 0,
    boxShadow: '0px 1px 8px rgba(20, 46, 110, 0.1)',
    borderRadius: '10px',
    marginBottom: '10px',
  },
  grid: {
    padding: '2%',
  },
  commentLeftHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  commentRightHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  commentSection: {
    padding: '2%',
    width: '100%',
    flexWrap: 'wrap',
    whiteSpace: 'pre-line',
  },
  button: {
    padding: 0,
  },
  date: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    color: '#808CA3',
    padding: '12px',
  },
  topic: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#626799',
    paddingLeft: '20px',
  },
  card: {
    background: '#FFFFFF',
    border: '1px solid #B5BDE9',
    boxSizing: 'border-box',
    borderRadius: '6px',
    boxShadow: 'none',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    scrollbarWidth: 'none'
  },
  highlightedCard: {
    background: '#eff3fa',
    border: '1px solid #B5BDE9',
    boxSizing: 'border-box',
    borderRadius: '6px',
    boxShadow: 'none',
    overflowX: 'auto',
    scrollbarWidth: 'none',
  },

  popOver: { background: '#001847', color: '#FF7C7C', borderRadius: '4px' },
  popOverIcon: {
    color: '#FF7C7C',
  },
  popOverShadow: {
    boxShadow: '0px 4px 19px rgba(0, 0, 0, 0.25)',
  },
}));

const Comments = ({ ticketData, ticketComments }) => {
  const classes = useStyles();
  const [comment, setComment] = useState([]);
  const [commentValue, setCommentValue] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [edit, setEdit] = React.useState(null);
  const [buttonEdit, setButtonEdit] = React.useState(null);
  const [assigned, setAssigned] = useState();

  const open = Boolean(anchorEl);

  const { mutateAsync: isAssigned } = useIsAssigned(
    ticketData.entitlementId,
    ticketData.ticketId
  );

  const userId = useSelector(selectUser);

  function convertGMTtoEST(gmtDateString) {
    const gmtDate = new Date(gmtDateString);
    const utcTime = gmtDate.getTime();
    const estOffset = -4 * 60 * 60 * 1000;
    const estDate = new Date(utcTime + estOffset);
    return estDate.toLocaleString();
  }
  const { mutateAsync: addCommentApi } = useAddComment({
    entitlementId: ticketData.entitlementId,
    ticketId: ticketData.ticketId,
  });

  const { mutateAsync: updateCommentApi } = useUpdateComment({
    entitlementId: ticketData.entitlementId,
    ticketId: ticketData.ticketId,
    commentId: buttonEdit,
  });

  const { mutateAsync: deleteCommentApi } = useDeleteComment({
    entitlementId: ticketData.entitlementId,
    ticketId: ticketData.ticketId,
    commentId: buttonEdit,
  });

  useEffect(() => {
    if (ticketComments) {
      setComment(ticketComments.reverse());
    }
  }, [ticketComments]);

  const addComment = async (event) => {
    if (event.key === KeyCode.CODE_ENTER && event.target.value !== '') {
      event.preventDefault();
      const newComment = {
        commentText: event.target.value,
      };
      addCommentApi(newComment);
    }
  };

  const addCommentButton = async () => {
    try {
      const newComment = {
        commentText: commentValue,
      };
      const checkAssignee = {
        isAssigned: false,
      };
      isAssigned(checkAssignee);
      const result = await addCommentApi(newComment);
      let newCommentlist = [...comment, result.data.comment];
      setComment(newCommentlist);
      setCommentValue('');
    } catch (error) {
      console.log(error);
    }
  };

  const updateComment = async (event) => {
    if (event.key === KeyCode.CODE_ENTER) {
      event.preventDefault();
      const commentText = event.target.value;
      try {
        const newComment = {
          commentText,
        };
        const result = await updateCommentApi(newComment);
        const commentIndex = comment.findIndex(
          (element) => element.commentId == buttonEdit
        );
        let newArray = [...comment];
        newArray[commentIndex] = result.data.comment;
        setComment(newArray);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const openMenu = (commentId) => (e) => {
    setButtonEdit(commentId);
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const openEdit = () => {
    setEdit(buttonEdit);
  };

  const closeEdit = () => {
    setEdit(null);
  };

  const closeAfterDelete = () => {
    setAnchorEl(null);
    closeEdit();
  };

  const deleteComment = async () => {
    try {
      const commentId = buttonEdit;
      deleteCommentApi();
      const commentIndex = comment.findIndex(
        (element) => element.commentId == commentId
      );
      let newArray = [...comment];
      newArray.splice(commentIndex, 1);
      setComment(newArray);
      closeAfterDelete();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const checkIfEditable = (lastUpdate) => {
    var date = new Date();
    var dbDate = new Date(lastUpdate + ' GMT');

    var FIVE_MIN = 5 * 60 * 1000;

    //Returns false if comments was made more than 5 minutes ago
    if (date - dbDate > FIVE_MIN) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item className={classes.commentsListContainer} xs={12}>
          <p className={classes.topic}>Comments</p>
          {comment.map(
            ({ commentText, lastUpdate, commentId, createdUserName, createdUserId }) => (
              <Grid key={commentId} container className={classes.grid}>
                <Grid item xs={12}>
                  <Card key={commentId} className={userId === createdUserId ? classes.highlightedCard : classes.card}>
                    <Grid
                      container
                      item
                      justifyContent="space-between"
                      style={{ padding: '10px' }}
                    >
                      <Grid item alignContent="center">
                        <p> {createdUserName} </p>
                      </Grid>

                      <Grid item alignContent="center">
                        <span className={classes.date}>
                          {convertGMTtoEST(lastUpdate)}
                          {' (EST)'}
                        </span>
                        <IconButton
                          aria-haspopup="true"
                          onClick={openMenu(commentId)}
                          style={{
                            visibility: checkIfEditable(lastUpdate)
                              ? 'visible'
                              : 'hidden',
                          }}
                        >
                          <MoreHorizOutlinedIcon />
                        </IconButton>
                        <Popover
                          anchorEl={anchorEl}
                          open={open}
                          onClose={closeMenu}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          classes={{ paper: classes.popOverShadow }}
                        >
                          <List
                            component="nav"
                            aria-label="main mailbox folders"
                            className={classes.popOver}
                          >
                            <ListItem button onClick={openEdit}>
                              <ListItemIcon>
                                <EditOutlinedIcon
                                  className={classes.popOverIcon}
                                />
                              </ListItemIcon>
                              <ListItemText primary="Edit" />
                            </ListItem>
                            <ListItem button onClick={deleteComment}>
                              <ListItemIcon>
                                <DeleteForeverOutlinedIcon
                                  className={classes.popOverIcon}
                                />
                              </ListItemIcon>
                              <ListItemText primary="Delete" />
                            </ListItem>
                          </List>
                        </Popover>
                      </Grid>
                    </Grid>
                    <Divider variant="middle" />
                    {edit === commentId ? (
                      <Grid container item className={classes.commentSection}>
                        <TextField
                          onKeyDown={updateComment}
                          variant="outlined"
                          fullWidth
                          defaultValue={commentText}
                          id={commentId}
                          onBlur={closeEdit}
                        />
                      </Grid>
                    ) : (
                      <Grid container item className={classes.commentSection}>
                        {commentText}
                      </Grid>
                    )}
                  </Card>
                </Grid>
              </Grid>
            )
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <CommentItem
          xs={12}
          addComment={addComment}
          addCommentButton={addCommentButton}
          commentValue={commentValue}
          setCommentValue={setCommentValue}
        />
      </Grid>
    </>
  );
};

export default React.memo(Comments);
