import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  isFetching: false,
  billingInfo: [],
  errors: [],
};

const billingInfoSlice = createSlice({
  name: 'billingInfo',
  initialState: INITIAL_STATE,
  reducers: {
    billingInfoFetchStart(state, action) {
      state.isFetching = true;
      state.billingInfo = [];
    },
    billingInfoFetchSuccess(state, { payload: { billingInfo } }) {
      state.isFetching = false;
      state.billingInfo = billingInfo;
    },
    billingInfoFetchError(state, { payload: { errors } }) {
      state.errors = errors;
      state.isFetching = false;
      state.billingInfo = [];
    },
  },
});

export const { name, actions, reducer } = billingInfoSlice;
export const {
  billingInfoFetchStart,
  billingInfoFetchSuccess,
  billingInfoFetchError,
} = actions;
export default reducer;
