import { Enumify } from 'enumify';

class EntitlmentError extends Enumify {

  static E1 = new EntitlmentError({
    get displayValue() {
      return 'E01';
    },
  });
  static E2 = new EntitlmentError({
    get displayValue() {
      return 'The customer already has a valid entitlement with the product selected in period informed';
    },
  });
  static _ = this.closeEnum();

  constructor(props) {
    super();
    Object.defineProperties(this, Object.getOwnPropertyDescriptors(props));
  }
}

export default EntitlmentError;
