import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useForm } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import CredentialType from 'schema/credential-type';
import useCreateCredentials from 'hooks/services/useCreateCredential';
import useUpdateCredential from 'hooks/services/useUpdateCredential';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  heading: {
    paddingTop: '29px',
  },
  headingTitle: theme.appDirector.pageHeading,
  section: {
    paddingTop: '40px',
  },
  label: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    paddingBottom: '14px',
  },
  block: {
    paddingBottom: '20px',
    gap: '19px',
    flexWrap: 'nowrap',
  },
  button: {
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingTop: '36px',
  },
  required: {
    color: 'red',
  },
  cancelButton: {
    color: '#FF5E5E',
    backgroundColor: '#FFFFFF',
    border: '1px solid #FF5E5E',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
}));

const FromInput = ({
  flow,
  disable,
  name,
  credentialId,
  organizationId,
  projectId,
  inputData,
  setIsEmpty,
  handleCloseCredential,
  setCheckCredWarning,
  refetchCred,
  setOpenCreateCredential,
}) => {
  const [t] = useTranslation('credentials');
  const classes = useStyles();
  const history = useHistory();
  const { register } = useForm({
    mode: 'onBlur',
  });
  const [dockerConfigData, setDockerConfigData] = useState({
    username: '',
    password: '',
    hostname: '',
    email: '',
  });

  const { mutateAsync: credentialsCreater, isError } = useCreateCredentials();
  const { mutateAsync: credentialsUpdater } = useUpdateCredential(credentialId);

  useEffect(() => {
    if (inputData) {
      try {
        const data = JSON.parse(atob(inputData?.secretData));
        const host = Object.keys(data['auths'])[0];
        setDockerConfigData({ ...data.auths[`${host}`], hostname: host });
      } catch (e) {
        console.log(e);
      }
    }
  }, [inputData]);

  const handleDockerData = (e, name) => {
    setDockerConfigData({ ...dockerConfigData, [name]: e.target.value });
  };

  useEffect(() => {
    if (
      dockerConfigData?.username ||
      dockerConfigData?.password ||
      dockerConfigData?.hostname ||
      dockerConfigData?.email
    ) {
      setCheckCredWarning(true);
    } else {
      setCheckCredWarning(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dockerConfigData]);

  const handleSubmit = async () => {
    const configJson = {
      auths: {
        [dockerConfigData?.hostname]: {
          username: dockerConfigData?.username,
          password: dockerConfigData?.password,
          email: dockerConfigData?.email,
          auth: btoa(
            dockerConfigData?.username + ':' + dockerConfigData?.password
          ),
        },
      },
    };

    const newCredentials = {
      name: name,
      type: CredentialType.DOCKER_CONFIG_JSON.enumKey,
      data: [
        {
          secretName: '.dockerconfigjson',
          secretData: btoa(JSON.stringify(configJson)),
        },
      ],
    };

    try {
      if (flow === 'Create') {
        newCredentials['organizationId'] = organizationId;
        newCredentials['projectId'] = projectId;
        await credentialsCreater(newCredentials);
        if (!isError) {
          refetchCred();
          setOpenCreateCredential(false);
        }
      }
      if (flow === 'Update') {
        await credentialsUpdater(newCredentials);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Grid container classes={{ container: classes.gridContainer }}>
      <Grid item container className={classes.block}>
        <Grid item xs={6}>
          <div className={classes.label}>
            {t('credentials.create.docker.input.username')}
            {flow === 'View' ? null : (
              <span className={classes.required}> *</span>
            )}
          </div>
          <FormControl fullWidth>
            <TextField
              inputRef={register}
              name="name"
              placeholder={t(
                'credentials.create.docker.input.usernamePlaceholder'
              )}
              variant="outlined"
              value={dockerConfigData?.username}
              onChange={(e) => {
                handleDockerData(e, 'username');
                setIsEmpty(false);
              }}
              disabled={disable ? false : true}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.label}>
            {t('credentials.create.docker.input.pwd')}
            {flow === 'View' ? null : (
              <span className={classes.required}> *</span>
            )}
          </div>
          <FormControl fullWidth>
            <TextField
              inputRef={register}
              name="password"
              placeholder={t('credentials.create.docker.input.pwdPlaceholder')}
              type="password"
              variant="outlined"
              value={dockerConfigData?.password}
              onChange={(e) => {
                handleDockerData(e, 'password');
                setIsEmpty(false);
              }}
              disabled={disable ? false : true}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item container className={classes.block}>
        <Grid item xs={6}>
          <div className={classes.label}>
            {t('credentials.create.docker.input.hostname')}
            {flow === 'View' ? null : (
              <span className={classes.required}> *</span>
            )}
          </div>
          <FormControl fullWidth>
            <TextField
              inputRef={register}
              name="hostname"
              placeholder={t(
                'credentials.create.docker.input.hostnamePlaceholder'
              )}
              variant="outlined"
              value={dockerConfigData?.hostname}
              onChange={(e) => {
                handleDockerData(e, 'hostname');
                setIsEmpty(false);
              }}
              disabled={disable ? false : true}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.label}>
            {t('credentials.create.docker.input.email')}
          </div>
          <FormControl fullWidth>
            <TextField
              inputRef={register}
              name="email"
              placeholder={t(
                'credentials.create.docker.input.emailPlaceholder'
              )}
              variant="outlined"
              value={dockerConfigData?.email}
              onChange={(e) => {
                handleDockerData(e, 'email');
                setIsEmpty(false);
              }}
              disabled={disable ? false : true}
            />
          </FormControl>
        </Grid>
      </Grid>
      {flow === 'Update' || flow === 'Create' ? (
        <Grid item container className={classes.button}>
          <Button
            onClick={handleSubmit}
            disabled={
              name &&
              dockerConfigData?.username &&
              dockerConfigData?.password &&
              dockerConfigData?.hostname
                ? false
                : true
            }
          >
            {flow === 'Create' ? <>Add</> : <>Update</>}
            {t('credentials.create.docker.saveBtn')}
          </Button>
          <div style={{ paddingRight: '10px' }}>
            <Button
              onClick={handleCloseCredential}
              color="secondary"
              variant="outlined"
              className={classes.cancelButton}
            >
              Cancel
            </Button>
          </div>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default FromInput;
