import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import './index.css';

import AppContainer from './components/App';
import dashboard from './text/en/dashboard.json';
import applications from './text/en/applications.json';
import header from './text/en/header.json';
import integrations from './text/en/integrations.json';
import audit from './text/en/audit.json';
import project from './text/en/project.json';
import customers from './text/en/customers.json';
import addon from './text/en/addon.json';
import billing from './text/en/billing.json';
import support from './text/en/support.json';
import insights from './text/en/insights.json';
import clusters from './text/en/clusters.json';
import users from './text/en/users.json';
import credentials from './text/en/credentials.json';
import registries from 'text/en/registries.json';
import entitlements from 'text/en/entitlements.json'
i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en', // language to use
  resources: {
    en: {
      dashboard: dashboard,
      applications: applications,
      header: header,
      integrations: integrations,
      audit: audit,
      project: project,
      customers: customers,
      addon: addon,
      billing: billing,
      support: support,
      insights: insights,
      clusters: clusters,
      users: users,
      credentials: credentials,
      registries: registries,
      entitlements : entitlements
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <AppContainer />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
