import * as yup from 'yup';

import TicketStatus from 'schema/ticket-status';
import Priority from 'schema/priority';

export const priority = [
  {
    value: Priority.HIGH.enumKey,
    name: 'High',
  },
  {
    value: Priority.MEDIUM.enumKey,
    name: 'Medium',
  },
  {
    value: Priority.LOW.enumKey,
    name: 'Low',
  },
  {
    value: Priority.URGENT.enumKey,
    name: 'Urgent',
  },
];

export const status = [
  {
    value: TicketStatus.OPENED.enumKey,
    name: 'Open',
  },
  {
    value: TicketStatus.IN_PROGRESS.enumKey,
    name: 'In Progress',
  },
  {
    value: TicketStatus.ON_HOLD.enumKey,
    name: 'On Hold',
  },
  {
    value: TicketStatus.IN_REVIEW.enumKey,
    name: 'In Review',
  },
  {
    value: TicketStatus.REOPENED.enumKey,
    name: 'Re Open',
  },

  {
    value: TicketStatus.CLOSED.enumKey,
    name: 'Closed',
  },
];

export const event = [
  {
    comboValue: TicketStatus.OPENED.enumKey,
    value: 'OPENED',
  },
  {
    comboValue: TicketStatus.IN_PROGRESS.enumKey,
    value: 'IN_PROGRESS',
  },
  {
    comboValue: TicketStatus.ON_HOLD.enumKey,
    value: 'ON_HOLD',
  },
  {
    comboValue: TicketStatus.IN_REVIEW.enumKey,
    value: 'IN_REVIEW',
  },
  {
    comboValue: TicketStatus.REOPENED.enumKey,
    value: 'REOPEN',
  },

  {
    value: TicketStatus.CLOSED.enumKey,
    name: 'CLOSE',
  },
];

export const enableStatus = {
  [TicketStatus.IN_PROGRESS.enumKey]: {
    [TicketStatus.IN_REVIEW.enumKey]: 'IN_REVIEW',
    [TicketStatus.ON_HOLD.enumKey]: 'ON_HOLD',
  },
  [TicketStatus.ON_HOLD.enumKey]: {
    [TicketStatus.IN_PROGRESS.enumKey]: 'IN_PROGRESS',
  },
  [TicketStatus.IN_REVIEW.enumKey]: {
    [TicketStatus.CLOSED.enumKey]: 'CLOSE',
    [TicketStatus.REOPENED.enumKey]: 'REOPEN',
  },
  [TicketStatus.CLOSED.enumKey]: {
    [TicketStatus.REOPENED.enumKey]: 'REOPEN',
  },
  [TicketStatus.REOPENED.enumKey]: {
    [TicketStatus.IN_PROGRESS.enumKey]: 'IN_PROGRESS',
  },
  [TicketStatus.OPENED.enumKey]: {
    [TicketStatus.CLOSED.enumKey]: 'CLOSE',
    [TicketStatus.IN_PROGRESS.enumKey]: 'IN_PROGRESS',
  },
};

export let Schema = yup.object().shape({
  entitlementId: yup.string().required('This field is required'),
  title: yup.string().required('This field is required'),
});

// To pass the return type correctly to the custom select
export const select = (arr) => {
  return arr.map((x, index) => {
    if (x.name && x.value) {
      return { id: index++, ...x };
    } else {
      return { id: index++, name: x };
    }
  });
};
