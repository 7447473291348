import React, { useState, useMemo } from 'react';

import { useTable } from 'react-table';
import memoize from 'memoize-one';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';

import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';

import useHover from 'hooks/useHover';
import StatusType from 'schema/status-type';
import { useWindowSize } from 'hooks/useViewport';
let scrollBarSize;

const marginLeft = 3;

const useStyles = makeStyles((theme) => ({
  thead: {
    display: 'table',
    tableLayout: 'fixed',
    width: `calc(98% - ${scrollBarSize}px - ${marginLeft}px)`,
  },
  head: {
    borderBottom: '0px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#808CA3',
    paddingLeft: '1.8em',
    '&:first-child': {
      paddingLeft: '2.6em',
    },
    '&:last-child': {
      paddingRight: '13em',
    },
  },
  theading: {
    display: 'table',
    tableLayout: 'fixed',
    width: `calc(107% - ${scrollBarSize}px - ${marginLeft}px)`,
  },
  heading: {
    borderBottom: '0px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#808CA3',
    paddingLeft: '1.8em',
    '&:first-child': {
      paddingLeft: '2.5em',
    },
    '&:last-child': {
      paddingRight: '13.3em',
    },
  },
  tbody: (props) => ({
    overflowY: 'auto',
    display: 'block',
    height: '',
  }),
  rowHeight: {
    '& .MuiTableCell-root': {
      padding: '0px 35px 0px 0px',
    },
  },
  row: {
    display: 'table',
    width: ` !important`,
    tableLayout: 'fixed',
    boxShadow: '0px 1px 8px rgba(20, 46, 110, 0.1)',
    borderRadius: '10px',
    height: '50px !important',
  },
  cell: {
    padding: '0px',
    borderBottom: '0px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#001847',
    paddingLeft: '1.5em',
    '&:first-child': {
      paddingLeft: '3em',
    },
    '&:last-child': {
      paddingRight: '1em',
    },
  },
  cellDeleted: {
    padding: '0px',
    borderBottom: '0px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#878787',

    '&:first-child': {
      paddingLeft: '2em',
    },
    '&:last-child': {
      paddingRight: '2em',
    },
  },
  textTruncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  textTruncateTextWrap: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const scrollbarWidth = () => {
  // thanks to https://davidwalsh.name/detect-scrollbar-width
  const scrollDiv = document.createElement('div');
  scrollDiv.setAttribute(
    'style',
    'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;'
  );

  document.body.appendChild(scrollDiv);

  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);

  return scrollbarWidth;
};

const DataTable = ({
  setState,
  hiddenColumns,
  columns,
  data,
  onClickTableRow,
  children,
  setRowData,
  isNextPageLoading,
  hasNextPage,
  loadMore,
  textWrap,
  ...props
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
      onClickTableRow,
      initialState: {
        hiddenColumns:
          hiddenColumns?.length !== 0 ? hiddenColumns : [hiddenColumns],
      },
    });

  scrollBarSize = useMemo(() => scrollbarWidth(), []);

  const classes = useStyles(props);
  const [selectedId, setSelectedId] = useState(false);
  const windowSize = useWindowSize();
  const heightToReduce = props?.tableHeightPercentage <= 50 ? 470 : 275;

  const tableHeight = windowSize?.height - heightToReduce;
  const handleRowClick = (idx, rowData) => {
    if (setState && setRowData) {
      setRowData(rowData);
    }
    setSelectedId(idx);
    onClickTableRow && onClickTableRow(idx, rowData);
  };

  const createItemData = memoize((prepareRow, data) => ({
    prepareRow,
    selectedId,
    items: data,
    handleRowClick: handleRowClick,
    textWrap: textWrap,
  }));

  const itemData = createItemData(prepareRow, rows, selectedId);

  const loadMoreItems = isNextPageLoading ? () => {} : loadMore;

  const isItemLoaded = ({ index }) => !hasNextPage || index < rows.length;

  const itemCount = hasNextPage ? rows.length + 1 : rows.length;

  return (
    <Table {...getTableProps()} stickyHeader>
      <TableHead
        className={columns.length == 7 ? classes.thead : classes.theading}
      >
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) =>
              column.render('Header') === 'Manage' ||
              column.render('Header') === 'Settings' ? (
                <TableCell
                  {...column.getHeaderProps()}
                  className={
                    columns.length == 7 ? classes.head : classes.heading
                  }
                  style={{
                    textAlign: 'center',
                    paddingLeft: '20%',
                    width: column.render('width'),
                  }}
                >
                  {column.render('Header')}
                </TableCell>
              ) : (
                <TableCell
                  {...column.getHeaderProps()}
                  className={
                    columns.length == 7 ? classes.head : classes.heading
                  }
                  style={{ width: column.render('width') }}
                >
                  {column.render('Header')}
                </TableCell>
              )
            )}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        <AutoSizer disableHeight>
          {({ width }) => {
            const tableWidth = Math.max(800, width);
            return (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}
              >
                {({ onItemsRendered, ref }) => (
                  <List
                    height={tableHeight}
                    width={tableWidth} // Use the calculated table width here
                    itemCount={rows.length}
                    itemSize={60}
                    overscanCount={4}
                    itemData={itemData}
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                  >
                    {Row}
                  </List>
                )}
              </InfiniteLoader>
            );
          }}
        </AutoSizer>
      </TableBody>
    </Table>
  );
};

const Row = ({
  index,
  style,
  data: { prepareRow, items, selectedId, handleRowClick, textWrap },
}) => {
  const classes = useStyles();
  const openRef = React.useRef(false);
  const [open, setOpen] = React.useState(openRef.current);
  const [hoverRef, isHovered] = useHover();
  const row = items[index];
  prepareRow(row);
  const setCellClass = (status) => {
    if (status === StatusType.DELETED.enumKey) {
      return classes.cellDeleted;
    } else {
      return classes.cell;
    }
  };

  return (
    <>
      <TableRow
        id={index}
        style={style}
        ref={hoverRef}
        classes={{
          root: classes.row,
          hover: classes.hover,
        }}
        key={index}
        hover
        selected={selectedId === index}
        {...row.getRowProps()}
        onClick={() =>
          handleRowClick(index, {
            state: row.state,
            original: row.original,
            index: row.index,
            values: row.values,
          })
        }
      >
        {row.cells.map((cell) => {
          return (
            <TableCell
              style={{ width: cell.column.width }}
              component="td"
              scope="row"
              {...cell.getCellProps()}
              classes={{
                root: setCellClass(
                  row.values.clusterAgentId || row.values.clusterId
                    ? row.values.recordState
                    : row.values.status
                ),
              }}
              className={
                textWrap ? classes.textTruncateTextWrap : classes.textTruncate
              }
              onClick={() => {
                openRef.current = !openRef.current;
                setOpen(openRef.current);
              }}
            >
              {cell.render('Cell', { isHovered })}
            </TableCell>
          );
        })}
      </TableRow>
    </>
  );
};
export default DataTable;
