import { Enumify } from 'enumify';

class StatusTypeEnt extends Enumify {
  static SUSPENDED = new StatusTypeEnt({
    get displayValue() {
      return 'SUSPENDED';
    },
  });
  static ACTIVE = new StatusTypeEnt({
    get displayValue() {
      return 'ACTIVE';
    },
  });
  static INACTIVE = new StatusTypeEnt({
    get displayValue() {
      return 'INACTIVE';
    },
  });
  static _ = this.closeEnum();

  constructor(props) {
    super();
    Object.defineProperties(this, Object.getOwnPropertyDescriptors(props));
  }
}

export default StatusTypeEnt;
