import { useQuery } from 'react-query';

import api from 'services/Api';

const useKubeResources = (orgId, projectId) => {
  const fetchKubeResources = async () => {
    const query = new URLSearchParams({
      'organization-id': orgId,
    });

    if (projectId) {
      query.append('project-id', projectId);
    }

    try {
      if (orgId) {
        const resources = await api.get(
          `appdir/director-aggregator/api/v1/summaries/kube-resources?${query.toString()}`
        );

        const data = await resources.json();
        return data.data.kubeResourceCosts;
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useQuery(['kubeResources', orgId, projectId], fetchKubeResources, {
    refetchOnWindowFocus: false,
  });
};

export default useKubeResources;
