import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Modal, makeStyles, useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Divider } from '@material-ui/core';
import ViewListIcon from '@material-ui/icons/ViewList';
import Tooltip from '@material-ui/core/Tooltip';
import Draggable from 'react-draggable';

import DashboardIcon from 'components/DashboardEntIcon';
import EditIcon from 'components/EditIcon';
import CloseButton from 'components/CloseButton';
import DataTable from 'components/ReactTable/tableScroll';
import LabeledChip from 'components/LabeledChip';
import LoaderSpinner from 'components/LoaderSpinner';
import ErrorDataCard from 'components/ErrorDataCard';
import NoDataCard from 'components/NoDataCard';
import useEntitlements from 'hooks/services/useEntitlements';
import useProducts from 'hooks/services/useProducts';
import StatusType from 'schema/status-type';
import Status from 'schema/status';
import CustomerSummary from 'components/CustomerSummary/CustomerSummary';
import useUpdateEntitlementStatus from 'hooks/services/useUpdateEntitlementStatus';
import StatusTypeEnt from 'schema/status-type-ent';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  headingTitle: theme.appDirector.pageHeading,
  section: {
    height: '80%',
    paddingTop: '40px 0px',
  },
  btn: {
    minWidth: '133px',
  },

  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    direction: 'rtl',
  },
  menuIconRoot: {
    color: '#808CA3',
    marginRight: '10px',
  },
  viewTicketBtn: {
    marginRight: '10px !important',
    // marginLeft: '90px !important',
  },
  viewDashboardBtn: {
    marginRight: '10px !important',
  },
  editEntitlementBtn: {
    marginRight: '10px !important',
  },

  btnRoot: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    textTransform: 'none',
    color: '#808CA3',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    height: '26px',
    minWidth: '30px',
    padding: 0,
  },
  btnText: {
    '@media (max-width: 1330px)': {
      display: 'none',
    },
  },
  divider: {
    margin: '0px 2px',
    '@media (max-width: 1330px)': {
      margin: '0px',
    },
  },
  root: {
    marginRight: '10px !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    minWidth: '80px',
    // width: "90px",
    height: '25px',
    padding: '0px',
  },
  switchBase: {
    color: '#818181',
    padding: '2px',
    '&$checked': {
      '& + $track': {
        backgroundColor: '#050a07',
      },
    },
  },
  thumb: {
    color: 'white',
    borderRadius: '15px',
    width: '35px',
    height: '22px',
    marginLeft: '1px',
  },
  track: {
    borderRadius: '20px',
    backgroundColor: '#818181',
    opacity: '1 !important',
    '&:after, &:before': {
      content: '""',
      color: 'white',
      fontSize: '9px',
      fontWeight: '5px',
      position: 'absolute',
      top: '4px',
    },
    '&:after': {
      content: "'Suspend'",
      left: '5px',
    },
    '&:before': {
      content: "'Enable'",
      right: '3px',
    },
  },
  checked: {
    color: '#050a07 !important',
    transform: 'translateX(38px) !important',
  },
}));

const ListEntitlements = ({
  location: {
    state: {
      data: { customerId, customerName, adminEmailAddress, organizationId },
    },
  },
}) => {
  const [t] = useTranslation('entitlements');
  const matches = useMediaQuery('(max-width:1330px)');
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [msrp, setMsrp] = useState();
  const [productCategoryId, setProductCategoryId] = useState();
  const [modifiedProductType, setModifiedProductType] = useState([]);
  const [productName, setProductName] = useState();
  const [productCategory, setProductCategory] = useState();
  const [cursorPoint, setCusrsorPoint] = useState('grab');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRowClick = () => {};

  const handleClickOpen = () => {
    history.goBack();
  };

  const { data: products } = useProducts();
  const {
    data: entitlements,
    isFetching,
    isError,
    isFetchingNextPage: isFetchingMoreEntitlements,
    hasNextPage: canFetchMoreEntitlements,
    fetchNextPage: fetchMoreEntitlements,
  } = useEntitlements({ customerId: customerId });

  const flattenedEntitlements =
    entitlements &&
    entitlements.pages.reduce((accum, curr) => {
      return [...accum, ...curr.entitlements];
    }, []);
  const flattenedProducts =
    products &&
    products.pages.reduce((accum, curr) => {
      return [...accum, ...curr.productsCategories];
    }, []);
  const flattenedProductNames =
    flattenedProducts &&
    flattenedProducts.reduce((accum, curr) => {
      return [...accum, ...curr.products];
    }, []);
  const getProductName = (id) => {
    if (flattenedProductNames && flattenedProductNames.length > 0) {
      const product = flattenedProductNames.find(
        (product) => product.product_id === id
      );
      return product ? product?.product_name : 'No data';
    }
  };

  const entitlementsWithName =
    flattenedEntitlements &&
    flattenedEntitlements.map((entitlement) => {
      const namedUser = {
        entitlementId: entitlement.entitlementId,
        name: entitlement.name,
        productName: getProductName(entitlement.productId),
        productCategoryId: entitlement.productCategoryId,
        customerId: entitlement.customerId,
        entitlementStatus: entitlement.entitlementStatus,
        msrp: entitlement.msrp,
        qty: entitlement.qty,
        startDate: entitlement.startDate,
        endDate: entitlement.endDate,
      };
      return namedUser;
    }, []);

  const getEntitlementData = (id) => {
    const result = flattenedEntitlements.filter(
      (data) => data.entitlementId === id
    );
    return result[0];
  };

  const getProductCategory = (id) => {
    const result = flattenedEntitlements.filter(
      (data) => data.entitlementId === id
    );

    const product = flattenedProducts.find(
      (product) => product.product_category_id === result[0].productCategoryId
    );
    const modifiedProductType = flattenedProducts.map((PC) => {
      return {
        name: PC.product_category,
        value: PC.product_category,
      };
    });

    setModifiedProductType(modifiedProductType);
    return modifiedProductType;
  };

  const columns = useMemo(() => [
    {
      Header: 'Customer Id',
      accessor: 'customerId',
    },
    {
      Header: 'Entitlement Id',
      accessor: 'entitlementId',
    },
    {
      Header: 'Product Id',
      accessor: 'productId',
    },
    {
      Header: t('entitlements.tableHeaders.name'),
      accessor: 'name',
      width: '15%',
    },
    {
      Header: t('entitlements.tableHeaders.productPlan'),
      accessor: 'productName',
      width: '15%',
    },
    {
      Header: t('entitlements.tableHeaders.startDate'),
      accessor: 'startDate',
      width: '10%',
    },

    {
      Header: t('entitlements.tableHeaders.endDate'),
      accessor: 'endDate',
      width: '10%',
    },
    {
      Header: t('entitlements.tableHeaders.endDate'),
      accessor: 'productCategoryId',
    },
    // {
    //   Header: t('entitlements.tableHeaders.status'),
    //   accessor: 'entitlementStatus',
    // },
    {
      Header: t('entitlements.tableHeaders.status'),
      accessor: 'entitlementStatus',
      width: '10%',
    },
    {
      Header: t('entitlements.tableHeaders.manage.title'),
      accessor: 'actions',
      width: '25%',
      Cell: ({
        cell: {
          row: { values },
        },
      }) => {
        const { mutateAsync: entitlementUpdaterStatus, error } =
          useUpdateEntitlementStatus(values.entitlementId);

        const [state, setState] = React.useState(
          values.entitlementStatus === StatusTypeEnt.ACTIVE.displayValue
            ? true
            : false
        );

        const handleChange = (stat, entitlementUpdaterStatus) => {
          let EntStatus;

          if (stat === false) {
            EntStatus = StatusTypeEnt.SUSPENDED.displayValue;

            const payload = {
              entitlementStatus: EntStatus,
            };

            entitlementUpdaterStatus(payload);
          } else {
            const payload = {
              entitlementStatus: StatusTypeEnt.ACTIVE.displayValue,
            };

            entitlementUpdaterStatus(payload);
          }
          state ? setState(false) : setState(true);
        };

        return (
          <div className={classes.actionContainer}>
            {values.status === StatusType.DELETED.displayValue ? (
              <LabeledChip
                label={'Deleted Project'}
                backgroundColor={'#B5B5B5'}
              />
            ) : (
              <>
                <Tooltip title="View Usage">
                  <Button
                    variant="text"
                    classes={{
                      root: classes.btnRoot,
                    }}
                    endIcon={
                      <DashboardIcon
                        color="#808CA3"
                        className={classes.viewDashboardBtn}
                      />
                    }
                    onClick={() => {
                      setProductName(values.productName);
                      setProductCategory(values.productCategoryId);
                      handleOpen();
                    }}
                  ></Button>
                </Tooltip>
                <Divider
                  orientation="vertical"
                  flexItem
                  className={classes.divider}
                />
                <Tooltip
                  title={t('entitlements.tableHeaders.manage.viewTicket')}
                >
                  <Button
                    id="btn-edit"
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push({
                        state: {
                          flow: 'Entitlements',
                          id: values.entitlementId,
                        },
                        pathname: `/customers/${customerId}/entitlements/${values.entitlementId}/tickets`,
                      });
                    }}
                    variant="text"
                    classes={{
                      root: classes.btnRoot,
                    }}
                    endIcon={
                      <ViewListIcon
                        className={classes.viewTicketBtn}
                        color={'#808CA3'}
                      />
                    }
                  ></Button>
                </Tooltip>
                <Divider
                  orientation="vertical"
                  flexItem
                  className={classes.divider}
                />
                <Tooltip title="Edit">
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push({
                        state: {
                          data: {
                            entitlementData: getEntitlementData(
                              values.entitlementId
                            ),
                            entitlementId: values.entitlementId,
                            productCategory: getProductCategory(
                              values.entitlementId
                            ),
                            adminEmailAddress: adminEmailAddress,
                          },
                          path: 'edit',
                          flow: 'Edit',
                        },
                        pathname: `/customers/${customerId}/entitlements/${values.entitlementId}/edit`,
                      });
                    }}
                    variant="text"
                    classes={{
                      root: classes.btnRoot,
                    }}
                    endIcon={
                      <EditIcon
                        color="#808CA3"
                        className={classes.editEntitlementBtn}
                      />
                    }
                  ></Button>
                </Tooltip>
                <Divider
                  orientation="vertical"
                  flexItem
                  className={classes.divider}
                />
                <Tooltip>
                  <Switch
                    classes={{
                      root: classes.root,
                      switchBase: classes.switchBase,
                      thumb: classes.thumb,
                      track: classes.track,
                      checked: classes.checked,
                    }}
                    checked={state}
                    onChange={(e) => {
                      handleChange(e.target.checked, entitlementUpdaterStatus);
                    }}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                </Tooltip>
              </>
            )}
          </div>
        );
      },
    },
  ]);

  return (
    <Grid container classes={{ container: classes.gridContainer }}>
      <Grid item container justify="space-between">
        <span className={classes.headingTitle}>{t('entitlements.title')}</span>
        <div>
          <div className={classes.closeButton}>
            <CloseButton onClick={handleClickOpen} id="closeButton" />
          </div>

          <Button
            id="btn-create-project"
            endIcon={<AddCircleOutlineIcon />}
            classes={{ root: classes.btn }}
            onClick={() =>
              history.push({
                state: {
                  data: {
                    customerId: customerId,
                    adminEmailAddress: adminEmailAddress,
                  },
                  flow: 'Create',
                },
                pathname: `/customers/${customerId}/entitlements/create`,
              })
            }
          >
            {t('entitlements.create.createBtn')}
          </Button>
        </div>
      </Grid>

      {isError ? (
        <ErrorDataCard message={t('entitlements.errorMsg')} />
      ) : isFetching && !entitlements ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.spinner}
        >
          <LoaderSpinner type="Oval" color="#00BFFF" height={60} width={60} />
        </Grid>
      ) : flattenedEntitlements.length === 0 ? (
        <NoDataCard message={t('entitlements.noDataMsg')} />
      ) : (
        <Grid item className={classes.section}>
          {/* {console.log(entitlementsWithName)} */}
          <DataTable
            data={entitlementsWithName}
            onClickTableRow={handleRowClick}
            hiddenColumns={
              matches
                ? [
                    'customerId',
                    'productId',
                    'entitlementId',
                    'productCategoryId',
                  ]
                : [
                    'customerId',
                    'productId',
                    'entitlementId',
                    'productCategoryId',
                  ]
            }
            columns={columns}
            isNextPageLoading={isFetchingMoreEntitlements}
            hasNextPage={canFetchMoreEntitlements}
            loadMore={fetchMoreEntitlements}
          />
        </Grid>
      )}

      <Grid container>
        <Grid item>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <Draggable
              axis="both"
              handle=".handle"
              defaultPosition={{ x: 0, y: 0 }}
              position={null}
              grid={[25, 25]}
              scale={1}
              onStart={() => setCusrsorPoint('grabbing')}
              onStop={() => setCusrsorPoint('grab')}
            >
              <div>
                <div className="handle">
                  <Box
                    sx={{
                      p: '2%',
                      mt: '8%',
                      mx: 'auto',
                      backgroundColor: 'white',
                      borderRadius: '25px',
                      maxHeight: '900px',
                      position: 'relative',
                      width: '60%',
                    }}
                    style={{
                      cursor: cursorPoint,
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        position: 'relative',
                      }}
                    >
                      <div
                        className={classes.closeButton}
                        style={{ position: 'absolute', top: 0, right: 0 }}
                      >
                        <CloseButton onClick={handleClose} id="closeButton" />
                      </div>
                      <Grid container justifyContent="center">
                        <Grid item style={{ width: '100%' }}>
                          <CustomerSummary
                            customerId={customerId}
                            productName={productName}
                            customerName={customerName}
                            productCategoryId={productCategory}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Box>
                </div>
              </div>
            </Draggable>
          </Modal>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ListEntitlements;
