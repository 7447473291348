import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  isFetching: false,
  activeTab: {},
};

const activeTabSlice = createSlice({
  name: 'activeTab',
  initialState: INITIAL_STATE,
  reducers: {
    activeTabFetchStart(state, action) {
      state.isFetching = true;
      state.activeTab = {};
    },
    activeTabFetchSuccess(state, { payload: { activeTab } }) {
      state.isFetching = false;
      state.activeTab = activeTab;
    },
    activeTabFetchError(state, action) {
      state.isFetching = false;
      state.activeTab = {};
    },
  },
});

export const { name, actions, reducer } = activeTabSlice;
export const {
  activeTabFetchStart,
  activeTabFetchSuccess,
  activeTabFetchError,
} = actions;
export default reducer;
