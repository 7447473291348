import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { useTheme, useMediaQuery } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import FilterDramaIcon from '@material-ui/icons/FilterDrama';
import LinkIcon from '@material-ui/icons/Link';
import ViewListIcon from '@material-ui/icons/ViewList';

import Header from 'components/Header';
import HamburgerIcon from 'components/HamburgerIcon';
import DashboardIcon from 'components/DashboardIcon';
import VisibilityIcon from 'components/VisibilityIcon';
import AppsIcon from 'components/AppsIcon';
import ProjectIcon from 'components/ProjectIcon';
import IamIcon from 'components/IamIcon';
import BillingIcon from 'components/BillingIcon';
import SupportIcon from 'components/SupportIcon';
import { selectRoles } from 'state/auth/selectors';
import { useTranslation } from 'react-i18next';
import {
  activeTabFetchStart,
  activeTabFetchSuccess,
} from 'state/drawerActiveTab';
import { selectActiveTabNum } from 'state/drawerActiveTab/selector';
import { billingInfoFetchSuccess } from 'state/billingInfo';
import { generalInfoFetchSuccess } from 'state/generalInfo';
import { referenceInfoFetchSuccess } from 'state/reference';
import { checkBoxInfoFetchSuccess } from 'state/checkBox';

const useStyles = makeStyles((theme) => ({
  branding: {
    color: 'white',
    fontFamily: 'Comfortaa',
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '17.84px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    paddingBottom: '43px',
  },
  menuButtonRoot: {
    paddingTop: '1.5rem',
    marginLeft: '45px',
    userSelect: 'none',
    backgroundColor: 'transparent',

    [theme.breakpoints.down('xs', 'sm')]: {
      marginLeft: '30px',
    },
  },
  tabRoot: {
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '13px',
    lineHeight: '18px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    padding: '10px 0 10px 0',
    marginLeft: '45px',
    minHeight: '43px',
    maxHeight: '43px',
    whiteSpace: 'nowrap',
    color: `${theme.palette.primary.light} !important`,
    '&:hover': {
      color: 'white !important',
    },
    '&.Mui-selected': {
      color: 'white !important',
    },

    [theme.breakpoints.down('xs', 'sm')]: {
      fontSize: '11px',
      marginLeft: '15px',
    },
  },
  tabWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  tabIndicator: {
    border: '3px solid white',
    borderLeftWidth: '0',
  },
  iconRoot: {
    marginBottom: '0!important',
    marginRight: '10px',
    fontSize: '18px',
  },
  dashboardAndVisibiltyIconRoot: {
    marginBottom: '0!important',
    marginRight: '10px',
    marginLeft: '1px',
    '@media (min-width: 1960px)': {
      height: '1.7em !important',
      width: '1.7em !important',
    },
  },
  identifyAndAccessIconRoot: {
    marginBottom: '0!important',
    marginRight: '14px',
    marginLeft: '3px',
    '@media (min-width: 1960px)': {
      height: '1.7em !important',
      width: '1.7em !important',
    },
  },
  appsAndProjectIconRoot: {
    marginBottom: '0!important',
    marginRight: '13px',
    marginLeft: '0px',
    '@media (min-width: 1960px)': {
      height: '1.7em !important',
      width: '1.7em !important',
    },
  },
  ticketsIconRoot: {
    marginBottom: '0!important',
    marginRight: '13px',
    marginLeft: '0px',
    '@media (min-width: 1960px)': {
      height: '1.7em !important',
      width: '1.7em !important',
    },
  },
  playlistIconRoot: {
    marginBottom: '0!important',
    marginRight: '7px',
    marginLeft: '1px',
    fontSize: '25px',
    '@media (min-width: 1960px)': {
      height: '1.7em !important',
      width: '1.7em !important',
    },
  },
  LinkIconRoot: {
    marginBottom: '0!important',
    marginRight: '8px',
    marginLeft: '4px',
    fontSize: '18px',
    '@media (min-width: 1960px)': {
      height: '1.7em !important',
      width: '1.7em !important',
    },
  },
  FilterDramaIcon: {
    marginBottom: '0!important',
    marginRight: '7px',
    marginLeft: '3px',
    fontSize: '20px',
    '@media (min-width: 1960px)': {
      height: '1.7em !important',
      width: '1.7em !important',
    },
  },
  BillingIconRoot: {
    marginBottom: '0!important',
    marginRight: '6px',
    marginLeft: '4px',
    fontSize: '21px',
    '@media (min-width: 1960px)': {
      height: '1.7em !important',
      width: '1.7em !important',
    },
  },
  materialIconRoot: {
    marginBottom: '0!important',
    marginRight: '7px',
    marginLeft: '5px',
    fontSize: '22px',
    '@media (min-width: 1960px)': {
      height: '1.7em !important',
      width: '1.7em !important',
    },
  },
  drawerPaper: {
    backgroundColor: theme.palette.primary.main,
  },
  drawer: {
    width: theme.appDirector.drawer.openWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    '@media (min-width: 1960px)': {
      width: '375px',
    },
  },
  drawerOpen: {
    width: theme.appDirector.drawer.openWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden', // To prevent the scrollbar from flashing while the drawer is opening
    '@media (min-width: 1960px)': {
      width: '400px',
    },
    [theme.breakpoints.down('xs', 'sm')]: {
      width: '200px',
    },
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.appDirector.drawer.closeWidth,
    '@media (max-width: 1330px)': {
      width: '100px',
    },
    '@media (min-width: 1960px)': {
      width: '165px',
    },
  },
  divider: {
    borderTop: '1px solid #808ca3',
    borderRadius: '2px',
    margin: '10px 20px',
    opacity: '0.5',
  },
  rootButton: {
    '@media (min-width: 1960px)': {
      height: '2em !important',
      width: '2em !important',
    },
  },
}));

const NavigationBar = ({ isDrawerOpen, onDrawerOpen }) => {
  const [t] = useTranslation('header');
  const classes = useStyles();
  const theme = useTheme();
  const roles = useSelector(selectRoles);
  const cachedActiveTabData = useSelector(selectActiveTabNum);
  const cachedActiveTab = cachedActiveTabData?.activeTab;
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();
  const isSmOrXs = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  if (isMd) {
    isDrawerOpen = true;
  }

  useEffect(() => {
    if (!roles.includes('admin_portal_view_dashboard')) {
      setActiveTab(1);
    }
  }, [roles]);

  const routeListTop = [
    {
      name: t('menu.dashboard'),
      icon: <DashboardIcon className={classes.dashboardAndVisibiltyIconRoot} />,
      link: '/dashboard',
      activeIndex: 0,
      requiredRoles: ['admin_portal_view_dashboard'],
    },
    {
      name: t('menu.customers'),
      link: '/customers',
      icon: <ProjectIcon className={classes.appsAndProjectIconRoot} />,
      activeIndex: 3,
      requiredRoles: ['admin_portal_manage_entitlements'],
    },
    {
      name: t('menu.tickets'),
      link: '/tickets',
      icon: <VisibilityIcon className={classes.ticketsIconRoot} />,
      activeIndex: 4,
      requiredRoles: ['admin_portal_manage_tickets'],
    },
  ];

  const routeListMiddleOne = [];

  const routeListMiddleTwo = [
    // {
    //   name: t('menu.iam'),
    //   link: '/iam',
    //   icon: <IamIcon className={classes.materialIconRoot} />,
    //   activeIndex: 8,
    //   requiredRoles: ['admin_portal_manage_iam'],
    // },
    {
      name: t('menu.identity'),
      icon: <IamIcon className={classes.identifyAndAccessIconRoot} />,
      link: '/iam',
      activeIndex: 8,
      requiredRoles: ['admin_portal_manage_iam'],
    },
  ];

  const routeListBottom = [
    {
      name: t('menu.support'),
      link: '/support',
      icon: <SupportIcon className={classes.materialIconRoot} />,
      activeIndex: 12,
      requiredRoles: ['manage_support'],
    },
  ];

  const [filteredTopRoutes, setFilteredTopRoutes] = useState(routeListTop);
  const [filteredOneRoutes, setFilteredOneRoutes] =
    useState(routeListMiddleOne);
  const [filteredTwoRoutes, setFilteredTwoRoutes] =
    useState(routeListMiddleTwo);
  const [filteredBottomRoutes, setFilteredBottomRoutes] =
    useState(routeListBottom);

  useEffect(() => {
    const filteredTopRoutes = routeListTop
      .filter(({ requiredRoles }) => {
        return requiredRoles.some((role) => {
          return roles.includes(role);
        });
      })
      .map((route, index) => {
        route.activeIndex = index;
        return route;
      });

    const filteredOneRoutes = routeListMiddleOne
      .filter(({ requiredRoles }) => {
        return requiredRoles.some((role) => {
          return roles.includes(role);
        });
      })
      .map((route, index) => {
        route.activeIndex = index;
        return route;
      });

    const filteredTwoRoutes = routeListMiddleTwo
      .filter(({ requiredRoles }) => {
        return requiredRoles.some((role) => {
          return roles.includes(role);
        });
      })
      .map((route, index) => {
        route.activeIndex = index;
        return route;
      });

    const filteredBottomRoutes = routeListBottom
      .filter(({ requiredRoles }) => {
        return requiredRoles.some((role) => {
          return roles.includes(role);
        });
      })
      .map((route, index) => {
        route.activeIndex = index;
        return route;
      });

    setFilteredTopRoutes(filteredTopRoutes);
    setFilteredOneRoutes(filteredOneRoutes);
    setFilteredTwoRoutes(filteredTwoRoutes);
    setFilteredBottomRoutes(filteredBottomRoutes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);

  const handleTabChange = (event, newValue) => {
    isDrawerOpen && isSmOrXs && onDrawerOpen();
    setActiveTab(newValue);
    dispatch(activeTabFetchStart());
    dispatch(activeTabFetchSuccess({ activeTab: newValue }));
    dispatch(referenceInfoFetchSuccess({}));
    dispatch(billingInfoFetchSuccess({}));
    dispatch(generalInfoFetchSuccess({}));
    dispatch(checkBoxInfoFetchSuccess({}));
  };

  return (
    <>
      <Header isDrawerOpen={isDrawerOpen} onDrawerOpen={onDrawerOpen} />
      <Drawer
        variant={isSmOrXs ? 'temporary' : 'permanent'}
        anchor="left"
        open={isDrawerOpen}
        onClose={onDrawerOpen}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isDrawerOpen,
          [classes.drawerClose]: !isDrawerOpen,
        })}
        classes={{
          paper: clsx(classes.drawerPaper, {
            [classes.drawerOpen]: isDrawerOpen,
            [classes.drawerClose]: !isDrawerOpen,
          }),
        }}
      >
        <Typography className={classes.branding} onClick={false}>
          <div className={classes.menuButtonRoot}>{t('menu.title')}</div>
        </Typography>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={
            cachedActiveTab.toString() !== '[object Object]'
              ? cachedActiveTab
              : activeTab
          }
          onChange={handleTabChange}
          classes={{
            indicator: classes.tabIndicator,
          }}
        >
          {filteredTopRoutes.map(({ name, icon, link }, index) => {
            return (
              <Tab
                label={isDrawerOpen ? name : ''}
                icon={icon}
                key={name}
                to={link}
                component={NavLink}
                disableRipple
                classes={{
                  root: classes.tabRoot,
                  wrapper: classes.tabWrapper,
                }}
              />
            );
          })}
          {filteredOneRoutes.length > 0 ? (
            <Divider className={classes.divider} />
          ) : null}
          {filteredOneRoutes.map(({ name, icon, link }, index) => {
            return (
              <Tab
                label={isDrawerOpen ? name : ''}
                icon={icon}
                key={name}
                to={link}
                component={NavLink}
                disableRipple
                classes={{
                  root: classes.tabRoot,
                  wrapper: classes.tabWrapper,
                }}
              />
            );
          })}
          {filteredTwoRoutes.length > 0 ? (
            <Divider className={classes.divider} />
          ) : null}
          {filteredTwoRoutes.map(({ name, icon, link }, index) => {
            return (
              <Tab
                label={isDrawerOpen ? name : ''}
                icon={icon}
                key={name}
                to={link}
                component={NavLink}
                disableRipple
                classes={{
                  root: classes.tabRoot,
                  wrapper: classes.tabWrapper,
                }}
              />
            );
          })}
          {filteredBottomRoutes.length > 0 ? (
            <Divider className={classes.divider} />
          ) : null}
          {filteredBottomRoutes.map(({ name, icon, link }, index) => {
            return (
              <Tab
                label={isDrawerOpen ? name : ''}
                icon={icon}
                key={name}
                to={link}
                component={NavLink}
                disableRipple
                classes={{
                  root: classes.tabRoot,
                  wrapper: classes.tabWrapper,
                }}
              />
            );
          })}
        </Tabs>
      </Drawer>
    </>
  );
};

export default NavigationBar;
