import { useMutation, useQueryClient } from 'react-query';

import api from 'services/Api';

const useCreateEntitlements = () => {
  const QueryClient = useQueryClient();
  const entitlmentURL = '/core/entitlements/v1/entitlements';

  return useMutation(
    (obj) =>
      api.post(entitlmentURL, JSON.stringify(obj)).then((data) => data.json()),
    {
      onSuccess: async () => {
        QueryClient.invalidateQueries('success');
      },
    },
    {
      onError: async () => {
        console.log('error');
      },
    }
  );
};

export default useCreateEntitlements;
