import { useMutation, useQueryClient,useQuery } from 'react-query';

import api from 'services/Api';

const useInviteUsers = () => {
  const QueryClient = useQueryClient();

  const query = new URLSearchParams({
    'app-code': 'DEFAULT', // Later ADMIN_PORTAL
  });
  const projectUrl = `core/iam/v1/users/invite?${query.toString()}`;

  return useMutation(
    (obj) => api.post(projectUrl, JSON.stringify(obj)).then((x) => x.json()),
    {
      onSuccess: async () => {
        QueryClient.invalidateQueries();
      },
    },
    {
      onError: async () => {
        console.log('error');
      },
    }
  );
};



export default useInviteUsers;
