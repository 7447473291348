import React, { useState } from 'react';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { Divider, Grid } from '@material-ui/core';

import StatusType from 'schema/status-type';
import LabeledButton from 'components/LabeledButton';

const useStyles = makeStyles((theme) => ({
  select: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: '#808CA3',
  },
  selectBox: {
    fontSize: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  enableBtn: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: '#000000',
  },
  labeledBtn: {
    padding: '6px 16px',
  },
}));

const CustomSelect = ({
  className,
  id,
  name,
  placeholder,
  defaultValue = '',
  register,
  selectItems,
  value,
  onChange,
  path,
  length,
  index,
  height,
  multiple,
  checkBox,
  status,
  create,
  handleCreate,
  disabled,
}) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [items, setItems] = useState([]);
  const setColor = (status) => {
    if (status === StatusType.DELETED.displayValue) {
      return '#878787';
    } else {
      return '#001847';
    }
  };

  return (
    <FormControl className={className} variant="outlined" fullWidth>
      <Select
        disabled={
          disabled ||
          (length - 1 !== index && name === 'action') ||
          status === StatusType.DELETED.displayValue ||
          ['edit', 'View', 'summary'].includes(path)
            ? true
            : false
        }
        id={id}
        displayEmpty
        multiple={multiple ? true : false}
        placeholder={placeholder}
        classes={disabled ? classes.select : classes.enableBtn}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        onOpen={() => setIsOpen(true)}
        renderValue={
          checkBox
            ? (selected) => {
                setItems(selected);
                var str = '';
                selectItems.filter((item, index) =>
                  items.includes(item.value)
                    ? (str = str + item.name + ', ')
                    : null
                );
                if (str.endsWith(', ')) {
                  return str.replace(/,\s*$/, '');
                } else {
                  return str;
                }
              }
            : null
        }
        inputProps={{
          name,
          inputRef: (ref) => {
            if (!ref) return;
            register({ name, value: ref.value });
          },
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              maxHeight: height,
            },
          },
        }}
        className={classes.selectBox}
      >
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
        {create ? (
          <>
            <LabeledButton
              handleClick={handleCreate}
              classes={{ root: classes.labeledBtn }}
            >
              {create}
            </LabeledButton>
            <Divider />
          </>
        ) : null}

        {selectItems?.map((item, index) => (
          <MenuItem
            key={index}
            value={item.value ? item.value : item.name}
            data-id={item.id}
            data-type={item.type}
            data-url={item.url ? item.url : null}
            style={{ color: setColor(item.status) }}
          >
            <Grid container justifyContent="space-between">
              {item.name}
              {checkBox ? (
                items.includes(item.value) ? (
                  isOpen ? (
                    <Grid item>
                      <CheckRoundedIcon />
                    </Grid>
                  ) : null
                ) : null
              ) : null}
            </Grid>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
