import { useMutation, useQueryClient } from 'react-query';

import api from 'services/Api';


const useUpdateEntitlementStatus = (entitlementId) => {
  const QueryClient = useQueryClient();
  const url = `core/entitlements/v1/entitlements/${entitlementId}/status`;


  let header = new Headers();
  header.append('entitlement-id', entitlementId);

  return useMutation(
   
    (obj) => { api.put(url, JSON.stringify(obj)).then((x) => x.json(),{header})},
    
    {
      onSuccess: async () => {
        QueryClient.invalidateQueries();
      },
    },
    {
      onError: async () => {
        console.log('error');
      },
    }
  );

 
};

export default useUpdateEntitlementStatus;
