import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  isFetching: false,
  appId: {},
};

const appIdSlice = createSlice({
  name: 'appId',
  initialState: INITIAL_STATE,
  reducers: {
    appIdFetchStart(state, action) {
      state.isFetching = true;
      state.appId = {};
    },
    appIdFetchSuccess(state, { payload: { appId } }) {
      state.isFetching = false;
      state.appId = appId;
    },
    appIdFetchError(state, action) {
      state.isFetching = false;
      state.appId = {};
    },
  },
});

export const { name, actions, reducer } = appIdSlice;
export const { appIdFetchStart, appIdFetchSuccess, appIdFetchError } = actions;
export default reducer;
