import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { useAuth } from 'hooks/useAuth';
import ErrorFallback from 'components/ErrorFallback';

import { ROUTE_UN_AUTHORIZED, ROUTE_SIGN_IN } from '../../constants';

const PrivateRoute = ({
  component: Component,
  path,
  exact = false,
  requiredRoles,
  ...rest
}) => {
  const location = useLocation();
  const { isAuthenticated, isAuthorized } = useAuth();
  const userHasRequiredRole = isAuthorized(requiredRoles);

  return (
    <Route
      path={path}
      exact={exact}
      {...rest}
      render={(props) =>
        isAuthenticated && userHasRequiredRole ? (
          <ErrorBoundary FallbackComponent={ErrorFallback} resetKeys={[path]}>
            <Component {...props} />
          </ErrorBoundary>
        ) : (
          <Redirect
            to={{
              pathname: userHasRequiredRole
                ? ROUTE_SIGN_IN
                : ROUTE_UN_AUTHORIZED,
              state: {
                from: location,
              },
            }}
          />
        )
      }
    ></Route>
  );
};

export default PrivateRoute;
