import { useMutation, useQueryClient } from 'react-query';

import api from 'services/Api';

const useAssignTicket = ({entitlementId,ticketId}) => {
  const QueryClient = useQueryClient();
  const assignTicket = `csp/ticket/v1/tickets/${entitlementId}/${ticketId}/assignee`;

  return useMutation(
    (obj) =>
      api.post(assignTicket, JSON.stringify(obj)).then((data) => data.json()),
    {
      onSuccess: async () => {
        QueryClient.invalidateQueries();
      },
    },
    {
      onError: async () => {
        console.log('error assign ticket');
      },
    }
  );
};

export default useAssignTicket;