import React from 'react';

const SearchIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.4756 12.3928C13.4756 12.2886 13.4444 12.2053 13.3819 12.1428L12.0937 10.8437C12.0312 10.7812 11.9479 10.75 11.8437 10.75H11.4062C11.9062 10.1666 12.2968 9.51039 12.5781 8.78123C12.8593 8.05206 13 7.29165 13 6.49998C13 5.31249 12.7083 4.22395 12.125 3.23437C11.5416 2.24479 10.7552 1.45833 9.7656 0.874998C8.77602 0.291666 7.68748 0 6.49998 0C5.31249 0 4.22395 0.291666 3.23437 0.874998C2.24479 1.45833 1.45833 2.24479 0.874998 3.23437C0.291666 4.22395 0 5.31249 0 6.49998C0 7.68748 0.291666 8.77602 0.874998 9.7656C1.45833 10.7552 2.24479 11.5416 3.23437 12.125C4.22395 12.7083 5.31249 13 6.49998 13C7.29165 13 8.05206 12.8593 8.78123 12.5781C9.51039 12.2968 10.1666 11.9062 10.75 11.4062V11.8437C10.75 11.9271 10.7812 12.0104 10.8437 12.0937L12.1319 13.3615C12.1944 13.4448 12.2777 13.4865 12.3819 13.4865C12.486 13.4865 12.5798 13.4448 12.6631 13.3615L13.3819 12.674C13.4444 12.5907 13.4756 12.4969 13.4756 12.3928ZM8.99998 10.8281C8.22915 11.276 7.39582 11.5 6.49998 11.5C5.60415 11.5 4.77082 11.276 3.99999 10.8281C3.22916 10.3802 2.61979 9.77081 2.17187 8.99998C1.72395 8.22915 1.5 7.39582 1.5 6.49998C1.5 5.60415 1.72395 4.77082 2.17187 3.99999C2.61979 3.22916 3.22916 2.61979 3.99999 2.17187C4.77082 1.72395 5.60415 1.5 6.49998 1.5C7.39582 1.5 8.22915 1.72395 8.99998 2.17187C9.77081 2.61979 10.3802 3.22916 10.8281 3.99999C11.276 4.77082 11.5 5.60415 11.5 6.49998C11.5 7.39582 11.276 8.22915 10.8281 8.99998C10.3802 9.77081 9.77081 10.3802 8.99998 10.8281Z"
      fill="#808CA3"
    />
  </svg>
);

export default SearchIcon;
