import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Priority from 'schema/priority';

const colorLow = `rgba(65, 152, 253, 1)`;
const colorMedium = `rgba(236, 173, 10, 1)`;
const colorHigh = `rgba(255, 147, 86, 1)`;
const colorCritical = `rgba(252, 45, 45, 1)`;
const colorUrgent = `rgba(255, 85, 31, 1)`;

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    color:'white',
    borderRadius: '21px',
    height: '22px',
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '13px',
    textTransform: 'capitalize',
    lineHeight: '18px',
  }),
  outlined: (props) => ({
    border: props.border,
    background: props.hoverBackgroundColor,
  }),
}));

const priorityMap = {
  [Priority.LOW.enumKey]: {
    hoverBackgroundColor: colorLow,
    color: colorLow,
    border: `1px solid ${colorLow}`,
  },
  [Priority.MEDIUM.enumKey]: {
    hoverBackgroundColor: colorMedium,
    color: colorMedium,
    border: `1px solid ${colorMedium}`,
  },
  [Priority.HIGH.enumKey]: {
    hoverBackgroundColor: colorHigh,
    color: colorHigh,
    border: `1px solid ${colorHigh}`,
  },
  [Priority.CRITICAL.enumKey]: {
    hoverBackgroundColor: colorCritical,
    color: colorCritical,
    border: `1px solid ${colorCritical}`,
  },
  [Priority.URGENT.enumKey]: {
    hoverBackgroundColor: colorUrgent,
    color: colorUrgent,
    border: `1px solid ${colorUrgent}`,
  },
};

const PriorityChip = ({ priority, isHovered }) => {
  const classes = useStyles({ ...priorityMap[priority], isHovered });

  return (
    <Chip
      variant="outlined"
      label={priority}
      classes={{ root: classes.root, outlined: classes.outlined }}
    />
  );
};

export default PriorityChip;
