import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import countryList from 'react-select-country-list';
import { v4 as uuidv4 } from 'uuid';

import { Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ResellerSelect from 'components/ResellerSelect';
import useCreate from 'hooks/services/useCreateCustomerSpec';
import {
  generalInfoFetchStart,
  generalInfoFetchError,
  generalInfoFetchSuccess,
} from 'state/generalInfo';
import { selectGeneralInfoData } from 'state/generalInfo/selectors';
import { visibleUpdateSuccess } from 'state/saveButton';
import BrowserCloseValidation from 'validation/browserCloseValidation';
import validate from 'validation/Customer';
//import CustomerType from 'schema/billing-method';
import CustomSelect from 'components/CustomSelect';
import useCheckEmail from 'hooks/services/useCheckEmail';
import useCustomers from 'hooks/services/useCustomers';
import CustomerType from 'schema/customer-type';
import useCustomerName from 'hooks/services/useCustomerName';
import getCountryAndStates from 'services/CountriesAndState/index';
const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: '0px 40px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  heading: {
    paddingTop: '25px',
  },
  headingTitle: theme.appDirector.pageHeading,
  section: {
    paddingTop: '18px',
    flex: 1,
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  textField: {
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flex: 'none',
    },
  },
  errorTextField: {
    borderColor: 'red !important',
  },
  textFieldBox: {
    '& .MuiInputBase-input': {
      fontSize: '16px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
  },
  label: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    paddingBottom: '14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
  },
  block: {
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingTop: '26px',
  },
  button: {
    width: '166px',
    height: '40px',
    marginBottom: '50px',
  },
  invalid: {
    color: 'red',
    fontSize: '12px',
  },
  tags: {
    paddingTop: '10px',
  },
  required: {
    color: 'red',
  },
  country: {
    popupHeight: '100px',
  },
}));

const GeneralInfo = ({
  data,
  setValue,
  isEmpty,
  setVisible,
  path,
  ...props
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cachedGeneralInfoData = useSelector(selectGeneralInfoData);
  const [setDirty, setPristine] = BrowserCloseValidation();
  const options = useMemo(() => getCountryAndStates().countries);
  const optionsUSState = useMemo(() => getCountryAndStates().usState);
  const optionsCAState = useMemo(() => getCountryAndStates().caState);
  const [usersArr, setUsersArr] = useState([]);
  const [emailCheck, setEmailCheck] = useState('');
  const [emailTypingCheck, setEmailTypingCheck] = useState('');
  const { data: users } = useCustomerName();
  const [edit, setEdit] = useState(
    data?.customerType == CustomerType.CUSTOMER.displayValue ? false : true
  );
  const flattenedUsers =
    users &&
    users.pages.reduce((accum, curr) => {
      return [...accum, ...curr];
    }, []);

  const CANADA = 'CA';
  const USA = 'US';

  useEffect(() => {
    if (users) {
      setUsersArr(flattenedUsers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);
  const [generalInfo, setGeneralInfo] = useState({
    name: `${cachedGeneralInfoData?.generalInfo?.name || data?.name || ''}`,
    adminEmailAddress: `${
      cachedGeneralInfoData?.generalInfo?.adminEmailAddress ||
      data?.adminEmailAddress ||
      ''
    }`,
    adminFirstName: `${
      cachedGeneralInfoData?.generalInfo?.adminFirstName ||
      data?.adminFirstName ||
      ''
    }`,
    adminLastName: `${
      cachedGeneralInfoData?.generalInfo?.adminLastName ||
      data?.adminLastName ||
      ''
    }`,
    customerType: `${
      cachedGeneralInfoData?.generalInfo?.customerType ||
      data?.customerType ||
      ''
    }`,
    address1: `${
      cachedGeneralInfoData?.generalInfo?.address1 || data?.address1 || ''
    }`,
    address2: `${
      cachedGeneralInfoData?.generalInfo?.address2 || data?.address2 || ''
    }`,
    city: `${cachedGeneralInfoData?.generalInfo?.city || data?.city || ''}`,
    province: `${
      cachedGeneralInfoData?.generalInfo?.province || data?.province || ''
    }`,
    postalCode: `${
      cachedGeneralInfoData?.generalInfo?.postalCode || data?.postalCode || ''
    }`,
    countryCode: `${
      cachedGeneralInfoData?.generalInfo?.countryCode || data?.countryCode || ''
    }`,
    phoneNumber: `${
      cachedGeneralInfoData?.generalInfo?.phoneNumber || data?.phoneNumber || ''
    }`,
    partnerOrgId: `${
      cachedGeneralInfoData?.generalInfo?.partnerOrgId ||
      data?.partnerOrgId ||
      ''
    }`,
  });

  const [errors, setErrors] = useState({});

  const { mutateAsync: customerSpecCreater } = useCreate({});

  const { register } = useForm({
    mode: 'onBlur',
  });

  const { mutateAsync: emailChecker, error } = useCheckEmail();

  const sampleCheckEmail = async (email) => {
    if (path === 'edit') {
      const optionalHeaders = { 'customer-email': email };

      if (email !== data?.adminEmailAddress) {
        let data = await emailChecker(optionalHeaders);
        setEmailCheck(data?.data?.value);
        setEmailTypingCheck(data?.data?.value);
      }
    } else {
      const optionalHeaders = { 'customer-email': email };
      let data = await emailChecker(optionalHeaders);
      setEmailCheck(data?.data?.value);
      setEmailTypingCheck(data?.data?.value);
    }
  };

  useEffect(() => {
    if (props.path === 'create' || props.path === 'edit') {
      setVisible(false);
    }
  });

  useEffect(() => {
    dispatch(visibleUpdateSuccess({ visible: false, page: 'general_info' }));
  });

  const [countryRelatedProvince, setCountryRelatedProvince] = useState();
  const [generalInfoProvinceState, setGeneralProvince] = useState();
  const handleChange = (e, name) => {
    if (e.target.value === 'CA' || e.target.value === 'US') {
      setGeneralInfo({ ...generalInfo, [name]: e.target.value });
    } else {
      setGeneralInfo({ ...generalInfo, [name]: e.target.value });
    }
  };

  const handleCustomerTypeChange = (e, name) => {
    e.target.value == CustomerType.CUSTOMER.displayValue
      ? setEdit(false)
      : setEdit(true);

    setGeneralInfo({ ...generalInfo, [name]: e.target.value });
  };
  const handleResellerChange = (e, name) => {
    setValue(true);
    setGeneralInfo({ ...generalInfo, [name]: e.target.value });
  };

  const handleEmail = (e) => {
    setErrors(validate('adminEmailAddress', e.target.value));
    handleChange(e, 'adminEmailAddress');
  };

  const addGeneralInfo = () => {
    if (
      props.path === 'create' &&
      !cachedGeneralInfoData?.generalInfo?.applicationId
    ) {
      const applicationId = uuidv4();
      generalInfo.applicationId = applicationId;
    }

    if (Object.keys(errors).length === 0) {
      dispatch(generalInfoFetchStart());
      dispatch(generalInfoFetchSuccess({ generalInfo }));
      props.nextStep();
      setPristine();
    } else {
      dispatch(generalInfoFetchError({ errors }));
    }
  };

  const customerType = [
    {
      id: '1',
      name: CustomerType.INTERNAL.displayValue,
      value: CustomerType.INTERNAL.enumKey,
    },
    {
      id: '2',
      name: CustomerType.PARTNER.displayValue,
      value: CustomerType.PARTNER.enumKey,
    },
    {
      id: '3',
      name: CustomerType.CUSTOMER.displayValue,
      value: CustomerType.CUSTOMER.enumKey,
    },
  ];

  const { data: adminAddressList } = useCustomers({});

  const isValidationErrors = errors ? Object.keys(errors).length : 0;

  return (
    <Grid container classes={{ container: classes.container }}>
      <Grid item container className={classes.section} spacing={3}>
        <Grid item className={classes.textField} xs={12}>
          <Grid item className={classes.label}>
            <span>
              Name<span className={classes.required}> *</span>
            </span>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                InputProps={
                  errors.name && {
                    classes: {
                      notchedOutline: classes.errorTextField,
                    },
                  }
                }
                inputRef={register}
                id="name"
                name="name"
                placeholder="Enter Customer Name"
                variant="outlined"
                onChange={(e) => {
                  handleChange(e, 'name');
                  setErrors(validate('name', e.target.value));
                }}
                defaultValue={generalInfo.name}
                inputProps={{ maxLength: 200 }}
                className={classes.textFieldBox}
              />
              {errors.name && <p className={classes.invalid}>{errors.name}</p>}
            </FormControl>
          </Grid>
        </Grid>
        <Grid item className={classes.textField} xs={12}>
          <Grid item className={classes.label}>
            <span>
              Admin Email<span className={classes.required}> *</span>
            </span>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                InputProps={
                  errors.adminEmailAddress && {
                    classes: {
                      notchedOutline: classes.errorTextField,
                      // color: 'secondary',
                    },
                  }
                }
                inputRef={register}
                id="admin_email"
                name="admin_email"
                placeholder="Enter Admin Email"
                variant="outlined"
                onChange={(e) => {
                  handleEmail(e);
                  handleChange(e, 'adminEmailAddress');
                }}
                defaultValue={generalInfo.adminEmailAddress}
                onBlur={(e) => {
                  !errors.email && sampleCheckEmail(e.target.value);
                }}
                onKeyUp={() => setEmailTypingCheck(false)}
                onClick={() => setEmailTypingCheck(false)}
                inputProps={{ maxLength: 200 }}
                className={classes.textFieldBox}
              />
              {errors.email && (
                <p className={classes.invalid}>{errors.email}</p>
              )}
              {emailCheck && emailTypingCheck && (
                <p className={classes.invalid}>Email already exists</p>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container className={classes.section} spacing={3}>
        <Grid item className={classes.textField} xs={12}>
          <Grid item className={classes.label}>
            <span>
              Admin First Name<span className={classes.required}> *</span>
            </span>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                InputProps={
                  errors.notificationEmail && {
                    classes: {
                      notchedOutline: classes.errorTextField,
                    },
                  }
                }
                inputRef={register}
                id="admin_first_name"
                name="admin_first_name"
                placeholder="Enter Admin First Name"
                variant="outlined"
                onChange={(e) => {
                  handleChange(e, 'adminFirstName');
                  setErrors(validate('adminFirstName', e.target.value));
                }}
                defaultValue={generalInfo.adminFirstName}
                inputProps={{ maxLength: 50 }}
                className={classes.textFieldBox}
              />
              {errors.adminFirstName && (
                <p className={classes.invalid}>{errors.adminFirstName}</p>
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Grid item className={classes.textField} xs={12}>
          <Grid item className={classes.label}>
            <span>
              Admin Last Name<span className={classes.required}> *</span>
            </span>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                InputProps={
                  errors.adminLastName && {
                    classes: {
                      notchedOutline: classes.errorTextField,
                    },
                  }
                }
                inputRef={register}
                id="admin_last_name"
                name="admin_kast_name"
                placeholder="Enter Admin Last Name"
                variant="outlined"
                onChange={(e) => {
                  handleChange(e, 'adminLastName');
                  setErrors(validate('adminLastName', e.target.value));
                }}
                defaultValue={generalInfo.adminLastName}
                inputProps={{ maxLength: 50 }}
                className={classes.textFieldBox}
              />
              {errors.adminLastName && (
                <p className={classes.invalid}>{errors.adminLastName}</p>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container className={classes.section} spacing={3}>
        <Grid item className={classes.textField} xs={12}>
          <Grid item className={classes.label}>
            <span>Customer Type</span>
            <span className={classes.required}> *</span>
          </Grid>
          <CustomSelect
            id="customerType"
            name="customerType"
            placeholder="Select Customer Type"
            selectItems={customerType}
            onChange={(e) => {
              handleCustomerTypeChange(e, 'customerType');
            }}
            defaultValue={generalInfo.customerType}
            register={register}
            className={classes.textFieldBox}
          />
        </Grid>
        <Grid item className={classes.textField} xs={12}>
          <Grid item className={classes.label}>
            <span>Reseller</span>
            <span className={classes.required}> </span>
          </Grid>
          <ResellerSelect
            disabled={edit}
            id="Reseller"
            name="partnerOrgId"
            placeholder="Select Reseller"
            selectItems={
              usersArr
                ? usersArr.map(({ name, id }) => {
                    return {
                      name: name,
                      value: id,
                    };
                  })
                : []
            }
            onChange={(e) => {
              handleResellerChange(e, 'partnerOrgId');
            }}
            register={register}
            value={edit ? null : generalInfo.partnerOrgId}
            className={classes.textFieldBox}
          />
        </Grid>
      </Grid>

      <Grid item container className={classes.section} spacing={3}>
        <Grid item className={classes.textField} xs={12}>
          <Grid item className={classes.label}>
            <span>
              Address Line 1<span className={classes.required}> *</span>
            </span>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                InputProps={
                  errors.address1 && {
                    classes: {
                      notchedOutline: classes.errorTextField,
                    },
                  }
                }
                inputRef={register}
                id="address1"
                name="address1"
                placeholder="Enter first line of address"
                variant="outlined"
                onChange={(e) => {
                  handleChange(e, 'address1');
                  setErrors(validate('address1', e.target.value));
                }}
                defaultValue={generalInfo.address1}
                inputProps={{ maxLength: 50 }}
                className={classes.textFieldBox}
              />
              {errors.address1 && (
                <p className={classes.invalid}>{errors.address1}</p>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid item className={classes.textField} xs={12}>
          <Grid item className={classes.label}>
            <span>Address Line 2</span>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                InputProps={
                  errors.address2 && {
                    classes: {
                      notchedOutline: classes.errorTextField,
                    },
                  }
                }
                inputRef={register}
                id="address2"
                name="address2"
                placeholder="Enter second line of address"
                variant="outlined"
                onChange={(e) => {
                  handleChange(e, 'address2');
                  setErrors(validate('address2', e.target.value));
                }}
                defaultValue={generalInfo.address2}
                inputProps={{ maxLength: 50 }}
                className={classes.textFieldBox}
              />
              {errors.address2 && (
                <p className={classes.invalid}>{errors.address2}</p>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container className={classes.section} spacing={3}>
        <Grid item className={classes.textField} xs={6}>
          <Grid item className={classes.label}>
            <span>
              City
              {/* <span className={classes.required}> *</span> */}
              {generalInfo.countryCode === CANADA ||
              generalInfo.countryCode === USA ? (
                <span className={classes.required}> *</span>
              ) : null}
            </span>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                InputProps={
                  errors.city && {
                    classes: {
                      notchedOutline: classes.errorTextField,
                    },
                  }
                }
                inputRef={register}
                id="city"
                name="city"
                placeholder="Enter City"
                variant="outlined"
                onChange={(e) => {
                  handleChange(e, 'city');
                  setErrors(validate('city', e.target.value));
                }}
                defaultValue={generalInfo.city}
                inputProps={{ maxLength: 50 }}
                className={classes.textFieldBox}
              />

              {errors.city && <p className={classes.invalid}>{errors.city}</p>}
            </FormControl>
          </Grid>
        </Grid>
        <Grid item className={classes.textField} xs={6}>
          <Grid item className={classes.label}>
            <span>
              Country <span className={classes.required}> *</span>
            </span>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: '400px',
                    },
                  },
                }}
                // className={classes.country}
                inputRef={register}
                id="countryCode"
                name="countryCode"
                placeholder="Enter country"
                variant="outlined"
                onChange={(e) => {
                  handleChange(e, 'countryCode');
                  setCountryRelatedProvince('');
                  setGeneralProvince(true);
                }}
                defaultValue={generalInfo.countryCode}
                className={classes.textFieldBox}
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {errors.countryCode && (
                <p className={classes.invalid}>{errors.countryCode}</p>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid item className={classes.textField}>
          <Grid item className={classes.label}>
            <span>
              Province / State
              {/* <span className={classes.required}> *</span> */}
              {generalInfo.countryCode === CANADA ||
              generalInfo.countryCode === USA ? (
                <span className={classes.required}> *</span>
              ) : null}
            </span>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              {generalInfo.countryCode === CANADA ||
              generalInfo.countryCode === USA ? (
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: {
                        maxHeight: '400px',
                      },
                    },
                  }}
                  inputRef={register}
                  id="provinceState"
                  name="province"
                  placeholder="Enter province"
                  variant="outlined"
                  onChange={(e) => {
                    handleChange(e, 'province');
                    setGeneralProvince(false);
                    setErrors(validate('province', e.target.value));
                  }}
                  value={generalInfo?.province}
                  className={classes.textFieldBox}
                >
                  {generalInfo.countryCode === USA &&
                    optionsUSState.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  {generalInfo.countryCode === CANADA &&
                    optionsCAState.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </Select>
              ) : (
                <TextField
                  InputProps={
                    errors.province && {
                      classes: {
                        notchedOutline: classes.errorTextField,
                      },
                    }
                  }
                  inputRef={register}
                  id="provinceState"
                  name="province"
                  placeholder="Enter Province / State"
                  variant="outlined"
                  onChange={(e) => {
                    handleChange(e, 'province');
                    setCountryRelatedProvince();
                    setGeneralProvince(false);
                    setErrors(validate('province', e.target.value));
                  }}
                  value={
                    countryRelatedProvince == null
                      ? generalInfo?.province
                      : countryRelatedProvince
                  }
                  inputProps={{ maxLength: 50 }}
                  className={classes.textFieldBox}
                />
              )}

              {errors.province && (
                <p className={classes.invalid}>{errors.province}</p>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container className={classes.section} spacing={3}>
        <Grid item className={classes.textField} xs={12}>
          <Grid item className={classes.label}>
            <span>
              Postal Code /ZIP code<span className={classes.required}> *</span>
            </span>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                InputProps={
                  errors.postalCode && {
                    classes: {
                      notchedOutline: classes.errorTextField,
                    },
                  }
                }
                inputRef={register}
                id="postal_code"
                name="postal_code"
                placeholder="Enter postal code / ZIP Code"
                variant="outlined"
                onChange={(e) => {
                  handleChange(e, 'postalCode');
                  setErrors(validate('postalCode', e.target.value));
                }}
                defaultValue={generalInfo.postalCode}
                inputProps={{ maxLength: 10 }}
                className={classes.textFieldBox}
              />
              {errors.postalCode && (
                <p className={classes.invalid}>{errors.postalCode}</p>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid item className={classes.textField} xs={12}>
          <Grid item className={classes.label}>
            <span>
              Phone Number<span className={classes.required}> *</span>
            </span>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                InputProps={
                  errors.phoneNumber && {
                    classes: {
                      notchedOutline: classes.errorTextField,
                    },
                  }
                }
                inputRef={register}
                id="phone_number"
                name="phone_number"
                placeholder="Enter phone number"
                variant="outlined"
                onChange={(e) => {
                  handleChange(e, 'phoneNumber');
                  setErrors(validate('phoneNumber', e.target.value));
                }}
                defaultValue={generalInfo.phoneNumber}
                inputProps={{ maxLength: 15 }}
                className={classes.textFieldBox}
              />
              {errors.phoneNumber && (
                <p className={classes.invalid}>{errors.phoneNumber}</p>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container className={classes.block}>
        {generalInfo.countryCode === CANADA ||
        generalInfo.countryCode === USA ? (
          <Button
            disabled={
              generalInfo.adminEmailAddress === '' ||
              generalInfo.name === '' ||
              generalInfo.adminFirstName === '' ||
              generalInfo.adminLastName === '' ||
              generalInfo.customerType === '' ||
              generalInfo.address1 === '' ||
              generalInfo.city === '' ||
              generalInfo.province === '' ||
              generalInfo.postalCode === '' ||
              generalInfo.countryCode === '' ||
              generalInfo.phoneNumber === '' ||
              emailCheck ||
              generalInfoProvinceState ||
              isValidationErrors > 0
            }
            onClick={() => {
              addGeneralInfo();
            }}
          >
            Next
          </Button>
        ) : (
          <Button
            disabled={
              generalInfo.adminEmailAddress === '' ||
              generalInfo.name === '' ||
              generalInfo.adminFirstName === '' ||
              generalInfo.adminLastName === '' ||
              generalInfo.customerType === '' ||
              generalInfo.address1 === '' ||
              generalInfo.postalCode === '' ||
              generalInfo.countryCode === '' ||
              generalInfo.phoneNumber === '' ||
              emailCheck ||
              isValidationErrors > 0
            }
            onClick={() => {
              addGeneralInfo();
            }}
          >
            Next
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default GeneralInfo;
