import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

import StatusType from 'schema/status-type';
import ActionTypes from 'schema/action-type';
import LabeledButton from 'components/LabeledButton';
import ViewIcon from 'components/ViewIcon';
import EditIcon from 'components/EditIcon';
import DeleteIcon from 'components/DeleteIcon';
import AlertDialog from 'components/AlertDialog';
import EditCreateSonarIntegration from 'components/EditCreateSonarIntegration';
import AlertDialogDelete from 'components/AlertDialogDelete';
import useDeleteIntegration from 'hooks/services/useDeleteIntegration';

const useStyles = makeStyles((theme) => ({
  select: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: '#808CA3',
  },
  labeledBtn: {
    padding: '6px 16px',
  },
  textTruncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  button: {
    padding: '4px',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  viewIcon: {
    width: '18px',
    height: '18px',
  },
  deleteIcon: {
    width: '16px',
    height: '16px',
    color: '#808CA3',
  },
  divider: {
    border: '1px solid #D7DDFE',
    height: '20px',
    // marginTop: '20px',
    // marginBottom: '20px',
  },
  diveiderItem: {
    '@media (max-width: 1330px)': {
      display: 'none',
    },
  },
}));

const ToolSet = ({
  setOpenEditSonarIntegration,
  setLocation,
  setOpenDelete,
  setSonarToDelete,
  setNameSonarToDelete,
  data,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid item container justify="flex-end" xs={2}>
        <IconButton
          className={classes.button}
          onClick={(e) => {
            e.stopPropagation();
            setLocation({
              state: { data: data, path: 'View' },
            });
            setOpenEditSonarIntegration(true);
          }}
        >
          <ViewIcon
            className={classes.viewIcon}
            color="#808CA3"
            strokeWidth="1.5"
          />
        </IconButton>
        <IconButton
          className={classes.button}
          onClick={(e) => {
            e.stopPropagation();
            setLocation({
              state: { data: data, path: 'Edit' },
            });
            setOpenEditSonarIntegration(true);
          }}
        >
          <EditIcon color="#808CA3" />
        </IconButton>
        <IconButton
          className={classes.button}
          onClick={(e) => {
            e.stopPropagation();
            setSonarToDelete(data?.value);
            setNameSonarToDelete(data?.data?.host);
            setOpenDelete(true);
          }}
        >
          <DeleteIcon className={classes.deleteIcon} />
        </IconButton>
      </Grid>
    </>
  );
};

const CustomInfiniteSelect = ({
  className,
  id,
  name,
  placeholder,
  defaultValue = '',
  register,
  selectItems,
  value,
  onChange,
  path,
  length,
  index,
  height,
  status,
  create,
  handleCreate,
  isNextPageLoading,
  hasNextPage,
  loadMore,
  actionType,
  refetchSonar,
}) => {
  const classes = useStyles();
  const [item, setItem] = useState();
  const [open, setOpen] = useState(false);
  const [location, setLocation] = useState();
  const [openEditSonarIntegration, setOpenEditSonarIntegration] =
    useState(false);
  const [checkWarning, setCheckWarning] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openPrepareAlert, setOpenPrepareAlert] = useState(false);
  const [deletedIntegration, setDeletedIntegration] = useState();
  const [sonarToDelete, setSonarToDelete] = useState();
  const [sonarNameToDelete, setNameSonarToDelete] = useState();
  const [openDeleteErrorAlert, setOpenDeleteErrorAlert] = useState(false);
  const [openReferenceAlert, setOpenReferenceAlert] = useState(false);
  const [openDeleteSuccessAlert, setOpenDeleteSuccessAlert] = useState(false);

  const { mutateAsync: integrationDeleter, isError } = useDeleteIntegration({
    integrationId: sonarToDelete,
  });

  const loadMoreItems = isNextPageLoading ? () => {} : loadMore;

  const isItemLoaded = ({ index }) =>
    !hasNextPage || index < selectItems.length;

  const itemCount = hasNextPage ? selectItems.length + 1 : selectItems.length;

  const totalHeight = 35 * selectItems.length;
  const expectedHeight = height ? height : 100;
  const listHeight =
    totalHeight > expectedHeight ? expectedHeight : totalHeight;

  useEffect(() => {
    if (item) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const handleOkAlert = async () => {
    setOpenDelete(false);
    setOpenPrepareAlert(true);
    try {
      const deleteIntegration = await integrationDeleter();
      setDeletedIntegration(deleteIntegration.data.integrationDeleteData);
      if (isError) {
        setOpenDeleteErrorAlert(true);
      }
    } catch (e) {
      setOpenDeleteErrorAlert(true);
    }
  };

  const handleCloseAlert = () => {
    setOpenDelete(false);
    setOpenReferenceAlert(false);
    setOpenDeleteSuccessAlert(false);
    setOpenPrepareAlert(false);
    setOpenDeleteErrorAlert(false);
  };

  useEffect(() => {
    if (deletedIntegration?.references === true) {
      setOpenReferenceAlert(true);
      setOpenPrepareAlert(false);
    }
    if (deletedIntegration?.references === false) {
      setOpenDeleteSuccessAlert(true);
      setOpenPrepareAlert(false);
    }
  }, [deletedIntegration]);

  const handleCloseSonarIntegration = () => {
    if (checkWarning) {
      setOpenAlert(true);
    } else {
      setOpenEditSonarIntegration(false);
    }
  };

  const handleAlertOk = () => {
    setOpenAlert(false);
    setOpenEditSonarIntegration(false);
    setCheckWarning(false);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  return (
    <>
      <FormControl className={className} variant="outlined" fullWidth>
        <Select
          disabled={
            path === 'edit' ||
            (length - 1 !== index && name === 'action') ||
            path === 'View' ||
            status === StatusType.DELETED.displayValue ||
            path === 'summary'
              ? true
              : false
          }
          id={id}
          displayEmpty
          placeholder={placeholder}
          classes={{ root: classes.select }}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          inputProps={{
            name,
            inputRef: (ref) => {
              if (!ref) return;
              register({ name, value: ref.value });
            },
          }}
          onClick={(e) => {
            if (open) {
              setOpen(false);
            } else {
              setOpen(true);
            }
          }}
          open={open}
          renderValue={() => {
            if (item) {
              return item;
            } else if (!item && !value) {
              return placeholder;
            } else {
              const displayVal = selectItems.filter(
                (item) => item.value === value
              );
              return displayVal[0]?.name ? displayVal[0].name : '';
            }
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            PaperProps: {
              style: {
                maxHeight: height,
              },
            },
          }}
        >
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
          {create ? (
            <>
              <LabeledButton
                handleClick={(e) => {
                  handleCreate(e);
                }}
                // handleClick={handleCreate}
                classes={{ root: classes.labeledBtn }}
              >
                {create}
              </LabeledButton>
              <Divider />
            </>
          ) : null}
          <AutoSizer disableHeight>
            {({ width }) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}
              >
                {({ onItemsRendered, ref }) => (
                  <List
                    height={listHeight}
                    width={width}
                    itemCount={selectItems.length}
                    itemSize={35}
                    itemData={selectItems}
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                  >
                    {({ data, index, style }) => {
                      return (
                        <MenuItem
                          key={index}
                          value={
                            actionType === ActionTypes.SCAN_SOURCE.value
                              ? data[index]?.data?.host
                              : data[index].value
                              ? data[index].value
                              : data[index].name
                          }
                          data-id={data[index].id}
                          data-type={data[index].type}
                          data-url={data[index].url ? data[index].url : null}
                          style={style}
                          onClick={(e) => {
                            onChange(e, data[index].value); //change
                            setItem(
                              actionType === ActionTypes.SCAN_SOURCE.value
                                ? data[index]?.data?.host
                                : data[index].name
                            );
                          }}
                        >
                          <Grid
                            item
                            container
                            justify="space-between"
                            className={classes.textTruncate}
                          >
                            {actionType === ActionTypes.SCAN_SOURCE.value ? (
                              <>
                                <Grid
                                  item
                                  xs={10}
                                  className={classes.textTruncate}
                                >
                                  {data[index]?.data?.host}
                                </Grid>
                                <ToolSet
                                  setOpenEditSonarIntegration={
                                    setOpenEditSonarIntegration
                                  }
                                  setLocation={setLocation}
                                  data={data[index]}
                                  setOpenDelete={setOpenDelete}
                                  setSonarToDelete={setSonarToDelete}
                                  setNameSonarToDelete={setNameSonarToDelete}
                                />
                              </>
                            ) : (
                              <Grid item>{data[index].name}</Grid>
                            )}
                          </Grid>
                        </MenuItem>
                      );
                    }}
                  </List>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </Select>
      </FormControl>
      <EditCreateSonarIntegration
        openEditCreateSonarIntegration={openEditSonarIntegration}
        handleCloseSonarIntegration={handleCloseSonarIntegration}
        title="Edit Sonar URL"
        location={location}
        refetchSonar={refetchSonar}
        setCheckWarning={setCheckWarning}
        setOpenEditCreateSonarIntegration={setOpenEditSonarIntegration}
      />
      <AlertDialog
        title="Do you want to leave?"
        buttonConfirmText="Ok"
        buttonCancelText="Cancel"
        open={openAlert}
        handleOk={handleAlertOk}
        handleClose={handleAlertClose}
      >
        The changes you made will be lost.
      </AlertDialog>
      <AlertDialogDelete
        openDeleteAlert={openDelete}
        handleOkDelete={handleOkAlert}
        handleClose={handleCloseAlert}
        openReferenceAlert={openReferenceAlert}
        openDeleteSuccessAlert={openDeleteSuccessAlert}
        openDeleteErrorAlert={openDeleteErrorAlert}
        openPrepareAlert={openPrepareAlert}
        applications={
          deletedIntegration?.references ? deletedIntegration?.appCount : null
        }
        name={sonarNameToDelete}
        type="sonar server URL"
      />
    </>
  );
};

export default CustomInfiniteSelect;
