import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { selectOrganization, selectUserName } from 'state/auth/selectors';
import LeaderBoard from 'components/LeaderBoard';
import CloseButton from 'components/CloseButton';
import AllTickets from 'components/AllTickets';
import useFilteredTicketSummary from 'hooks/services/useFilteredTicketSummary';
import useTickets from 'hooks/services/useTickets';
import useCustomerTickets from 'hooks/services/useCustomerTickets';
import useCustomerTicketSummary from 'hooks/services/useCustomerTicketSummary';

import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import useEntitlements from 'hooks/services/useEntitlements';
import useAllCustomers from 'hooks/services/useAllCustomers';
import { mapTickets, mapTicketsWithCustomerNames } from '../../../utils/index';
const useStyles = makeStyles((theme) => ({
  gridContainer: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  headingTitle: theme.appDirector.pageHeading,
  section: {
    height: '80%',
    padding: '40px 0px',
  },
  btn: {
    minWidth: '133px',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    direction: 'rtl',
  },
  menuIconRoot: {
    color: '#808CA3',
    marginRight: '10px',
  },
  editIconRoot: {
    marginRight: '10px !important',
  },
  btnRoot: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    textTransform: 'none',
    color: '#808CA3',
    padding: 0,
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    minWidth: '50px',
    height: '26px',
  },

  divider: {
    margin: '0px 30px',
    '@media (max-width: 1330px)': {
      margin: '0px 0px',
    },
  },
  spinner: {
    width: '100%',
    height: '100%',
    marginTop: '10%',
  },
  btnText: {
    '@media (max-width: 1330px)': {
      display: 'none',
    },
  },
}));

const ListTickets = ({
  location: {
    state: { id, flow },
  },
}) => {
  const classes = useStyles();
  const userName = useSelector(selectUserName);
  const history = useHistory();
  const { path } = useRouteMatch();
  const organizationId = useSelector(selectOrganization);

  const handleClickOpen = () => {
    history.goBack();
  };

  const { data: ticketSummary } = useFilteredTicketSummary({
    entitlementId: id,
  });

  const { data: customersTicketSummary } = useCustomerTicketSummary({
    organizationId: id,
  });

  const { data: allTickets } = useTickets(id);
  const { data: CustomerTickets } = useCustomerTickets({ organizationId: id });
  let [flattenedTickets, setFlattenedTickets] = useState([]);
  let [tickets, setAllTickets] = useState([]);
  const [summary, setSummary] = useState([]);
  const [paths, setPath] = useState();
  const { data: entitlementsData } = useEntitlements({});
  let [entitlement, setEntitlement] = useState([]);
  let [customer, setCustomer] = useState([]);

  const { data: customersAllData } = useAllCustomers();
  useEffect(() => {
    const reducedflattenedTickets = CustomerTickets?.pages?.reduce(
      (accum, curr) => [...accum, ...curr.tickets],
      []
    );
    const reducedflattenedEntitlements = entitlementsData?.pages?.reduce(
      (accum, curr) => [...accum, ...curr.entitlements],
      []
    );
    const reducedflattenedCustomers = customersAllData?.pages?.reduce(
      (accum, curr) => [...accum, ...curr.customers],
      []
    );

    setCustomer(reducedflattenedCustomers || []);
    setEntitlement(reducedflattenedEntitlements || []);
    if (reducedflattenedTickets) {
      setFlattenedTickets(reducedflattenedTickets);
      setAllTickets(reducedflattenedTickets);
    }
  }, [allTickets, entitlementsData, CustomerTickets]);

  useEffect(() => {
    const combinedTickets = mapTickets(tickets, entitlement);

    // Mapping combined tickets and adding customer names
    const ticketsWithCustomerNames = mapTicketsWithCustomerNames(
      combinedTickets,
      customer
    );

    if (flow === 'Entitlements') {
      setPath(flow);

      const filteredTickets = ticketsWithCustomerNames.filter(
        (value) => value.entitlementId === id
      );

      setFlattenedTickets(filteredTickets);
      setSummary(customersTicketSummary);
    } else if (flow === 'Tickets') {
      setPath(flow);
      setFlattenedTickets(ticketsWithCustomerNames);
      setSummary(customersTicketSummary);
    }
  }, [tickets, entitlement, customer]);

  return (
    <Grid container classes={{ container: classes.gridContainer }}>
      <Grid item container justifyContent="space-between">
        <span className={classes.headingTitle}>Tickets</span>

        <div className={classes.closeButton}>
          <CloseButton onClick={handleClickOpen} id="closeButton" />
        </div>
      </Grid>
      <Grid item className={classes.heading}>
        {summary && summary.length !== 0 && (
          <LeaderBoard firstName={userName.firstName} counts={summary} />
        )}
      </Grid>
      <Grid item className={classes.sectionAllTickets}>
        <AllTickets
          flattenedTickets={flattenedTickets}
          setFlattenedTickets={setFlattenedTickets}
          tickets={tickets}
          flow={paths}
        />
      </Grid>
    </Grid>
  );
};

export default ListTickets;
