import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { DashboardNumberWidget } from 'components/DashboardNumberWidget';
import useProjectDashboardCount from 'hooks/services/useProjectDashboardCounts';
import LoaderSpinner from 'components/LoaderSpinner';
import AppsIcon from 'components/AppsIcon';
import UsersIcon from 'components/UsersIcon';
import ClusterIcon from 'components/ClusterIcon';
import HandleProjectDashboardData from './handleChartData';
import useKubeResources from 'hooks/services/useKubeResources';
import { selectOrganization } from 'state/auth/selectors';
import EstimatedBillingKube from 'pages/Dashboard/estimatedBillingKube';

const today = new Date().getTime() / 1000;

const useStyles = makeStyles((theme) => ({
  headingTitle: theme.appDirector.pageHeading,
  widgetsContainer: {
    alignContent: 'space-between',
  },
  widget: {
    padding: '1.5em',
    background: '#FFFFFF',
    boxShadow: ' 0px 1px 8px rgba(20, 46, 110, 0.1)',
    borderRadius: '8px',
    minHeight: '100%',
    width: '100%',
  },
  widgetBox: {
    padding: '0.625em 0',
    width: '100%',
  },
  box: {
    background: '#EFF3FA',
    borderRadius: '8px',
    padding: '1.5em',
    marginTop: '10px',
    marginBottom: '25px',
  },
  topic: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#001847',
  },
  value: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '20px',
    color: '#001847',
  },
  divider: {
    border: '1px solid #B5BDE9',
    width: 'inherit',
    marginBottom: '1em',
  },
  legend: {
    margin: '20% 0 0 20% ',
  },
  legendLabel: {
    margin: '1% 0',
  },
  widgetTitle: {
    fontSize: '1.125rem',
    lineHeight: '20px',
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
  select: {
    backgroundColor: '#EFF3FA',
    color: '#808CA3',
    fontWeight: '600',
    fontSize: '0.875rem',
    lineHeight: '20px',
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: '155px',
    textAlign: 'start',
  },
  dropdown: {
    textAlign: 'end',
  },
  spinner: {
    width: '100%',
    height: '100%',
  },
  widgetBoxPipeline: {
    padding: '0.625em 0',
    width: '100%',
    height: '495px',
  },
  widgetBoxKube: {
    padding: '0.625em 0',
    width: '100%',
  },
  gridContainer: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  widgetBoxKubeEstimatedBillingValue: {
    padding: '0.625em 0',
    width: '100%',
  },
  heading: {
    paddingTop: '29px',
  },
  estimatedBillingValueHeader: {
    flex: 0,
  },
  estimatedBillingValueBody: {
    flex: 1,
    height: '100%',
    display: 'flex',
  },
  estimatedBillingValueContent: {
    margin: 'auto',
  },
  estimatedBillingValueLabel: {
    color: '#808CA3',
    margin: '0.5rem',
    display: 'block',
    textAlign: 'center',
  },
  estimatedBillingValue: {
    background: '#DFEAFF',
    color: '#0F5EF7',
    fontWeight: 'bold',
    fontSize: '2.5rem',
    lineHeight: '48px',
    padding: '10px',
    margin: 'auto',
    width: 'fit-content',
    borderRadius: '8px',
    '& sup': {
      fontSize: '1.25rem',
    },
  },
  billingValueKube: {
    background: '#DFEAFF',
    color: '#0F5EF7',
    fontWeight: 'bold',
    fontSize: '1.563rem',
    lineHeight: '34px',
    padding: '0.625em',
    width: 'fit-content',
    borderRadius: '8px',
  },
  billingValueKubeDiv: {
    paddingLeft: '0.625em',
  },
  billingLegendKube: {
    color: '#414D55',
    fontWeight: 'normal',
    fontSize: '0.813rem',
    lineHeight: '20px',
    padding: '10px',
    letterSpacing: '0.416667px',
  },
  estimatedBillingKubeLabel: {
    color: '#808CA3',
    padding: '0.625em 0 0.625em 0.625em',
    display: 'block',
  },
  zeroLabel: {
    color: '#808CA3',
    textAlign: 'center',
  },
  kubeResourcesBillingGrid: {
    height: '100%',
    paddingLeft: '0.3125em',
    '@media (min-width: 600px)': {
      height: '6.25em',
    },
    alignSelf: 'flex-start',
  },
  legendLabelDiv: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  root: {
    width: '100%',
    maxHeight: '12.25em',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    padding: '0px',
  },
  clusterListGrid: {
    paddingTop: '10%',
    '@media (min-width: 1330px)': {
      paddingTop: '4%',
    },
    width: '100%',
  },
  showZeroCheckbox: {
    paddingBottom: '1%',
  },
  barGrid: {
    alignSelf: 'flex-end',
  },
  barDiv: {
    height: '22em',
  },
  resourcesBillingGrid: {
    '@media (min-width: 600px)': {
      height: '100px',
    },
  },
}));

const ProjectDashboardDeployments = ({ date, projectId }) => {
  const [t] = useTranslation('dashboard');
  const classes = useStyles();

  const [value, setValue] = useState('Day');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  if (!value) {
    return (
      <Grid container className={classes.widget}>
        <Grid item sm={12} container>
          <Grid item sm={5}>
            <Typography variant="h2" className={classes.widgetTitle}>
              {t('dashboard.pipelineRuns.title')}
            </Typography>
          </Grid>
          <Grid item sm={5}>
            <Typography variant="h2" className={classes.widgetTitle}>
              {t('dashboard.deployments.title')}
            </Typography>
          </Grid>
          <Grid item sm={12} container>
            <Grid
              item
              sm={6}
              container
              justify="center"
              alignItems="center"
              className={classes.spinner}
            >
              <LoaderSpinner
                type="Oval"
                color="#00BFFF"
                height={60}
                width={60}
              />
            </Grid>
            <Grid
              item
              sm={6}
              container
              justify="center"
              alignItems="center"
              className={classes.spinner}
            >
              <LoaderSpinner
                type="Oval"
                color="#00BFFF"
                height={60}
                width={60}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container className={classes.widget}>
      <Grid item sm={12} container>
        <Grid item sm={6}>
          <Typography variant="h2" className={classes.widgetTitle}>
            {t('dashboard.pipelineRuns.title')}
          </Typography>
        </Grid>
        <Grid item sm={3}>
          <Typography variant="h2" className={classes.widgetTitle}>
            {t('dashboard.deployments.title')}
          </Typography>
        </Grid>
        <Grid item sm={3} className={classes.dropdown}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
          >
            <Select
              id="range"
              value={value ? value : 'Day'}
              className={classes.select}
              onChange={handleChange}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value={'Day'}>{t('dashboard.dropDown.today')}</MenuItem>
              <MenuItem value={'Week'}>{t('dashboard.dropDown.week')}</MenuItem>
              <MenuItem value={'Month'}>
                {t('dashboard.dropDown.month')}
              </MenuItem>
              <MenuItem value={'Year'}>{t('dashboard.dropDown.year')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <HandleProjectDashboardData
        category={value}
        date={date}
        projectId={projectId}
      />
    </Grid>
  );
};

const DetailBox = ({ projectData }) => {
  const [t] = useTranslation('project');
  const classes = useStyles();
  const organizationId = useSelector(selectOrganization);
  const { data: kubeResources, isFetching } = useKubeResources(
    organizationId,
    projectData?.projectId
  );

  const { data: counts } = useProjectDashboardCount(
    projectData?.organizationId,
    projectData?.projectId
  );
  const date = Math.floor(today).toString();

  return (
    <Grid
      item
      container
      spacing={2}
      classes={{ container: classes.widgetsContainer }}
    >
      <Grid item sm={4} classes={{ item: classes.widgetBox }}>
        <DashboardNumberWidget
          number={
            counts ? (
              counts?.applications ? (
                counts.applications
              ) : (
                '0'
              )
            ) : (
              <Grid container sm={12}>
                <LoaderSpinner
                  type="Oval"
                  color="#00BFFF"
                  height={30}
                  width={30}
                />
              </Grid>
            )
          }
          title={t('projects.details.overview.noApps')}
          icon={<AppsIcon color="#0F5EF7" />}
        />
      </Grid>
      <Grid item sm={4} classes={{ item: classes.widgetBox }}>
        <DashboardNumberWidget
          number={
            counts ? (
              counts?.users ? (
                counts.users
              ) : (
                '0'
              )
            ) : (
              <Grid container sm={12}>
                <LoaderSpinner
                  type="Oval"
                  color="#00BFFF"
                  height={30}
                  width={30}
                />
              </Grid>
            )
          }
          title={t('projects.details.overview.noUsers')}
          icon={<UsersIcon />}
        />
      </Grid>
      <Grid item sm={4} classes={{ item: classes.widgetBox }}>
        <DashboardNumberWidget
          number={
            counts ? (
              counts?.clusterAgents ? (
                counts.clusterAgents
              ) : (
                '0'
              )
            ) : (
              <Grid container sm={12}>
                <LoaderSpinner
                  type="Oval"
                  color="#00BFFF"
                  height={30}
                  width={30}
                />
              </Grid>
            )
          }
          title={t('projects.details.overview.noClusterAgents')}
          icon={<ClusterIcon />}
        />
      </Grid>
      <Grid
        item
        container
        spacing={2}
        classes={{ container: classes.widgetsContainer }}
      >
        <Grid lg={12} item classes={{ item: classes.widgetBoxKube }}>
          <EstimatedBillingKube
            classes={classes}
            kubeResources={kubeResources}
            isFetching={isFetching}
          />
        </Grid>
      </Grid>
      <Grid md={12} item classes={{ item: classes.widgetBoxPipeline }}>
        <ProjectDashboardDeployments
          date={date}
          projectId={projectData?.projectId}
        />
      </Grid>
    </Grid>
  );
};

export default DetailBox;
