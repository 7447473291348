import { useQuery } from 'react-query';

import api from 'services/Api';

const useUser = ({ userId }) => {
  const fetchUser = async () => {
    try {
      if (userId) {
        const result = await api.get(`core/iam/v1/users/${userId}`);
        const data = await result.json();
        const {
          data: { user },
        } = data;

        return user;
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useQuery(['User', userId], fetchUser, {
    refetchOnWindowFocus: false,
  });
};

export default useUser;
