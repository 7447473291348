import { createSelector } from 'reselect';

export const selectReferenceInfo = (state) => state.referenceInfo;

export const selectReferenceInfoData = createSelector(
  [selectReferenceInfo],
  (referenceInfo) => {
    return referenceInfo;
  }
);
