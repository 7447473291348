import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { selectUserName } from 'state/auth/selectors';
import useTicketSummary from 'hooks/services/useTicketSummary';
import useTickets from 'hooks/services/useTickets';

import LoaderSpinner from 'components/LoaderSpinner';
import LeaderBoard from 'components/LeaderBoard';
import AllTickets from 'components/AllTickets';
import useEntitlements from 'hooks/services/useEntitlements';
import useAllCustomers from 'hooks/services/useAllCustomers';
import { mapTickets, mapTicketsWithCustomerNames } from '../../utils/index';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  heading: {
    paddingTop: '10px',
  },
  headingTitle: theme.appDirector.pageHeading,
  sectionAllTickets: {
    paddingTop: '15px',
    paddingLeft: '10px',
  },
  spinner: {
    width: '100%',
    height: '100%',
    marginTop: '10%',
  },
}));

const ListTickets = () => {
  const classes = useStyles();
  const userName = useSelector(selectUserName);

  const { isLoading: isLoadingSummary, data: counts } = useTicketSummary();
  const { data: allTickets } = useTickets();

  let [flattenedTickets, setFlattenedTickets] = useState([]);
  let [tickets, setAllTickets] = useState([]);
  let [entitlement, setEntitlement] = useState([]);
  let [customer, setCustomer] = useState([]);
  const { data: entitlementsData } = useEntitlements({});
  const { data: customersData } = useAllCustomers();

  useEffect(() => {
    const reducedflattenedTickets = allTickets?.pages?.reduce(
      (accum, curr) => [...accum, ...curr.tickets],
      []
    );
    const reducedflattenedEntitlements = entitlementsData?.pages?.reduce(
      (accum, curr) => [...accum, ...curr.entitlements],
      []
    );
    const reducedflattenedCustomers = customersData?.pages?.reduce(
      (accum, curr) => [...accum, ...curr.customers],
      []
    );

    setCustomer(reducedflattenedCustomers || []);
    setEntitlement(reducedflattenedEntitlements || []);
    if (reducedflattenedTickets) {
      setFlattenedTickets(reducedflattenedTickets);
      setAllTickets(reducedflattenedTickets);
    }
  }, [allTickets, entitlementsData, customersData]);

  const combinedTickets = mapTickets(tickets, entitlement);

  // Mapping combined tickets and adding customer names
  const ticketsWithCustomerNames = mapTicketsWithCustomerNames(
    combinedTickets,
    customer
  );

  if (isLoadingSummary) {
    return (
      <>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.spinner}
        >
          <LoaderSpinner type="Oval" color="#00BFFF" height={60} width={60} />
        </Grid>
      </>
    );
  }

  return (
    <Grid container classes={{ container: classes.gridContainer }}>
      <Grid item container justifyContent="space-between">
        <span className={classes.headingTitle}>Tickets</span>
      </Grid>
      <Grid item className={classes.heading}>
        <LeaderBoard firstName={userName.firstName} counts={counts} />
      </Grid>
      <Grid item className={classes.sectionAllTickets}>
        <AllTickets
          flattenedTickets={ticketsWithCustomerNames}
          setFlattenedTickets={setFlattenedTickets}
          tickets={tickets}
          // tableHeightPercentage={50}
        />
      </Grid>
    </Grid>
  );
};

export default ListTickets;
