import React from 'react';

import { Grid } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  widget: {
    padding: '1.5em',
    background: 'rgb(253, 236, 234)',
    boxShadow: ' 0px 1px 8px rgba(20, 46, 110, 0.1)',
    borderRadius: '8px',
    height: '100%',
    width: '30%',
  },
  widgetContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  widgetHeader: {
    flex: 0,
    textAlign: 'center',
  },
  widgetTitle: {
    fontSize: '1rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
    '@media (min-width: 1960px)': {
      fontSize: '1.8rem',
    },
  },
});

const ErrorDataCard = ({ message }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ marginTop: '5%' }}
    >
      <Paper className={classes.widget}>
        <div className={classes.widgetContent}>
          {message && (
            <div className={classes.widgetHeader}>
              <InfoOutlinedIcon style={{ color: 'red' }} />
              <Typography variant="h2" className={classes.widgetTitle}>
                {message}
              </Typography>
            </div>
          )}
        </div>
      </Paper>
    </Grid>
  );
};

export default ErrorDataCard;
