import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';

import { DashboardWidget } from 'components/DashboardWidget';
import LegendIcon from 'components/DashboardLegendIcon';
import LoaderSpinner from 'components/LoaderSpinner';
import NoDataCard from 'components/NoDataCard';
import Checkbox from '@material-ui/core/Checkbox';

const EstimatedBillingKube = ({ classes, kubeResources, isFetching }) => {
  const [t] = useTranslation('dashboard');
  const [isZero, setIsZero] = useState(false);

  if (isFetching && !kubeResources) {
    return (
      //   <DashboardWidget title={t('dashboard.kubeResourcesBilling.title')}>
      //     <Grid
      //       container
      //       sm={12}
      //       justify="center"
      //       alignItems="center"
      //       className={classes.spinner}
      //     >
      //       <LoaderSpinner type="Oval" color="#00BFFF" height={60} width={60} />
      //     </Grid>
      //   </DashboardWidget>
      <></>
    );
  }

  if (kubeResources?.BillingData?.CostPerProvider === null) {
    return (
      <DashboardWidget title={t('dashboard.kubeResourcesBilling.title')}>
        <Grid container sm={12} justify="center" alignItems="center">
          <NoDataCard message={t('dashboard.kubeResourcesBilling.errorMsg')} />
        </Grid>
      </DashboardWidget>
    );
  }

  const dataall = isZero
    ? kubeResources?.BillingData?.CostPerProvider
    : kubeResources?.BillingData?.CostPerProvider?.filter(
        (cost) => cost.EstimatedCost !== 0
      );

  if (dataall) {
    dataall.sort((a, b) => b.EstimatedCost - a.EstimatedCost);
  }

  const displayValues = dataall?.reduce(function (accumulator, currentValue) {
    return accumulator.concat(currentValue.Provider.replace(/_/g, ' '));
  }, []);

  const costData = dataall?.reduce(function (accumulator, currentValue) {
    return accumulator.concat(
      Math.round(currentValue.EstimatedCost * 100) / 100
    );
  }, []);

  const colors = ['#00D4EB', '#8083FF', '#00E8BA', '#4198FD', '#808CA3'];

  const data = {
    labels: displayValues,
    datasets: [
      {
        data: costData,
        backgroundColor: colors,
        borderRadius: 10,
        borderSkipped: false,
        maxBarThickness: 75,
      },
    ],
  };

  const insideLabel = {
    id: 'custom_label',
    beforeDraw: (chart, args, options) => {
      const { ctx } = chart;

      ctx.save();
      var outerLabels = [];
      if (costData) {
        costData.forEach(function (label) {
          var text = label;
          var outerLabel = {
            text: text,
          };
          outerLabels.push(outerLabel);
        });
      }

      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.font = '14px Nunito';
      ctx.fillStyle = '#808CA3';

      var i;
      var ilen = outerLabels.length;
      for (i = 0; i < ilen; ++i) {
        var meta = chart.getDatasetMeta(i);
        meta.data.forEach(function (bar, index) {
          var data = costData[index];
          ctx.fillText(data, bar.x, bar.y - 10);
        });
      }
      ctx.restore();
    },
  };

  const plugins = [insideLabel];

  const SetColor = (index) => {
    if (index < colors.length) {
      return colors[index];
    } else {
      return colors[index % colors.length];
    }
  };

  return (
    // <DashboardWidget title={t('dashboard.kubeResourcesBilling.title')}>
    //   <Grid
    //     item
    //     sm={12}
    //     container
    //     direction="row"
    //     justify="space-between"
    //     alignItems="center"
    //     className={classes.resourcesBillingGrid}
    //   >
    //     <Grid item sm={7} className={classes.barGrid}>
    //       <div className={classes.barDiv}>
    //         <Bar
    //           data={data}
    //           redraw={isZero}
    //           options={{
    //             maintainAspectRatio: false,
    //             plugins: {
    //               legend: {
    //                 display: false,
    //               },
    //             },
    //             layout: {
    //               padding: {
    //                 left: 10,
    //                 right: 0,
    //                 top: 20,
    //                 bottom: 0,
    //               },
    //             },
    //             scales: {
    //               x: {
    //                 grid: {
    //                   color: 'white',
    //                 },
    //                 display: false,
    //               },
    //               y: {
    //                 grid: {
    //                   color: 'white',
    //                 },
    //                 display: false,
    //                 //barThickness: 5,
    //               },
    //             },
    //             animation: {
    //               duration: 0,
    //             },
    //             hover: {
    //               animationDuration: 0,
    //             },
    //             responsiveAnimationDuration: 0,
    //           }}
    //           plugins={plugins}
    //         />
    //       </div>
    //     </Grid>
    //     <Grid
    //       item
    //       container
    //       sm={5}
    //       // direction="column"
    //       direction="row"
    //       justify="space-between"
    //       alignItems="flex-start"
    //       className={classes.kubeResourcesBillingGrid}
    //     >
    //       <Grid item className={classes.showZeroCheckbox}>
    //         <Checkbox
    //           className={classes.checkbox}
    //           checked={isZero}
    //           onChange={(event) => setIsZero(event.target.checked)}
    //         />
    //         <Typography variant="inherit" className={classes.zeroLabel}>
    //           {t('dashboard.kubeResourcesBilling.zeroCost')}
    //         </Typography>
    //       </Grid>
    //       <Grid
    //         item
    //         container
    //         direction="row"
    //         justify="flex-start"
    //         alignItems="center"
    //       >
    //         <Typography
    //           variant="inherit"
    //           className={classes.estimatedBillingKubeLabel}
    //         >
    //           {t('dashboard.kubeResourcesBilling.total')}
    //         </Typography>
    //         <div className={classes.billingValueKubeDiv}>
    //           <div className={classes.billingValueKube}>
    //             <Typography variant="inherit">
    //               {Math.round(
    //                 kubeResources?.BillingData?.TotalEstimatedCost * 100
    //               ) / 100}
    //             </Typography>
    //           </div>
    //         </div>
    //       </Grid>
    //       <Grid item className={classes.clusterListGrid}>
    //         <List className={classes.root}>
    //           <Grid container>
    //             {displayValues?.map((data, index) => (
    //               <Grid
    //                 item
    //                 sm={12}
    //                 lg={6}
    //                 xl={6}
    //                 className={classes.legendLabel}
    //               >
    //                 <div class="Row" className={classes.legendLabelDiv}>
    //                   <Typography
    //                     noWrap
    //                     variant="inherit"
    //                     className={classes.billingLegendKube}
    //                   >
    //                     <LegendIcon color={SetColor(index)} />
    //                     &nbsp;&nbsp;{data}
    //                   </Typography>
    //                 </div>
    //               </Grid>
    //             ))}
    //           </Grid>
    //         </List>
    //       </Grid>
    //     </Grid>
    //   </Grid>
    // </DashboardWidget>
    <></>
  );
};

export default EstimatedBillingKube;
