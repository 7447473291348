import { useMutation, useQueryClient } from 'react-query';

import api from 'services/Api';

const useCreateCredential = () => {
  const QueryClient = useQueryClient();
  const credentialsUrl = `appdir/credentials/v1/credentials/`;

  return useMutation(
    (obj) =>
      api.post(credentialsUrl, JSON.stringify(obj)).then((data) => data.json()),
    {
      onSuccess: async () => {
        QueryClient.invalidateQueries('success');
      },
    },
    {
      onError: async () => {
        console.log('error');
      },
    }
  );
};

export default useCreateCredential;
