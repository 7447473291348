import React from 'react';

const ProjectIcon = (props) => (
  <svg className={props.className} width="30" height="30" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M50 0H0V50H50V0Z"/>
<path d="M27.8077 12.131C28.4287 11.469 28.812 10.5808 28.812 9.60344C28.812 7.56096 27.151 5.9 25.1086 5.9C23.0661 5.9 21.4042 7.56093 21.4042 9.60344C21.4042 10.5807 21.7877 11.4692 22.4091 12.131C20.0312 12.8181 18.3657 14.4069 18.3657 16.3387V19.1042C18.3657 19.5315 18.7124 19.8781 19.1396 19.8781H31.0774C31.5047 19.8781 31.8513 19.5315 31.8513 19.1042V16.3388C31.8513 16.3388 31.8513 16.3387 31.8513 16.3387C31.8519 14.4075 30.1856 12.8179 27.8077 12.131ZM21.4358 14.223C22.3833 13.6626 23.689 13.3078 25.1086 13.3078H25.1089C26.5285 13.3078 27.8343 13.6627 28.7821 14.223C29.7331 14.7853 30.3047 15.5415 30.3047 16.3396V18.3312H19.9136V16.3396C19.9136 15.5414 20.485 14.7853 21.4358 14.223ZM22.9517 9.60344C22.9517 8.41427 23.9196 7.44753 25.1086 7.44753C26.2978 7.44753 27.2645 8.41451 27.2645 9.60344C27.2645 10.7924 26.2977 11.7603 25.1086 11.7603C23.9193 11.7603 22.9517 10.7924 22.9517 9.60344Z" fill="white" stroke="white" stroke-width="0.2"/>
<path d="M14.3429 35.2719C14.9638 34.61 15.3471 33.7226 15.3471 32.7452C15.3471 30.7027 13.6853 29.0408 11.6428 29.0408C9.60112 29.0408 7.93935 30.7026 7.93935 32.7452C7.93935 33.7225 8.32284 34.6098 8.94346 35.271C6.56548 35.9581 4.9 37.5478 4.9 39.4797V42.2451C4.9 42.6724 5.24668 43.0191 5.67391 43.0191H17.6117C18.039 43.0191 18.3856 42.6724 18.3856 42.2451V39.4797C18.3856 39.4797 18.3856 39.4797 18.3856 39.4797C18.3862 37.5479 16.7205 35.9582 14.3429 35.2719ZM7.97048 37.3626C8.91802 36.802 10.2237 36.4469 11.6434 36.4469H11.6435C13.0631 36.4469 14.3691 36.8019 15.3169 37.3625C16.268 37.9249 16.8395 38.681 16.8395 39.4787V41.4703H6.44843V39.4787C6.44843 38.6811 7.01974 37.925 7.97048 37.3626ZM9.48748 32.7452C9.48748 31.5559 10.4545 30.5884 11.6434 30.5884C12.8326 30.5884 13.8002 31.5562 13.8002 32.7452C13.8002 33.9331 12.8324 34.9001 11.6435 34.9002C10.4544 34.8993 9.48748 33.9325 9.48748 32.7452Z" fill="white" stroke="white" stroke-width="0.2"/>
<path d="M41.2717 35.2709C41.8925 34.6091 42.2758 33.7225 42.2758 32.7452C42.2758 30.7027 40.6148 29.0408 38.5723 29.0408C36.5298 29.0408 34.8689 30.7027 34.8689 32.7452C34.8689 33.7225 35.2524 34.6098 35.873 35.271C33.495 35.9581 31.8286 37.5478 31.8286 39.4797V42.2451C31.8286 42.6724 32.1753 43.0191 32.6025 43.0191H44.5419C44.9691 43.0191 45.3158 42.6724 45.3158 42.2451V39.4797C45.3158 39.4797 45.3158 39.4797 45.3158 39.4797C45.3164 37.5477 43.6495 35.958 41.2717 35.2709ZM34.899 37.3626C35.8466 36.802 37.1523 36.4469 38.5719 36.4469H38.572C39.9917 36.4469 41.2976 36.8019 42.2455 37.3625C43.1965 37.9249 43.7681 38.681 43.7681 39.4787V41.4703H33.377V39.4787C33.377 38.6811 33.9483 37.925 34.899 37.3626ZM36.4169 32.7452C36.4169 31.5559 37.384 30.5884 38.5729 30.5884C39.762 30.5884 40.7288 31.5562 40.7288 32.7452C40.7288 33.9332 39.7618 34.9002 38.5729 34.9002C37.3839 34.9002 36.4169 33.9326 36.4169 32.7452Z" fill="white" stroke="white" stroke-width="0.2"/>
</svg>
);

export default ProjectIcon;
