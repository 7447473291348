import { Paper } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  widget: {
    padding: '1.5em',
    background: '#FFFFFF',
    boxShadow: ' 0px 1px 8px rgba(20, 46, 110, 0.1)',
    borderRadius: '8px',
    height: '100%',
    width: '100%',
  },
  widgetContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  widgetHeader: {
    flex: 0,
  },
  widgetTitle: {
    fontSize: '1rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
    '@media (min-width: 1960px)': {
      fontSize: '1.8rem',
    },
  },
  widgetBody: {
    flex: 1,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

export const DashboardWidget = (props) => {
  const { title, children } = props;
  const classes = useStyles(props);
  return (
    <Paper className={classes.widget}>
      <div className={classes.widgetContent}>
        {title && (
          <div className={classes.widgetHeader}>
            <Typography variant="h2" className={classes.widgetTitle}>
              {title}
            </Typography>
          </div>
        )}
        <div className={classes.widgetBody}>{children}</div>
      </div>
    </Paper>
  );
};
