const validate = (fieldName, value) => {
  const errors = {};

  const validateField = (field, validationRule, errorMsg) => {
    if (!value || value === '0' || value === null || value === '' || value === undefined) {
      errors[field] = errorMsg;
    } else if (validationRule(value)) {
      errors[field] = errorMsg;
    }
  };

  switch (fieldName) {
    case 'crmReference':
      validateField('crmReference', (v) => v.length > 100, 'Please enter a crm reference that is no more than 100 characters.');
      break;
    case 'accountingReference':
      validateField('accountingReference', (v) => v.length > 100, 'Please enter a accounting reference that is no more than 100 characters.');
      break;
    default:
      break;
  }

  return errors;
};  

export default validate;  