import { createSelector } from 'reselect';

export const selectBillingInfo = (state) => state.billingInfo;

export const selectBillingInfoData = createSelector(
  [selectBillingInfo],
  (billingInfo) => {
    return billingInfo;
  }
);
