import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import ListTickets from './view-tickets';
import ViewTicket from './manage-ticket';

const Tickets = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <ListTickets />
      </Route>
      <Route path={`${path}/:entitlementId/:ticketId`} component={ViewTicket} />
      <Redirect to={path} />
    </Switch>
  );
};

export default Tickets;
