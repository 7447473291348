import { useMutation, useQueryClient } from 'react-query';

import api from 'services/Api';

const useResetUserPassword = () => {
  const QueryClient = useQueryClient();
  const IamUrl = 'core/iam/v1/auth/reset';

  return useMutation(
    (obj) => api.post(IamUrl, JSON.stringify(obj)).then((x) => x.json()),
    {
      onSuccess: async () => {
        QueryClient.invalidateQueries();
      },
    },
    {
      onError: async () => {
        console.log('error');
      },
    }
  );
};

export default useResetUserPassword;
