import validateEmail from 'validation/emailValidationRules';
  
const validate = (fieldName, value) => {
  const errors = {};

  const validateField = (field, validationRule, errorMsg) => {
    if ((!value || value === '0' || value === null || value === '' || value === undefined) && (field !== 'billingProvince' && field !== 'billingAddress2')) {
      errors[field] = errorMsg;
    } else if (validationRule(value)) {
      errors[field] = errorMsg;
    }
  };

  switch (fieldName) {
    case 'billingEmailAddress':
      validateField('email', validateEmail, 'Please enter a valid email address.');
      if (value && value.length > 50) {
        errors.email = 'Please enter a email that is no more than 50 characters.';
      }
      break;
    case 'billingContactName':
      validateField('billingContactName', (v) => v.length > 50, 'Please enter a name that is no more than 50 characters.');
      break;
    case 'billingAddress1':
      validateField('billingAddress1', (v) => v.length > 50, 'Please enter a address that is no more than 50 characters.');
      break;
    case 'billingAddress2':
      validateField('billingAddress2', (v) => v.length > 50, 'Please enter a address that is no more than 50 characters.');
      break;
    case 'billingCity':
      validateField('billingCity', (v) => v.length > 50, 'Please enter a city that is no more than 50 characters.');
      break;
    case 'billingProvince':
      validateField('billingProvince', (v) => v.length > 50, 'Please enter a province that is no more than 50 characters.');
      break;
    case 'billingPostalCode':
      validateField('billingPostalCode', (v) => v.length > 10, 'Please enter a postal code that is no more than 10 characters.');
      break;
    default:
      break;
  }

  return errors;
};  

export default validate;  