import { useMutation, useQueryClient } from 'react-query';

import api from 'services/Api';

const useUpdateIntegration = (params) => {
  const QueryClient = useQueryClient();
  const query = new URLSearchParams({
    'project-id': params?.projectId,
  });

  const integrationApi = `appdir/integrations/v1/integrations/${
    params?.integrationId
  }?${query.toString()}`;

  return useMutation(
    (obj) => api.put(integrationApi, JSON.stringify(obj)),
    {
      onSuccess: async () => {
        QueryClient.invalidateQueries('success');
      },
    },
    {
      onError: async () => {
        console.log('error');
      },
    }
  );
};

export default useUpdateIntegration;
