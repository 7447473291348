import { parseISO, formatDistance } from 'date-fns';
import { enGB, enUS, enCA } from 'date-fns/locale';

export const convertTime = (time) => {
  if (time) {
    const date = new Date(time);
    const isoDate = date.toISOString();
    return `${isoDate.substr(0, 10)} ${isoDate.substr(11, 8)}`;
  } else {
    return null;
  }
};

export const convertLocalTimestamp = (time) => {
  if (time) {
    const convertedDate = new Date(time);
    const date = `${convertedDate.getFullYear()}-${
      (convertedDate.getMonth() + 1 < 10 ? '0' : '') +
      (convertedDate.getMonth() + 1)
    }-${
      (convertedDate.getDate() < 10 ? '0' : '') + convertedDate.getDate()
    } ${convertedDate.toLocaleTimeString()}`;

    return date;
  } else {
    return null;
  }
};

export const convertDeletedDateToLocalTime = (time) => {
  if (time) {
    const convertedDate = new Date(time.concat(' UTC'));
    const date = `${convertedDate.getFullYear()}-${
      (convertedDate.getMonth() + 1 < 10 ? '0' : '') +
      (convertedDate.getMonth() + 1)
    }-${
      (convertedDate.getDate() < 10 ? '0' : '') + convertedDate.getDate()
    } ${convertedDate.toLocaleTimeString()}`;

    return date;
  } else {
    return null;
  }
};

export const getLocale = () => {
  if (navigator.language === 'en-GB' || navigator.userLanguage === 'en-GB') {
    return enGB;
  } else if (
    navigator.language === 'en-US' ||
    navigator.userLanguage === 'en-US'
  ) {
    return enUS;
  } else {
    return enCA;
  }
};

export const getDuration = (start, end) => {
  let startDateTime = parseISO(start);
  let endDateTime = parseISO(end);
  var result = formatDistance(startDateTime, endDateTime, {
    locale: getLocale(),
    includeSeconds: true,
  });
  return result;
};
