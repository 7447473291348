import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid } from '@material-ui/core';

import CreateSonarIntegration from 'components/CreateIntegration/CreateSonarIntegration';
import CloseButton from 'components/CloseButton';

const EditCreateSonarIntegration = ({
  openEditCreateSonarIntegration,
  handleCloseSonarIntegration,
  title,
  location,
  refetchSonar,
  setCheckWarning,
  setOpenEditCreateSonarIntegration,
}) => {
  return (
    <>
      <Dialog
        open={openEditCreateSonarIntegration}
        fullWidth
        maxWidth="md"
        onClose={handleCloseSonarIntegration}
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <div>{title}</div>
            <CloseButton onClick={handleCloseSonarIntegration} id="closeButton"/>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <CreateSonarIntegration
            location={location}
            handleCloseIntegration={handleCloseSonarIntegration}
            refetch={refetchSonar}
            setCheckWarning={setCheckWarning}
            setOpenCreateIntegration={setOpenEditCreateSonarIntegration}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditCreateSonarIntegration;
