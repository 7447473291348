import { createSelector } from 'reselect';

export const selectActiveTab = (state) => state.activeTab;

export const selectActiveTabNum = createSelector(
  [selectActiveTab],
  (activeTab) => {
    return activeTab;
  }
);
