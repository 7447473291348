import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  isFetching: false,
  referenceInfo: [],
  errors: [],
};

const referenceInfoSlice = createSlice({
  name: 'referenceInfo',
  initialState: INITIAL_STATE,
  reducers: {
    referenceInfoFetchStart(state, action) {
      state.isFetching = true;
      state.referenceInfo = [];
    },
    referenceInfoFetchSuccess(state, { payload: { referenceInfo } }) {
      state.isFetching = false;
      state.referenceInfo = referenceInfo;
    },
    referenceInfoFetchError(state, { payload: { errors } }) {
      state.errors = errors;
      state.isFetching = false;
      state.referenceInfo = [];
    },
  },
});

export const { name, actions, reducer } = referenceInfoSlice;
export const {
  referenceInfoFetchStart,
  referenceInfoFetchSuccess,
  referenceInfoFetchError,
} = actions;
export default reducer;
