import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  isFetching: false,
  projectId: {},
  allProjectId: {},
  defaultProjectId: {},
  projectStatus: {},
};

const projectIdSlice = createSlice({
  name: 'projectId',
  initialState: INITIAL_STATE,
  reducers: {
    projectIdFetchStart(state, action) {
      state.isFetching = true;
      state.projectId = {};
    },
    projectIdFetchSuccess(state, { payload: { projectId, projectStatus } }) {
      state.isFetching = false;
      state.projectId = projectId;
      state.projectStatus = projectStatus;
    },
    projectIdFetchError(state, action) {
      state.isFetching = false;
      state.projectId = {};
    },
    allProjectIdFetchStart(state, action) {
      state.isFetching = true;
      state.allProjectId = {};
    },
    allProjectIdFetchSuccess(state, { payload: { allProjectId } }) {
      state.isFetching = false;
      state.allProjectId = allProjectId;
    },
    allProjectIdFetchError(state, action) {
      state.isFetching = false;
      state.allProjectId = {};
    },
    defaultProjectIdFetchStart(state, action) {
      state.isFetching = true;
      state.defaultProjectId = {};
    },
    defaultProjectIdFetchSuccess(state, { payload: { defaultProjectId } }) {
      state.isFetching = false;
      state.defaultProjectId = defaultProjectId;
    },
    defaultProjectIdFetchError(state, action) {
      state.isFetching = false;
      state.defaultProjectId = {};
    },
  },
});

export const { name, actions, reducer } = projectIdSlice;
export const {
  projectIdFetchStart,
  projectIdFetchSuccess,
  projectIdFetchError,
  allProjectIdFetchStart,
  allProjectIdFetchSuccess,
  allProjectIdFetchError,
  defaultProjectIdFetchStart,
  defaultProjectIdFetchSuccess,
  defaultProjectIdFetchError,
} = actions;
export default reducer;
