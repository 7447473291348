import React from 'react';

const AppsIcon = ({ className, color = '#B5BDE9' }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.46154 0H1.38462C1.01739 0 0.665216 0.145871 0.40555 0.405536C0.145885 0.665202 0 1.01739 0 1.38462V6.46154C0 6.82876 0.145885 7.18095 0.40555 7.44062C0.665216 7.70028 1.01739 7.84615 1.38462 7.84615H6.46154C6.82876 7.84615 7.18094 7.70028 7.4406 7.44062C7.70027 7.18095 7.84615 6.82876 7.84615 6.46154V1.38462C7.84615 1.01739 7.70027 0.665202 7.4406 0.405536C7.18094 0.145871 6.82876 0 6.46154 0ZM6.46154 6.46154H1.38462V1.38462H6.46154V6.46154ZM16.6154 0H11.5385C11.1712 0 10.8191 0.145871 10.5594 0.405536C10.2997 0.665202 10.1538 1.01739 10.1538 1.38462V6.46154C10.1538 6.82876 10.2997 7.18095 10.5594 7.44062C10.8191 7.70028 11.1712 7.84615 11.5385 7.84615H16.6154C16.9826 7.84615 17.3348 7.70028 17.5944 7.44062C17.8541 7.18095 18 6.82876 18 6.46154V1.38462C18 1.01739 17.8541 0.665202 17.5944 0.405536C17.3348 0.145871 16.9826 0 16.6154 0ZM16.6154 6.46154H11.5385V1.38462H16.6154V6.46154ZM6.46154 10.1538H1.38462C1.01739 10.1538 0.665216 10.2997 0.40555 10.5594C0.145885 10.819 0 11.1712 0 11.5385V16.6154C0 16.9826 0.145885 17.3348 0.40555 17.5945C0.665216 17.8541 1.01739 18 1.38462 18H6.46154C6.82876 18 7.18094 17.8541 7.4406 17.5945C7.70027 17.3348 7.84615 16.9826 7.84615 16.6154V11.5385C7.84615 11.1712 7.70027 10.819 7.4406 10.5594C7.18094 10.2997 6.82876 10.1538 6.46154 10.1538ZM6.46154 16.6154H1.38462V11.5385H6.46154V16.6154ZM16.6154 10.1538H11.5385C11.1712 10.1538 10.8191 10.2997 10.5594 10.5594C10.2997 10.819 10.1538 11.1712 10.1538 11.5385V16.6154C10.1538 16.9826 10.2997 17.3348 10.5594 17.5945C10.8191 17.8541 11.1712 18 11.5385 18H16.6154C16.9826 18 17.3348 17.8541 17.5944 17.5945C17.8541 17.3348 18 16.9826 18 16.6154V11.5385C18 11.1712 17.8541 10.819 17.5944 10.5594C17.3348 10.2997 16.9826 10.1538 16.6154 10.1538ZM16.6154 16.6154H11.5385V11.5385H16.6154V16.6154Z"
      fill={color}
    />
  </svg>
);

export default AppsIcon;
