import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';

import CustomSelect from 'components/CustomSelect';
import useInviteUsers from 'hooks/services/useInviteUsers';
import { selectOrganization } from 'state/auth/selectors';

const useStyles = makeStyles((theme) => ({
  btn: {
    minWidth: '133px',
    [theme.breakpoints.down('xs', 'sm')]: {
      minWidth: '30px',
    },
  },
  add: {
    marginBottom: '10px',
  },
  input: {
    marginTop: '5%',
  },
  user: {
    height: '30vh',
    overflow: 'auto',
  },
  fullContainer: {
    width: '100%',
  },
  bottomContainer: {
    marginTop: 10,
    width: '100%',
  },
  title: {
    marginTop: 10,
  },
}));

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('This field is required.'),
  group: yup.string().required('This field is required.'),
});

const InviteUser = ({
  groups,
  handleToggle,
  post,
  response,
  handleSpinnerClose,
  setRefreshKey,
}) => {
  const [t] = useTranslation('users');
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openInviteConfirm, setOpenInviteConfirm] = useState(false);
  const [inviteUsers, setinviteUsers] = useState([]);
  const [selectValue, setSelectValue] = useState('');
  const [groupId, setGroupId] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const { handleSubmit, register, getValues, errors } = useForm({
    mode: 'onBlur',
  });

  const organizationId = useSelector(selectOrganization);
  const { mutateAsync: inviteUsersOrg } = useInviteUsers();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEmailValue('');
    setSelectValue('');
    inviteUsers.length = 0;
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const handleOk = () => {
    handleClose();
    handleAlertClose();
  };

  const handleChange = (e, child) => {
    setSelectValue(e.target.value);
    setGroupId(e.target.value);
  };

  const addUsers = () => {
    var values = getValues(['email']);

    values['groupId'] = groupId;
    if (values.email !== '' && values.groupId !== '') {
      let found = false;

      inviteUsers.forEach((user) => {
        if (user.email === values.email) {
          found = true;
        }
      });

      if (!found) {
        setinviteUsers([...inviteUsers, values]);
      }

      setEmailValue('');
      setSelectValue('');
    }
  };

  const removeUser = (data) => {
    const filteredUsers = inviteUsers.filter((user) => user.email !== data);

    setinviteUsers(filteredUsers);
  };

  const handleInviteConfirm = async () => {
    setOpen(false);
    handleInviteClose();
    handleToggle();

    const payload = {
      organizationId: organizationId,
      invites: inviteUsers,
    };

    try {
      await inviteUsersOrg(payload);
      setinviteUsers([]);
      handleSpinnerClose();
    } catch (e) {
      console.log(e);
    }
  };

  const handleInviteClose = () => {
    setOpenInviteConfirm(false);
  };

  const handleSuccess = () => {
    setOpenSuccess(false);
  };

  const onSubmit = () => {
    setOpenInviteConfirm(true);
  };

  return (
    <>
      <Button
        variant="contained"
        classes={{
          root: classes.btn,
        }}
        onClick={handleClickOpen}
      >
        {isXs ? 'Invite' : <span>{t('users.create.createBtn')}</span>}
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>{t('users.create.title')}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    error={errors.email}
                    inputRef={register}
                    placeholder={t('users.create.email')}
                    name="email"
                    variant="outlined"
                    value={emailValue}
                    onChange={(e) => {
                      setEmailValue(e.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={10} sm={6} md={4}>
                <FormControl variant="outlined" error={errors.group} fullWidth>
                  <CustomSelect
                    name="group"
                    id="role-select"
                    inputLabelId="role-label"
                    placeholder={t('users.create.role')}
                    register={register}
                    selectItems={groups}
                    onChange={handleChange}
                    value={selectValue}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2} sm={6} md={2}>
                <IconButton color="primary" onClick={addUsers}>
                  <AddCircleOutlineIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h6" className={classes.title}>
                {t('users.create.selectedUsers')}
              </Typography>
            </Grid>
            {inviteUsers.map(({ email, groupId }, index) => (
              <Grid
                item
                container
                key={email}
                className={classes.bottomContainer}
                spacing={1}
              >
                <Grid item xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <TextField
                      error={errors.name}
                      inputRef={register}
                      name={`email-${index}`}
                      variant="outlined"
                      defaultValue={email}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={10} sm={6} md={4}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={errors.group}
                  >
                    <CustomSelect
                      name={`group-${index}`}
                      id={`role-select-${index}`}
                      inputLabelId={`role-label-${index}`}
                      register={register}
                      selectItems={groups}
                      defaultValue={groupId}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2} md={2}>
                  <IconButton
                    color="secondary"
                    onClick={() => removeUser(email)}
                  >
                    <RemoveCircleOutlineOutlinedIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </DialogContent>
        </form>
        <DialogActions>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs={12} sm={6}>
              <Button
                onClick={handleInviteConfirm}
                variant="contained"
                fullWidth
                disabled={inviteUsers.length === 0}
              >
                {t('users.create.inviteBtn')}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button onClick={handleClose} variant="contained" fullWidth>
                {t('users.create.cancelBtn')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InviteUser;
