import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import UploadIcon from 'components/UploadIcon';
import LoaderSpinner from 'components/LoaderSpinner';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '120px',
    padding: '0px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    color: '#0F5EF7',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  disableButton: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #626799',
    borderRadius: '4px',
    color: '#626799',
    '&:hover': {
      backgroundColor: '#FFFFFF !important',
      border: '2px solid #626799',
    },
  },
}));
const loading = '';
const LoadingButton = ({
  id,
  classes,
  children,
  onClickHandler,
  disableStatus,
  updatingStatus,
}) => {
  const labelStyles = useStyles();

  return (
    <Button
      variant="contained"
      id={id}
      startIcon={
        updatingStatus ? (
          <LoaderSpinner type="Oval" color="#808CA3" height={20} width={20} />
        ) : (
          ''
        )
      }
      disabled={disableStatus}
      onClick={onClickHandler}
    >
      {children}
    </Button>
  );
};

export default LoadingButton;
