import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import Entitlements from './detail-customers';
import CreateCustomer from './manage-customers';
import ListEntitlments from './list-entitlements';
import CreateEntitlement from './manage-entitlements';
import ListTickets from './list-tickets';
import ManageTicket from 'pages/Tickets/manage-ticket';
import ListCustomers from './list-customers';
const Customer = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <ListCustomers />
      </Route>
      <Route path={`${path}/create`} component={CreateCustomer} />
      <Route path={`${path}/:id/edit`} component={CreateCustomer} />
      <Route path={`${path}/Entitlements`} component={Entitlements} />
      <Route
        exact
        path={`${path}/:id/entitlements`}
        component={ListEntitlments}
      ></Route>
      <Route exact path={`${path}/:id/tickets`} component={ListTickets}></Route>
      <Route
        path={`/customers/:id/entitlements/create`}
        component={CreateEntitlement}
      />
      <Route
        path={`/customers/:id/entitlements/:id/edit`}
        component={CreateEntitlement}
      />
      <Route
        path={`/customers/:id/entitlements/:id/tickets`}
        component={ListTickets}
      />
      <Redirect to={path} />
    </Switch>
  );
};

export default Customer;
