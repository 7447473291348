import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import LabeledCustomSelect from 'components/LabeledCustomSelect';
import UploadButton from 'components/UploadButton';
import CredentialType from 'schema/credential-type';
import useCreateCredentials from 'hooks/services/useCreateCredential';
import { selectProjectId } from 'state/projectId/selector';
import validate from 'validation/Credentials/validationRules';
import { selectOrganization } from 'state/auth/selectors';
import BrowserCloseValidation from 'validation/browserCloseValidation';
import DockerConfig from './dockerConfig';
import { projectIdFetchSuccess } from 'state/projectId';
import useCredential from 'hooks/services/useCredential';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  heading: {
    paddingTop: '29px',
  },
  headingTitle: theme.appDirector.pageHeading,
  section: {
    padding: '10px 25px 0px 25px',
  },
  label: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    paddingBottom: '14px',
  },
  space: {
    gap: '19px',
    flexWrap: 'nowrap',
  },
  block: {
    paddingTop: '30px',
    gap: '19px',
    flexWrap: 'nowrap',
  },
  button: {
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingTop: '36px',
    marginBottom: '10px',
  },
  errorTextField: {
    borderColor: 'red !important',
  },
  invalid: {
    color: 'red',
    fontSize: '12px',
  },
  editIcon: {
    marginLeft: '1em',
    cursor: 'pointer',
    color: '#0F5EF7',
  },
  editIconDisabled: {
    color: '#FFFFFF',
  },
  required: {
    color: 'red',
  },
  deletedProjectInfo: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    color: '#808CA3',
    fontWeight: 600,
    fontSize: '15px',
    paddingRight: '5px',
    marginTop: '5px',
  },
  deletedProjectTitle: {
    paddingRight: '40px',
  },
  deleteBtn: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    textTransform: 'none',
    color: '#FF7C7C',
    padding: 0,
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    minWidth: '50px',
    height: '26px',
  },
  menuIconRoot: {
    width: '25px',
    height: '25px',
    color: '#808CA3',
    marginRight: '30px',
  },
  cancelButton: {
    color: '#FF5E5E',
    backgroundColor: '#FFFFFF',
    border: '1px solid #FF5E5E',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
}));

const CreateCredential = ({
  location: {
    state: { data, flow },
  },
  handleCloseCredential,
  refetchCred,
  type,
  setCheckCredWarning,
  setOpenCreateCredential,
}) => {
  const [t] = useTranslation('credentials');
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { register, handleSubmit } = useForm({
    mode: 'onChnage',
  });
  const [previousProjectId, setPreviousProjectId] = useState();
  const [disable, setDisable] = useState(flow === 'View' ? false : true);
  const [setDirty, setPristine] = BrowserCloseValidation();
  const [errors, setErrors] = useState({});
  const [credData, setCredData] = useState();
  const [credName, setCredName] = useState(data?.name);
  const [credentialData, setCredentialData] = useState(
    type === 'scanSource' ? { type: CredentialType.TOKEN.enumKey } : { ...data }
  );
  const organizationId = useSelector(selectOrganization);
  const cachedProjectId = useSelector(selectProjectId);
  const projectId = cachedProjectId?.projectId;

  const { data: credentials } = useCredential({ organizationId, projectId });

  const flattenedCredentials =
    credentials &&
    credentials.pages.reduce((accum, curr) => {
      return [...accum, ...curr.credentials];
    }, []);

  useEffect(() => {
    if (flattenedCredentials && flattenedCredentials.length !== 0) {
      const result = flattenedCredentials.find(
        (cred) => cred.credentialId === data?.credentialId
      );
      setCredData(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flattenedCredentials]);

  const [currentProjectId, setCurrentProjectId] = useState();
  const [key, setKey] = useState(
    atob(credentialData?.data?.['ssh-privatekey'] || '')
  );
  const [secret, setSecret] = useState(credentialData?.data?.['secret-name']);
  const [token, setToken] = useState(credentialData?.data?.token);
  const [username, setUsername] = useState(
    atob(credentialData?.data?.username || '')
  );
  const [password, setPassword] = useState(
    atob(credentialData?.data?.password || '')
  );

  const [isEmpty, setIsEmpty] = useState(true);
  const [openPrepareAlert, setOpenPrepareAlert] = useState(false);
  const [deletedCred, setDeletedCred] = useState();
  const [status, setStatus] = useState(credentialData?.status);

  const CredentialTypeForIntegrations = {
    git: [
      {
        id: 1,
        name: CredentialType.USER_PASSWORD.displayValue,
        value: CredentialType.USER_PASSWORD.enumKey,
      },
      {
        id: 2,
        name: CredentialType.KUBERNETES_SECRET_REF.displayValue,
        value: CredentialType.KUBERNETES_SECRET_REF.enumKey,
      },
      {
        id: 3,
        name: CredentialType.SSH_PRIVATE_KEY.displayValue,
        value: CredentialType.SSH_PRIVATE_KEY.enumKey,
      },
    ],
    image: [
      {
        id: 1,
        name: CredentialType.KUBERNETES_SECRET_REF.displayValue,
        value: CredentialType.KUBERNETES_SECRET_REF.enumKey,
      },
      {
        id: 2,
        name: CredentialType.DOCKER_CONFIG_JSON.displayValue,
        value: CredentialType.DOCKER_CONFIG_JSON.enumKey,
      },
    ],
    slack: [CredentialType.TOKEN.enumKey],
    scanSource: [
      {
        id: 1,
        name: CredentialType.TOKEN.displayValue,
        value: CredentialType.TOKEN.enumKey,
      },
    ],
  };

  const { mutateAsync: credentialsCreater, isError } = useCreateCredentials();

  useEffect(() => {
    if (username || password || secret || token || credName) {
      setCheckCredWarning(true);
    } else {
      setCheckCredWarning(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, secret, token, username, credName]);

  const onSubmit = async (data) => {
    const name = credentialData?.name;
    const type = credentialData?.type;

    const newCredentials = {
      name,
      type,
      data: [],
    };

    if (username) {
      const data = {
        secretName: 'username',
        secretData: btoa(username),
      };
      newCredentials.data.push(data);
    }
    if (password) {
      const data = {
        secretName: 'password',
        secretData: btoa(password),
      };
      newCredentials.data.push(data);
    }
    if (key) {
      const data = {
        secretName: 'ssh-privatekey',
        secretData: btoa(key),
      };
      newCredentials.data.push(data);
    }
    if (secret) {
      const data = {
        secretName: secret,
      };
      newCredentials.data.push(data);
    }
    if (token) {
      const data = {
        secretName: 'token',
        secretData: btoa(token),
      };
      newCredentials.data.push(data);
    }

    try {
      if (flow === 'Create') {
        newCredentials['organizationId'] = organizationId;
        newCredentials['projectId'] = projectId;
        await credentialsCreater(newCredentials);
        if (!isError) {
          refetchCred();
          setOpenCreateCredential(false);
        }
      }
      setPristine();
    } catch (e) {
      console.log(e);
    }
  };

  const handleDataChange = (e, name) => {
    e.preventDefault();
    setCredentialData({ ...credentialData, [name]: e.target.value });
    setErrors(validate(name, e.target.value));
  };

  const handleKey = (e) => {
    setKey(e.target.value);
  };

  const handleSecret = (e) => {
    setSecret(e.target.value);
  };

  const handleToken = (e) => {
    setToken(e.target.value);
  };

  const handleUsername = (e) => {
    setUsername(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleKeyChange = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = (e) => {
      setKey(e.target.result);
    };
  };

  useEffect(() => {
    if (credentialData?.projectId !== undefined) {
      setPreviousProjectId(projectId);
      dispatch(projectIdFetchSuccess({ projectId: credentialData?.projectId }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentialData]);

  useEffect(() => {
    if (credData?.status) {
      setStatus(credData.status);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credData]);

  useEffect(() => {
    if (flow === 'View') {
      if (credentialData?.projectId !== undefined) {
        setCurrentProjectId(credentialData?.projectId);
      }
      if (currentProjectId !== undefined && currentProjectId !== projectId) {
        history.push({ pathname: '/credentials' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProjectId, projectId]);

  useEffect(() => {
    if (deletedCred?.references === true) {
      setOpenPrepareAlert(false);
    } else if (deletedCred?.references === false) {
      setOpenPrepareAlert(false);
    }
  }, [deletedCred]);

  const displayType = () => {
    if (credentialData?.type === CredentialType.USER_PASSWORD.enumKey) {
      return (
        <>
          <Grid item xs={6}>
            <div className={classes.label}>
              {t('credentials.create.userPwd.username')}
              {flow === 'View' ? null : (
                <span className={classes.required}> *</span>
              )}
            </div>
            <FormControl fullWidth>
              <TextField
                inputRef={register}
                name="username"
                placeholder={t(
                  'credentials.create.userPwd.usernamePlaceholder'
                )}
                variant="outlined"
                value={username}
                onChange={(e) => {
                  handleUsername(e);
                  setIsEmpty(false);
                  setDirty();
                }}
                disabled={disable ? false : true}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.label}>
              {t('credentials.create.userPwd.pwd')}
              {flow === 'View' ? null : (
                <span className={classes.required}> *</span>
              )}
            </div>
            <FormControl fullWidth>
              <TextField
                inputRef={register}
                name="password"
                placeholder={t('credentials.create.userPwd.pwdPlaceholder')}
                variant="outlined"
                type="password"
                value={password}
                onChange={(e) => {
                  handlePassword(e);
                  setIsEmpty(false);
                  setDirty();
                }}
                disabled={disable ? false : true}
              />
            </FormControl>
          </Grid>
        </>
      );
    }
    if (credentialData?.type === CredentialType.SSH_PRIVATE_KEY.enumKey) {
      return (
        <Grid item xs={12}>
          <Grid item container justify="space-between">
            <div className={classes.label}>
              {t('credentials.create.SSHkey.key')}
              {flow === 'View' ? null : (
                <span className={classes.required}> *</span>
              )}
            </div>
            {flow === 'View' ? null : (
              <UploadButton handleChange={handleKeyChange} path={flow}>
                {t('credentials.create.SSHkey.importFile')}
              </UploadButton>
            )}
          </Grid>
          <FormControl fullWidth>
            <TextField
              inputRef={register}
              name="key"
              multiline
              rows={10}
              variant="outlined"
              value={key}
              onChange={(e) => {
                handleKey(e);
                setIsEmpty(false);
                setDirty();
              }}
              disabled={disable ? false : true}
            />
          </FormControl>
        </Grid>
      );
    }
    if (credentialData?.type === CredentialType.TOKEN.enumKey) {
      return (
        <Grid item xs={12}>
          <div className={classes.label}>
            {t('credentials.create.token.token')}
            {flow === 'View' ? null : (
              <span className={classes.required}> *</span>
            )}
          </div>
          <FormControl fullWidth>
            <TextField
              inputRef={register}
              name="token"
              placeholder={t('credentials.create.token.tokenPlaceholder')}
              variant="outlined"
              value={token}
              onChange={(e) => {
                handleToken(e);
                setIsEmpty(false);
                setDirty();
              }}
              disabled={disable ? false : true}
            />
          </FormControl>
        </Grid>
      );
    }
    if (credentialData?.type === CredentialType.KUBERNETES_SECRET_REF.enumKey) {
      return (
        <Grid item xs={12}>
          <div className={classes.label}>
            {t('credentials.create.k8sSecret.secret')}
            {flow === 'View' ? null : (
              <span className={classes.required}> *</span>
            )}
          </div>
          <FormControl fullWidth>
            <TextField
              inputRef={register}
              name="secret"
              placeholder={t('credentials.create.k8sSecret.secretPlaceholder')}
              variant="outlined"
              value={secret}
              onChange={(e) => {
                handleSecret(e);
                setIsEmpty(false);
                setDirty();
              }}
              disabled={disable ? false : true}
            />
          </FormControl>
        </Grid>
      );
    }
    if (credentialData?.type === CredentialType.DOCKER_CONFIG_JSON.enumKey) {
      return (
        <DockerConfig
          flow={flow}
          projectId={projectId}
          organizationId={organizationId}
          name={credentialData?.name}
          disable={disable}
          data={credentialData?.data}
          credentialId={credentialData?.credentialId}
          setIsEmpty={setIsEmpty}
          handleCloseCredential={handleCloseCredential}
          setCheckCredWarning={setCheckCredWarning}
          refetchCred={refetchCred}
          setOpenCreateCredential={setOpenCreateCredential}
        />
      );
    }
  };

  return (
    <form>
      <Grid container classes={{ container: classes.gridContainer }}>
        <Grid item className={classes.section}>
          <Grid container className={classes.space}>
            <Grid item xs={6}>
              <LabeledCustomSelect
                label={t('credentials.create.credType')}
                name="type"
                id="type"
                placeholder={t('credentials.create.credTypePlaceholder')}
                value={credentialData?.type}
                onChange={(e) => {
                  handleDataChange(e, 'type');
                }}
                register={register}
                selectItems={CredentialTypeForIntegrations[type]}
                path={flow}
              />
            </Grid>
          </Grid>
          <Grid item xs={6} className={classes.block}>
            <div className={classes.label}>
              {t('credentials.create.credName')}
              {flow === 'View' ? null : (
                <span className={classes.required}> *</span>
              )}
            </div>
            <FormControl fullWidth>
              <TextField
                InputProps={
                  errors.name && {
                    classes: {
                      notchedOutline: classes.errorTextField,
                    },
                  }
                }
                inputRef={register}
                name="name"
                placeholder={t('credentials.create.credNamePlaceholder')}
                variant="outlined"
                value={credName}
                onChange={(e) => {
                  setCredName(e.target.value);
                  handleDataChange(e, 'name');
                  setIsEmpty(false);
                  setDirty();
                }}
                disabled={disable ? false : true}
              />
              {errors.name && <p className={classes.invalid}>{errors.name}</p>}
            </FormControl>
          </Grid>
          <Grid item container className={classes.block}>
            {displayType()}
          </Grid>

          {credentialData?.type !==
          CredentialType.DOCKER_CONFIG_JSON.enumKey ? (
            flow === 'Update' || flow === 'Create' ? (
              <Grid item container className={classes.button}>
                <Button
                  disabled={
                    credentialData?.name &&
                    (token || secret || key || (username && password))
                      ? false
                      : true
                  }
                  onClick={handleSubmit(onSubmit)}
                >
                  {flow === 'Create' ? <>Add</> : <>Update</>}
                  {t('credentials.create.saveBtn')}
                </Button>
                <div style={{ paddingRight: '10px' }}>
                  <Button
                    onClick={handleCloseCredential}
                    color="secondary"
                    variant="outlined"
                    className={classes.cancelButton}
                  >
                    Cancel
                  </Button>
                </div>
              </Grid>
            ) : (
              <></>
            )
          ) : null}
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateCredential;
