import React from 'react';

import { default as MaterialAvatar } from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 1.25em 0 1.25em',
    flexWrap: 'nowrap',
  },
  avatar: {
    height: '45px',
    width: '45px',
    border: `1px solid ${theme.palette.grey[650]}`,
    '@media (min-width: 1960px)': {
      height: '65px',
      width: '65px',
    },
    [theme.breakpoints.up('xs')]: {
      height: '40px',
      width: '40px',
    },
  },
  userNameRoot: {
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '9px',
  },
  userName: {
    fontFamily: 'Nunito',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '18px',
    color: theme.palette.grey[650],
    '@media (min-width: 1960px)': {
      fontSize: '1.5rem',
    },
  },
  userRole: {
    fontFamily: 'Nunito',
    fontSize: '0.813rem',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '16px',
    color: theme.palette.grey[650],
    '@media (min-width: 1960px)': {
      fontSize: '1.3rem',
    },
    [theme.breakpoints.up('xs')]: {
      fontSize: '0.7rem',
    },
  },
  blockTop: {
    '@media (min-width: 1960px)': {
      paddingBottom: '0.5em',
    },
  },
}));

const Avatar = ({ group, picture, userName, role }) => {
  const classes = useStyles();

  return (
    <Grid container classes={{ root: classes.root }}>
      <Grid item>
        <MaterialAvatar alt={userName} classes={{ root: classes.avatar }} />
      </Grid>
      <Grid container item classes={{ root: classes.userNameRoot }}>
        <Grid item className={classes.blockTop}>
          <span className={classes.userName}>{userName}</span>
        </Grid>
        <Grid item>
          <span className={classes.userRole}>{group?.name}</span>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Avatar;
