import { Enumify } from 'enumify';

class ActionTypes extends Enumify {
  static SCAN_SOURCE = new ActionTypes({
    get displayValue() {
      return 'Scan Source';
    },
    get value() {
      return 'task-scan-source';
    },
  });
  static BUILD_DOCKERFILE_IMAGE = new ActionTypes({
    get displayValue() {
      return 'Build From Docker Image';
    },
    get value() {
      return 'task-build-dockerfile-image';
    },
  });
  static BUILD_IMAGE = new ActionTypes({
    get displayValue() {
      return 'Build Image';
    },
    get value() {
      return 'task-build-source-image';
    },
  });

  static TASK_FETCH_SOURCE_FOR_DOCKERBUILD = new ActionTypes({
    get displayValue() {
      return 'Fetch Source For Dockerbuild';
    },
    get value() {
      return 'TASK_FETCH_SOURCE_FOR_DOCKERBUILD';
    },
  });
  static SCAN_IMAGE = new ActionTypes({
    get displayValue() {
      return 'Scan Image';
    },
    get value() {
      return 'task-scan-image';
    },
  });
  static DEPLOY_IMAGE = new ActionTypes({
    get displayValue() {
      return 'Deploy Image';
    },
    get value() {
      return 'task-deploy-image';
    },
  });
  static TAG_IMAGE = new ActionTypes({
    get displayValue() {
      return 'Tag Image';
    },
    get value() {
      return 'task-tag-image';
    },
  });
  static INVOKE_WEBHOOK = new ActionTypes({
    get displayValue() {
      return 'Invoke Webhook';
    },
    get value() {
      return 'task-invoke-webhook';
    },
  });
  static CLEANUP_IMAGE = new ActionTypes({
    get displayValue() {
      return 'Cleanup Image';
    },
    get value() {
      return 'clean-up-image';
    },
  });
  static _ = this.closeEnum();

  static getDisplayValueOf(value) {
    const index = this.enumValues.findIndex((x) => x.value === value);
    if (index >= 0) {
      return this.enumValues[index].displayValue;
    }
    return undefined;
  }

  constructor(props) {
    super();
    Object.defineProperties(this, Object.getOwnPropertyDescriptors(props));
  }
}

export default ActionTypes;
