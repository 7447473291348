export default function scanSourceInputValidation(fieldName, value) {
  var errors = {};

  if (fieldName === 'sonarServerURL') {
    if (!value || value === '' || value === undefined) {
      errors.sonarServerURL = 'Sonar Server URL is required';
    } else {
      var regex =
        /(?:^|\s)((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)/;
      if (!regex.test(value)) {
        errors.sonarServerURL = 'Sonar Server URL is invalid';
      }
    }
  }

  if (fieldName === 'projectKey') {
    if (!value || value === '' || value === undefined) {
      errors.projectKey = 'Project key is required';
    } else {
      if (/\s/.test(value)) {
        errors.projectKey = 'Project key is invalid';
      }
    }
  }

  if (fieldName === 'integrationId') {
    if (!value || value === '' || value === undefined) {
      errors.projectKey = 'A valid sonar server URL is required';
    }
  }
  return errors;
}
