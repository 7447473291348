import { useState, useEffect } from 'react';

const BrowserCloseValidation = (
  message = 'Are you sure want to discard changes?'
) => {
  const [isDirty, setDirty] = useState(false);

  useEffect(() => {
    //Detecting browser closing
    window.onbeforeunload = isDirty && (() => message);

    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty, message]);

  return [() => setDirty(true), () => setDirty(false)];
};

export default BrowserCloseValidation;
