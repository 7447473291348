import { useInfiniteQuery } from 'react-query';

import api from 'services/Api';

const useGroups = (queryParams) => {
  const fetchGroups = async (nextPage = 0) => {
    const query = new URLSearchParams({
      appCode: 'ADMIN_PORTAL',
    });

    try {
      const groups = await api.get(
        `core/iam/v1/groups/internal?${query.toString()}`
      );

      const data = await groups.json();

      const groupArray = data.data.groups.map((group) => {
        return group;
      });

      return groupArray;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useInfiniteQuery(
    ['Groups', queryParams?.organizationId],
    fetchGroups,
    {
      refetchOnWindowFocus: false,
    }
  );
};

export default useGroups;
