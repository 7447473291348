import { useQuery } from 'react-query';

import api from 'services/Api';

const useProjectDashboardCount = (orgId, projectId) => {
  const fetchProjectCounts = async () => {
    try {
      if (orgId && projectId) {
        const resources = await api.get(
          `appdir/director-aggregator/api/v1/summaries/project/${projectId}/counts?organization-id=${orgId}`
        );

        const data = await resources.json();
        return data.data.counts;
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useQuery(['counts', orgId, projectId], fetchProjectCounts, {
    refetchOnWindowFocus: false,
  });
};

export default useProjectDashboardCount;
