import { useMutation, useQueryClient } from 'react-query';

import api from 'services/Api';

const useUpdateUserStatus = (userId) => {
  const QueryClient = useQueryClient();
  //const userUrl = `core/iam/v1/users/${userId}/state`;


  return useMutation(
    (obj) => api.put(`core/iam/v1/users/${userId}/state`, JSON.stringify(obj)).then((x) => x.json()),
    {
      onSuccess: async () => {
        QueryClient.invalidateQueries();
      },
    },
    {
      onError: async () => {
        console.log('error');
      },
    }
  );
};

export default useUpdateUserStatus;
