import { useQuery } from 'react-query';

import api from 'services/Api';

const useMetrics = (customerId) => {
  const fetchCounts = async () => {
    try {
      if (customerId) {
        const resources = await api.get(`core/customers/v1/customers/metrics`, {
          
        },{ 'randoli-customer-id': customerId });

        const data = await resources.json();
        return data.data;
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useQuery(['counts', customerId], fetchCounts, {
    refetchOnWindowFocus: false,
  });
};

export default useMetrics;
