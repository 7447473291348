import validateEmail from 'validation/emailValidationRules';

const validate = (fieldName, value) => {
  const errors = {};

  const validateField = (field, validationRule, errorMsg) => {
    if ((!value || value === '0' || value === null || value === '' || value === undefined) && (field !== 'province' && field !== 'address2')) {
      errors[field] = errorMsg;
    } else if (validationRule(value)) {
      errors[field] = errorMsg;
    }
  };

  switch (fieldName) {
    case 'adminEmailAddress':
      validateField('email', validateEmail, 'Please enter a valid email address.');
      if (value && value.length > 200) {
        errors.email = 'Please enter a email that is no more than 200 characters.';
      }
      break;
    case 'name':
      validateField('name', (v) => v.length > 200, 'Please enter a name that is no more than 200 characters.');
      break;
    case 'adminFirstName':
      validateField('adminFirstName', (v) => v.length > 50, 'Please enter a first name that is no more than 50 characters.');
      break;
    case 'adminLastName':
      validateField('adminLastName', (v) => v.length > 50, 'Please enter a last name that is no more than 50 characters.');
      break;
    case 'address1':
      validateField('address1', (v) => v.length > 50, 'Please enter a address that is no more than 50 characters.');
      break;
    case 'address2':
      validateField('address2', (v) => v.length > 50, 'Please enter a address that is no more than 50 characters.');
      break;
    case 'city':
      validateField('city', (v) => v.length > 50, 'Please enter a city that is no more than 50 characters.');
      break;
    case 'province':
      validateField('province', (v) => v.length > 50, 'Please enter a province that is no more than 50 characters.');
      break;
    case 'postalCode':
      validateField('postalCode', (v) => v.length > 10, 'Please enter a postal code that is no more than 10 characters.');
      break;
    case 'phoneNumber':
      validateField('phoneNumber', (v) => v.length > 15, 'Please enter a phone number that is no more than 15 characters.');
      break;
    default:
      break;
  }

  return errors;
};

export default validate;