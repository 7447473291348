import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseButton from 'components/CloseButton';
import FormControl from '@material-ui/core/FormControl';
import CustomSelect from 'components/CustomSelect';
import LoaderSpinner from 'components/LoaderSpinner';
import LoadingButton from 'components/LoadingButton';

import { useAuth } from 'hooks/useAuth';
import useUser from 'hooks/services/useUser';
import useUpdateUserProfile from 'hooks/services/useUpdateUserProfile';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: '16px',
  },
  fullContainer: {
    width: '100%',
  },
  dialogTitle: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    color: '#FFFFFF',
  },
  label: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '15px',
    paddingTop: '5px',
    color: '#001847',
  },
  headerLabel: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '13px',
    paddingBottom: '10px',
    color: '#808CA3',
  },
  inputContainer: {
    paddingBottom: '1em',
  },
  cancelButton: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #626799',
    borderRadius: '4px',
    color: '#626799',
    '&:hover': {
      backgroundColor: '#FFFFFF !important',
      border: '2px solid #626799',
    },
  },
}));

const UserRoleDialog = ({
  open,
  onClose,
  currentUserId,
  currentUserGroupId,
  groups,
}) => {
  const classes = useStyles();
  const [t] = useTranslation('users');
  const [groupId, setGroupId] = useState('');
  const { logout, handleAuthentication } = useAuth();

  const { enqueueSnackbar } = useSnackbar();
  const [isUpdating, setIsUpdating] = useState(false);

  const { handleSubmit, register, getValues, errors } = useForm({
    mode: 'onBlur',
  });
  const {
    data: user,
    isFetching: isUserFetching,
    isError: isErrorUsers,
  } = useUser({
    userId: currentUserId,
  });

  const handleChange = (e, child) => {
    setGroupId(e.target.value);
  };

  const {
    mutateAsync: userProfileUpdater,
    isError: isProfileUpdateError,
  } = useUpdateUserProfile(currentUserId);

  const handleUpdate = async () => {
    setIsUpdating(true);

    if (groupId !== '' && groupId !== currentUserGroupId) {
      const data = {
        groupId: groupId,
        state: user.state,
        firstName: user.firstName,
        lastName: user.lastName,
        termsAccepted: 'true',
      };
      try {
        await userProfileUpdater(data);
        if (!isProfileUpdateError) {
          handleAuthentication();
          onClose();
          enqueueSnackbar('User group updated successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
      } catch (e) {
        enqueueSnackbar('User group updated failed', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      setDefaultValues();
    }
  };

  const handleClick = (e) => {
    e.stopPropagation();
  };

  const setDefaultValues = () => {
    setIsUpdating(false);
    setGroupId('');
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={() => {
        onClose();
        setDefaultValues();
      }}
      onClick={handleClick}
    >
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center">
          <div>{t('users.tableHeaders.manage.changeUserRole')}</div>
          <CloseButton
            id="closeButton"
            onClick={() => {
              onClose();
              setDefaultValues();
            }}
          />
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          alignItems="flex-end"
          className={classes.inputContainer}
        >
          {isUserFetching ? (
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ width: '100%', height: '100%' }}
            >
              <LoaderSpinner
                type="Oval"
                color="#00BFFF"
                height={40}
                width={40}
              />
            </Grid>
          ) : (
            <div style={{ display: 'flex', flexGrow: 1, gap: '8px' }}>
              <Grid item sm={4}>
                <div className={classes.headerLabel}>
                  {t('users.tableHeaders.name')}
                </div>
                <FormControl fullWidth>
                  <div className={classes.label}>
                    {user ? user.firstName + ' ' + user.lastName : null}
                  </div>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <div className={classes.headerLabel}>
                  {t('users.tableHeaders.email')}
                </div>
                <FormControl fullWidth>
                  <div className={classes.label}>
                    {user ? user.email : null}
                  </div>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <div className={classes.headerLabel}>
                  {t('users.tableHeaders.group')}
                </div>
                <FormControl fullWidth>
                  <CustomSelect
                    name="group"
                    id="role-select"
                    inputLabelId="role-label"
                    placeholder={t('users.create.role')}
                    register={register}
                    selectItems={groups}
                    onChange={handleChange}
                    value={groupId}
                    defaultValue={user ? user.groupId : ''}
                  />
                </FormControl>
              </Grid>
            </div>
          )}
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actionBtns}>
        <Button
          onClick={() => {
            onClose();
            setDefaultValues();
          }}
          className={classes.cancelButton}
          autoFocus
        >
          Cancel
        </Button>
        <LoadingButton
          onClickHandler={handleUpdate}
          updatingStatus={isUpdating}
          disableStatus={
            groupId === '' || groupId === currentUserGroupId || isUpdating
          }
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UserRoleDialog;
