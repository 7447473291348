import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  isUpdating: false,
  buttonVisible: false,
};

const buttonVisibleSlice = createSlice({
  name: 'buttonVisible',
  initialState: INITIAL_STATE,
  reducers: {
    visibleUpdateStart(state, action) {
      state.isUpdating = true;
      state.buttonVisible = false;
    },
    visibleUpdateSuccess(state, { payload: { visible } }) {
      state.isUpdating = false;
      state.buttonVisible = visible;
    },
    visibleUpdateError(state, action) {
      state.isUpdating = false;
      state.buttonVisible = false;
    },
  },
});

export const { name, actions, reducer } = buttonVisibleSlice;
export const {
  visibleUpdateStart,
  visibleUpdateSuccess,
  visibleUpdateError,
} = actions;
export default reducer;
