import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

import FromFile from './file';
import FromInput from './input';

const useStyles = makeStyles((theme) => ({
  section: {
    paddingTop: '40px',
    height: '80%',
    paddingBottom: '40px',
  },
  btn: {
    minWidth: '133px',
  },
  menuIconRoot: {
    width: '16px',
    height: '16px',
    color: '#808CA3',
    marginRight: '10px',
  },
  editIconRoot: {
    marginRight: '10px !important',
  },
  tabs: {
    margin: '0px',
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
  },
  dividerRoot: {
    borderLeft: `1px solid ${theme.palette.primary.light}`,
    opacity: '0.5',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ paddingLeft: '24px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
};

const DockerConfig = ({
  flow,
  disable,
  projectId,
  organizationId,
  name,
  data,
  credentialId,
  setIsEmpty,
  handleCloseCredential,
  setCheckCredWarning,
  refetchCred,
  setOpenCreateCredential,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [inputData, setInputData] = useState();
  const [fileData, setFileData] = useState();

  useEffect(() => {
    if (data) {
      setInputData(data);
      setFileData(data);
    }
  }, [data]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container>
      <Grid item>
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          style={{ width: '200px' }}
        >
          <Tab
            className={classes.tabs}
            label="Create from input"
            {...a11yProps(0)}
          />
          <Tab
            className={classes.tabs}
            label="Add file contents"
            {...a11yProps(1)}
          />
        </Tabs>
      </Grid>
      <Divider flexItem classes={{ root: classes.dividerRoot }} />
      <Grid item style={{ flexGrow: '1' }}>
        <TabPanel value={value} index={0}>
          <FromInput
            flow={flow}
            disable={disable}
            projectId={projectId}
            organizationId={organizationId}
            name={name}
            inputData={inputData}
            credentialId={credentialId}
            setIsEmpty={setIsEmpty}
            handleCloseCredential={handleCloseCredential}
            setCheckCredWarning={setCheckCredWarning}
            refetchCred={refetchCred}
            setOpenCreateCredential={setOpenCreateCredential}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <FromFile
            flow={flow}
            disable={disable}
            projectId={projectId}
            organizationId={organizationId}
            name={name}
            fileData={fileData}
            credentialId={credentialId}
            setIsEmpty={setIsEmpty}
            handleCloseCredential={handleCloseCredential}
            setCheckCredWarning={setCheckCredWarning}
            refetchCred={refetchCred}
            setOpenCreateCredential={setOpenCreateCredential}
          />
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default DockerConfig;
