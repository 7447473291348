import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import DoughnutChart from 'components/CustomDoughnutChart';
import useDashboardPipelineDetails from 'hooks/services/useDashboardPipelineDetails';
import { selectOrganization } from 'state/auth/selectors';
import LoaderSpinner from 'components/LoaderSpinner';
import NoDataCard from 'components/NoDataCard';
import ErrorDataCard from 'components/ErrorDataCard';
import useDashboardDeploymentDetails from 'hooks/services/useDashboardDeploymentDetails';

const useStyles = makeStyles((theme) => ({
  spinner: {
    width: '100%',
    height: '100%',
  },
  noDataCard: {
    paddingTop: '15%',
  },
}));

const HandleProjectDashboardData = ({ category, date, projectId }) => {
  const [t] = useTranslation('dashboard');
  const classes = useStyles();
  const [deploymentData, setDeploymentData] = useState();
  const [pipelineData, setPipelineData] = useState();
  const organizationId = useSelector(selectOrganization);

  const {
    data: pipeData,
    isFetching: isFetchingPipelineData,
    isError: isErrorPipelineData,
  } = useDashboardPipelineDetails(organizationId, date, category, projectId);

  const {
    data: deployData,
    isFetching: isFetchingDeploymentData,
    isError: isErrorDeploymentData,
  } = useDashboardDeploymentDetails(date, category, projectId);

  useEffect(() => {
    setDeploymentData(deployData);
    setPipelineData(pipeData);
  }, [deployData, pipeData]);

  const generatePipelineColors = (type) => {
    if (type === 'PIPELINE RUN SUCCEEDED') {
      return '#00BC8B';
    } else if (type === 'PIPELINE RUN FAILED') {
      return '#FF5E5E';
    } else if (type === 'PIPELINE RUN CANCELLED') {
      return '#E09111';
    } else {
      return '#FFEA00';
    }
  };

  const kFormatter = (num) => {
    var val =
      Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
        : Math.sign(num) * Math.abs(num);

    return val;
  };

  const deploymentsDisplayValues = deploymentData?.deploymentDetails?.map(
    (x) => x?.provider
  );

  const colorsDeployment = [
    '#00D4EB',
    '#8083FF',
    '#00E8BA',
    '#4198FD',
    '#808CA3',
  ];

  const deploymentsCostData = deploymentData?.deploymentDetails?.map(
    (x) => x?.deploymentsCount
  );

  const pipelineRunsDisplayValues = pipelineData?.PipelineRuns?.Details?.reduce(
    (accumulator, currentValue) => {
      return accumulator.concat(currentValue.DisplayValue);
    },
    []
  );

  const colorsPipeline = pipelineData?.PipelineRuns?.Details?.reduce(
    (accumulator, currentValue) => {
      return accumulator.concat(
        generatePipelineColors(currentValue.DisplayValue)
      );
    },
    []
  );

  const pipelineRunsData = pipelineData?.PipelineRuns?.Details?.reduce(
    (accumulator, currentValue) => {
      return accumulator.concat(currentValue.Value);
    },
    []
  );

  return (
    <Grid item sm={12} container>
      {isFetchingPipelineData && !pipelineData ? (
        <Grid item sm={6} container justify="center" alignItems="flex-start">
          <LoaderSpinner
            className={classes.spinner}
            type="Oval"
            color="#00BFFF"
            height={60}
            width={60}
          />
        </Grid>
      ) : (
        <>
          {isErrorPipelineData ? (
            <Grid
              item
              sm={6}
              container
              justify="center"
              alignItems="flex-start"
            >
              <ErrorDataCard
                message={t('dashboard.pipelineRuns.errorDataMsg')}
              />
            </Grid>
          ) : (
            <>
              {!pipelineData?.PipelineRuns ||
              !pipelineData?.PipelineRuns?.Details ? (
                <Grid
                  item
                  sm={6}
                  container
                  justify="center"
                  alignItems="flex-start"
                >
                  <Grid item sm={10}>
                    <NoDataCard
                      message={`${t(
                        'dashboard.deployments.noDataMsg'
                      )} ${category.toLowerCase()}`}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  item
                  sm={6}
                  container
                  justify="center"
                  style={{
                    maxHeight: '0%',
                  }}
                >
                  <DoughnutChart
                    labels={pipelineRunsDisplayValues}
                    data={pipelineRunsData}
                    dataAll={pipelineData?.PipelineRuns}
                    colors={colorsPipeline}
                    total={kFormatter(pipelineData?.PipelineRuns?.Total)}
                    type="pipeline-runs"
                  />
                </Grid>
              )}
            </>
          )}
        </>
      )}

      {isFetchingDeploymentData && !deploymentData ? (
        <Grid item sm={6} container justify="center" alignItems="flex-start">
          <LoaderSpinner
            className={classes.spinner}
            type="Oval"
            color="#00BFFF"
            height={60}
            width={60}
          />
        </Grid>
      ) : (
        <>
          {isErrorDeploymentData ? (
            <Grid
              item
              sm={6}
              container
              justify="center"
              alignItems="flex-start"
            >
              <ErrorDataCard
                message={t('dashboard.pipelineRuns.errorDataMsg')}
              />
            </Grid>
          ) : (
            <>
              {!deploymentData || !deploymentData?.deploymentDetails ? (
                <Grid
                  item
                  sm={6}
                  container
                  justify="center"
                  alignItems="flex-start"
                >
                  <Grid item sm={10}>
                    <NoDataCard
                      message={`${t(
                        'dashboard.deployments.noDataMsg'
                      )} ${category.toLowerCase()}`}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  item
                  sm={6}
                  container
                  justify="center"
                  style={{
                    maxHeight: '0%',
                  }}
                >
                  <DoughnutChart
                    labels={deploymentsDisplayValues}
                    data={deploymentsCostData}
                    dataAll={deploymentData}
                    colors={colorsDeployment}
                    total={kFormatter(deploymentData?.totalDeployments)}
                    type="deployments"
                  />
                </Grid>
              )}
            </>
          )}
        </>
      )}
    </Grid>
  );
};

export default HandleProjectDashboardData;
