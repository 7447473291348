import { useQuery } from 'react-query';

import api from 'services/Api';

const useDashboardDeploymentDetails = (date, category, projectId) => {
  const fetchDeploymentDetails = async () => {
    const query = new URLSearchParams();

    if (date) {
      query.append('date', date);
    }

    if (category) {
      query.append('category', category);
    }

    if (projectId) {
      query.append('project-id', projectId);
    }

    try {
      const resources = await api.get(
        `appdir/director-aggregator/api/v1/audit/infra-provider-summary?${query.toString()}`
      );
      const data = await resources.json();

      return data.data.deploymentsSummary;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useQuery(
    ['deploymentDetails', date, category, projectId],
    fetchDeploymentDetails,
    {
      refetchOnWindowFocus: false,
    }
  );
};

export default useDashboardDeploymentDetails;
