import { configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage
import { combineReducers } from 'redux';

import { reducer as AuthReducer } from './auth';
import { reducer as GeneralInfoReducer } from './generalInfo';
import { reducer as BilingInfoReducer } from './billingInfo';
import { reducer as ReferenceInfoReducer} from './reference';
import { reducer as checkBoxInfoReducer } from './checkBox';
import { reducer as AppIdReducer } from './applicationId';
import { reducer as ProjectIdReducer } from './projectId';
import { reducer as SaveButtonReducer } from './saveButton';
import { reducer as SaveApplicationReducer } from './saveApplication';
import { reducer as CloseApplicationReducer } from './closeApplication';
import { reducer as DeleteApplicationReducer } from './deleteApplication';
import { reducer as LastLocationReducer } from './lastLocation';
import { reducer as ActiveTabReducer } from './drawerActiveTab';

const reducers = combineReducers({
  auth: AuthReducer,
  generalInfo: GeneralInfoReducer,
  billingInfo: BilingInfoReducer,
  referenceInfo: ReferenceInfoReducer,
  checkBoxInfo: checkBoxInfoReducer,
  appId: AppIdReducer,
  projectId: ProjectIdReducer,
  buttonVisible: SaveButtonReducer,
  saveApplication: SaveApplicationReducer,
  closeApplication: CloseApplicationReducer,
  deleteApplication: DeleteApplicationReducer,
  lastLoc: LastLocationReducer,
  activeTab: ActiveTabReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export default store;
