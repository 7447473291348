import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, useMediaQuery } from '@material-ui/core';
import format from 'date-fns/format';

import ScoreCard from 'components/ScoreCard';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'rgba(238, 244, 255, 1)',
    borderRadius: '25px',
    padding: '18px',
  },
  gridRoot: {
    flexDirection: 'column',
  },
  welcomeText: {
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '25px',
    lineHeight: '34px',
  },
  welcomeName: {
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '25px',
    lineHeight: '34px',
  },
  subTitleRoot: {
    paddingTop: '4px',
  },
  subTitle: {
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '22px',
    color: 'rgba(93, 103, 121, 1)',
  },
  scoreCardRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '25px',
  },
}));

const LeaderBoard = ({ firstName, counts }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Paper elevation={0} classes={{ root: classes.root }}>
      <Grid container classes={{ root: classes.gridRoot }}>
        {/* <Grid item>
          <span className={classes.welcomeText}>Welcome back, </span>
          <span className={classes.welcomeName}>{firstName}!</span>
        </Grid>
        <Grid item classes={{ root: classes.subTitleRoot }}>
          <span className={classes.subTitle}>
            Here are your stats for Today,{' '}
            {`${format(new Date(), 'dd MMM yyyy')}`}
          </span>
        </Grid> */}
        <Grid
          className={classes.scoreCardRoot}
          style={{ flexDirection: isMobile ? 'column' : 'row' }}
        >
          {counts &&
            counts.map((count, i) => (
              <ScoreCard key={i} {...count} isMobile={isMobile} />
            ))}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default LeaderBoard;
