
import { Enumify } from 'enumify';

class TicketStatus extends Enumify {
  static OPENED = new TicketStatus({
    get displayValue() {
      return 'OPEN';
    },
  });
  static ASSIGNED = new TicketStatus({
    get displayValue() {
      return 'ASSIGNED';
    },
  });
  static IN_PROGRESS = new TicketStatus({
    get displayValue() {
      return 'IN PROGRESS';
    },
  });
  static IN_REVIEW = new TicketStatus({
    get displayValue() {
      return 'IN REVIEW';
    },
  });
  static CLOSED = new TicketStatus({
    get displayValue() {
      return 'CLOSED';
    },
  });
  static ON_HOLD = new TicketStatus({
    get displayValue() {
      return 'ON HOLD';
    },
  });
  static REOPENED = new TicketStatus({
    get displayValue() {
      return 'REOPENED';
    },
  });
  static _ = this.closeEnum();

  constructor(props) {
    super();
    Object.defineProperties(this, Object.getOwnPropertyDescriptors(props));
  }
}

export default TicketStatus;
