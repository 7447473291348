import { Enumify } from 'enumify';

class BillingMethod extends Enumify {
  static cc = new BillingMethod({
    get displayValue() {
      return 'Credit Card';
    },
  });

  static inv = new BillingMethod({
    get displayValue() {
      return 'Invoice';
    },
  });

  static aws = new BillingMethod({
    get displayValue() {
      return 'AWS MarketPlace';
    },
  });

  static az = new BillingMethod({
    get displayValue() {
      return 'Azure MarketPlace';
    },
  });
  static nb = new BillingMethod({
    get displayValue() {
      return 'Non Billable';
    },
  });

  static ibm = new BillingMethod({
    get displayValue() {
      return 'IBM MarketPlace';
    }
  })

  static _ = this.closeEnum();

  constructor(props) {
    super();
    Object.defineProperties(this, Object.getOwnPropertyDescriptors(props));
  }
}

export default BillingMethod;
