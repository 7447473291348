import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { Input, InputAdornment } from '@material-ui/core';
import SearchIcon from 'components/SearchIcon';

const useStyles = makeStyles((theme) => ({
  select: {
    background: '#EFF3FA',
    borderRadius: theme.shape.borderRadius,
    height: '35px',
    paddingLeft: '20px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    color: '#001847 !important',
    marginRight: '10px',
  },
}));

const SearchBar = ({
  id,
  name,
  placeholder,
  disableUnderline = true,
  defaultValue = '',
  value,
  register,
  handleChange,
  disabled,
  path,
  width,
}) => {
  const classes = useStyles();

  return (
    <div>
      <FormControl fullWidth>
        <Input
          disabled={path === 'summary' || disabled === 'true' ? true : false}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          id={id}
          name={name}
          className={classes.select}
          placeholder={placeholder}
          inputRef={register}
          disableUnderline={disableUnderline}
          defaultValue={defaultValue}
          value={value}
          onChange={handleChange}
        />
      </FormControl>
    </div>
  );
};

export default SearchBar;
