import { useMutation, useQueryClient } from 'react-query';

import api from 'services/Api';

import { PATH_TICKET_SERVICE } from '../../constants';

const useDeleteComment = ({ entitlementId,ticketId,commentId }) => {
  const QueryClient = useQueryClient();
  const deleteCommentUrl = `${PATH_TICKET_SERVICE}/v1/tickets/${entitlementId}/${ticketId}/comments/${commentId}`;

  return useMutation(
    (obj) =>
      api
        .delete(deleteCommentUrl),
    {
      onSuccess: async () => {
        QueryClient.invalidateQueries();
      },
    },
    {
      onError: async () => {
        console.log('error');
      },
    }
  );
};

export default useDeleteComment;