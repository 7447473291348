import { useMutation, useQueryClient } from 'react-query';

import api from 'services/Api';
import { PATH_TICKET_SERVICE } from '../../constants';

const useUpdateComment = ({ entitlementId, ticketId, commentId }) => {
  const QueryClient = useQueryClient();
  const url = `${PATH_TICKET_SERVICE}/v1/tickets/${entitlementId}/${ticketId}/comments/${commentId}`;

  return useMutation(
    (obj) => api.put(url, JSON.stringify(obj)).then((data) => data.json()),

    {
      onSuccess: async () => {
        QueryClient.invalidateQueries();
      },
    },
    {
      onError: async () => {
        console.log('error');
      },
    }
  );
};

export default useUpdateComment;
