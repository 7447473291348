import { useQuery } from 'react-query';
import api from 'services/Api';

const useFindCustomer = ({customerId}) => {

  const fetchCustomer = async () => {
    try {
      if (customerId) {
        const result = await api.get(`core/customers/v1/customers/${customerId}`);
        const data = await result.json();
        const { data: { customer }, } = data;
        return customer;
      }
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useQuery(['Customer', customerId], fetchCustomer, {refetchOnWindowFocus: false,});

};

export default useFindCustomer;
