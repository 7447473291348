import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, useMediaQuery } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import DataTable from 'components/ReactTable';
import { ACCOUNT_STATUS } from '../../constants';
import InviteUser from './invite-users';
import PrefixedSelect from 'components/PrefixedSelect';
import Spinner from 'components/Spinner';
import CogWheelIcon from 'components/CogWheelIcon';
import useUsers from 'hooks/services/useUsers';
import useGroups from 'hooks/services/useGroups';
import { selectOrganization } from 'state/auth/selectors';
import useResendInviteUsers from 'hooks/services/useResendInviteUsers';
import SnackbarAlert from 'components/SnackbarAlert';
import AlertDialog from 'components/AlertDialog';
import useResetUserPassword from 'hooks/services/useResetUserPassword';
import LoaderSpinner from 'components/LoaderSpinner';
import ErrorDataCard from 'components/ErrorDataCard';
import NoDataCard from 'components/NoDataCard';
import UserRoleDialog from 'components/UserRoleDialog';
//import UserAppCode from 'schema/user-app-code-type';
//import ExternalAuth from './external-auth';

import useUpdateUserStatus from 'hooks/services/useUpdateUserStatus';
//import UserAppCode from 'schema/user-app-code-type';
//import ExternalAuth from './external-auth';

const states = [
  {
    id: '0',
    name: 'ACTIVE',
  },
  {
    id: '1',
    name: 'INACTIVE',
  },
  {
    id: '2',
    name: 'PENDING',
  },
];

const useStyles = makeStyles((theme) => ({
  iconRoot: {
    padding: 0,
    float: 'right',
  },
  headingTitle: theme.appDirector.pageHeading,
  section: {
    height: '80%',
    paddingTop: '40px 0px',
  },
  text: {
    marginBottom: '10%',
  },
  commandText: {
    fontWeight: 'bold',
  },
  rightCommandContainer: {
    alignItems: 'center',
  },
  tableContainer: { flex: 1 },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  paperRoot: {
    color: 'white',
    backgroundColor: 'rgba(0, 24, 71, 1)',
    width: '160px',
    borderRadius: theme.shape.borderRadius,
  },
  emailSpan: {
    fontWeight: 'bold',
  },
}));

const Users = () => {
  const [t] = useTranslation('users');
  const [userId, setUserId] = useState();
  const matches = useMediaQuery('(max-width:1330px)');
  const classes = useStyles();
  const [openAlertAnimation, setOpenAlertAnimation] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = React.useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openResetAlert, setOpenResetAlert] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [currentUserState, setCurrentUserState] = useState(false);
  const [currentUserId, setCurrentUserId] = useState();
  const [currentUserGroup, setCurrentUserGroup] = useState();
  const [currentUserEmail, setCurretUserEmail] = useState();
  const [currentUserGroupId, setCurrentUserGroupId] = useState();
  const [openNew, setOpenNew] = useState(false);
  const [openUserRoleDialog, setOpenUserRoleDialog] = useState(false);
  const [filter, setFilter] = useState({
    status: ACCOUNT_STATUS.ALL,
  });
  const organizationId = useSelector(selectOrganization);
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutateAsync: resendInviteUsersOrg,
    isSuccess,
    isError,
  } = useResendInviteUsers();

  const { mutateAsync: userStatusUpdater, error } =
    useUpdateUserStatus(currentUserId);

  const { mutateAsync: resetPassword, isError: isResetError } =
    useResetUserPassword();

  const {
    data: users,
    isFetching,
    isError: isErrorUsers,
    isFetchingNextPage: isFetchingMoreUsers,
    hasNextPage: canFetchMoreUsers,
    fetchNextPage: fetchMoreUsers,
  } = useUsers({
    userStatus: filter.status,
  });

  const { data: groups } = useGroups({});

  const flattenedUsers =
    users &&
    users.pages.reduce((accum, curr) => {
      return [...accum, ...curr];
    }, []);

  const flattenedGroups =
    groups &&
    groups.pages.reduce((accum, curr) => {
      return [...accum, ...curr];
    }, []);

  const modifiedGroups =
    flattenedGroups &&
    flattenedGroups
      // .filter((g) => g.appCode !== UserAppCode.CSP.enumKey)
      .map((group) => {
        const newGroup = {
          name: group.name,
          value: group.id,
        };
        return newGroup;
      });

  const getGroupName = (groupId) => {
    if (flattenedGroups && flattenedUsers) {
      const group = flattenedGroups.find((group) => group.id === groupId);
      return group?.name;
    }
  };

  const usersWithNameGroupType =
    flattenedUsers &&
    flattenedUsers.map((user) => {
      const namedUser = {
        id: user.id,
        name: user.firstName + ' ' + user.lastName,
        group: user.groups[0].name,
        type: user.groups[0].type,
        organizationId: user.organizationId,
        email: user.email,
        state: user.state,
      };
      return namedUser;
    }, []);

  const usersWithName =
    usersWithNameGroupType &&
    usersWithNameGroupType.filter((user) => user.type === 'INTERNAL');

  const handleClose = () => {
    setOpenNew(false);
  };
  const handleToggle = () => {
    setOpenNew(!openNew);
  };

  const computeNextState = (currentUserState) => {
    switch (currentUserState) {
      case ACCOUNT_STATUS.ACTIVE:
        return t('users.tableHeaders.manage.currentState.active');
      case ACCOUNT_STATUS.INACTIVE:
        return t('users.tableHeaders.manage.currentState.inactive');
      case ACCOUNT_STATUS.PENDING:
        return t('users.tableHeaders.manage.currentState.pending');
      default:
        return '';
    }
  };

  const handleStateChange = async (userId) => {
    if (
      currentUserState === ACCOUNT_STATUS.ACTIVE ||
      currentUserState === ACCOUNT_STATUS.INACTIVE
    )
      if (currentUserState == ACCOUNT_STATUS.ACTIVE) {
        const payload = {
          // userId: currentUserId,
          userState: ACCOUNT_STATUS.INACTIVE,
        };

        await userStatusUpdater(payload, userId);
      } else if (currentUserState == ACCOUNT_STATUS.INACTIVE) {
        const payload = {
          // userId: currentUserId,
          userState: ACCOUNT_STATUS.ACTIVE,
        };

        await userStatusUpdater(payload, userId);
      }
    setOpenAlert();

    if (currentUserState === ACCOUNT_STATUS.PENDING) handleResendInvite();
  };

  const handleResetPassword = () => {
    handleManageUserClose();
    setOpenResetAlert(true);
  };

  const handleResetConfirm = async () => {
    handleResetClose();

    try {
      await resetPassword({ username: currentUserEmail });
      if (!isResetError) {
        enqueueSnackbar(t('users.resetPwd.successMsg'), {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleResetClose = () => {
    setOpenResetAlert(false);
  };

  const handleManageUser = (event, status, id, email, group) => {
    setAnchorEl(event.currentTarget);
    setCurrentUserState(status);
    setCurrentUserId(id);
    setCurretUserEmail(email);
    setCurrentUserGroup(group);
  };

  const handleManageUserClose = () => {
    setAnchorEl(null);
  };

  const getGroupId = (groupName) => {
    if (flattenedGroups) {
      const { id } = flattenedGroups.find((group) => group.name === groupName);
      return id;
    }
  };

  const handleFilterState = (e) => {
    setFilter({ status: e.target.value });
  };

  const handleResendInvite = async () => {
    const payload = {
      organizationId: organizationId,
      userId: currentUserId,
      groupId: getGroupId(currentUserGroup),
    };
    try {
      await resendInviteUsersOrg(payload);
      if (!isError) {
        setOpenAlertAnimation(true);
      }
    } catch (e) {
      setOpenErrorAlert(true);
    }
  };

  const handleAnimationClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlertAnimation(false);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenErrorAlert(false);
  };

  const handleUserRoleDialog = () => {
    setCurrentUserGroupId(getGroupId(currentUserGroup));
    setOpenUserRoleDialog(true);
  };

  const handleUserRoleDialogClose = () => {
    setOpenUserRoleDialog(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: `${t('users.tableHeaders.name')}`,
        accessor: 'nameAndEmail',
        width: '20%',
        Cell: ({
          cell: {
            row: { values },
          },
        }) => {
          return (
            <>
              <span>{values.name}</span>
              <br></br>
              <span>
                <span className={classes.emailSpan}>
                  {t('users.tableCells.email')}
                </span>
                &nbsp;
                <span>{values.email}</span>
              </span>
            </>
          );
        },
      },
      {
        Header: `${t('users.tableHeaders.name')}`,
        accessor: 'name',
        width: '25%',
      },
      {
        Header: `${t('users.tableHeaders.email')}`,
        accessor: 'email',
        width: '25%',
      },
      {
        Header: `${t('users.tableHeaders.role')}`,
        accessor: 'group',
        width: '25%',
      },
      {
        Header: `${t('users.tableHeaders.status')}`,
        accessor: 'state',
        width: '15',
      },
      {
        Header: `${t('users.tableHeaders.manage.title')}`,
        accessor: 'test',
        width: '10%',
        Cell: ({
          cell: {
            row: { values },
          },
        }) => {
          return (
            <IconButton
              onClick={(e) =>
                handleManageUser(
                  e,
                  values.state,
                  values.id,
                  values.email,
                  values.group
                )
              }
              classes={{ root: classes.iconRoot }}
            >
              <CogWheelIcon size="18" />
            </IconButton>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [classes.iconRoot]
  );

  return (
    <Grid container alignContent="flex-start">
      <Grid
        item
        container
        justifyContent="space-between"
        style={{ paddingBottom: '20px' }}
      >
        <div>
          <div className={classes.headingTitle}>{t('users.title')}</div>
        </div>
        <span>
          {/* <ExternalAuth /> */}
          <InviteUser
            groups={modifiedGroups}
            handleToggle={handleToggle}
            handleSpinnerClose={handleClose}
            setRefreshKey={setRefreshKey}
          />
        </span>
      </Grid>
      <Grid container justifyContent="flex-end">
        <PrefixedSelect selectData={states} handleOnChange={handleFilterState}>
          <Typography className={classes.commandText}>
            {t('users.status')}
          </Typography>
        </PrefixedSelect>
      </Grid>
      <Grid item className={classes.tableContainer}>
        {isErrorUsers ? (
          <ErrorDataCard message={t('users.errorMsg')} />
        ) : isFetching && !users ? (
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            style={{ width: '100%', height: '100%' }}
          >
            <LoaderSpinner type="Oval" color="#00BFFF" height={50} width={50} />
          </Grid>
        ) : flattenedUsers.length === 0 ? (
          <NoDataCard message={t('users.noDataMsg')} />
        ) : (
          <Grid item className={classes.section}>
            <DataTable
              data={usersWithName}
              hiddenColumns={
                matches ? ['id', 'name', 'email'] : ['id', 'nameAndEmail']
              }
              columns={columns}
              isNextPageLoading={isFetchingMoreUsers}
              hasNextPage={canFetchMoreUsers}
              loadMore={fetchMoreUsers}
            />
          </Grid>
        )}

        <UserRoleDialog
          open={openUserRoleDialog}
          onClose={handleUserRoleDialogClose}
          currentUserId={currentUserId}
          currentUserGroupId={currentUserGroupId}
          groups={modifiedGroups}
        />
        <Menu
          id="simple-menu"
          classes={{ paper: classes.paperRoot }}
          keepMounted
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleManageUserClose}
        >
          <MenuItem onClick={handleResetPassword}>
            {t('users.tableHeaders.manage.resetPwd')}
          </MenuItem>
          <MenuItem onClick={() => handleStateChange(currentUserId)}>
            {computeNextState(currentUserState)}
            {/* {t('users.tableHeaders.manage.currentState.active')} */}
          </MenuItem>
          <MenuItem onClick={handleUserRoleDialog}>
            {t('users.tableHeaders.manage.changeUserRole')}
          </MenuItem>
        </Menu>
      </Grid>
      <Spinner open={openNew} className={classes.backdrop} invisible={true} />
      <SnackbarAlert
        open={openAlertAnimation}
        autoHideDuration={6000}
        onClose={handleAnimationClose}
        message={t('users.inviteUser.successMsg')}
        severity="success"
      />
      <SnackbarAlert
        open={openErrorAlert}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        message={t('users.inviteUser.errorMsg')}
        severity="error"
      />
      <AlertDialog
        open={openResetAlert}
        icon="question"
        title={t('users.resetPwd.title')}
        handleOk={handleResetConfirm}
        handleClose={handleResetClose}
        buttonConfirmText={t('users.resetPwd.ok')}
        buttonCancelText={t('users.resetPwd.cancel')}
      >
        {t('users.resetPwd.message')}
      </AlertDialog>
    </Grid>
  );
};

export default Users;

// import React from 'react';

// const Users = () => {
//   return (
//     <>
//       <h1>Identity & Access</h1>
//     </>
//   );
// };

// export default Users;
