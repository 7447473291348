import { useMutation, useQueryClient } from 'react-query';

import api from 'services/Api';

const useResendInviteUsers = () => {
  const QueryClient = useQueryClient();
  const projectUrl = 'core/iam/v1/users/resend-invite';

  return useMutation(
    (obj) => api.post(projectUrl, JSON.stringify(obj)).then((x) => x.json()),
    {
      onSuccess: async () => {
        QueryClient.invalidateQueries();
      },
    },
    {
      onError: async () => {
        console.log('error');
      },
    }
  );
};

export default useResendInviteUsers;
