import { useInfiniteQuery } from 'react-query';
import { useSelector } from 'react-redux';

import api from 'services/Api';
import { selectProjectId } from 'state/projectId/selector';

const useCredential = ({ organizationId, projectId, types, status }) => {
  const cachedProjectId = useSelector(selectProjectId);
  const cachedAllProjectId = cachedProjectId?.allProjectId;

  const fetchCredential = async (nextPage) => {
    const query = new URLSearchParams({
      'organization-id': organizationId,
      page: nextPage,
    });
    if (projectId !== cachedAllProjectId) {
      query.append('project-id', projectId);
    }
    if (types) {
      query.append('types', types);
    }
    if (status) {
      query.append('status', status);
    }

    try {
      const credential = await api.get(
        `appdir/credentials/v1/credentials?${query.toString()}`
      );

      const data = await credential.json();

      return { credentials: data.data.credentials, meta: data.data.meta };
    } catch (e) {
      return Promise.reject(e);
    }
  };
  return useInfiniteQuery(
    ['credential', types, projectId, status],
    ({ pageParam = 0 }) => fetchCredential(pageParam),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: ({ credentials, meta }) => {
        if (meta.nextPage !== 0) {
          return meta.nextPage;
        }
        return false;
      },
    }
  );
};

export default useCredential;
