import React from 'react';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const Spinner = ({ open, className }) => {
  return (
    <Backdrop open={open} className={className}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Spinner;
