import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import UploadIcon from 'components/UploadIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '120px',
    padding: '0px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    color: '#0F5EF7',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
}));

const UploadButton = ({ classes, children, handleChange, path }) => {
  const labelStyles = useStyles();

  return (
    <Button
      variant="text"
      component="label"
      endIcon={<UploadIcon />}
      classes={{
        root: clsx(labelStyles.root, classes && classes.root),
        text: clsx(classes && classes.text),
      }}
      disabled={path === 'View' ? true : false}
    >
      {children}
      <input type="file" hidden onChange={handleChange} />
    </Button>
  );
};

export default UploadButton;
