import { useInfiniteQuery } from 'react-query';

import api from 'services/Api';

const useCustomerName = () => {
  const fetchCustomer = async (nextPage = 0) => {
    try {
      const customernames = await api.get(`core/iam/v1/org/orgid`);

      const data = await customernames.json();

      const user = data.data.Customer.map((usr) => {
        return usr;
      });

      return user;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useInfiniteQuery(['Users'], fetchCustomer, {
    refetchOnWindowFocus: false,
    //getFetchMore: (lastGroup) => lastGroup.meta.nextPage,
  });
};

export default useCustomerName;
