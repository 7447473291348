import { useInfiniteQuery } from 'react-query';

import api from 'services/Api';

const useEntitlements = ({ customerId, entitlementId }) => {
  const fetchEntitlements = async (nextPage) => {
    try {
      const query = new URLSearchParams({
        page: nextPage,
      });
      if (customerId) {
        query.append('customer-id', customerId);
      }
      if (entitlementId) {
        query.append('entitlement-id', entitlementId);
      }
      const entitlements = await api.get(
        `core/entitlements/v1/entitlements/search/?${query.toString()}`
      );

      const data = await entitlements.json();
      return { entitlements: data.data.entitlements, meta: data.data.meta };
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useInfiniteQuery(
    ['entitlements', customerId, entitlementId],
    ({ pageParam = 0 }) => fetchEntitlements(pageParam),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: ({ clusters, meta }) => {
        if (meta.nextPage !== 0) {
          return meta.nextPage;
        }
        return false;
      },
    }
  );
};

export default useEntitlements;
