import { useMutation, useQueryClient } from 'react-query';

import api from 'services/Api';

const useDeleteIntegration = ({ integrationId }) => {
  const QueryClient = useQueryClient();
  const deleteIntegrationUrl = `appdir/director-aggregator/api/v1/integrations/${integrationId}`;

  return useMutation(
    (obj) =>
      api
        .delete(deleteIntegrationUrl, JSON.stringify(obj))
        .then((x) => x.json()),
    {
      onSuccess: async () => {
        QueryClient.invalidateQueries();
      },
    },
    {
      onError: async () => {
        console.log('error');
      },
    }
  );
};

export default useDeleteIntegration;
