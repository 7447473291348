import { Enumify } from 'enumify';

class CredentialType extends Enumify {
  static USER_PASSWORD = new CredentialType({
    get displayValue() {
      return 'User Password';
    },
  });

  static TOKEN = new CredentialType({
    get displayValue() {
      return 'Token';
    },
  });

  static SSH_PRIVATE_KEY = new CredentialType({
    get displayValue() {
      return 'SSH Private Key';
    },
  });

  static KUBERNETES_SECRET_REF = new CredentialType({
    get displayValue() {
      return 'Kubernetes Secret Ref';
    },
  });

  static DOCKER_CONFIG_JSON = new CredentialType({
    get displayValue() {
      return 'Docker Config Json';
    },
  });

  static TLS_CERTIFICATE = new CredentialType({
    get displayValue() {
      return 'TLS User Certificates';
    },
  });

  static _ = this.closeEnum();

  constructor(props) {
    super();
    Object.defineProperties(this, Object.getOwnPropertyDescriptors(props));
  }
}

export default CredentialType;
