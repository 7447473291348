import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import CloseButton from 'components/CloseButton';
import CustomSelectDate from 'components/DatePicker';
import LabeledCustomSelect from 'components/LabeledCustomSelect';
import LoadingButton from 'components/LoadingButton';

import BrowserCloseValidation from 'validation/browserCloseValidation';
import { useSnackbar } from 'notistack';

import useCreateEntitlements from 'hooks/services/useCreateEntitlements';
import useProducts from 'hooks/services/useProducts';
import useUpdateEntitlement from 'hooks/services/useUpdateEntitlement';
import moment from 'moment';
import EntitlmentError from 'schema/Entitlement-errors';
const useStyles = makeStyles((theme) => ({
  gridContainer: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  container: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: '0px 57px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  heading: {
    paddingTop: '29px',
  },
  textField: {
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flex: 'none',
    },
  },
  dateField: {
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flex: 'none',
    },
  },
  label: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    paddingBottom: '14px',
  },
  headingTitle: theme.appDirector.pageHeading,
  section: {
    padding: '10px 47px 0px 60px',
  },
  disabled: {
    '& .Mui-disabled': {
      backgroundColor: 'lightgrey',
      color: 'black',
    },
  },

  space: {
    paddingTop: '30px',
  },
  button: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '80px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px',
    },
  },

  required: {
    color: 'red',
  },
}));

const entitlementArray = [
  {
    name: 'Subscription',
    value: 'subscription',
  },
  {
    name: 'Units',
    value: 'units',
  },
  {
    name: 'Service',
    value: 'service',
  },
];

const CreateEntitlements = ({
  location: {
    state: { data, flow },
  },
}) => {
  const [entitlementData, setEntitlementData] = useState({
    ...data.entitlementData,
    productAdminEmail: data.adminEmailAddress,
  });
  const history = useHistory();
  const classes = useStyles();
  const [t] = useTranslation('entitlements');

  const { enqueueSnackbar } = useSnackbar();
  const [setDirty, setPristine] = BrowserCloseValidation();
  const { mutateAsync: entitlementCreate } = useCreateEntitlements();

  const [Sdate, setSdate] = useState(new Date());
  const [Edate, setEdate] = useState(new Date());
  const [productType, setProductType] = useState();
  const [product, setProduct] = useState();
  const [productArr, setProductArr] = useState();
  const [entitlementType, setEntitlementType] = useState(
    entitlementData?.entitlementType
  );
  const [isQtyDisabled, setIsQtyDisabled] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [quantity, setQuantitty] = useState(entitlementData?.qty);
  const [productNamesArr, setProductNamesArr] = useState();
  const [price, setPrice] = useState(entitlementData?.totalPrice);
  const [productId, setProductId] = useState(entitlementData?.productId);
  const [productCategoryId, setProductCategoryId] = useState();
  const [modifiedProductType, setModifiedProductType] = useState([]);
  const [unitPrice, setUnitPrice] = useState(entitlementData?.msrp);
  const [entitlementName, setEntitlementName] = useState(entitlementData?.name);
  const [isEditable, setEditable] = useState(true);
  const adminEmailAddress = data.adminEmailAddress;
  const { data: products } = useProducts();
  const { register, handleSubmit, getValues } = useForm({});
  const { mutateAsync: entitlementUpdater } = useUpdateEntitlement(
    data.entitlementId
  );
  const ProductType = Object.freeze({
    APP_DIRECTOR: 'App Director',
    APP_INSIGHTS: 'App Insights',
    CONSULTING_UNIT: 'Consulting Unit',
    APP_MIGRATOR: 'App Migrator',
  });
  const EntitlementType = Object.freeze({
    SUBSCRIPTION: 'subscription',
    UNITS: 'units',
    SERVICE: 'service',
  });

  const flattenedProducts =
    products &&
    products.pages.reduce((prev, curr) => {
      return [...prev, ...curr.productsCategories];
    }, []);

  const flattenedProductNames =
    flattenedProducts &&
    flattenedProducts.reduce((accum, curr) => {
      return [...accum, ...curr.products];
    }, []);

  const handleEntitlementType = (value) => {
    setEntitlementType(value);
  };
  const handleProductType = (value) => {
    setEntitlementName('');
    setUnitPrice(0);
    setPrice(0);
    setProduct('');
    setProductType(value);
    if (
      value === ProductType.APP_DIRECTOR ||
      value === ProductType.APP_INSIGHTS ||
      value === ProductType.APP_MIGRATOR
    ) {
      setEntitlementType(EntitlementType.SUBSCRIPTION);
    } else if (value === ProductType.CONSULTING_UNIT) {
      setEntitlementType(EntitlementType.UNITS);
    }
  };

  const handleChangeQuantity = (e, name) => {
    setEntitlementData({ ...entitlementData, [name]: e.target.value });
    setQuantitty(e.target.value);
  };

  const handleChangeUnitPrice = (e, name) => {
    setEntitlementData({ ...entitlementData, [name]: e.target.value });
    setUnitPrice(e.target.value);
  };

  const handleChangeEntitlementName = (e, name) => {
    setEntitlementData({ ...entitlementData, [name]: e.target.value });
    setEntitlementName(e.target.value);
  };

  const handleClickOpen = () => {
    history.goBack();
  };

  const handleChangeEntitlement = async (e, name) => {
    setEntitlementData({ ...entitlementData, [name]: e.target.value });
  };

  const setEnqueueSnackbar = (msg, snackerVariant) => {
    enqueueSnackbar(msg, {
      variant: snackerVariant,
      autoHideDuration: 3000,
    });
  };

  const handleChangeProduct = (value) => {
    setProduct(value);
    setEntitlementName(productType + ' - ' + value);
    setEditable(true);
    const product = productNamesArr.find(
      (product) => product.product_name === value
    );
    setProductId(product?.product_id);
    if (value === 'Trial') {
      setUnitPrice(0);
      setPrice(0);
      setProduct(value);
    } else {
      setUnitPrice(product?.msrp);
    }
  };

  useEffect(() => {
    if (
      entitlementType === EntitlementType.SUBSCRIPTION ||
      entitlementType === EntitlementType.SERVICE
    ) {
      setPrice(1 * unitPrice);
      setQuantitty(1);
      setIsQtyDisabled(true);
    } else {
      setPrice(quantity * unitPrice);
      setIsQtyDisabled(false);
    }
  }, [entitlementType]);

  useEffect(() => {
    if (flattenedProducts) {
      const modifiedProductType = flattenedProducts.map((PC) => {
        return {
          name: PC.product_category,
          value: PC.product_category,
        };
      });
      setModifiedProductType(modifiedProductType);

      const product = flattenedProducts.find(
        (product) => product.product_category === productType
      );
      setProductCategoryId(product?.product_category_id);
      const products = product ? product.products : [];
      setProductNamesArr(products);

      const modifiedProduct = products.map((product) => {
        return {
          id: product.product_id,
          name: product.product_name,
          value: product.product_name,
        };
      });
      setProductArr(modifiedProduct);
    }
  }, [productType]);

  useEffect(() => {
    setPrice(quantity * unitPrice);
  }, [product]);

  useEffect(() => {
    setPrice(quantity * unitPrice);
  }, [quantity]);

  useEffect(() => {
    setPrice(quantity * unitPrice);
  }, [unitPrice]);

  useEffect(() => {
    if (flow === 'Edit' && flattenedProducts) {
      const startDate = moment(entitlementData?.startDate);
      const endDate = moment(entitlementData?.endDate);
      setSdate(startDate._d);
      setEdate(endDate._d);

      const productCategory = flattenedProducts.find(
        (productCategory) =>
          productCategory?.product_category_id ===
          data.entitlementData.productCategoryId
      );
      setProductType(productCategory.product_category);

      const product = flattenedProductNames.find(
        (product) => product.product_id === data.entitlementData.productId
      );
      setProduct(product.product_name);
    }
  }, []);

  const onSubmit = async () => {
    const values = getValues();
    const customerId = data.customerId
      ? data.customerId
      : entitlementData?.customerId;
    const startDate = Sdate.toLocaleDateString('en-CA');
    const endDate = Edate.toLocaleDateString('en-CA');
    const qty = quantity;
    const name = values?.entitlementName;
    const status = 'ACTIVE';
    const totalPrice = price;
    const productAdminEmail = entitlementData?.productAdminEmail;
    const msrp = unitPrice;
    setIsUpdating(true);
    try {
      const newEntitlement = {
        customerId,
        productId,
        startDate,
        endDate,
        msrp,
        qty,
        status,
        name,
        totalPrice,
        productAdminEmail,
        entitlementType,
        productCategoryId,
      };
      if (flow === 'Create') {
        await entitlementCreate(newEntitlement);
        setEnqueueSnackbar(t('entitlements.create.snackBarMsg'), 'success');
        history.goBack();
      } else if (flow === 'Edit') {
        await entitlementUpdater(newEntitlement);

        setEnqueueSnackbar(t('entitlements.create.snackBarUdMsg'), 'success');
        history.goBack();
      }
      setPristine();
    } catch (e) {
      console.log(e);
      if (flow === 'Create') {
        if (e.message == EntitlmentError.E1.displayValue) {
          setEnqueueSnackbar(
            t('entitlements.create.snackBarErrorMsg_Entitlement'),
            'error'
          );
        } else {
          setEnqueueSnackbar(
            t('entitlements.create.snackBarErrorMsg'),
            'error'
          );
        }
      }
      if (flow === 'Edit') {
        if (e.message == EntitlmentError.E1.displayValue) {
          setEnqueueSnackbar(
            t('entitlements.create.snackBarErrorMsg_Entitlement'),
            'error'
          );
        } else {
          setEnqueueSnackbar(
            t('entitlements.update.snackBarErrorMsg'),
            'error'
          );
        }
      }
    }
    setIsUpdating(false);
  };

  const setDisableStatus = () => {
    if (
      productType &&
      product &&
      entitlementName &&
      entitlementType &&
      quantity &&
      Edate & Sdate
    ) {
      if (isUpdating) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <Grid container classes={{ container: classes.gridContainer }}>
      <Grid item container justifyContent="space-between">
        <span className={classes.headingTitle}>
          {entitlementData && flow === 'Edit'
            ? `Edit : ${entitlementData['name']}`
            : t('entitlements.create.title', { flow: `${flow}` })}
        </span>
        <div className={classes.closeButton}>
          <CloseButton onClick={handleClickOpen} id="closeButton" />
        </div>
      </Grid>
      <Grid className={classes.body}>
        <Grid item container className={classes.section} spacing={6}>
          <Grid item className={classes.dateField} xs={12}>
            <Grid item>
              <FormControl fullWidth>
                <LabeledCustomSelect
                  label={t('entitlements.tableHeaders.productEnt')}
                  id="productType"
                  name="productType"
                  placeholder="Select Product"
                  selectItems={modifiedProductType}
                  value={productType || ''}
                  required={true}
                  register={register}
                  onChange={(e) => {
                    handleProductType(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item className={classes.dateField} xs={12}>
            <Grid item>
              <FormControl fullWidth>
                <LabeledCustomSelect
                  label={t('entitlements.tableHeaders.productPlan')}
                  id="product"
                  name="product"
                  placeholder="Select Product"
                  selectItems={productArr ? productArr : []}
                  value={product || ''}
                  required={true}
                  register={register}
                  onChange={(e) => {
                    handleChangeProduct(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container className={classes.section} spacing={3}>
          <Grid item className={classes.textField} xs={12}>
            <Grid item className={classes.label}>
              <span>{t('entitlements.tableHeaders.name')}</span>
              <span className={classes.required}> *</span>
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <TextField
                  inputRef={register}
                  className={classes.disabled}
                  name="entitlementName"
                  placeholder={t('entitlements.tableHeaders.name')}
                  variant="outlined"
                  disabled={isEditable}
                  value={entitlementName}
                  onChange={(e) => {
                    handleChangeEntitlementName(e, 'name');
                    setDirty();
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item className={classes.dateField} xs={12}>
            <Grid item>
              <FormControl fullWidth>
                <LabeledCustomSelect
                  label={t('entitlements.tableHeaders.entitlementType')}
                  id="entitlementType"
                  name="entitlementType"
                  checkBox={true}
                  selectItems={entitlementArray}
                  value={entitlementType || ''}
                  required={true}
                  register={register}
                  onChange={(e) => {
                    handleEntitlementType(e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container className={classes.section} spacing={6}>
          <Grid item className={classes.dateField} xs={12}>
            <Grid item className={classes.label}>
              <span>{t('entitlements.tableHeaders.startDate')}</span>
              <span className={classes.required}> *</span>
            </Grid>
            <Grid item>
              <FormControl fullWidth id="start-date">
                <CustomSelectDate
                  inputRef={register}
                  selected={Sdate}
                  onChange={(date) => setSdate(date)}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item className={classes.dateField} xs={12}>
            <Grid item className={classes.label}>
              <span>{t('entitlements.tableHeaders.endDate')}</span>
              <span className={classes.required}> *</span>
            </Grid>
            <Grid item>
              <FormControl fullWidth id="end-date">
                <CustomSelectDate
                  inputRef={register}
                  selected={Edate}
                  onChange={(date) => setEdate(date)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container className={classes.section} spacing={6}>
          <Grid item className={classes.textField} xs={6}>
            <Grid item className={classes.label}>
              <span>{t('entitlements.tableHeaders.unitPrice')}</span>
              <span className={classes.required}> *</span>
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <TextField
                  inputRef={register}
                  name="msrp"
                  required
                  className={classes.textField}
                  placeholder={t('entitlements.tableHeaders.unitPrice')}
                  variant="outlined"
                  value={unitPrice}
                  onChange={(e) => {
                    handleChangeUnitPrice(e, 'msrp');
                    setDirty();
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item className={classes.textField} xs={6}>
            <Grid item className={classes.label}>
              <span>{t('entitlements.tableHeaders.qyt')}</span>
              <span className={classes.required}> *</span>
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <TextField
                  inputRef={register}
                  name="qty"
                  className={classes.textField}
                  placeholder={t('entitlements.tableHeaders.qyt')}
                  variant="outlined"
                  value={quantity}
                  disabled={isQtyDisabled}
                  onChange={(e) => {
                    handleChangeQuantity(e, 'qty');
                    setDirty();
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container className={classes.section} spacing={6}>
            <Grid item className={classes.textField} xs={12}>
              <Grid item className={classes.label}>
                <span>{t('entitlements.tableHeaders.totalPrice')}</span>
                <span className={classes.required}> *</span>
              </Grid>
              <Grid item>
                <FormControl fullWidth>
                  <TextField
                    inputRef={register}
                    name="price"
                    className={classes.textField}
                    id="price"
                    disabled={true}
                    placeholder={t('entitlements.tableHeaders.totalPrice')}
                    variant="outlined"
                    value={price >= 0 ? price : ''}
                    onChange={(e) => {
                      handleChangeEntitlement(e, 'price');
                      setDirty();
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item className={classes.textField} xs={12}>
              <Grid item className={classes.label}>
                <span>{t('entitlements.tableHeaders.productAdminEmail')}</span>
                <span className={classes.required}> *</span>
              </Grid>
              <Grid item>
                <FormControl fullWidth>
                  <TextField
                    inputRef={register}
                    name="productAdminEmail"
                    className={classes.textField}
                    id="productAdminEmail"
                    placeholder={t(
                      'entitlements.tableHeaders.productAdminEmail'
                    )}
                    variant="outlined"
                    value={entitlementData?.productAdminEmail}
                    onChange={(e) => {
                      handleChangeEntitlement(e, 'productAdminEmail');
                      setDirty();
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container className={classes.section} spacing={3}>
          <Grid item container className={classes.button}>
            <LoadingButton
              id="btn-create-entitlement"
              classes={{ root: classes.btn }}
              onClickHandler={handleSubmit(onSubmit)}
              updatingStatus={isUpdating}
              disableStatus={setDisableStatus()}
            >
              {entitlementData && flow === 'Edit'
                ? 'Update'
                : t('entitlements.create.saveBtn')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateEntitlements;
