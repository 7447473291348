import React from 'react';

const UploadIcon = ({ className, color }) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.61719 12.0151V1.70264"
      stroke="#0F5EF7"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M11.3672 5.45264L7.61719 1.70264L3.86719 5.45264"
      stroke="#0F5EF7"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      d="M0.585938 11.8359V14.6484H14.6484V11.8359"
      stroke="#0F5EF7"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
  </svg>
);

export default UploadIcon;
