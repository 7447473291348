import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DashboardWidget } from '../DashboardWidget';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  widget: {
    display: 'flex',
    background: '#EFF3FA',
    boxShadow: 'none',
  },
  widgetContent: {
    display: 'flex',
  },
  widgetValue: {
    flex: '1',
  },
  widgetNumber: {
    margin: '0',
    '@media (min-width: 1960px)': {
      fontSize: '45px',
    },
  },
  widgetTitle: {
    margin: '0',
    '@media (min-width: 1960px)': {
      fontSize: '25px',
    },
  },
  widgetIcon: {
    background: '#ffffff',
    borderRadius: '50%',
    border: 'none',
    height: '32px',
    width: '32px',
    padding: '7px',
  },
}));

export const DashboardNumberWidget = ({ number, title, icon }) => {
  const classes = useStyles();
  return (
    <DashboardWidget classes={{ widget: classes.widget }}>
      <div className={classes.widgetContent}>
        <div className={classes.widgetValue}>
          <Typography variant="h4" className={classes.widgetNumber}>
            {number}
          </Typography>
        </div>
        <div className={classes.widgetIcon}>{icon}</div>
      </div>
      <div className={classes.widgetValue}>
        <Typography variant="inherit" className={classes.widgetTitle}>
          {title}
        </Typography>
      </div>
    </DashboardWidget>
  );
};
