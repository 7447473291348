import CredentialType from 'schema/credential-type';

const CredentialTypeForIntegrations = {
  git: [
    CredentialType.KUBERNETES_SECRET_REF.enumKey,
    CredentialType.USER_PASSWORD.enumKey,
    CredentialType.SSH_PRIVATE_KEY.enumKey,
  ],
  image: [
    CredentialType.DOCKER_CONFIG_JSON.enumKey,
    CredentialType.KUBERNETES_SECRET_REF.enumKey,
  ],
  slack: [CredentialType.TOKEN.enumKey],
  sonar: [CredentialType.TOKEN.enumKey],
};

export default CredentialTypeForIntegrations;
