import { useQueryClient, useMutation } from 'react-query';

import api from 'services/Api';

const useCheckEmail = () => {
  const QueryClient = useQueryClient();

  const checkExistEmailUrl = `core/customers/v1/customers/checkEmail`;

  return useMutation(
    (optionalHeaders) =>
      api.post(checkExistEmailUrl, null, {}, optionalHeaders).then((data) => {
        return data.json();
      }),
    {
      onSuccess: async () => {
        QueryClient.invalidateQueries('success');
      },
    },
    {
      onError: async () => {
        console.log('error');
      },
    }
  );
};

export default useCheckEmail;