import { createSelector } from 'reselect';

export const selectCheckBoxInfo = (state) => state.checkBoxInfo;

export const selectCheckBoxInfoData = createSelector(
  [selectCheckBoxInfo],
  (checkInfo) => {
    return checkInfo;
  }
);
