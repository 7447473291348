import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CustomSelect from 'components/CustomSelect/index';
import CustomInfiniteSelect from 'components/CustomSelect/infiniteScroll';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
  },
  label: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    lineHeight: '20px',
    paddingBottom: '14px',
    color: 'rgba(0, 24, 71, 1)',
  },
  required: {
    color: 'red',
  },
}));

const LabeledCustomSelect = ({
  label,
  required,
  labelNotRequired,
  isInfinite,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {!labelNotRequired ? (
        <span className={classes.label}>
          {label}
          {required ? <span className={classes.required}> *</span> : ''}
        </span>
      ) : null}
      {isInfinite ? (
        <CustomInfiniteSelect {...rest} />
      ) : (
        <CustomSelect {...rest} />
      )}
    </div>
  );
};

export default LabeledCustomSelect;
