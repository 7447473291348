import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    height: '100%',
    maxHeight: '100%',
    marginBottom: '20px',
  },
  root: {
    marginBottom: 0,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 0,
      },
    },
    background: '#FFFFFF',
    boxShadow: '0px 1px 8px rgba(20, 46, 110, 0.1)',
    borderRadius: '10px',
  },
  textField: {
    flexGrow: 1,
    borderRadius: '10px',
  },
  sendButton: {
    backgroundColor: '#0F5EF7',
    color: '#FFFFFF',
    minWidth: '80px',
    height: '35px',
    borderRadius: '25px',
    textTransform: 'none',
    fontFamily: 'inherit',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#0F5EF7',
      color: '#FFFFFF',
      boxShadow: '0px 2px 6px 1px rgba(20, 46, 110, 0.2)',
    },
    '&:disabled': {
      backgroundColor: '#EBEBEB',
      color: '#5D5D5D',
    },
  },
}));

const CommentItem = ({
  addComment,
  addCommentButton,
  commentValue,
  setCommentValue,
}) => {
  const classes = useStyles();

  const isCommentEmpty = commentValue.trim() === '';

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item className={classes.textField}>
          <TextField
            variant="outlined"
            placeholder="Write your comment here. Press enter for a new line..."
            fullWidth
            multiline
            minRows={3}
            value={commentValue}
            onChange={(e) => {
              setCommentValue(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    onClick={addCommentButton}
                    id="commentButtonId"
                    disabled={isCommentEmpty}
                    className={classes.sendButton}
                  >
                    Send
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(CommentItem);
