// routes
export const ROUTE_ROOT = '/';
export const ROUTE_SIGN_IN = '/signin';
export const ROUTE_AUTH_CALLBACK = '/auth/callback/';
export const ROUTE_UN_AUTHORIZED = '/unauthorized';

export const ROUTE_DASHBOARD = '/dashboard';
export const ROUTE_ENTITLEMENTS = '/entitlements';
export const ROUTE_APPLICATIONS = '/applications';
export const ROUTE_CUSTOMERS = '/customers';
export const ROUTE_TICKETS = '/tickets';
export const ROUTE_INTEGRATIONS = '/integrations';
export const ROUTE_REGISTRIES = '/registries';
export const ROUTE_CREDENTIALS = '/credentials';
export const ROUTE_INSIGHTS = '/insights';
export const ROUTE_CLUSTERS = '/clusters';
export const ROUTE_PIPELINES = '/pipelines';
export const ROUTE_AUDITLOG = '/audit-log';
export const ROUTE_ADDONSERVICES = '/addon-services';
export const ROUTE_BILLINGANDPAYMENT = '/billing';
export const ROUTE_SUPPORT = '/support';
export const ROUTE_IAM = '/iam';
export const ROUTE_USERS = '/users';
export const ROUTE_USERPROFILE = '/userprofile';

//user-account-status
export const ACCOUNT_STATUS = {
  ALL: 'All',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PENDING: 'PENDING',
};

export const RANDOLI_USER_ACCESS_TOKEN = 'RANDOLI_USER_ACCESS_TOKEN';

export const PATH_CSP_AGGREGATOR_SERVICE = 'csp/aggregator';
export const PATH_TICKET_SERVICE = 'csp/ticket';
export const PATH_IAM_SERVICE = 'core/iam';
export const PATH_UPLOAD_SERVICE = 'core/files';
