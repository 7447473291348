import React from 'react';

import { Grid } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  widget: (props) => ({
    padding: '1.5em',
    background: props?.backgroundColor ? props.backgroundColor : '#FFFFFF',
    boxShadow: props?.isRemoveShadow
      ? '0 0 0 0'
      : '0px 1px 8px rgba(20, 46, 110, 0.1)',
    borderRadius: '8px',
  }),
  widgetContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  widgetHeader: {
    flex: 0,
    textAlign: 'center',
  },
  widgetTitle: {
    fontSize: '1rem',
    fontWeight: 'bold',
    '@media (min-width: 1960px)': {
      fontSize: '1.8rem',
    },
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: '0.3em',
    '@media (min-width: 1960px)': {
      fontSize: '3rem',
    },
  },
});

const NoDataCard = ({ message, backgroundColor, isRemoveShadow }) => {
  const classes = useStyles({ backgroundColor, isRemoveShadow });
  return (
    <Grid container justify="center" alignItems="center">
      <Paper className={classes.widget}>
        <div className={classes.widgetContent}>
          {message && (
            <Typography variant="h2" className={classes.widgetTitle}>
              <InfoOutlinedIcon className={classes.icon} />
              {message}
            </Typography>
          )}
        </div>
      </Paper>
    </Grid>
  );
};

export default NoDataCard;
