import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiIconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(() => ({
  btn: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: '0',
  },
}));

const IconButton = ({ id, onClickHandler, disabled, children, classes }) => {
  const localStyles = useStyles();

  return (
    <MuiIconButton
      id={id}
      onClick={onClickHandler}
      className={localStyles.btn}
      classes={{ root: classes && classes.root }}
      disabled={disabled}
    >
      {children}
    </MuiIconButton>
  );
};

export default IconButton;
