import React, { useState, useMemo } from 'react';

import { useTable } from 'react-table';
import memoize from 'memoize-one';

import MaTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';

import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';

import useHover from 'hooks/useHover';
import StatusType from 'schema/status-type';
import { useWindowSize } from 'hooks/useViewport';
import { Collapse } from '@material-ui/core';
import CustomerSummary from 'components/CustomerSummary/CustomerSummary';

let scrollBarSize;

const marginLeft = 3;

const useStyles = makeStyles((theme) => ({
  root: {
    borderSpacing: '0 8px',
  },
  container: {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 280px)',
    flex: 1,
    marginBottom: '40px',
  },
  head: {
    top: 0,
    zIndex: 10,
    position: 'sticky',
    borderBottom: '0px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#808CA3',
    // paddingLeft: '1.8em',
    // '&:first-child': {
    //   paddingLeft: '2.6em',
    // },
    '&:last-child': {
      paddingRight: '13em',
    },
  },
  thead1: {
    display: 'table',
    tableLayout: 'fixed',
    width: `calc(107% - ${scrollBarSize}px - ${marginLeft}px)`,
  },
  head1: {
    borderBottom: '0px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#808CA3',
    // '&:first-child': {
    //   paddingLeft: '2.5em',
    // },
    // '&:last-child': {
    //   paddingRight: '14.5em',
    // },
  },
  tbody: (props) => ({
    overflowY: 'auto',
    display: 'block',
    height: '',
  }),
  rowHeight: {
    '& .MuiTableCell-root': {
      padding: '0px 35px 0px 0px',
    },
  },
  row: {
    // display: 'table',
    width: '100%',
    tableLayout: 'fixed',
    marginTop: '8px',
    marginBottom: '8px',
    //marginLeft: `${marginLeft}px`,
    boxShadow: '0px 1px 8px rgba(20, 46, 110, 0.1)',
    borderRadius: '10px',
    overflowY: 'auto',
    height: '50px !important',
    transition: 'border-color 0.3s ease',
    border: '2px solid transparent',
    '&:hover': {
      borderColor: '#007bff',
    },
  },
  cell: {
    borderBottom: '0px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '19px',
    color: '#001847',
    paddingLeft: '1.5em',
    '&:first-child': {
      paddingLeft: '3em',
    },
    '&:last-child': {
      paddingRight: '1em',
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cellDeleted: {
    padding: '0px',
    borderBottom: '0px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#878787',

    '&:first-child': {
      paddingLeft: '2em',
    },
    '&:last-child': {
      paddingRight: '2em',
    },
  },
  textTruncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  textTruncateTextWrap: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const DataTable = ({
  setState,
  hiddenColumns,
  columns,
  data,
  onClickTableRow,
  children,
  setRowData,
  isNextPageLoading,
  hasNextPage,
  loadMore,
  textWrap,
  ...props
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
      onClickTableRow,
      initialState: {
        hiddenColumns:
          hiddenColumns?.length !== 0 ? hiddenColumns : [hiddenColumns],
      },
    });
  const classes = useStyles(props);
  const [selectedId, setSelectedId] = useState(false);
  // const windowSize = useWindowSize();
  // const heightToReduce = props?.tableHeightPercentage <= 50 ? 600 : 275;

  // const tableHeight = windowSize?.height - heightToReduce;

  const handleRowClick = (idx, rowData) => {
    // if (setState && setRowData) {
    //   setRowData(rowData);
    // }
    setSelectedId(idx);
    onClickTableRow && onClickTableRow(idx, rowData);
  };

  const createItemData = memoize((prepareRow, data) => ({
    prepareRow,
    selectedId,
    items: data,
    handleRowClick: handleRowClick,
    textWrap: textWrap,
  }));

  const itemData = createItemData(prepareRow, rows, selectedId);

  const loadMoreItems = isNextPageLoading ? () => {} : loadMore;

  const isItemLoaded = ({ index }) => !hasNextPage || index < rows.length;

  const itemCount = hasNextPage ? rows.length + 1 : rows.length;

  return (
    <div className={classes.container}>
      <MaTable
        {...getTableProps()}
        stickyHeader
        classes={{ root: classes.root }}
      >
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) =>
                column.render('Header') === 'Manage' ||
                column.render('Header') === 'Settings' ? (
                  <TableCell
                    {...column.getHeaderProps()}
                    className={
                      columns.length == 7 ? classes.head : classes.head1
                    }
                    style={{
                      textAlign: 'center',
                      paddingLeft: '20%',
                      width: column.render('width'),
                    }}
                  >
                    {column.render('Header')}
                  </TableCell>
                ) : (
                  <TableCell
                    {...column.getHeaderProps()}
                    className={
                      columns.length == 7 ? classes.head : classes.head1
                    }
                    style={{ width: column.render('width') }}
                  >
                    {column.render('Header')}
                  </TableCell>
                )
              )}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map(
            (row, index) =>
              prepareRow(row) || (
                <Row
                  index={index}
                  row={row}
                  {...row.getRowProps()}
                  selectedId={selectedId}
                  handleRowClick={handleRowClick}
                />
              )
          )}
        </TableBody>
      </MaTable>
    </div>
  );
};

const Row = ({ row, index, selectedId, handleRowClick, items, textWrap }) => {
  const classes = useStyles();
  const [hoverRef, isHovered] = useHover();

  return (
    <TableRow
      id={index}
      ref={hoverRef}
      classes={{
        root: classes.row,
        hover: classes.hover,
      }}
      key={index}
      hover
      selected={selectedId === index}
      {...row.getRowProps()}
      onClick={() =>
        handleRowClick(index, {
          state: row.state,
          original: row.original,
          index: row.index,
          values: row.values,
        })
      }
    >
      {row.cells.map((cell) => {
        return (
          <TableCell
            component="td"
            scope="row"
            {...cell.getCellProps()}
            className={
              textWrap ? classes.textTruncateTextWrap : classes.textTruncate
            }
          >
            {cell.render('Cell', { isHovered })}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default DataTable;
