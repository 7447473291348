import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { Button, Checkbox } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import {
  referenceInfoFetchSuccess,
  referenceInfoFetchError,
  referenceInfoFetchStart,
} from 'state/reference';
import { selectReferenceInfoData } from 'state/reference/selectors';
import { selectGeneralInfoData } from 'state/generalInfo/selectors';
import { selectBillingInfoData } from 'state/billingInfo/selectors';
import { visibleUpdateSuccess } from 'state/saveButton';
import BrowserCloseValidation from 'validation/browserCloseValidation';
import useCreate from 'hooks/services/useCreateCustomerSpec';
import { billingInfoFetchSuccess } from 'state/billingInfo';
import { generalInfoFetchSuccess } from 'state/generalInfo';
import { checkBoxInfoFetchSuccess } from 'state/checkBox';
import LoadingButton from 'components/LoadingButton';
import useEdit from 'hooks/services/useEditCustomerSpec';
import validate from 'validation/Crm';

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: '0px 57px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  heading: {
    paddingTop: '29px',
  },
  headingTitle: theme.appDirector.pageHeading,
  section: {
    paddingTop: '30px',
    flex: 1,
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  textField: {
    flex: 1,
  },
  errorTextField: {
    borderColor: 'red !important',
  },
  label: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    paddingBottom: '14px',
  },
  block: {
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingTop: '36px',
  },
  button: {
    width: '166px',
    height: '40px',
    marginBottom: '50px',
    marginLeft: '10px',
    marginRight: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      marginTop: '20px',
      marginLeft: '50px',
      marginRight: '0',
      flex: 1,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100px',
      marginTop: '20px',
      marginLeft: '50px',
      marginRight: '0',
      flex: 1,
    },
  },
  invalid: {
    color: 'red',
    fontSize: '12px',
  },
  tags: {
    paddingTop: '10px',
  },
  required: {
    color: 'red',
  },
  disabled: {
    '& .Mui-disabled': {
      backgroundColor: 'lightgrey',
      color: 'black',
    },
  },
}));

const CRMInfomation = ({ data, path, isEmpty, setVisible, ...props }) => {
  const [t] = useTranslation('project');
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const cachedBillingInfoData = useSelector(selectBillingInfoData);
  const cachedGeneralInfoData = useSelector(selectGeneralInfoData);
  const cachedReferenceInfoData = useSelector(selectReferenceInfoData);
  const [setDirty, setPristine] = BrowserCloseValidation();
  const [isCreating, setIsCreating] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isUpdating, setIsUpdating] = useState(false);

  const [referenceInfo, setReferenceInfo] = useState({
    crmReference: `${
      cachedReferenceInfoData?.referenceInfo?.crmReference ||
      data?.crmReference ||
      ''
    }`,
    accountingReference: `${
      cachedReferenceInfoData?.referenceInfo?.accountingReference ||
      data?.accountingReference ||
      ''
    }`,
  });

  const [errors, setErrors] = useState({});

  const { mutateAsync: customerSpecCreater, isError } = useCreate({});

  const { mutateAsync: customerSpecEditor, isError: editError } = useEdit(
    data?.customerId
  );

  const { register } = useForm({
    mode: 'onBlur',
  });

  const setEnqueueSnackbar = (msg, snackerVariant) => {
    enqueueSnackbar(msg, {
      variant: snackerVariant,
      autoHideDuration: 3000,
    });
  };

  const setDisableStatus = () => {
    if (isUpdating) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (props.path === 'create' || props.path === 'edit') {
      setVisible(false);
    }
  });

  useEffect(() => {
    dispatch(visibleUpdateSuccess({ visible: false, page: 'general_info' }));
  });

  const handleChange = (e, name) => {
    setReferenceInfo({ ...referenceInfo, [name]: e.target.value });
  };

  const addReferenceInfoAndSaveCustomer = () => {
    if (Object.keys(errors).length === 0) {
      dispatch(referenceInfoFetchStart());
      dispatch(referenceInfoFetchSuccess({ referenceInfo }));
      setPristine();
    } else {
      dispatch(referenceInfoFetchError({ errors }));
    }

    const addReferenceInfo = (ref) => (path === 'create' ? ref : '');

    const filteredReference = addReferenceInfo(referenceInfo);

    const payload = {
      ...cachedGeneralInfoData?.generalInfo,
      ...cachedBillingInfoData?.billingInfo,
      ...filteredReference,
    };
    const edit_payload = {
      ...cachedGeneralInfoData?.generalInfo,
      ...cachedBillingInfoData?.billingInfo,
      ...filteredReference,
      organizationId: data?.organizationId,
    };
    setIsUpdating(true);
    try {
      if (path === 'create') {
        customerSpecCreater(payload);
        !isError
          ? setEnqueueSnackbar('customer saved successfully', 'success')
          : setEnqueueSnackbar('customer saving faild', 'error');
        dispatch(referenceInfoFetchSuccess({}));
        dispatch(billingInfoFetchSuccess({}));
        dispatch(generalInfoFetchSuccess({}));
        dispatch(checkBoxInfoFetchSuccess({}));
        history.push({ pathname: '/customers' });
        // setTimeout(() => {
        //   window.location.reload(true);
        // }, 50);
      } else if (path === 'edit') {
        customerSpecEditor(edit_payload);
        !editError
          ? setEnqueueSnackbar('customer saved successfully', 'success')
          : setEnqueueSnackbar('customer saving faild', 'error');
        dispatch(referenceInfoFetchSuccess({}));
        dispatch(billingInfoFetchSuccess({}));
        dispatch(generalInfoFetchSuccess({}));
        dispatch(checkBoxInfoFetchSuccess({}));
        history.push({ pathname: '/customers' });
        // setTimeout(() => {
        //   window.location.reload(true);
        // }, 50);
      }
    } catch (e) {
      console.log(e);
    }
    setIsUpdating(false);
  };

  const addReferenceInfo = () => {
    if (Object.keys(errors).length === 0) {
      dispatch(referenceInfoFetchStart());
      dispatch(referenceInfoFetchSuccess({ referenceInfo }));
      setPristine();
    } else {
      dispatch(referenceInfoFetchError({ errors }));
    }
  };

  return (
    <Grid container classes={{ container: classes.container }}>
      <Grid item container className={classes.section} spacing={3}>
        <Grid item className={classes.textField}>
          <Grid item className={classes.label}>
            <span>
              CRM reference<span className={classes.required}> *</span>
            </span>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                className={classes.disabled}
                disabled={path === 'create' ? false : true}
                InputProps={
                  errors.crmReference && {
                    classes: {
                      notchedOutline: classes.errorTextField,
                    },
                  }
                }
                inputRef={register}
                id="crmReference"
                name="crmReference"
                placeholder="Enter CRM reference"
                variant="outlined"
                onChange={(e) => {
                  handleChange(e, 'crmReference');
                  setErrors(validate('crmReference', e.target.value));
                }}
                defaultValue={referenceInfo.crmReference}
                inputProps={{ maxLength: 100 }}
              />
              {errors.crmReference && (
                <p className={classes.invalid}>{errors.crmReference}</p>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid item className={classes.textField}>
          <Grid item className={classes.label}>
            <span>
              Accounting reference<span className={classes.required}> *</span>
            </span>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                className={classes.disabled}
                disabled={path === 'create' ? false : true}
                InputProps={
                  errors.accountingReference && {
                    classes: {
                      notchedOutline: classes.errorTextField,
                    },
                  }
                }
                inputRef={register}
                id="accountingReference"
                name="accountingReference"
                placeholder="Enter accounting reference"
                variant="outlined"
                onChange={(e) => {
                  handleChange(e, 'accountingReference');
                  setErrors(validate('accountingReference', e.target.value));
                }}
                defaultValue={referenceInfo.accountingReference}
                inputProps={{ maxLength: 100 }}
              />
              {errors.accountingReference && (
                <p className={classes.invalid}>{errors.accountingReference}</p>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container className={classes.block}>
        <LoadingButton
          id="btn-create-customer"
          classes={{ root: classes.btn }}
          onClickHandler={addReferenceInfoAndSaveCustomer}
          updatingStatus={isUpdating}
          disableStatus={
            referenceInfo.crmReference === '' ||
            referenceInfo.accountingReference === '' ||
            (errors && Object.keys(errors).length > 0) ||
            setDisableStatus()
          }
        >
          Save
        </LoadingButton>
        <Button
          className={classes.button}
          onClick={() => {
            addReferenceInfo();
            props.previousStep();
          }}
        >
          Back
        </Button>
      </Grid>
    </Grid>
  );
};

export default CRMInfomation;
