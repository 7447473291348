import { createSelector } from 'reselect';

export const selectLastLoc = (state) => state.lastLoc;

export const selectLastLocation = createSelector(
  [selectLastLoc],
  ({ lastLoc, lastLocState }) => {
    return { lastLoc, lastLocState };
  }
);
