import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  datePickerBox: {
    height: '40px',
    color: '#808CA3',
    fontStyle: 'normal',
    fontFamily: 'Nunito',
    fontWeight: 'normal',
    borderRadius: '4px',
    borderColor: 'rgba(181, 189, 233, 1)',
    borderWidth: '1px',
    boxShadow: 'none',
    padding: '10px 12px',
    borderStyle: 'solid',
    fontSize: '16px',
    width: '100%',
  },
  calender: {
    width: 'max-content',
  },
  wrapper: {
    width: '100%',
  },
}));

const CustomSelectDate = ({ onChange, selected }) => {
  const classes = useStyles();
  return (
    <DatePicker
      selected={selected}
      id= "datePicker"
      placeholderText="Select Date"
      dateFormat="yyyy-MM-dd"
      onChange={onChange}
      className={classes.datePickerBox}
      calendarClassName={classes.calender}
      wrapperClassName={classes.wrapper}
    />
  );
};

export default CustomSelectDate;
