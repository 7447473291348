import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { FormControl, Select, MenuItem } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    marginLeft: '5px',
  },
}));

const PrefixedSelect = ({
  children,
  disableUnderline = true,
  selectData,
  handleOnChange,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState('All');

  return (
    <div className={classes.container}>
      {children}
      <FormControl className={children ? classes.select : null}>
        <Select
          labelId="prfixed-select-label"
          id="prefixed-select"
          disableUnderline={disableUnderline}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            handleOnChange(e);
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem key="all" value="All">
            All
          </MenuItem>

          {selectData.map((data) => (
            <MenuItem
              key={`${data.id ? data.id : data.value}`}
              value={data.value ? data.value : data.name}
            >
              {data.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default PrefixedSelect;
