import { useInfiniteQuery } from 'react-query';

import api from 'services/Api';

const useCustomerTickets = (organizationId) => {
  const fetchTickets = async () => {
    
    try {
      
      const query = new URLSearchParams({
        'organization-id': organizationId.organizationId
      });
      const response = await api.get(
        `csp/aggregator/v1/tickets?${query.toString()}`
      );

      const tickets = await response.json();
      return tickets.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useInfiniteQuery(['tickets', organizationId], fetchTickets, {
    refetchOnWindowFocus: false,
  });
};

export default useCustomerTickets;