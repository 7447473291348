import { useMutation, useQueryClient } from 'react-query';

import api from 'services/Api';

const useCreateIntegration = () => {
  const QueryClient = useQueryClient();
  const integrationApi = `appdir/integrations/v1/integrations/`;

  return useMutation(
    (obj) =>
      api.post(integrationApi, JSON.stringify(obj)).then((data) => data.json()),
    {
      onSuccess: async () => {
        QueryClient.invalidateQueries('success');
      },
    },
    {
      onError: async () => {
        console.log('error');
      },
    }
  );
};

export default useCreateIntegration;
