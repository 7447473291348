import { useMutation, useQueryClient } from 'react-query';

import api from 'services/Api';

const useUpdateProject = (projectId) => {
  const QueryClient = useQueryClient();
  const projectUrl = `appdir/applications/v1/projects/${projectId}`;

  return useMutation(
    (obj) => api.put(projectUrl, JSON.stringify(obj)).then((x) => x.json()),
    {
      onSuccess: async () => {
        QueryClient.invalidateQueries();
      },
    },
    {
      onError: async () => {
        console.log('error');
      },
    }
  );
};

export default useUpdateProject;
