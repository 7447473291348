import { useMutation, useQueryClient } from 'react-query';

import api from 'services/Api';
import { PATH_TICKET_SERVICE } from '../../constants';


const useAddComment = ({entitlementId,ticketId}) => {  
    const QueryClient = useQueryClient();
    const addComment = `${PATH_TICKET_SERVICE}/v1/tickets/${entitlementId}/${ticketId}/comments`;
  
    return useMutation(
      (obj) =>
        api.post(addComment, JSON.stringify(obj)).then((data) => data.json()),
      {
        onSuccess: async () => {
          QueryClient.invalidateQueries();
        },
      },
      {
        onError: async () => {
          console.log('error commenting');
        },
      },
     
    );
};

export default useAddComment;