import React from 'react';

const UploadIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.85219 8.73094C6.55125 8.09687 5.90469 7.65625 5.15625 7.65625H2.65625C1.62063 7.65625 0.78125 8.49563 0.78125 9.53125V12.0312L2.03125 12.6562L2.34375 17.0312H5.78125"
      stroke="#0F5EF7"
      stroke-miterlimit="10"
    />
    <path
      d="M3.90625 5.78125C4.94178 5.78125 5.78125 4.94178 5.78125 3.90625C5.78125 2.87072 4.94178 2.03125 3.90625 2.03125C2.87072 2.03125 2.03125 2.87072 2.03125 3.90625C2.03125 4.94178 2.87072 5.78125 3.90625 5.78125Z"
      stroke="#0F5EF7"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      d="M13.46 8.73094C13.7609 8.09687 14.4075 7.65625 15.1559 7.65625H17.6559C18.6915 7.65625 19.5309 8.49563 19.5309 9.53125V12.0312L18.2809 12.6562L17.9684 17.0312H14.5309"
      stroke="#0F5EF7"
      stroke-miterlimit="10"
    />
    <path
      d="M16.4062 5.78125C17.4418 5.78125 18.2812 4.94178 18.2812 3.90625C18.2812 2.87072 17.4418 2.03125 16.4062 2.03125C15.3707 2.03125 14.5312 2.87072 14.5312 3.90625C14.5312 4.94178 15.3707 5.78125 16.4062 5.78125Z"
      stroke="#0F5EF7"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      d="M12.0312 19.5312H8.28125L7.96875 14.5312L6.40625 14.2188V10.1562C6.40625 8.77562 7.52562 7.65625 8.90625 7.65625H11.4062C12.7869 7.65625 13.9062 8.77562 13.9062 10.1562V14.2188L12.3438 14.5312L12.0312 19.5312Z"
      stroke="#0F5EF7"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      d="M10.1562 5.78125C11.537 5.78125 12.6562 4.66196 12.6562 3.28125C12.6562 1.90054 11.537 0.78125 10.1562 0.78125C8.77554 0.78125 7.65625 1.90054 7.65625 3.28125C7.65625 4.66196 8.77554 5.78125 10.1562 5.78125Z"
      stroke="#0F5EF7"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
  </svg>
);

export default UploadIcon;
