import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { useMediaQuery } from '@material-ui/core';

import LegendIcon from 'components/DashboardLegendIcon';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  widget: {
    padding: '1.5em',
    background: '#FFFFFF',
    boxShadow: ' 0px 1px 8px rgba(20, 46, 110, 0.1)',
    borderRadius: '8px',
    height: '100%',
    width: '100%',
  },
  billingLegendKube: {
    color: '#414D55',
    fontWeight: 'normal',
    fontSize: '0.813rem',
    lineHeight: '20px',
    paddingLeft: '10px',
    letterSpacing: '0.416667px',
    textTransform: 'uppercase',
    '@media (min-width: 1960px)': {
      fontSize: '1.5rem',
    },
  },
  billingLegendValue: {
    color: 'black',
    fontWeight: 'normal',
    fontSize: '0.813rem',
    lineHeight: '20px',
    padding: '10px',
    letterSpacing: '0.416667px',
  },
  estimatedBillingKubeLabel: {
    color: '#808CA3',
    margin: '1% 20%',
    display: 'block',
  },
  legendLabel: {
    marginBottom: '5%',
  },
  widgetTitle: {
    fontSize: '1.125rem',
    lineHeight: '20px',
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
  list: {
    flexGrow: 1,
    marginTop: '15px',
  },
  listItem: {
    fonstSize: '1rem',
    padding: '0 15%',
    margin: '0px',
  },
  rowDiv: {
    display: 'flex',
    flexDirection: 'row',
  },
  table: {
    width: '50%',
    margin: 'auto',
  },
  tableCellLable: {
    borderBottom: '0rem',
    padding: '1.2% 0',
    width: 'auto',
    '@media (min-width: 1960px)': {
      padding: '1.4% 0',
    },
  },
  tableCellValue: {
    paddingLeft: '0.5rem',
    padding: '1.2% 0',
    width: '10%',
    borderBottom: '0rem',
    '@media (min-width: 1960px)': {
      fontSize: '1.5rem',
      paddingLeft: '1rem',
      padding: '1.4% 0',
    },
  },
  barDiv: {
    '@media (max-width: 1330px)': {
      height: '16em',
    },
  },
  test: {
    '@media (min-width: 1960px)': {
      padding: '4em',
    },
  },
}));

const DoughnutChart = ({ labels, data, colors, dataAll, total, type }) => {
  const [t] = useTranslation('dashboard');
  const matches = useMediaQuery('(max-width:1330px)');
  const classes = useStyles();
  const [displayData, setDisplayData] = useState([]);

  useEffect(() => {
    if (type === 'pipeline-runs') {
      setDisplayData(dataAll?.Details);
    }
    if (type === 'deployments') {
      setDisplayData(dataAll?.deploymentDetails);
    }
  }, [type, dataAll]);

  const setLabel = (dataToSetLabel) => {
    if (type === 'pipeline-runs') {
      return dataToSetLabel?.DisplayValue;
    }
    if (type === 'deployments') {
      return dataToSetLabel?.provider;
    }
  };

  const setValue = (dataToSetValue) => {
    if (type === 'pipeline-runs') {
      return dataToSetValue?.Value;
    }
    if (type === 'deployments') {
      return dataToSetValue?.deploymentsCount;
    }
  };

  const toFontString = (font) => {
    return (
      (font.style ? font.style + ' ' : '') +
      (font.weight ? font.weight + ' ' : '') +
      font.size +
      'px ' +
      font.family
    );
  };

  const centerLabel = {
    id: 'custom_label',
    beforeDraw: (chart, args, options) => {
      const {
        ctx,
        chartArea: { left, top, right, bottom },
      } = chart;
      ctx.save();
      const labels = [
        {
          text: total,
          font: {
            family: 'Nunito',
            lineHeight: 40,
            size: 28,
            style: 'normal',
            weight: 'bold',
            color: '#001847',
          },
        },
        {
          text: t('dashboard.pipelineRuns.total'),
          font: {
            family: 'Nunito',
            lineHeight: 20,
            size: 14,
            style: 'normal',
            weight: '600',
            color: '#808CA3',
          },
        },
      ];
      var innerLabels = [];
      labels.forEach(function (label) {
        var text =
          typeof label.text === 'function' ? label.text(chart) : label.text;
        var innerLabel = {
          text: text,
          color: label.font.color,
          font: label.font,
        };
        innerLabels.push(innerLabel);
      });

      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';

      // The center of the inner circle
      var centerX = (left + right) / 2;
      var centerY = (top + bottom) / 2;

      // The top Y coordinate of the text area
      var topY = centerY - 50 / 2;

      var i;
      var ilen = innerLabels.length;
      var currentHeight = 0;
      for (i = 0; i < ilen; ++i) {
        ctx.fillStyle = innerLabels[i].color;
        ctx.font = toFontString(innerLabels[i].font);
        // The Y center of each line
        var lineCenterY =
          topY + innerLabels[i].font.lineHeight / 2 + currentHeight;
        currentHeight += innerLabels[i].font.lineHeight;

        // Draw each line of text
        ctx.fillText(innerLabels[i].text, centerX, lineCenterY);
      }
      ctx.restore();
    },
  };

  //plugin to display outer label
  const outsideLabel = {
    id: 'custom_label_outer',
    beforeDraw: (chart, args, options) => {
      const {
        ctx,
        chartArea: { left, top, right, bottom },
      } = chart;

      ctx.save();
      var outerLabels = [];
      dataAll.deploymentDetails.forEach(function (label) {
        var percentage =
          (label.deploymentsCount / dataAll?.totalDeployments) * 100;
        var text = percentage.toFixed(0) + '%';
        var outerLabel = {
          text: text,
          color: label.color,
        };
        outerLabels.push(outerLabel);
      });

      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';

      var centerX = (left + right) / 2;
      var centerY = (top + bottom) / 2;

      var i;
      var ilen = outerLabels.length;
      var X = 0,
        Y = 0;

      for (i = 0; i < ilen; ++i) {
        ctx.fillStyle = outerLabels[i].color;
        ctx.font = 'bold 13px Nunito';

        if (X === 0 && Y === 0) {
          X = right - 20;
          Y = centerY - 45;
        } else if (X > centerX) {
          X = centerX;
          Y = bottom - 20;
        } else {
          X = left + 20;
          Y = centerY - 45;
        }

        ctx.fillText(outerLabels[i].text, X, Y);
      }
      ctx.restore();
    },
  };

  const dataPipelineRuns = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: colors,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: matches ? false : true,
    plugins: {
      legend: {
        display: false,
      },
    },
    radius: '80%',
    cutout: '75%',
    scales: {
      x: {
        grid: {
          color: 'white',
        },
        display: false,
      },
      y: {
        grid: {
          color: 'white',
        },
        display: false,
      },
    },
    animation: {
      duration: 0,
    },
    hover: {
      animationDuration: 0,
    },
    responsiveAnimationDuration: 0,
  };

  const plugins = [centerLabel];

  const SetColor = (index) => {
    if (index < colors.length) {
      return colors[index];
    } else {
      return colors[index % colors.length];
    }
  };

  return (
    <>
      <Grid
        item
        xs={6}
        container
        justify="center"
        alignItems="flex-start"
        classes={{ root: classes.test }}
      >
        {matches ? (
          <div className={classes.barDiv}>
            <Doughnut
              data={dataPipelineRuns}
              options={options}
              plugins={plugins}
            />
          </div>
        ) : (
          <Doughnut
            data={dataPipelineRuns}
            options={options}
            plugins={plugins}
          />
        )}
      </Grid>
      <Grid item lg={6} container justify="center" alignItems="center">
        <Grid item md className={classes.legendLabel}>
          <TableContainer>
            <Table className={classes.table}>
              {displayData?.map((chartData, index) => (
                <TableRow key={index}>
                  <TableCell
                    component="th"
                    align="left"
                    scope="row"
                    className={classes.tableCellLable}
                  >
                    <div className={classes.rowDiv}>
                      <div
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: 'auto',
                        }}
                      >
                        <Typography
                          noWrap
                          variant="inherit"
                          className={classes.billingLegendKube}
                        >
                          <LegendIcon color={SetColor(index)} />
                          &nbsp;&nbsp;{setLabel(chartData)}
                        </Typography>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    component="th"
                    align="left"
                    className={classes.tableCellValue}
                  >
                    {setValue(chartData)}
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default DoughnutChart;
