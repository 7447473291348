import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import RemoveRedEyeOutlinedIcon from '@material-ui/icons/RemoveRedEyeOutlined';
import { Button } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: 0,
  },
  watcherBtn: {
    width: '137px',
    height: '35px',
    background: '#EEF4FF',
    borderRadius: '27px',
    display: 'flex',
    textTransform: 'uppercase',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '15px',
    lineHeight: '15px',
    alignItems: 'center',
    color: '#5C7BB6',
  },
}));

const Watchers = ({ watchers, selectedWatchers }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [watchersArr, setWatchersArr] = React.useState([]);

  React.useEffect(() => {
    setWatchersArr(
      watchers.map((watcher) => {
        if (
          selectedWatchers &&
          selectedWatchers.length > 0 &&
          selectedWatchers.filter(function (e) {
            return e.userId === watcher.userId;
          }).length > 0
        ) {
          return { ...watcher, isChecked: true };
        } else {
          return { ...watcher, isChecked: false };
        }
      })
    );
  }, [watchers]);

  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (event, index) => {
    let updatedWatchersArr = [...watchersArr];
    updatedWatchersArr[index] = {
      ...updatedWatchersArr[index],
      isChecked: event.target.checked,
    };
    selectedWatchers.push(updatedWatchersArr[index]);

    setWatchersArr(updatedWatchersArr);
  };

  const handleClose = () => {
    setAnchorEl(null);
    watchersArr
      .filter((watcher) => watcher.isChecked)
      .map((watcher) => watcher.userId);
  };

  return (
    <>
      <Button
        className={classes.watcherBtn}
        onClick={handleMenu}
        aria-haspopup="true"
        endIcon={<RemoveRedEyeOutlinedIcon style={{ color: '#5C7BB6' }} />}
      >
        Watchers
      </Button>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: '20em',
            width: '15em',
          },
        }}
      >
        {watchersArr.map((watcher, index) => (
          <MenuItem
            key={watcher.userId}
            userId={watcher.userId}
            onClick={(event) => handleChange(event, index)}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={watcher.isChecked}
                  icon={<RadioButtonUncheckedRoundedIcon />}
                  checkedIcon={<CheckCircleRoundedIcon />}
                />
              }
              label={watcher.userName}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default Watchers;
