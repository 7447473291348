import { Enumify } from 'enumify';

class ReposAndRegistryTypes extends Enumify {
  static IMAGE_REGISTRY = new ReposAndRegistryTypes({
    get displayValue() {
      return 'image';
    },
    get value() {
      return 'IMAGE_REGISTRY';
    },
  });

  static GIT = new ReposAndRegistryTypes({
    get displayValue() {
      return 'git';
    },
    get value() {
      return 'GIT';
    },
  });

  static SONAR = new ReposAndRegistryTypes({
    get displayValue() {
      return 'sonar';
    },
    get value() {
      return 'SONAR';
    },
  });

  static _ = this.closeEnum();

  static getDisplayValueOf(value) {
    const index = this.enumValues.findIndex((x) => x.value === value);
    if (index >= 0) {
      return this.enumValues[index].displayValue;
    }
    return undefined;
  }

  constructor(props) {
    super();
    Object.defineProperties(this, Object.getOwnPropertyDescriptors(props));
  }
}

export default ReposAndRegistryTypes;
