import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  isUpdating: false,
  deleteApplication: false,
};

const deleteApplicationSlice = createSlice({
  name: 'deleteApplication',
  initialState: INITIAL_STATE,
  reducers: {
    deleteApplicationUpdateStart(state, action) {
      state.isUpdating = true;
      state.deleteApplication = false;
    },
    deleteApplicationUpdateSuccess(state, { payload: { deleteApp } }) {
      state.isUpdating = false;
      state.deleteApplication = deleteApp;
    },
    deleteApplicationUpdateError(state, action) {
      state.isUpdating = false;
      state.deleteApplication = false;
    },
  },
});

export const { name, actions, reducer } = deleteApplicationSlice;
export const {
  deleteApplicationUpdateStart,
  deleteApplicationUpdateSuccess,
  deleteApplicationUpdateError,
} = actions;
export default reducer;
