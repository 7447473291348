import React from 'react';

const ViewIcon = ({ className, color, strokeWidth }) => (
  <svg
    className={className}
    viewBox="0 0 18 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 5.701C1 5.701 4.134 1 8.835 1C13.536 1 16.67 5.701 16.67 5.701C16.67 5.701 13.536 10.402 8.835 10.402C4.134 10.402 1 5.701 1 5.701Z"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="square"
      stroke-width={strokeWidth}
    />
    <path
      d="M8.83499 7.268C10.5658 7.268 11.969 5.86486 11.969 4.134C11.969 2.40314 10.5658 1 8.83499 1C7.10413 1 5.70099 2.40314 5.70099 4.134C5.70099 5.86486 7.10413 7.268 8.83499 7.268Z"
      stroke={color}
      strokeMiterlimit="10"
      strokeLinecap="square"
      stroke-width={strokeWidth}
    />
  </svg>
);

export default ViewIcon;
