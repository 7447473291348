import validateEmail from 'validation/emailValidationRules';
import validateUrl from 'validation/urlValidationRules';

export default function validate(fieldName, value) {
  var errors = {};

  if (fieldName === 'name') {
    if (!value) {
      errors.name = 'Credential name is required';
    } else if (/[^- a-zA-Z0-9]/.test(value)) {
      errors.name =
        'Invalid credential name. Valid values are letters, numbers, hyphens and spaces';
    }
  }

  if (fieldName === 'email') {
    let emailErrors = validateEmail(value);
    if (emailErrors != null && value) {
      errors.email = emailErrors;
    }
  }

  if (fieldName === 'hostname') {
    let urlErrors = validateUrl(value);
    if (urlErrors != null || /\s/g.test(value)) {
      errors.hostname = 'Invalid host name';
    }
  }

  return errors;
}
