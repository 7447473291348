import { useInfiniteQuery } from 'react-query';

import api from 'services/Api';

const useFilterAssignedTickets = () => {
  const fetchTickets = async () => {
    const query = new URLSearchParams({});

    try {
      const response = await api.get(`csp/ticket/v1/tickets/assigned/`);

      const tickets = await response.json();

      return tickets.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return useInfiniteQuery(['tickets/assigned'], fetchTickets, {
    refetchOnWindowFocus: false,
    // getFetchMore: (lastGroup) => lastGroup.meta.nextPage,
  });
};

export default useFilterAssignedTickets;
