import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  isFetching: false,
  lastLoc: {},
  lastLocState: {},
};

const locationSlice = createSlice({
  name: 'lastLoc',
  initialState: INITIAL_STATE,
  reducers: {
    lastLocFetchStart(state, action) {
      state.isFetching = true;
      state.lastLoc = {};
      state.lastLocState = {};
    },
    lastLocFetchSuccess(state, { payload: { lastLoc, lastLocState } }) {
      state.isFetching = false;
      state.lastLoc = lastLoc;
      state.lastLocState = lastLocState;
    },
    lastLocFetchError(state, action) {
      state.isFetching = false;
      state.lastLoc = {};
      state.lastLocState = {};
    },
  },
});

export const { name, actions, reducer } = locationSlice;
export const { lastLocFetchStart, lastLocFetchSuccess, lastLocFetchError } =
  actions;
export default reducer;
