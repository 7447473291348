import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  isFetching: false,
  checkBoxInfo: [],
  errors: [],
};

const checkBoxInfoSlice = createSlice({
  name: 'checkBoxInfo',
  initialState: INITIAL_STATE,
  reducers: {
    checkBoxInfoFetchStart(state, action) {
      state.isFetching = true;
      state.checkBoxInfo = [];
    },
    checkBoxInfoFetchSuccess(state, { payload: { checkBoxInfo } }) {
      state.isFetching = false;
      state.checkBoxInfo = checkBoxInfo;
    },
    checkBoxInfoFetchError(state, { payload: { errors } }) {
      state.errors = errors;
      state.isFetching = false;
      state.checkBoxInfo = [];
    },
  },
});

export const { name, actions, reducer } = checkBoxInfoSlice;
export const {
  checkBoxInfoFetchStart,
  checkBoxInfoFetchSuccess,
  checkBoxInfoFetchError,
} = actions;
export default reducer;