import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import StepWizard from 'react-step-wizard';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import CloseButton from 'components/CloseButton';
import AlertDialog from 'components/AlertDialog';
import StepperNav from 'components/Stepper';
import BillingInformation from './billing-information';
import GeneralInfo from './general-info';
import CRMInfomation from './crm-info';
import { billingInfoFetchSuccess } from 'state/billingInfo';
import { generalInfoFetchSuccess } from 'state/generalInfo';
import { referenceInfoFetchSuccess } from 'state/reference';
import { checkBoxInfoFetchSuccess } from 'state/checkBox';
import useFindCustomer from 'hooks/services/useFindCustomer';

const useStyles = makeStyles((theme) => ({
  accordianRoot: {
    width: '450px',
    padding: '5px 0px 5px 0px',
  },
  gridContainer: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  heading: {
    paddingTop: '29px',
  },
  headingTitle: theme.appDirector.pageHeading,
  section: {
    paddingTop: '40px',
  },
  appType: {
    height: '34px',
    background: '#EEF4FF',
    borderRadius: '40px',
  },
  saveButton: {
    marginRight: '20px',
  },
  saveButtonInEdit: {
    marginRight: '5px',
  },
  deleteBtn: {
    marginRight: '20px',
  },
  deleteBtnIcon: {
    width: '29px',
    height: '29px',
    color: '#808CA3',
  },
}));

const getSteps = () => {
  return ['General Information', 'Billing Information', 'References'];
};

const CreateCustomer = ({
  location: {
    state: { customerId, path },
  },
}) => {
  const [t] = useTranslation('customers');
  const classes = useStyles();
  const steps = getSteps();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(false);
  const dispatch = useDispatch();

  const { data: customerData } = useFindCustomer({ customerId: customerId });

  // const { register, handleSubmit, errors } = useForm({
  //   criteriaMode: 'all',
  //   mode: 'onChange',
  //   reValidateMode: 'onChange',
  // });

  const handleClickOpen = () => {
    value ? setOpen(true) : history.push({ pathname: '/customers' });
  };

  const handleOk = () => {
    dispatch(referenceInfoFetchSuccess({}));
    dispatch(billingInfoFetchSuccess({}));
    dispatch(generalInfoFetchSuccess({}));
    dispatch(checkBoxInfoFetchSuccess({}));
    history.push({ pathname: '/customers' });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container classes={{ container: classes.gridContainer }}>
      <Grid item container justifyContent="space-between">
        <>
          <span className={classes.headingTitle}>
            {path === 'create' ? 'Create Customer' : 'Edit Customer'}
          </span>
          <CloseButton onClick={handleClickOpen} id="closeButton" />
          <AlertDialog
            title={t('customers.create.alertTitle')}
            buttonConfirmText={t('customers.create.alertOkBtn')}
            buttonCancelText={t('customers.create.alertCancelBtn')}
            open={open}
            handleOk={handleOk}
            handleClose={handleClose}
          >
            {t('customers.create.alertMsg')}
          </AlertDialog>
        </>
      </Grid>
      {path !== 'create' ? (
        customerData && (
          <Grid item className={classes.section}>
            <StepWizard isLazyMount="true" nav={<StepperNav steps={steps} />}>
              <GeneralInfo
                setValue={setValue}
                data={customerData}
                path={path}
              />
              <BillingInformation path={path} data={customerData} />
              <CRMInfomation data={customerData} path={path} />
            </StepWizard>
          </Grid>
        )
      ) : (
        <Grid item className={classes.section}>
          <StepWizard isLazyMount="true" nav={<StepperNav steps={steps} />}>
            <GeneralInfo setValue={setValue} data={customerData} path={path} />
            <BillingInformation path={path} data={customerData} />
            <CRMInfomation data={customerData} path={path} />
          </StepWizard>
        </Grid>
      )}
    </Grid>
  );
};

export default CreateCustomer;
