import React from 'react';
import { useSelector } from 'react-redux';

import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import './styles.css';
import UnAuthorized from 'services/UnAuthorized';
import NavigationBar from 'components/NavigationBar';
import PrivateRoute from 'services/PrivateRoute';
import Dashboard from 'pages/Dashboard';
import Iam from 'pages/Iam';

import {
  ROUTE_UN_AUTHORIZED,
  ROUTE_DASHBOARD,
  ROUTE_CUSTOMERS,
  ROUTE_IAM,
  ROUTE_TICKETS,
  ROUTE_USERPROFILE,
} from '../../constants';

import UserProfile from 'pages/UserProfile';
import Customers from 'pages/Customers';
import Tickets from 'pages/Tickets';
import { selectRoles } from 'state/auth/selectors';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    flexDirection: 'column',
    height: '94%',
    padding: '30px 40px 0 30px',
    overflowY: 'auto', // this prevents scrollbar squashing the contents
    overflowX: 'hidden',
    '@media (min-width: 1960px)': {
      padding: '100px 40px 0 30px',
    },
  },
  shiftContent: {
    marginLeft: theme.appDirector.drawer.openWidth,
    transition: 'margin-left 300ms cubic-bezier(0.23, 1, 0.32, 1)',
    '@media (min-width: 1960px)': {
      marginLeft: '400px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
      transition: 'margin-left 300ms cubic-bezier(0.23, 1, 0.32, 1)',
    },
  },
  unShiftContent: {
    marginLeft: theme.appDirector.drawer.closeWidth,
    transition: 'margin-left 300ms cubic-bezier(0.23, 1, 0.32, 1)',
    '@media (min-width: 1960px)': {
      marginLeft: '165px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
      paddingBottom: '40px',
      transition: 'margin-left 300ms cubic-bezier(0.23, 1, 0.32, 1)',
    },
  },
}));

const AppRouter = () => {
  const location = useLocation();
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const roles = useSelector(selectRoles);

  const handleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <NavigationBar
        isDrawerOpen={drawerOpen}
        onDrawerOpen={handleDrawerOpen}
      />
      <main
        className={clsx(
          classes.content,
          drawerOpen ? classes.shiftContent : classes.unShiftContent
        )}
      >
        <Switch>
          <Route exact path={ROUTE_UN_AUTHORIZED} component={UnAuthorized} />
          <PrivateRoute
            path={ROUTE_DASHBOARD}
            requiredRoles={['admin_portal_view_dashboard']}
          >
            <Dashboard />
          </PrivateRoute>
          <PrivateRoute
            path={ROUTE_CUSTOMERS}
            requiredRoles={['admin_portal_manage_entitlements']}
          >
            <Customers />
          </PrivateRoute>
          <PrivateRoute
            path={ROUTE_IAM}
            requiredRoles={['admin_portal_manage_iam']}
          >
            <Iam />
          </PrivateRoute>
          <PrivateRoute
            path={ROUTE_TICKETS}
            requiredRoles={['admin_portal_manage_tickets']}
          >
            <Tickets />
          </PrivateRoute>
          <PrivateRoute
            path={ROUTE_USERPROFILE}
            requiredRoles={['admin_portal_manage_iam']}
          >
            <UserProfile />
          </PrivateRoute>

          {roles.includes('admin_portal_view_dashboard') ? (
            <Redirect
              to={{ pathname: ROUTE_DASHBOARD, state: { from: location } }}
            />
          ) : (
            <Redirect
              to={{ pathname: ROUTE_CUSTOMERS, state: { from: location } }}
            />
          )}
        </Switch>
      </main>
      {/* <ReactQueryDevtools initialIsOpen /> */}
    </>
  );
};

export default AppRouter;
